import { CONTACTLIST_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, permissionPresets, services, sizes, states } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import info from '@/components/misc/info'
import HeadTitle from '@/components/misc/HeadTitle'
import preloader from '@/components/misc/preloader'
import pagination from '@/components/misc/pagination'
import FileManager from '@/components/file/manager'
import RefreshButton from '@/components/button/refresh'
import ContactsItem from '@/components/services/contacts/item'
import ContactsFilter from '@/components/services/contacts/filter'
import ContactsDialogs from '@/components/services/contacts/dialogs'
import eventsHistoryButton from '@/components/services/eventsHistory/button'
import overflowString from '@/components/misc/overflowString'
import sticker from '@/components/misc/sticker'
import buttonFavorite from '@/components/button/favorite'

function renderDivider(h, trigger) {
  if (trigger) {
    return h('g-divider')
  }

  return h('div')
}

function renderPreloader(h) {
  return h(
    preloader,
    {
      props: {
        value: true,
        size: 12
      }
    }
  )
}

function renderTitle(h, item) {
  return h(
    overflowString,
    {
      class: 'mlpx-7',
      props: {
        value: item.title,
        font: { size: 13 },
        color: item.id === this.$route.params.id ? colors.primary : colors.text
      }
    }
  )
}
function renderType(h, item) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.contactLists}.types.${item.type}`),
        size: sizes.tiny,
        color: colors.grey
      }
    }
  )
}
function renderSearchGeneratorItem(h, item) {
  return h(
    'router-link',
    {
      class: {
        'search-generator__item': true,
        'search-generator__item--active': item.id === this.$route.params.id
      },
      props: {
        to: {
          name: `${services.contacts}.single`,
          params: { id: item.id }
        }
      },
      key: item.id
    },
    [
      h(
        'div',
        {
          class: 'fjcsb grid-gap--4 pxpx-2'
        },
        [
          renderTitle.call(this, h, item),
          renderType.call(this, h, item)
        ]
      )
    ]
  )
}
function renderSearchGenerator(h) {
  return h(
    'search-generator',
    {
      scopedSlots: {
        create: () => {
          return h(
            components['list-item'],
            {
              props: {
                color: colors.secondary,
                label: this.getTranslate(`${services.contactLists}.buttons.create`),
                dense: true
              },
              on: {
                click: () => {
                  this.dialogType = `${services.contactLists}.create`
                  this.showDialog = true
                }
              }
            }
          )
        },
        filter: ({ filter }) => {
          return h(
            components.select,
            {
              props: {
                value: filter.type,
                label: this.getTranslate(`${services.contactLists}.labels.type`),
                items: CONTACTLIST_TYPES.map(type => {
                  return {
                    title: this.getTranslate(`${services.contactLists}.types.${type}`),
                    value: type
                  }
                }),
                clearable: true,
                mode: inputModes.outline,
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  filter.type = event
                }
              }
            }
          )
        },
        default: ({ item }) => renderSearchGeneratorItem.call(this, h, item)
      }
    }
  )
}

function renderEventsHistoryButton(h) {
  if (this.checkPermissions(`advanced.${services.contacts}.get`, permissionPresets.true)) {
    return h(
      eventsHistoryButton,
      {
        props: {
          label: this.viewport.breakpoint.xl ? true : false,
          entityId: this.$route.params.id,
          rounded: true
        }
      }
    )
  }
}
function renderRemoveContactListButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate(`${services.contactLists}.buttons.remove`) : undefined,
        icon: icons.delete,
        color: colors.error,
        mode: buttonModes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contactLists}.tooltips.remove`) }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData[services.contactLists].get.data.id
          this.dialogType = `${services.contactLists}.remove`
          this.showDialog = true
        }
      }
    }
  )
}
function renderAddContactButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate(`${services.contacts}.buttons.add`) : undefined,
        icon: icons.exposure_plus_1,
        color: colors.primary,
        mode: buttonModes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contacts}.tooltips.add`) }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData[services.contactLists].get.data.id
          this.dialogType = `${services.contacts}.create`
          this.showDialog = true
        }
      }
    }
  )
}
function renderImportContactsButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate('misc.buttons.import') : undefined,
        icon: icons.cloud_upload,
        mode: buttonModes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contactLists}.tooltips.import`) }
        }
      ],
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}
function renderFilterButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: icons.filter_list,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'repeat(5, auto)' }
    },
    [
      renderEventsHistoryButton.call(this, h),
      renderRemoveContactListButton.call(this, h),
      renderAddContactButton.call(this, h),
      renderImportContactsButton.call(this, h),
      renderFilterButton.call(this, h)
    ]
  )
}

function renderFavoriteButton(h, data) {
  return h(
    buttonFavorite,
    {
      props: {
        id: data.id,
        service: services.contactLists
      }
    }
  )
}
function renderContactListsTitle(h) {
  const { id, title, type } = this.restData[services.contactLists].get.data

  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'repeat(2, auto) 1fr' }
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.edit,
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.contactListId = id
              this.dialogType = `${services.contactLists}.update`
              this.showDialog = true
            }
          }
        }
      ),

      renderFavoriteButton.call(this, h, {
        id,
        title
      }),

      h(
        sticker,
        {
          props: {
            value: title,
            label: this.getTranslate(`${services.contactLists}.types.${type}`),
            maxWidth: 'fit-content',
            copy: true
          }
        }
      )
    ]
  )
}
function renderContactListsContent(h) {
  if (this.restData[services.contactLists].get.state === states.ready) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '1fr auto' }
      },
      [
        renderContactListsTitle.call(this, h),
        renderButtons.call(this, h)
      ]
    )
  } else if (this.restData[services.contactLists].get.state === states.loading) {
    return h(
      'div',
      {
        class: 'pa-3'
      },
      [ renderPreloader.call(this, h) ]
    )
  }
}
function renderContactListsFilter(h) {
  if (this.showFilter) {
    return h(
      ContactsFilter,
      {
        props: {
          value: this.restData[services.contacts].find.filter
        },
        on: {
          input: event => {
            this.restData[services.contacts].find.filter = event
          }
        }
      }
    )
  }
}
function renderContactsListByViewport(h) {
  const contactsEvents = [ `${services.contacts}.update`, `${services.contacts}.remove` ]
  const on = contactsEvents.reduce((result, event) => {
    result[event] = e => {
      this.contactId = e
      this.dialogType = event
      this.showDialog = true
    }

    return result
  }, {})
  switch (true) {
    case this.viewport.breakpoint.smDown: {
      return h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        this.restData[services.contacts].find.data.map(item => {
          return h(
            ContactsItem,
            {
              props: { value: item },
              on
            }
          )
        })
      )
    }

    case this.viewport.breakpoint.mdUp:
    default: {
      return h(
        'g-table',
        {
          props: {
            headers: this.headers,
            hideHead: this.restData[services.contacts].find.pagination.total < 1 || this.viewport.breakpoint.smDown,
            items: this.restData[services.contacts].find.data,
            value: this.restData[services.contacts].find.order,
            noDataText: this.getTranslate('misc.no.data.text')
          },
          on: {
            input: event => {
              this.restData[services.contacts].find.order = event
            }
          },
          scopedSlots: {
            items: ({ item }) => {
              return h(
                ContactsItem,
                {
                  props: { value: item },
                  on
                }
              )
            }
          }
        }
      )
    }
  }
}
function renderContactsList(h) {
  if (this.restData[services.contacts].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'pa-5'
      },
      [ renderPreloader.call(this, h) ]
    )
  } else if (this.restData[services.contacts].find.state === states.ready) {
    if (this.restData[services.contacts].find.pagination.total > 0) {
      return renderContactsListByViewport.call(this, h)
    }
  }

  return h(components.empty)
}
function renderPagination(h, trigger) {
  if (trigger) {
    const columns = []
    if (this.restData[services.contacts].find.pagination.total > 0) {
      columns.push('auto')
    }
    columns.push('38px')

    return h(
      'div',
      {
        class: 'grid grid-gap--8 fjcfe faic pa-2',
        style: { 'grid-template-columns': columns.join(' ') }
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.contacts].find.pagination,
              service: services.contacts
            },
            on: {
              input: event => {
                this.restData[services.contacts].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            props: {
              state: this.restData[services.contacts].find.state,
              shortClickMethod: () => this.rest[services.contacts].find(),
              longClickMethod: () => this.rest[services.contacts].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}
function renderContactsContent(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderDivider.call(this, h),
      renderPagination.call(this, h, true),
      renderDivider.call(this, h, this.restData[services.contacts].find.pagination.total),
      renderContactsList.call(this, h),
      renderDivider.call(this, h, this.restData[services.contacts].find.pagination.total),
      renderPagination.call(this, h, !!this.restData[services.contacts].find.pagination.total)
    ]
  )
}
function renderContent(h) {
  if (this.$route.params.id) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 ff'
      },
      [
        renderContactListsContent.call(this, h),
        renderContactListsFilter.call(this, h),
        renderContactsContent.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderContactsDialogs(h) {
  return h(
    ContactsDialogs,
    {
      props: {
        id: this.contactId || this.contactListId,
        type: this.dialogType,
        show: this.showDialog,
        ListId: this.restData[services.contactLists].get.data && this.restData[services.contactLists].get.data.id
      },
      on: {
        show: event => {
          if (event === false) {
            this.contactId = undefined
            this.contactListId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}
function renderImportDialogHeader(h) {
  return h(
    HeadTitle,
    {
      props: { value: this.getTranslate(`${services.contacts}.dialogs.titles.import`) },
      slot: 'header'
    }
  )
}
function renderImportDialogBody(h) {
  switch (this.importContactsResult.state) {
    case states.loading: {
      return h(
        'div',
        {
          class: 'grid grid-gap--0 pb-3'
        },
        [
          h(
            'div',
            {
              class: 'pa-3'
            },
            [ renderPreloader.call(this, h) ]
          ),

          h(
            'div',
            {
              domProps: { innerHTML: this.getTranslate(`${services.contacts}.contents.import.${states.loading}`) }
            }
          )
        ]
      )
    }
    case states.success: {
      return h(
        info,
        {
          style: {
            width: '100%'
          },
          props: {
            icon: icons.done,
            color: colors.success,
            value: this.getTranslate(`${services.contacts}.contents.import.${states.success}`, { count: this.importContactsResult.total })
          }
        }
      )
    }
    case states.error: {
      return h(
        info,
        {
          props: {
            icon: icons.warning,
            color: colors.warning,
            value: this.getTranslate(`${services.contacts}.contents.import.${states.error}`)
          }
        }
      )
    }
  }
}
function renderImportDialogFooter(h) {
  switch (this.importContactsResult.state) {
    case states.success:
    case states.error: {
      return h(
        'div',
        {
          class: 'faic fjcfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showImportContacts = false
                  this.importContactsResult.state = undefined
                  this.importContactsResult.total = 0
                }
              }
            }
          )
        ]
      )
    }
  }
}
function renderImportDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showImportContacts,
        closeOnClick: false,
        closeOnEsc: false,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showImportContacts = event
        }
      }
    },
    [
      renderImportDialogHeader.call(this, h),
      h(
        'div',
        {
          class: 'fc faic fjcfc px-2'
        },
        [ renderImportDialogBody.call(this, h) ]
      ),

      renderImportDialogFooter.call(this, h)
    ]
  )
}

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        show: this.showFileManager,
        flat: true,
        readonly: true,
        embedded: true,
        types: [ 'csv', 'xls', 'xlsx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.showImportContacts = true
          this.importContacts(event.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.contacts
    },
    [
      renderSearchGenerator.call(this, h),
      renderContent.call(this, h),
      renderContactsDialogs.call(this, h),
      renderImportDialog.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
