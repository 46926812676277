import { get } from 'lodash'

import { colors, modes } from '@/utils'

import button from '@/components/button'

export default function(h) {
  const text = get(this.value, 'data.sending.payload.button.text')
  const url = get(this.value, 'data.sending.payload.button.url')

  if (text && url) {
    return h(
      button,
      {
        props: {
          label: text,
          mode: modes.flat,
          color: colors.secondary,
          icon: 'link'
        },
        on: {
          click: () => {
            window.open(url, '_blank')
          }
        }
      }
    )
  }
}
