import proxy from '@sigma-legacy-libs/g-proxy'

import { currentTab, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [
    proxy({ type: 'object' }),
    currentTab('general')
  ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      editable: false,
      passwordVisibility: true
    }
  },

  computed: {
    tabs() {
      const result = [ 'general', services.settingsUser, services.tariffs ]
      const servicesFotTabs = [
        services.tokens,
        services.requisites,
        services.recurrentPayments
      ]
      for (const service of servicesFotTabs) {
        if (this.checkPermissions(`advanced.${service}.get`, permissionPresets.meUp)) {
          result.push(service)
        }
      }
      if (this.checkPermissions(`advanced.${services.billings}.find`, permissionPresets.meUp)) {
        result.push('referral')
      }

      return result
    }
  },

  render
}
