import { borderStyles, colors, getPrice, icons, modes, permissionPresets, services } from '@/utils'
import { paymentsColors, paymentsIcons } from '@/components/services/payments/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'
import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import button from '@/components/button'

function renderStatus(h) {
  return h(
    sticker,
    {
      props: {
        value: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.payments}.statuses.${this.value.status}`),
        label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.payments}.labels.status`),
        icon: paymentsIcons[this.value.status],
        iconTooltip: this.getTranslate(`${services.payments}.statuses.${this.value.status}`),
        color: paymentsColors[this.value.status],
        borderStyle: borderStyles.dashed
      }
    }
  )
}

function renderOwner(h) {
  if (this.viewport.breakpoint.smUp) {
    if (this.checkPermissions('advanced.users.get', permissionPresets.true)) {
      return h(
        sticker,
        {
          props: {
            value: this.value.Owner || this.value.OwnerId,
            label: this.getTranslate(`${services.payments}.labels.owner`),
            pathToTitle: 'username',
            icon: icons.person,
            color: colors.grey
          }
        }
      )
    }
  }
}

function renderSumIcon(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    squircle,
    {
      props: { color: colors.grey },
      slot: 'icon'
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: this.value.currency || this.globalSettings.billings.currency,
            color: colors.grey
          }
        }
      )
    ]
  )
}

function renderSum(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount),
        label: this.viewport.breakpoint.xs ? this.value.Owner.username : this.getTranslate(`${services.payments}.labels.amount`),
        borderStyle: borderStyles.dashed
      }
    },
    [ renderSumIcon.call(this, h) ]
  )
}
function renderMethod(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          label: this.getTranslate(`${services.recurrentPayments}.labels.method`),
          iconSVG: this.value.method,
          color: colors[this.value.method],
          iconTooltip: this.getTranslate(`${services.payments}.methods.${this.value.method}`)
        }
      }
    )
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.payments}__panel-info`
    },
    [
      renderStatus.call(this, h),
      renderSum.call(this, h),
      renderMethod.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderPaymentLogsItem(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.true)) {
    return h(
      'g-list-item',
      {
        props: {
          label: this.getTranslate(`${services.payments}.buttons.paymentLogs`),
          dense: true
        },
        scopedSlots: {
          prefix: () => {
            return h(
              squircle,
              {
                props: {
                  icon: icons.history,
                  color: colors.grey
                }
              }
            )
          }
        },
        on: {
          click: () => {
            this.$emit('paymentLogs', this.value.id)
          }
        }
      }
    )
  }
}

function renderBillingsItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate(`${services.payments}.buttons.billings`),

        dense: true,
        disabled: !Array.isArray(this.value.Billings) || Array.isArray(this.value.Billings) && !this.value.Billings.length
      },
      scopedSlots: {
        prefix: () => {
          return h(
            squircle,
            {
              props: {
                icon: icons.request_page,
                color: colors.grey
              }
            }
          )
        }
      },
      on: {
        click: () => {
          this.$emit('billings', this.value.Billings)
        }
      }
    }
  )
}

function renderMenu(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.true)) {
    return h(
      'g-menu',
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp },
        props: {
          rounded: true,
          placement: 'bottom-end',
          offsetDistance: 8
        }
      },
      [
        h(
          button,
          {
            props: {
              icon: icons.more_vert,
              mode: modes.flat
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: {
              rounded: true,
              dense: true
            }
          },
          [
            renderPaymentLogsItem.call(this, h),
            renderBillingsItem.call(this, h)
          ]
        )
      ]
    )
  }
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-panel']: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp,
        [`${services.payments}__panel`]: true,
        [`${services.payments}__panel--admin`]: this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.true)
      }
    },
    [
      renderInfo.call(this, h),
      renderMenu.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
