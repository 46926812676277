import { isSingleDate } from '@/utils'

import time from '@/components/filter/date/time'
import sticker from '@/components/misc/sticker'
import createdAtPreset from '@/components/misc/createdAtPreset'

function renderPresetPicker(h) {
  if (this.presets) {
    return h(
      createdAtPreset,
      {
        props: {
          value: this.preset
        },
        on: {
          input: this._presetInputHandler
        }
      }
    )
  }
}
function renderDatePicker(h) {
  return h(
    'g-card',
    {
      props: {
        title: this.getTranslate('filter.date.title'),
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-date-picker',
        {
          props: {
            value: this.date,
            localeTag: this.locale,
            disabled: this.disabled,
            min: this.min,
            max: this.max
          },
          on: {
            input: this._inputHandler,
            pick: this._pickHandler
          }
        }
      )
    ]
  )
}
function renderTimePicker(h) {
  return h(
    time,
    {
      props: {
        value: this.proxy,
        disabled: this.disabled,
        isSingleDate: isSingleDate(this.proxy.date)
      },
      on: {
        input: value => {
          this.proxy = value
        }
      }
    }
  )
}

function renderPickers(h) {
  return h(
    'div',
    {
      class: 'filter-date-time__pickers'
    },
    [
      renderPresetPicker.call(this, h),
      renderDatePicker.call(this, h),
      renderTimePicker.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: {
        'filter-date-time__content': true
      }
    },
    [ renderPickers.call(this, h) ]
  )
}

function renderActivator(h) {
  return h(
    sticker,
    {
      props: {
        value: this.activatorContent,
        label: this.getTranslate(`filter.date.labels.${this.label}`),
        icon: 'date_range',
        color: 'grey',
        backgroundColor: 'white',
        disabled: this.disabled,
        width: '100%',
        to: { service: undefined }
      },
      slot: 'activator'
    }
  )
}

export default function(h) {
  if (this.asIs) {
    return renderContent.call(this, h)
  }

  return h(
    'g-menu',
    {
      props: {
        closeOnContentClick: false,
        rounded: true
      }
    },
    [
      renderActivator.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
