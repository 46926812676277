export const getIconSVGByType = type => {
  switch (type) {
    case 'smsping': return 'sms'
    case 'apisender': return 'whatsapp'
    case 'mobileid_mts_push':
    case 'mobileid_mts_push_sms': return 'mobileid'
    case 'incoming_tg_bot': return 'telegram'
    case 'whatsappchat':
    case 'viberchat':
    case 'vkchat':
    case 'telegramchat':
    case 'facebookchat': return 'chat'
    default: return type
  }
}
