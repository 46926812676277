import Vue from 'vue'

import { modes, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.id) {
    return h(
      button,
      {
        props: {
          icon: 'edit',
          mode: modes.flat
        },
        on: {
          click: () => {
            Vue.router.push({
              name: `${services.eventsHistory}.single`,
              params: { id: this.id }
            })
          }
        }
      }
    )
  }
}
