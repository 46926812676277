import { buttonModes, colors, components, getPrice, icons, permissionPresets, popperPlacements, services } from '@/utils'
import { paymentStatusColors, paymentStatusIcons } from '@/components/services/payments/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'
import squircle from '@/components/icon/squircle'
import button from '@/components/button'
import owner from '@/components/serviceTemplate/item/owner'

function renderStatus(h) {
  return h(
    sticker,
    {
      props: {
        value: this.viewport.size.width > 500 ? this.getTranslate(`${services.payments}.statuses.${this.value.status}`) : undefined,
        label: this.viewport.size.width > 500 ? this.getTranslate(`${services.payments}.labels.status`) : undefined,
        icon: paymentStatusIcons[this.value.status],
        iconTooltip: this.getTranslate(`${services.payments}.statuses.${this.value.status}`),
        color: paymentStatusColors[this.value.status]
      }
    }
  )
}
function renderSum(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount, { currency: this.currency }),
        label: this.viewport.breakpoint.xs ? this.value.Owner.username : this.getTranslate(`${services.payments}.labels.amount`),
        iconSVG: this.currency,
        color: colors.grey
      }
    }
  )
}
function renderMethod(h) {
  if (this.viewport.size.width > 610) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          label: this.getTranslate(`${services.recurrentPayments}.labels.method`),
          iconSVG: this.value.method,
          iconTooltip: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          color: colors[this.value.method]
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.viewport.size.width > 820) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.payments}-panel__info`]: true,
        [`${services.payments}-panel__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderStatus.call(this, h),
      renderSum.call(this, h),
      renderMethod.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderPaymentLogsItem(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`)) {
    return h(
      components['list-item'],
      {
        props: {
          label: this.getTranslate(`${services.payments}.buttons.paymentLogs`),
          dense: true
        },
        scopedSlots: {
          prefix: () => {
            return h(
              squircle,
              {
                props: {
                  icon: icons.history,
                  color: colors.grey
                }
              }
            )
          }
        },
        on: {
          click: () => {
            this.$emit('paymentLogs', this.value.id)
          }
        }
      }
    )
  }
}
function renderBillingsItem(h) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(`${services.payments}.buttons.billings`),
        dense: true,
        disabled: !Array.isArray(this.value.Billings) || Array.isArray(this.value.Billings) && !this.value.Billings.length
      },
      scopedSlots: {
        prefix: () => {
          return h(
            squircle,
            {
              props: {
                icon: icons.request_page,
                color: colors.grey
              }
            }
          )
        }
      },
      on: {
        click: () => {
          this.$emit(services.billings, this.value.Billings)
        }
      }
    }
  )
}
function renderMenu(h) {
  return h(
    components.menu,
    {
      class: { 'hover-child': this.viewport.breakpoint.mdUp },
      props: {
        rounded: true,
        placement: popperPlacements['bottom-end'],
        offsetDistance: 8
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.more_vert,
            mode: buttonModes.flat
          },
          slot: 'activator'
        }
      ),
      h(
        components.list,
        {
          props: {
            rounded: true,
            dense: true
          }
        },
        [
          renderPaymentLogsItem.call(this, h),
          renderBillingsItem.call(this, h)
        ]
      )
    ]
  )
}
function renderActions(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`)) {
    return h(
      'div',
      {
        class: 'ff fjcfe w--100'
      },
      [ renderMenu.call(this, h) ]
    )
  }
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-panel']: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp,
        [`${services.payments}-panel`]: true,
        [`${services.payments}-panel--admin`]: this.checkPermissions(`advanced.${services.payments}.get`)
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
