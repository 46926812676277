import { colors, generateUniqueKey } from '@/utils'

function renderItem(h, { name, meta }) {
  const { navIcon, navType, params, color, iconColor } = meta || {}
  const active = this.$route.matched.some(({ name: routeName }) => routeName === name)
  const props = {
    color,
    icon: navIcon,
    iconColor: iconColor || 'grey',
    label: this.getTranslate(`${meta.pathToTitle || name}.title`),
    active,
    dense: true
  }
  const nativeOn = {}

  if (!navType || navType !== 'group') {
    props.to = {
      name,
      params
    }

    if (this.viewport.breakpoint.smDown) {
      nativeOn.click = () => this.$emit('click', false)
    }
  }

  const scopedSlots = {}
  for (const slot of [ 'prefix', 'suffix' ]) {
    if (meta[slot]) {
      scopedSlots[slot] = () => {
        return h(
          require(`@/components/services/${name}/${meta[slot]}`).default,
          {
            props: {
              color: props.active ? colors.primary : colors.grey
            }
          }
        )
      }
    }
  }

  return h(
    'g-list-item',
    {
      props,
      scopedSlots,
      nativeOn,
      key: generateUniqueKey()
    }
  )
}

function renderItemByType(h, { name, meta, children }) {
  const { permission, permissionAccept, navType } = meta || {}

  if (permission) {
    if (!this.checkPermissions(permission, permissionAccept)) {
      return
    }
  }

  switch (navType) {
    case 'subheader': {
      return h(
        'g-subheader',
        {
          class: 'plpx-40',
          props: { dense: true }
        },
        [ this.getTranslate(`commons.subheader.${name}`) ]
      )
    }

    case 'group': {
      return h(
        'g-list-group',
        {
          props: {
            icon: meta.navIcon,
            iconColor: 'grey',
            label: this.getTranslate(`${name}.title`),
            active: !!~this.$route.path.split('/').indexOf(name),
            dense: true
          }
        },
        children.map(child => renderItem.call(this, h, child))
      )
    }

    case 'item':
    default: {
      return renderItem.call(
        this,
        h,
        {
          name,
          meta
        }
      )
    }
  }
}

export default function(h) {
  return h(
    'g-list',
    {
      props: { dense: true }
    },
    this.routes.map(({ name, meta, children }) => {
      return renderItemByType.call(
        this,
        h,
        {
          name,
          meta,
          children
        }
      )
    })
  )
}
