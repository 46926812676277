import { buttonModes, colors, getUrlToStorage, icons, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import player from '@/components/file/item/player'

function renderContentByType(h) {
  switch (this.$file.type) {
    case 'jpg': {
      return h(
        'div',
        {
          class: 'file__type-image',
          style: `background-image: url(${getUrlToStorage([ this.OwnerId, this.value.id ])})`
        }
      )
    }
    case 'wav': {
      return h(
        player,
        {
          props: {
            value: this.value
          }
        }
      )
    }
    default: {
      return h(
        squircle,
        {
          class: 'file__type-icon',
          props: {
            iconSVG: 'folder-file',
            size: sizes.medium,
            color: this.$file.color
          }
        }
      )
    }
  }
}

function renderDownloadButton(h) {
  if (this.$file.type !== 'wav' && !this.readonly) {
    return h(
      button,
      {
        class: 'file__type-download',
        props: {
          icon: icons.file_download,
          mode: buttonModes.flat,
          color: colors.primary
        },
        on: {
          click: () => {
            window.location.href = getUrlToStorage([ this.OwnerId, this.value.id ])
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        file__type: true,
        'file__type--readonly': this.readonly
      }
    },
    [
      renderContentByType.call(this, h),
      renderDownloadButton.call(this, h)
    ]
  )
}
