import { colors, getPrice, services } from '@/utils'

import SingleLink from '@/components/misc/SingleLink'
import OneRowString from '@/components/misc/OneRowString'
import TwoRowString from '@/components/misc/TwoRowString'
import RefreshButton from '@/components/button/refresh'
import squircle from '@/components/icon/squircle'

function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        fw: this.viewport.breakpoint.smDown
      }
    },
    [
      this.$slots.title,

      h('div', { class: 'ff' }),

      h(
        RefreshButton,
        {
          props: {
            disabled: this.downloadable,
            label: this.getTranslate('sendings.buttons.refresh'),
            tooltip: this.getTranslate('sendings.tooltips.refresh'),
            state: this.loading,
            shortClickMethod: () => this.getReport(),
            longClickMethod: () => this.getReport()
          }
        }
      )
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderTotalTr(h, item, index) {
  return h(
    'tr',
    {
      class: 'caption',
      style: { [`border-${index === 0 ? 'bottom' : 'top'}`]: '1px solid darkgrey' },
      key: `sendings-groups-003-total-${index === 0 ? 'up' : 'bottom'}`
    },
    [
      h(
        'th',
        {
          class: 'pl-2 pr-2 w--100',
          attrs: { colspan: 3 }
        },
        this.getTranslate('misc.total')
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countDelivered}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countDelivered / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countSeen}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countSeen / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countFailed}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countFailed / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countSent}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countSent / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${this.total.countTotal}`
              }
            }
          )
        ]
      ),

      h('th'),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: getPrice(this.total.countSumCompleted),
                second: `${this.total.countSumMultiplierCompleted}`
              }
            }
          )
        ]
      ),
      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: getPrice(this.total.countSumRefunded),
                second: `${this.total.countSumMultiplierRefunded}`
              }
            }
          )
        ]
      ),
      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: getPrice(this.total.countSum),
                second: `${this.total.countSumMultiplier}`
              }
            }
          )
        ]
      )
    ]
  )
}

function renderTr(h, item, index) {
  return h(
    'tr',
    {
      key: `sendings-groups-003-${index}`
    },
    [
      h(
        'td',
        {
          class: 'pl-2 pr-2 w--100'
        },
        [ h(SingleLink, { props: { value: item.Owner } }) ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: { first: item.type }
            },
            [
              h(
                SingleLink,
                {
                  props: {
                    value: item.Tariff,
                    service: 'tariffs'
                  },
                  slot: 'second'
                }
              )
            ]
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: item.operator_group
              }
            },
            [
              h(
                SingleLink,
                {
                  props: {
                    value: item.Provider,
                    service: 'providers'
                  },
                  slot: 'second'
                }
              )
            ]
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${item.message_parts_delivered}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${item.message_parts_seen}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${item.message_parts_failed}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${item.message_parts_sent}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${item.message_parts_all}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: getPrice(item.billing_tariff_price)
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: getPrice(item.billing_sum_pending_complete),
                second: `${item.billing_multiplier_sum_pending_complete}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(TwoRowString, {
            props: {
              first: getPrice(item.billing_sum_refunded_invalid),
              second: `${item.billing_multiplier_sum_refunded_invalid}`
            }
          })
        ]
      ),

      h(
        'td',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(TwoRowString, {
            props: {
              first: getPrice(item.billing_sum_all),
              second: `${item.billing_multiplier_sum_all}`
            }
          })
        ]
      )
    ]
  )
}

function renderItems(h, item, index) {
  if (index === 0) {
    return [ renderTotalTr.call(this, h, item, index), renderTr.call(this, h, item, index) ]
  } else if (index === this.report.length - 1) {
    return [ renderTr.call(this, h, item, index), renderTotalTr.call(this, h, item, index) ]
  } else {
    return [ renderTr.call(this, h, item, index) ]
  }
}

function renderTable(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.headers,
          hideHead: this.report.length < 1,
          items: this.report,
          value: this.order,
          sortLocal: true,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.order = event
          }
        },
        scopedSlots: {
          items: ({ item, index }) => {
            return renderItems.call(this, h, item, index)
          }
        }
      }
    )
  }
}

function renderPanels(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.report.map(item => {
        return h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: () => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header caption pl-2 pr-2'
                  },
                  [
                    h(
                      squircle,
                      {
                        class: 'mr-2',
                        props: {
                          iconSVG: item.type,
                          color: colors[item.type]
                        }
                      }
                    ),

                    h(
                      'div',
                      {
                        class: 'fc ff'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'faic'
                          },
                          [
                            h(
                              'div',
                              {
                                class: 'text--grey mr-1'
                              },
                              this.getTranslate('sendings.headers.count_total')
                            ),

                            item.message_parts_all
                          ]
                        ),

                        h(SingleLink, { props: { value: item.Owner } })
                      ]
                    ),

                    h(
                      'div',
                      {
                        class: 'fc'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'fjcfe facfe faic'
                          },
                          [
                            item.message_parts_delivered,

                            h(
                              'g-icon',
                              {
                                class: 'ml-1',
                                props: {
                                  value: 'done',
                                  size: 16,
                                  color: 'success'
                                }
                              }
                            )
                          ]
                        ),
                        h(
                          'div',
                          {
                            class: 'fjcfe facfe faic'
                          },
                          [
                            item.message_parts_seen,

                            h(
                              'g-icon',
                              {
                                class: 'ml-1',
                                props: {
                                  value: 'done_all',
                                  size: 16,
                                  color: 'success'
                                }
                              }
                            )
                          ]
                        )
                      ]
                    ),

                    h(
                      'div',
                      {
                        class: 'fc ml-2'
                      },
                      [
                        h(
                          'div',
                          {
                            class: 'fjcfe facfe faic'
                          },
                          [
                            item.message_parts_failed,

                            h(
                              'g-icon',
                              {
                                class: 'ml-1',
                                props: {
                                  value: 'clear',
                                  size: 16,
                                  color: 'error'
                                }
                              }
                            )
                          ]
                        ),
                        h(
                          'div',
                          {
                            class: 'fjcfe facfe faic'
                          },
                          [
                            item.message_parts_sent,

                            h(
                              'g-icon',
                              {
                                class: 'ml-1',
                                props: {
                                  value: 'trending_flat',
                                  size: 16,
                                  color: 'info'
                                }
                              }
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              },
              default: () => {
                return h(
                  'table',
                  {
                    class: 'entity__table entity__table--dense my-2'
                  },
                  [
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.type`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          this.getTranslate(`${services.sendings}.types.${item.type}`)
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.owner`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          [ h(SingleLink, { props: { value: item.Owner } }) ]
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.tariff`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          [
                            h(
                              SingleLink,
                              {
                                props: {
                                  value: item.Tariff,
                                  service: 'tariffs'
                                }
                              }
                            )
                          ]
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.provider`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          [
                            h(
                              SingleLink,
                              {
                                props: {
                                  value: item.Provider,
                                  service: 'providers'
                                }
                              }
                            )
                          ]
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.operatorGroup`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          item.operator_group
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.count_delivered`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          item.message_parts_delivered
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.count_seen`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          item.message_parts_seen
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.count_failed`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          item.message_parts_failed
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.count_sent`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          item.message_parts_sent
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.count_total`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          item.message_parts_all
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.billing_tariff_price`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          getPrice(item.billing_tariff_price)
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.billing_sum_pending_complete`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          [
                            h(
                              'div',
                              {
                                class: 'faic'
                              },
                              [
                                h(
                                  'div',
                                  {
                                    class: 'text--grey mr-1'
                                  },
                                  `(${item.billing_multiplier_sum_pending_complete})`
                                ),

                                getPrice(item.billing_sum_pending_complete)
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.billing_sum_refunded_invalid`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          [
                            h(
                              'div',
                              {
                                class: 'faic'
                              },
                              [
                                h(
                                  'div',
                                  {
                                    class: 'text--grey mr-1'
                                  },
                                  `(${item.billing_multiplier_sum_refunded_invalid})`
                                ),

                                getPrice(item.billing_sum_refunded_invalid)
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    h(
                      'tr',
                      {},
                      [
                        h(
                          'td',
                          {
                            domProps: {
                              innerHTML: this.getTranslate(`${services.sendings}.headers.billing_sum_all`)
                            }
                          }
                        ),

                        h(
                          'td',
                          {},
                          [
                            h(
                              'div',
                              {
                                class: 'faic'
                              },
                              [
                                h(
                                  'div',
                                  {
                                    class: 'text--grey mr-1'
                                  },
                                  `(${item.billing_multiplier_sum_all})`
                                ),

                                getPrice(item.billing_sum_all)
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              }
            },
            key: item.id
          }
        )
      })
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderTable.call(this, h),
      renderPanels.call(this, h)
    ]
  )
}
