import proxy from '@sigma-legacy-libs/g-proxy'

import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'TokenCreateDialog',

  mixins: [ proxy() ],

  props: {
    OwnerId: String,

    loading: Boolean
  },

  data() {
    return {
      payload: {
        OwnerId: this.OwnerId,
        description: undefined
      },

      formValidation: false
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.payload.description = undefined
        this.payload.OwnerId = this.OwnerId
      }
    }
  },

  methods: {
    createHandler() {
      if (this.checkPermissions(`advanced.${services.tokens}.create`, permissionPresets.meUp)) {
        if (this.formValidation) {
          this.$emit('create', this.payload)
        }
      }
    }
  },

  render
}
