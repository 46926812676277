import { get } from 'lodash'

import { buttonModes, colors, components, icons, permissionPresets, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'
import invoicesForm from '@/components/services/invoices/form'
import paymentsForm from '@/components/services/payments/form'
import yooMoneyForm from '@/components/global/dialog/payment/yooMoneyForm'
import squircle from '@/components/icon/squircle'

const paymentTypeIcons = {
  legal: icons.groups,
  individual: icons.person
}
const paymentMethodIcons = {
  yooMoney: icons.wallet,
  cards: icons.credit_card,
  recurrent: icons.autorenew
}

function renderArrowLeft(h) {
  if (this.step !== 'first') {
    return h(
      button,
      {
        props: {
          icon: icons.keyboard_arrow_left,
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            switch (this.step) {
              case 'legal':
              case 'individual': {
                this.step = 'first'
                break
              }
              case 'yooMoney':
              case 'cards': {
                this.step = 'individual'
                break
              }
            }
          }
        }
      }
    )
  }
}
function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        'faic ff title text-center px-2': true,
        'fjcc facc': this.step !== 'first'
      }
    },
    [
      h(
        'div',
        {
          class: 'subheading'
        },
        [ this.getTranslate(`${services.payments}.titles.step.${this.step}`) ]
      )
    ]
  )
}
function renderCloseButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.clear,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.step = 'first'
          this.amount = undefined
          this.setShowPaymentDialog(false)
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8 pa-2',
      slot: 'header'
    },
    [
      renderArrowLeft.call(this, h),
      renderTitle.call(this, h),
      renderCloseButton.call(this, h)
    ]
  )
}

function renderRowContent(h, icon, title, subtitle) {
  return h(
    'div',
    {
      class: 'faic grid-gap--16'
    },
    [
      h(
        squircle,
        {
          props: {
            icon,
            color: colors.grey
          }
        }
      ),
      h(
        'div',
        {
          class: 'fc fjcc'
        },
        [
          h(
            'div',
            {
              class: 'body-2'
            },
            [ title ]
          ),
          h(
            'div',
            {
              class: 'caption text--grey'
            },
            [ subtitle ]
          )
        ]
      )
    ]
  )
}
function renderFirstStep(h) {
  return h(
    components.list,
    {},
    [
      this.paymentTypes.map(type => {
        const subtitle =
          type === 'legal' ?
            this.getTranslate(`${services.payments}.methods.invoice`) :
            this.$paymentsMethods.map(paymentMethod => this.getTranslate(`${services.payments}.methods.${paymentMethod}`)).join(', ')

        return h(
          components['list-item'],
          {
            on: {
              click: () => {
                this.step = type
              }
            }
          },
          [
            renderRowContent.call(
              this,
              h,
              paymentTypeIcons[type],
              this.getTranslate(`${services.payments}.types.${type}`),
              subtitle
            )
          ]
        )
      })
    ]
  )
}
function renderLink(h, value, protocol = 'mailto') {
  return h(
    'a',
    {
      attrs: {
        target: '_blank',
        href: `${protocol}:${value}`
      },
      class: 'link'
    },
    [ value ]
  )
}
function renderPaymentMethods(h, method) {
  if (method === 'cards') {
    return h(
      'div',
      {
        class: 'faic grid-gap--4'
      },
      [
        this.paymentsMethods.map(paymentMethod => {
          return h(
            squircle,
            {
              props: {
                iconSVG: paymentMethod,
                color: colors[paymentMethod],
                tooltip: this.getTranslate(`${services.payments}.methods.${paymentMethod}`)
              }
            }
          )
        })
      ]
    )
  }
}
function renderPaymentMethod(h, method) {
  const subtitle = method === 'cards' ?
    this.paymentsMethods.map(paymentMethod => this.getTranslate(`${services.payments}.methods.${paymentMethod}`)).join(', ') :
    method === 'yooMoney' ? this.getTranslate(`${services.payments}.hints.delay`) :
      method === 'recurrent' ? this.getTranslate(`${services.payments}.hints.recurrent`) : undefined

  return h(
    components['list-item'],
    {
      props: {
        loading: this.loading,
        disabled: this.loading
      },
      on: { click: () => this.paymentMethodClickHandler(method) }
    },
    [
      h(
        'div',
        {
          class: 'fjcsb grid-gap--8'
        },
        [
          renderRowContent.call(
            this,
            h,
            paymentMethodIcons[method],
            this.getTranslate(`${services.payments}.methods.${method}`),
            subtitle
          ),

          renderPaymentMethods.call(this, h, method)
        ]
      )
    ]
  )
}
function renderIndividualStep(h) {
  if (this.checkPermissions(`advanced.${services.payments}.create`, permissionPresets.meUp)) {
    return h(
      components.list,
      {},
      [ this.$paymentsMethods.map(method => renderPaymentMethod.call(this, h, method)) ]
    )
  } else {
    const contacts = get(this.globalSettings, 'contacts.phones', [])
    const emails = get(this.globalSettings, 'contacts.emails', [])

    return h(
      'div',
      {
        class: 'grid'
      },
      [
        h(
          info,
          {
            props: {
              value: this.getTranslate(`${services.payments}.hints.individual`)
            }
          }
        ),
        h(
          'div',
          {
            class: 'grid grid-gap--4 px-3'
          },
          [
            h(
              'div',
              {
                class: 'grid grid-gap--2'
              },
              [ contacts.map(({ value }) => renderLink(h, value, 'tel')) ]
            ),
            h(
              'div',
              {
                class: 'grid grid-gap--2'
              },
              [ emails.map(({ value }) => renderLink(h, value)) ]
            )
          ]
        )
      ]
    )
  }
}
function renderLegalStep(h) {
  return h(
    invoicesForm,
    {
      props: {
        value: this.payload.legal,
        isNew: true
      },
      on: {
        input: event => {
          this.payload.legal = event
        },
        show: event => {
          this.setShowPaymentDialog(event)
        }
      }
    }
  )
}
function renderUseYooMoney(h) {
  if (this.currencyIsRUB && this.hostnameIsOwn) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.payments}.hints.yooMoney`),
          color: colors.info
        }
      }
    )
  }
}
function renderGoToYooMoney(h) {
  if (this.currencyIsRUB && this.hostnameIsOwn) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${services.payments}.methods.yooMoney`),
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.step = 'yooMoney'
          }
        }
      }
    )
  }
}
function renderCardsStep(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pt-2 px-2'
    },
    [
      h(
        paymentsForm,
        {
          props: { value: this.payload.individual },
          on: {
            input: event => {
              this.payload.individual = event
            },
            validation: event => {
              this.payload.individualValidation = event
            }
          }
        }
      ),

      renderUseYooMoney.call(this, h)
    ]
  )
}
function renderBody(h) {
  switch (this.step) {
    case 'individual': return renderIndividualStep.call(this, h)
    case 'legal': return renderLegalStep.call(this, h)
    case 'cards': return renderCardsStep.call(this, h)
    case 'yooMoney': return h(yooMoneyForm)
    case 'first':
    default: return renderFirstStep.call(this, h)
  }
}

function renderFooterByStep(h) {
  switch (this.step) {
    case 'cards': {
      return [
        renderGoToYooMoney.call(this, h),
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.pay'),
              mode: buttonModes.filled,
              color: colors.primary,
              loading: this.loading,
              disabled: this.loading || !this.payload.individualValidation
            },
            on: {
              click: () => this.requestPayment()
            }
          }
        )
      ]
    }
    case 'legal': {
      return h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.bill'),
            mode: buttonModes.filled,
            color: colors.primary,
            loading: this.loading,
            disabled: this.loading || !this.validation
          },
          on: {
            click: () => this.requestDocument()
          }
        }
      )
    }
  }
}
function renderFooter(h) {
  if (this.step !== 'yooMoney') {
    return h(
      'div',
      {
        class: 'fjcfe facfe grid-gap--8 pa-2',
        slot: 'footer'
      },
      [ renderFooterByStep.call(this, h) ]
    )
  }
}

export default function(h) {
  return h(
    components.dialog,
    {
      style: { 'margin-bottom': this.viewport.breakpoint.smDown ? '70px' : undefined },
      props: {
        value: this.showPaymentDialog,
        rounded: this.viewport.breakpoint.mdUp,
        closeOnEsc: false,
        closeOnClick: true,
        maxWidth: this.viewport.breakpoint.smDown ? '100vw' : 400,
        fullscreenMobile: false,
        align: this.viewport.breakpoint.smDown ? 'bottom' : undefined
      },
      on: {
        input: event => {
          if (event === false) {
            this.step = 'first'
          }
          this.setShowPaymentDialog(event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
