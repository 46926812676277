import { colors, components, getIconSVGByType } from '@/utils'

import render from './render'

export default {
  name: 'svgIcon',

  props: {
    value: {
      type: String,
      default: undefined
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    size: {
      type: Number,
      default: 24
    },

    tooltip: {
      type: String,
      default: undefined
    }
  },

  computed: {
    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: components.tooltip,
          options: { value: this.tooltip }
        })
      }

      return result
    },

    $iconSVG() {
      return getIconSVGByType(this.value)
    }
  },

  render
}
