import { components, inputModes } from '@/utils'

import buttonConfirm from '@/components/button/confirm'

function renderIDField(h, { path, serviceName }) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.id,
        label: this.getTranslate(`${serviceName}.labels.${path}.id`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.id = event
        }
      }
    }
  )
}
function renderTokenField(h, { path, serviceName }) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.token,
        label: this.getTranslate(`${serviceName}.labels.${path}.token`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.token = event
        }
      }
    }
  )
}
function renderForm(h, options) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--8 grid-cols--2'
    },
    [
      renderIDField.call(this, h, options),
      renderTokenField.call(this, h, options)
    ]
  )
}

export default function(h, options) {
  const { path, serviceName } = options

  return h(
    components.card,
    {
      class: 'pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`${serviceName}.subtitles.${path}`),
        outline: true,
        rounded: true
      }
    },
    [
      h(
        buttonConfirm,
        {
          props: {
            value: this.proxy
          },
          on: {
            input: event => {
              this.$emit('input', event)
            }
          }
        },
        [ renderForm.call(this, h, options) ]
      )
    ]
  )
}
