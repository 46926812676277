import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'RemoveButton',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      loading: false
    }
  },

  methods: {
    async remove() {
      if (this.checkPermissions(`advanced.${services.tokens}.remove`, permissionPresets.meUp)) {
        this.loading = true
        try {
          const { id } = await Vue.$GRequest.remove(services.tokens, this.value.id)
          if (id) {
            this.addSnackbar({
              text: this.getTranslate(`${services.tokens}.snackbars.removed`),
              type: 'error'
            })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
          this.showDialog = false
        }
      }
    }
  },

  render
}
