import { colors, modes, permissionPresets, services, sizes } from '@/utils'
import { localServices, rowStatesIcon } from '@/components/trees/routes/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import buttonConfirmRemove from '@/components/button/confirm/remove'
import sticker from '@/components/misc/sticker'
import draggable from '@/components/misc/draggable'
import squircle from '@/components/icon/squircle'
import overflowString from '@/components/misc/overflowString'
import routesConditions from '@/components/trees/routes/conditions'
import messageTypeIcon from '@/components/message/type/icon'
import routesCreateFull from '@/components/services/routes/create/full'
import routesCreateDirect from '@/components/services/routes/create/direct'

function renderTitle(h, { value, title }) {
  if (this.viewport.breakpoint.smUp || !this.showAddRouteMenu[value]) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(title),
          label: this.getTranslate(`${services.routingGroups}.labels.type`),
          borderStyle: 'dashed'
        }
      },
      [
        h(
          squircle,
          {
            props: {
              color: value.includes('chat') ? 'warning' : value
            },
            slot: 'icon'
          },
          [
            h(
              messageTypeIcon,
              {
                props: {
                  value,
                  size: 22
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderAddButton(h, type) {
  return h(
    'div',
    {
      class: `${services.routingGroups}-sendingType__add-button`
    },
    [
      h(
        button,
        {
          props: {
            mode: modes.flat,
            icon: this.showAddRouteMenu[type] ? 'keyboard_arrow_left' : 'add',
            color: this.showAddRouteMenu[type] ? 'black' : 'secondary'
          },
          on: {
            click: () => {
              this.clearShowAddRouteMenu(type)
              this.showAddRouteMenu[type] = !this.showAddRouteMenu[type]
            }
          }
        }
      )
    ]
  )
}
function renderDescriptionTag(h, value) {
  if (this.checkPermissions(`advanced.${services.routingGroups}.get`, permissionPresets.true)) {
    if (value) {
      return h(
        'div',
        {
          class: 'fjcfs'
        },
        [
          h(
            tag,
            {
              props: {
                label: value,
                color: 'primary',
                size: 'tiny'
              }
            }
          )
        ]
      )
    }
  }
}
function renderRoutingGroupSelector(h, type) {
  return h(
    'g-select',
    {
      props: {
        value: this.routeSelector,
        label: this.getTranslate(`${services.routingGroups}.labels.route`),
        itemsDisabled: this.RoutingGroups[type].map(({ id }) => id),
        service: 'routingGroups',
        query: {
          type,
          isActive: true
        },
        mode: 'outline',
        autocomplete: true,
        rounded: true,
        dense: true,
        details: false
      },
      scopedSlots: {
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'grid grid-gap--2'
            },
            [
              h(
                overflowString,
                {
                  props: {
                    value: item.title,
                    font: { size: 12 },
                    color: 'text'
                  }
                }
              ),
              renderDescriptionTag.call(this, h, item._item.description)
            ]
          )
        }
      },
      on: {
        input: event => {
          this.addRoutingGroup(event, type)
        }
      }
    }
  )
}
function renderCreateMenu(h, type) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        offsetSkidding: -34
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: modes.flat,
            icon: 'add',
            color: 'secondary'
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          h(
            'g-list-item',
            {
              props: {
                label: this.getTranslate('routes.types.full'),
                dense: true
              },
              on: {
                click: () => {
                  this.showDialog.full[type] = true
                }
              }
            }
          ),

          h(
            'g-list-item',
            {
              props: {
                label: this.getTranslate('routes.types.direct'),
                dense: true
              },
              on: {
                click: () => {
                  this.showDialog.direct[type] = true
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderAddMenuContent(h, type) {
  if (this.showAddRouteMenu[type]) {
    return h(
      'div',
      {
        class: `${services.routingGroups}-sendingType__add-menu`
      },
      [
        renderRoutingGroupSelector.call(this, h, type),
        renderCreateMenu.call(this, h, type),

        h(
          routesCreateFull,
          {
            props: {
              show: this.showDialog.full[type],
              type
            },
            on: {
              input: event => {
                if (!~this.RoutingGroups[type].findIndex(item => item.id === event)) {
                  this.RoutingGroups[type].push({ id: event })
                  this.fillRoutingGroups(true)
                }
              },
              showDialog: event => {
                if (typeof event === 'boolean') {
                  this.showDialog.full[type] = event
                }
              }
            }
          }
        ),
        h(
          routesCreateDirect,
          {
            props: {
              value: this.showDialog.direct[type],
              type
            },
            on: {
              input: event => {
                if (!~this.RoutingGroups[type].findIndex(item => item.id === event)) {
                  this.RoutingGroups[type].push({ id: event })
                  this.fillRoutingGroups(true)
                }
              },
              showDialog: event => {
                if (typeof event === 'boolean') {
                  this.showDialog.direct[type] = event
                }
              }
            }
          }
        )
      ]
    )
  }
}
function renderAddMenu(h, type) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'transition',
      {
        props: { name: 'route-show-menu' }
      },
      [ renderAddMenuContent.call(this, h, type) ]
    )
  }

  return renderAddMenuContent.call(this, h, type)
}
function renderAddRoutingGroup(h, value) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: {
          [`${services.routingGroups}-sendingType__add`]: true

        }
      },
      [
        renderAddButton.call(this, h, value),
        renderAddMenu.call(this, h, value)
      ]
    )
  }
}
function renderSendingType(h, { title, value }) {
  return h(
    'div',
    {
      class: {
        [`${services.routingGroups}-sendingType`]: true,
        [`${services.routingGroups}-sendingType--open`]: this.showAddRouteMenu[value]
      }
    },
    [
      renderTitle.call(this, h, {
        title,
        value
      }),

      renderAddRoutingGroup.call(this, h, value)
    ]
  )
}

function renderInfoTitle(h, title) {
  return h(
    overflowString,
    {
      props: {
        value: title,
        color: 'text',
        font: {
          size: 14
        }
      }
    }
  )
}
function renderAdditionalInfo(h, service, additionalInfo) {
  if (service === 'providerGroups') {
    return h(
      tag,
      {
        props: {
          label: `${additionalInfo.percentage}%`,
          color: 'primary',
          size: 'tiny'
        }
      }
    )
  }

  return h('div')
}
function renderTreeRowArrowIndicator(h, state, trigger) {
  if (trigger) {
    return h(
      'div',
      {
        class: 'faic fjcc square--28'
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: rowStatesIcon[state],
              size: 20,
              color: colors.grey
            }
          }
        )
      ]
    )
  }
}
function renderTreeRowInfo(h, title, service, additionalInfo = {}) {
  return h(
    'div',
    {
      class: `${services.routingGroups}-tree-row__info`
    },
    [
      renderInfoTitle.call(this, h, title),
      renderAdditionalInfo.call(this, h, service, additionalInfo)
    ]
  )
}
function renderTreeEditLink(h, service, id) {
  return h(
    'router-link',
    {
      class: {
        'link link--passive link--no-active link--no-hover': true,
        'hover-child': this.viewport.breakpoint.mdUp
      },
      props: {
        to: {
          name: `${service}.single`,
          params: { id }
        }
      }
    },
    [
      h(
        button,
        {
          props: {
            size: sizes.small,
            mode: modes.flat,
            icon: 'edit'
          }
        }
      )
    ]
  )
}
function renderTreeRow(h, id, title, service, additionalInfo = {}) {
  const state = service === 'providers' ? undefined : this.getRowState(service, id)

  return h(
    'div',
    {
      class: {
        [`${services.routingGroups}-tree-row`]: true,
        [`${services.routingGroups}-tree-row--${state}`]: !!state,
        [`${services.routingGroups}-tree-row--${service}`]: !!service,
        'hover-parent hover-parent--opacity': true
      },
      on: {
        click: () => this.getEntities(service, localServices[service].association, id),
        dblclick: () => this.getAllEntities(service, localServices[service].association, id)
      }
    },
    [
      renderTreeRowArrowIndicator.call(this, h, state, service !== 'providers'),
      renderTreeRowInfo.call(this, h, title, service, additionalInfo),
      renderTreeEditLink.call(this, h, service, id)
    ]
  )
}
function renderActiveIndicator(h, active = false) {
  if (active) {
    return h(
      squircle,
      {
        props: {
          value: 'keyboard_double_arrow_right',
          color: 'epic'
        }
      }
    )
  }
}
function renderProviders(h, parentId, parentActive = false) {
  if (Array.isArray(this.Providers[parentId]) && this.Providers[parentId].length) {
    const service = 'providers'

    return h(
      'div',
      {
        class: `${services.routingGroups}-tree-${service}`
      },
      this.Providers[parentId].map(({ id, title }) => {
        return h(
          'div',
          {
            class: {
              [`${services.routingGroups}-tree__section`]: true,
              [`${services.routingGroups}-tree-provider`]: true,
              [`${services.routingGroups}-tree-provider--active`]: !!this.highlight[service] && !!~this.highlight[service].indexOf(id) && parentActive
            }
          },
          [
            renderActiveIndicator.call(this, h, !!this.highlight[service] && !!~this.highlight[service].indexOf(id) && parentActive),
            renderTreeRow.call(this, h, id, title, service)
          ]
        )
      })
    )
  }
}
function renderProviderGroups(h, parentId, parentActive = false) {
  if (Array.isArray(this.ProviderGroups[parentId]) && this.ProviderGroups[parentId].length) {
    const service = 'providerGroups'

    return h(
      'div',
      {
        class: `${services.routingGroups}-tree-${service}`
      },
      this.ProviderGroups[parentId].map(({ id, title, percentage }) => {
        return h(
          'div',
          {
            class: {
              [`${services.routingGroups}-tree__section`]: true,
              [`${services.routingGroups}-tree__section--active`]: !!this.highlight[service] && !!~this.highlight[service].indexOf(id) && parentActive,
              [`${services.routingGroups}-tree-providerGroup`]: true
            }
          },
          [
            renderTreeRow.call(this, h, id, title, service, { percentage }),

            h(
              'div',
              {
                class: {
                  [`${services.routingGroups}-tree__holder`]: true,
                  [`${services.routingGroups}-tree__holder--${this.getRowState(service, id)}`]: true
                }
              },
              [ renderProviders.call(this, h, id, !!this.highlight[service] && !!~this.highlight[service].indexOf(id) && parentActive) ]
            )
          ]
        )
      })
    )
  }
}

function renderConditions(h, rules) {
  if (Array.isArray(rules) && rules.length) {
    return h(
      'div',
      {
        class: `${services.routingGroups}-tree-routingRule__conditions`
      },
      [ h(routesConditions, { props: { value: rules } }) ]
    )
  }
}
function renderRoutingRules(h, parentId, parentActive = false) {
  if (Array.isArray(this.RoutingRules[parentId]) && this.RoutingRules[parentId].length) {
    const service = 'routingRules'

    return h(
      'div',
      {
        class: `${services.routingGroups}-tree-${service}`
      },
      this.RoutingRules[parentId].map(({ id, title, rules }) => {
        return h(
          'div',
          {
            class: {
              [`${services.routingGroups}-tree__section`]: true,
              [`${services.routingGroups}-tree__section--active`]: !!this.highlight[service] && !!~this.highlight[service].indexOf(id) && parentActive,
              [`${services.routingGroups}-tree-routingRule`]: true
            }
          },
          [
            renderTreeRow.call(this, h, id, title, service),

            h(
              'div',
              {
                class: {
                  [`${services.routingGroups}-tree__holder`]: true,
                  [`${services.routingGroups}-tree__holder--${this.getRowState(service, id)}`]: true
                }
              },
              [
                renderConditions.call(this, h, rules),
                renderProviderGroups.call(this, h, id, !!this.highlight[service] && !!~this.highlight[service].indexOf(id) && parentActive)
              ]
            )
          ]
        )
      })
    )
  }
}

function renderDragHandler(h, state) {
  return h(
    'g-icon',
    {
      class: {
        drag_handle: !this.readonly,
        [`${services.routingGroups}-tree-routingGroup--${state}`]: true
      },
      props: {
        value: this.readonly ? rowStatesIcon[state] : state === 'loading' ? rowStatesIcon.loading : 'drag_handle',
        color: 'grey'
      }
    }
  )
}
function renderRoutingGroupInfo(h, title, description) {
  return h(
    'div',
    {
      class: `${services.routingGroups}-tree-routingGroup__info`
    },
    [
      renderInfoTitle.call(this, h, title),
      renderDescriptionTag.call(this, h, description)
    ]
  )
}
function renderRoutingGroupEditButton(h, id) {
  return h(
    'router-link',
    {
      class: 'link link--passive link--no-active link--no-hover',
      props: {
        to: {
          name: `${services.routingGroups}.single`,
          params: { id }
        }
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: modes.flat,
            icon: 'edit',
            size: this.viewport.breakpoint.lgUp ? sizes.small : sizes.medium
          }
        }
      )
    ]
  )
}
function renderRoutingGroupRemoveButton(h, id, value) {
  if (!this.readonly) {
    return h(
      buttonConfirmRemove,
      {
        props: {
          component: 'btn',
          labeled: false,
          icon: 'remove',
          size: this.viewport.breakpoint.lgUp ? sizes.small : sizes.medium,
          callback: () => this.removeRoutingGroup(id, value)
        }
      }
    )
  }
}
function renderRoutingGroupButtons(h, id, value) {
  return h(
    'div',
    {
      class: {
        [`${services.routingGroups}-tree-routingGroup__buttons`]: true,
        'hover-child': this.viewport.breakpoint.mdUp
      },
      style: {
        gridTemplateColumns: this.readonly ? 'auto' : 'repeat(2, auto)'
      }
    },
    [
      renderRoutingGroupEditButton.call(this, h, id),
      renderRoutingGroupRemoveButton.call(this, h, id, value)
    ]
  )
}
function renderRoutingGroupHeader(h, value, { id, title, description }) {
  return h(
    'div',
    {
      class: `${services.routingGroups}-tree-routingGroup__header hover-parent hover-parent--opacity`,
      on: {
        click: event => {
          this.clickRow(event, this.getEntities(services.routingGroups, localServices[services.routingGroups].association, id))
        },
        dblclick: event => {
          this.clickRow(event, this.getAllEntities(services.routingGroups, localServices[services.routingGroups].association, id))
        }
      }
    },
    [
      renderDragHandler.call(this, h, this.getRowState(services.routingGroups, id)),
      renderRoutingGroupInfo.call(this, h, title, description),
      renderRoutingGroupButtons.call(this, h, id, value)
    ]
  )
}
function renderRoutingGroup(h, value, { id, title, description }) {
  return h(
    'div',
    {
      class: {
        [`${services.routingGroups}-tree-routingGroup`]: true,
        [`${services.routingGroups}-tree-routingGroup--active`]: !!this.highlight.routingGroups && !!~this.highlight.routingGroups.indexOf(id)
      }
    },
    [
      renderRoutingGroupHeader.call(this, h, value, {
        id,
        title,
        description
      }),

      h(
        'div',
        {
          class: {
            [`${services.routingGroups}-tree__holder`]: true,
            [`${services.routingGroups}-tree__holder--${this.getRowState(services.routingGroups, id)}`]: true
          }
        },
        [ renderRoutingRules.call(this, h, id, !!this.highlight.routingGroups && !!~this.highlight.routingGroups.indexOf(id)) ]
      )
    ]
  )
}
function renderRoutingGroups(h, { value }) {
  if (Array.isArray(this.RoutingGroups[value]) && this.RoutingGroups[value].length) {
    return h(
      draggable,
      {
        class: `${services.routingGroups}-tree`,
        props: {
          value: this.RoutingGroups[value],
          handle: 'drag_handle',
          disabled: this.readonly
        },
        scopedSlots: {
          item: item => {
            return renderRoutingGroup.call(this, h, value, item)
          }
        },
        on: {
          input: event => {
            this.RoutingGroups[value] = event
          }
        }
      }
    )
  }
}

function renderType(h, type) {
  return h(
    'div',
    {
      class: `${services.routingGroups}-tab__item`
    },
    [
      renderSendingType.call(this, h, type),
      renderRoutingGroups.call(this, h, type)
    ]
  )
}
function renderTypes(h) {
  return this.types.map(type => renderType.call(this, h, type))
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.routingGroups}-tab`
    },
    [ renderTypes.call(this, h) ]
  )
}
