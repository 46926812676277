import { cloneDeep } from 'lodash'

import render from './render'

export default {
  name: 'ConfirmButton',

  props: {
    value: null,
    title: String,

    dialog: Boolean
  },

  data() {
    return {
      proxy: cloneDeep(this.value),

      showDialog: false
    }
  },

  methods: {
    emitInput() {
      this.proxy = cloneDeep(this.value)
      this.$emit('input', this.value)
    }
  },

  render
}
