import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'AfterCreateDialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      sensitivity: true
    }
  },

  computed: {
    token() {
      const token = this.data.token
      if (token) {
        if (this.sensitivity) {
          return '•'.repeat(token.length)
        }

        return token
      }
    }
  },

  render
}
