import { colors, modes, sizes } from '@/utils'

import buttonCopy from '@/components/button/copy'
import button from '@/components/button'

function renderActions(h) {
  return h(
    'div',
    {
      class: 'source-box__actions'
    },
    [
      h(
        buttonCopy,
        {
          props: {
            value: this.value ? JSON.stringify(this.value) : '',
            size: sizes.small,
            mode: modes.flat
          }
        }
      ),

      h(
        button,
        {
          props: {
            icon: 'subject',
            mode: modes.flat,
            size: sizes.small,
            color: this.preWrap ? colors.primary : colors.white
          },
          on: {
            click: () => {
              this.preWrap = !this.preWrap,
              this.overflow = !this.overflow
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'pre',
    {
      class: `language-${this.language} source-box__content`,
      style: this.contentStyle,
      domProps: { innerHTML: this.parsedSource }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'source-box',
      style: this.sourceBoxStyle
    },
    [
      renderActions.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
