export default {
  title: 'Счета',

  titles: {
    create: 'Создание нового счета',
    confirm: {
      rejected: 'Отклонение счета',
      paid: 'Подтверждение счета'
    }
  },

  labels: {
    docNumber: 'Номер',
    invoice: 'Счет',
    contract: 'Договор',
    type: 'Тип',
    status: 'Статус',
    amount: 'Сумма',
    requisite: 'Реквизиты',
    company: 'Компания',
    customer: 'Клиент',
    owner: 'Создатель',
    balance: 'Баланс',
    requisites: 'Реквизиты',
    create: 'Создать счет',
    edit: 'Просмотреть',
    paid: 'Подтвердить',
    rejected: 'Отклонить',
    download: 'Скачать счет',
    delete: 'Удалить',

    statuses: {
      paid: 'Оплачен',
      rejected: 'Отклонен',
      pending: 'В ожидании'
    }
  },

  contents: {
    confirm: {
      rejected: 'Вы уверены, что хотите отклонить этот счет?',
      paid: 'Вы уверены, что хотите подтвердить этот счет?'
    }
  },

  warnings: {
    notFound: {
      requisites: 'К сожалению, мы не можем найти ваши реквизиты.<br>Создайте их и попробуйте снова.',
      requisitesForAdmin: 'К сожалению, мы не можем найти реквизиты для этого пользователя.<br>Вы можете создать их для него самостоятельно.',
      companies: 'К сожалению, для выбранных реквизитов существует одна из следующих проблем:<strong>- нет ни одного контрагента с указанной налоговой формой</strong><strong>- у существующих контрагентов не привязан шаблон договора</strong><br><div class="mt-2">Обратитесь в поддержку для решения этого вопроса.</div>',
      template: 'К сожалению, мы не можем найти ни одного шаблона счета для выбранной компании.<br>Обратитесь в поддержку для решения этого вопроса.'
    }
  },

  tooltips: {
    download: 'Скачать счет',
    paid: 'Подтвердить',
    rejected: 'Отклонить',
    delete: 'Удалить',
    edit: 'Просмотреть',
    balance: 'Обновить баланс'
  },

  snackbars: {
    created: 'Счет успешно создан.',
    updated: 'Счет успешно обновлен.',
    deleted: 'Счет успешно удален.',
    paid: 'Счет успешно подтвержден.',
    rejected: 'Счет успешно отклонен.'
  }
}
