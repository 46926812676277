import { mapActions } from 'vuex'

import render from './render'

export default {
  name: 'ButtonPayment',

  methods: {
    ...mapActions({ setShowPaymentDialog: 'payments/setShowPaymentDialog' })
  },

  render
}
