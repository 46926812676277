import { TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { billingsSources, colors, getLocaleDateString, getYesterday, inputModes, modes, services, sizes } from '@/utils'

import switcher from '@/components/switcher'
import sticker from '@/components/misc/sticker'
import button from '@/components/button'
import OwnerSelect from '@/components/misc/OwnerSelect'

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate('tasks.labels.title'),
        required: true,
        autofocus: true,
        clearable: true,
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        dense: true,
        mask: /^.{0,255}$/
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderAmount(h) {
  const currency = this.currency || this.globalSettings.billings.currency

  return h(
    'div',
    {
      class: 'grid-gap--16 faifs'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.amount,
            label: this.getTranslate(`${services.tasks}.labels.data.amount`),
            suffix: currency ? this.getTranslate(`currency.${currency}.symbol.unicode`) : null,
            mode: inputModes['line-label'],
            type: 'number',
            step: 'any',
            min: 0,
            dense: true,
            rounded: true,
            required: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.data.amount = event
            }
          }
        }
      ),
      h(
        switcher,
        {
          props: {
            value: this.proxy.data.source,
            trueValue: billingsSources.accrual,
            falseValue: billingsSources.subtract,
            tooltip: this.getTranslate(`${services.billings}.tooltips.actions`),
            icons: {
              on: 'add',
              off: 'remove'
            }
          },
          on: {
            input: event => {
              this.proxy.data.source = event
            }
          }
        }
      )
    ]
  )
}

function renderFrequency(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.data.frequency,
        label: this.getTranslate(`${services.tasks}.labels.data.frequency`),
        items: TASK_FREQUENCY.reduce((result, frequency) => {
          if (frequency !== TASK_FREQUENCY.weekly) {
            result.push({
              title: this.getTranslate(`${services.tasks}.frequencies.${frequency}`),
              value: frequency
            })
          }

          return result
        }, []),
        required: true,
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.data.frequency = event
        }
      }
    }
  )
}

function renderStartsAt(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        closeOnContentClick: false
      }
    },
    [
      h(
        sticker,
        {
          props: {
            value: this.proxy.startsAt ? getLocaleDateString(this.proxy.startsAt, this.locale) : this.getTranslate('filter.date.titles.startsAt'),
            label: this.getTranslate('tasks.labels.startsAt'),
            width: '100%',
            icon: 'event',
            color: colors.grey,
            to: { service: undefined }
          },
          slot: 'activator'
        }
      ),

      h(
        'g-date-picker',
        {
          props: {
            value: this.proxy.startsAt,
            localeTag: this.locale,
            range: false,
            min: getYesterday()
          },
          on: {
            input: event => {
              this.proxy.startsAt = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe pa-2'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.clear'),
                mode: modes.flat,
                color: colors.black,
                size: sizes.small
              },
              on: {
                click: () => {
                  this.proxy.startsAt = undefined
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderEndsAt(h) {
  if (this.proxy.data.frequency !== TASK_FREQUENCY.once) {
    return h(
      'g-menu',
      {
        props: {
          rounded: true,
          closeOnContentClick: false
        }
      },
      [
        h(
          sticker,
          {
            props: {
              value: this.proxy.data.endsAt ? getLocaleDateString(this.proxy.data.endsAt, this.locale) : this.getTranslate('filter.date.titles.endsAt'),
              label: this.getTranslate('tasks.labels.data.endsAt'),
              width: '100%',
              icon: 'event',
              color: colors.grey,
              to: { service: undefined }
            },
            slot: 'activator'
          }
        ),

        h(
          'g-date-picker',
          {
            props: {
              value: this.proxy.data.endsAt,
              localeTag: this.locale,
              range: false,
              min: this.proxy.startsAt
            },
            on: {
              input: event => {
                this.proxy.data.endsAt = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'fjcfe facfe pa-2'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.clear'),
                  mode: modes.flat,
                  color: colors.black,
                  size: sizes.small
                },
                on: {
                  click: () => {
                    this.proxy.data.endsAt = undefined
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        itemsDisabled: [ this.account.id ],
        mode: inputModes['line-label'],
        autocomplete: true,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3'
    },
    [
      renderTitleField.call(this, h),
      renderAmount.call(this, h),
      renderFrequency.call(this, h),
      renderStartsAt.call(this, h),
      renderEndsAt.call(this, h),
      renderOwnerSelect.call(this, h)
    ]
  )
}
