import { FILE_TYPES, colors } from '@/utils'

export const dialogTypes = {
  editMeta: 'editMeta',
  remove: 'remove',
  edit: 'edit',
  source: 'source',
  export: 'export'
}

export const fileInfoIcons = {
  id: '123',
  hash: 'more_horiz',
  size: 'dns',
  type: 'type_specimen',
  owner: 'person_outline',
  createdAt: 'access_time',
  updatedAt: 'access_time',
  willBeDeleted: 'history'
}

export const getFileMiscInfo = file => {
  const result = {
    type: FILE_TYPES.file.value,
    color: FILE_TYPES.file.color
  }

  if (file) {
    if (/(ms-?word|wordprocessingml)/.test(file.type)) {
      result.type = FILE_TYPES.doc.value
      result.color = FILE_TYPES.doc.color
    }
    if (/(ms-excel|spreadsheetml)/.test(file.type)) {
      result.type = FILE_TYPES.csv.value
      result.color = FILE_TYPES.csv.color
    }
    if (/.*?\/csv/.test(file.type)) {
      result.type = FILE_TYPES.csv.value
      result.color = FILE_TYPES.csv.color
    }
    if (/.*?\/pdf/.test(file.type)) {
      result.type = FILE_TYPES.pdf.value
      result.color = FILE_TYPES.pdf.color
    }
    if (/image\//.test(file.type)) {
      result.type = FILE_TYPES.jpg.value
      result.color = FILE_TYPES.jpg.color
    }
    if (/audio\//.test(file.type)) {
      result.type = FILE_TYPES.wav.value
      result.color = FILE_TYPES.wav.color
    }
    if (/video\//.test(file.type)) {
      result.type = FILE_TYPES.mp4.value
      result.color = FILE_TYPES.mp4.color
    }
  }

  return result
}

export const getFileSizeColor = size => {
  switch (true) {
    case size < 1024: return colors.file
    case size < 1024 * 1024: return colors.success
    case size < 1024 * 1024 * 1024: return colors.warning
    case size < 1024 * 1024 * 1024 * 1024: return colors.error
    case size < 1024 * 1024 * 1024 * 1024 * 1024: return colors.legendary
    case size < 1024 * 1024 * 1024 * 1024 * 1024 * 1024: return colors.epic
    default: return colors.file
  }
}
