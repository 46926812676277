import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Dialog',

  props: {
    value: null,

    show: Boolean
  },

  data() {
    return {
      showDialog: false,

      currentTab: 'source'
    }
  },

  computed: {
    tabs() {
      const result = [ 'source' ]
      if (this.checkPermissions(`advanced.${services.eventsHistory}.get`, permissionPresets.true)) {
        result.push(services.eventsHistory)
      }

      return result
    }
  },

  watch: {
    show() {
      this.showDialog = !!this.show
    }
  },

  mounted() {
    this.showDialog = !!this.show
  },

  render
}

