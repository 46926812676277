import Vue from 'vue'
import Clipboard from 'clipboard'

import { mapActions, mapGetters } from 'vuex'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'

import { createClicker, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'
import { inputFilter, outputFilter, serviceName } from '@/components/services/users/utils'

import SearchGenerator from '@/components/generator/search'
import GetCreateTemplate from '@/components/GetCreateTemplate'

import render from './render'

export default {
  name: serviceName,

  mixins: [
    generateServices({
      name: 'billings',

      inputFilter(result) {
        result.amount = 10

        return result
      },

      outputFilter(result) {
        if (result.source !== 'accrual') {
          result.amount = result.amount * -1
        }

        result.amount = parseFloat(result.amount)

        return result
      },

      get: false,
      find: false,
      update: false,
      remove: false
    })
  ],

  components: {
    'search-generator': SearchGenerator({
      name: serviceName,

      inputFilterForCreateButton: inputFilter,
      outputFilterForCreateButton: outputFilter,

      find: {
        defaultFilter: {
          isActive: true
        },
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      avoid304: true
    }),

    'get-create-template': GetCreateTemplate({
      name: serviceName,

      inputFilter,
      outputFilter,

      find: false,
      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      create: false,
      remove: {
        redirect: true,
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  },

  data() {
    return {
      showAddFunds: false,
      showTokenDialog: false,

      token: '',

      validation: false,

      cp: undefined,

      tab: undefined
    }
  },

  computed: {
    ...mapGetters({ statusLoginAs: 'login/status' }),

    createDataIsValid() {
      return this.restData.billings.create.state !== 'loading' && this.restData.billings.create.isValid
    },

    computedTabs() {
      const result = []

      if (this.checkPermissions('advanced.userComments.get')) {
        result.push('userComments')
      }

      if (this.checkPermissions('advanced.eventsHistory.get', [ true ])) {
        result.push('eventsHistoryTab')
      }

      return result
    }
  },

  watch: {
    '$route.params.id'() {
      this.currentTab = 'general'
    }
  },

  mounted() {
    this.cp = new Clipboard('.clipboard-button')
    this.tab = this.computedTabs[0]
  },

  beforeDestroy() {
    this.cp.destroy()
  },

  methods: {
    ...mapActions({ loginAs: 'login/loginAs' }),

    clickHandler: createClicker({
      shortClickHandler(event) {
        if (event.shiftKey) {
          this.showUserToken()
        } else {
          this.loginAs(this.$route.params.id)
        }
      },

      longClickHandler() {
        this.showUserToken()
      },

      longClickPreFire: true
    }),

    async showUserToken() {
      try {
        if (isUUID(this.$route.params.id)) {
          const result = await Vue.$GRequest.get('login/as', this.$route.params.id)
          if (result && result.status === 200) {
            this.token = result.data.token
            this.showTokenDialog = true
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async createBilling() {
      if (this.createDataIsValid) {
        try {
          const result = await this.rest.billings.create(this.restData.billings.create.data)
          if (result) {
            this.showAddFunds = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
