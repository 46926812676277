import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'
import { icons } from '@/utils'

export default {
  name: 'NotificationTemplate',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    service: String,
    path: String,
    prop: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    $icon() {
      let result
      switch (this.prop) {
        case 'billings.limit': {
          result = icons.currency_ruble
          break
        }
        case 'auth.registration': {
          result = icons.person_add_alt
          break
        }
        case 'auth.recover': {
          result = icons.key
          break
        }
        case 'providers.health': {
          result = icons.health_and_safety
          break
        }
        case 'otp.generate.sendernames.moderation': {
          result = icons.sms
          break
        }
      }

      return result
    }
  },

  methods: {
    watchValueHandler() {
      return
    },
    watchProxyHandler() {
      return
    }
  },

  render
}
