import { colors, components } from '@/utils'

import buttonToolbar from '@/components/button/toolbar'

function renderItem(h, item) {
  return h(
    'div',
    {
      class: {
        'mobile-panel-item': true,
        'mobile-panel-item--active': this.tab.name === item.name
      },
      on: {
        click: () => {
          this.itemProcessor(item)
        }
      }
    },
    [
      h(
        buttonToolbar,
        {
          props: {
            iconSVG: item.icon
          },
          on: {
            click: () => {
              this.itemProcessor(item)
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'mobile-panel-item-title'
        },
        [ this.getTranslate(`mobilePanel.title.${item.name}`) ]
      )
    ]
  )
}

export default function(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      components.footer,
      {
        class: 'mobile-panel',
        props: {
          fixed: true,
          color: colors.primary,
          height: 70
        }
      },
      [
        this.items.map(item => {
          return renderItem.call(this, h, item)
        })
      ]
    )
  }
}
