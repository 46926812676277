import { buttonModes, colors, components, getLocaleDateString, icons, popperPlacements, services } from '@/utils'
import { contactFields } from '@/components/services/contacts/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import button from '@/components/button'
import date from '@/components/serviceTemplate/item/date'

function renderMenuItem(h, options) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(`misc.buttons.${options.label || options.type}`),
        color: options.color,
        icon: options.icon || options.type,
        dense: true
      },
      on: {
        click: () => {
          this.$emit(`${services.contacts}.${options.type}`, this.value.id)
        }
      }
    }
  )
}

function renderMenu(h) {
  return h(
    components.menu,
    {
      class: 'hover-child',
      props: {
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end']
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.more_vert,
            mode: buttonModes.flat
          },
          slot: 'activator'
        }
      ),

      h(
        components.list,
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderMenuItem.call(this, h, {
            type: 'update',
            icon: icons.edit,
            label: 'edit'
          }),
          renderMenuItem.call(this, h, {
            type: 'remove',
            color: colors.error,
            icon: icons.delete
          })
        ]
      )
    ]
  )
}

function renderTableData(h) {
  return contactFields.map(field => {
    switch (field) {
      case 'custom01':
      case 'custom02':
      case 'custom03': {
        if (this.viewport.size.width > 1440) {
          return h('td', { class: 'pl-2 pr-2' }, this.value[field])
        }
        break
      }
      case 'createdAt': {
        return h(
          'td',
          {
            class: 'pl-2 pr-2'
          },
          [
            h(
              date,
              {
                props: {
                  value: this.value
                }
              }
            )
          ]
        )
      }
      case 'actions': {
        return h(
          'td',
          {
            class: 'pl-0 pr-0 w--100'
          },
          [
            h(
              'div',
              {
                class: 'fjcfe facfe'
              },
              [ renderMenu.call(this, h) ]
            )
          ]
        )
      }

      default: {
        return h('td', { class: 'pl-2 pr-2' }, this.value[field])
      }
    }
  })
}

function renderTableRow(h) {
  return h(
    'tr',
    {},
    renderTableData.call(this, h)
  )
}

function renderPanelRow(h, path) {
  if (this.value[path]) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${services.contacts}.labels.${path}`)),
        h('td', {}, this.value[path])
      ]
    )
  }
}

function renderPanelRowDate(h) {
  const date = this.value.date ? getLocaleDateString(this.value.date) : undefined
  if (date) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${services.contacts}.labels.date`)),
        h('td', {}, date)
      ]
    )
  }
}
function renderPanelRowGender(h) {
  const gender = this.value.gender ? this.getTranslate(`misc.genders.${this.value.gender}`) : undefined
  if (gender) {
    return h(
      'tr',
      {},
      [
        h('td', {}, this.getTranslate(`${services.contacts}.labels.gender`)),
        h('td', {}, gender)
      ]
    )
  }
}

function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: ({ expanded }) => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header pr-0'
            },
            [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  h('div', {}, [ this.value.lastName, this.value.firstName ].join(' ')),
                  h('div', { class: 'caption text--grey' }, [ this.value.phone || this.value.email ])
                ]
              ),

              h('div', { class: 'ff' }),

              h(ExpandArrow, { props: { expanded } })
            ]
          )
        },
        default: () => {
          return h(
            'div',
            {
              class: 'faifs'
            },
            [
              h(
                'table',
                {
                  class: `${services.contacts}-table`
                },
                [
                  renderPanelRow.call(this, h, 'lastName'),
                  renderPanelRow.call(this, h, 'firstName'),
                  renderPanelRow.call(this, h, 'middleName'),
                  renderPanelRow.call(this, h, 'phone'),
                  renderPanelRow.call(this, h, 'email'),

                  renderPanelRowDate.call(this, h),
                  renderPanelRowGender.call(this, h),

                  renderPanelRow.call(this, h, 'custom01'),
                  renderPanelRow.call(this, h, 'custom02'),
                  renderPanelRow.call(this, h, 'custom03'),
                  renderPanelRow.call(this, h, 'custom04'),
                  renderPanelRow.call(this, h, 'custom05'),
                  renderPanelRow.call(this, h, 'custom06'),
                  renderPanelRow.call(this, h, 'custom07'),
                  renderPanelRow.call(this, h, 'custom08'),
                  renderPanelRow.call(this, h, 'custom09'),
                  renderPanelRow.call(this, h, 'custom10'),
                  renderPanelRow.call(this, h, 'custom11'),
                  renderPanelRow.call(this, h, 'custom12'),
                  renderPanelRow.call(this, h, 'custom13'),
                  renderPanelRow.call(this, h, 'custom14'),
                  renderPanelRow.call(this, h, 'custom15'),
                  renderPanelRow.call(this, h, 'custom16'),
                  renderPanelRow.call(this, h, 'custom17'),
                  renderPanelRow.call(this, h, 'custom18'),
                  renderPanelRow.call(this, h, 'custom19'),
                  renderPanelRow.call(this, h, 'custom20')
                ]
              ),

              renderMenu.call(this, h)
            ]
          )
        }
      }
    }
  )
}

export default function(h) {
  switch (true) {
    case this.viewport.breakpoint.smDown: {
      return renderPanel.call(this, h)
    }

    case this.viewport.breakpoint.lgUp:
    default: {
      return renderTableRow.call(this, h)
    }
  }
}
