import Vue from 'vue'

import { get } from 'lodash'

import render from './render'

export default {
  name: 'Billings',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    id: String
  },

  data() {
    return {
      data: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.get()
      },
      deep: true
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      let ids = get(this.value, 'ids', [])
      if (!ids.length) {
        if (this.id) {
          ids = [ this.id ]
        }
      }
      if (ids.length) {
        this.data = await Promise.all(ids.map(async id => {
          const { data } = await Vue.$GRequest.get('billings', id)

          return data
        }))
      }
    }
  },

  render
}
