import { buttonModes, colors, icons } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: this.isFavorite ? icons.favorite : icons.favorite_outline,
        color: this.isFavorite ? colors.error : undefined,
        tooltip: this.getTranslate(`${this.service}.tooltips.favorites`)
      },
      on: {
        click: this.clickHandler
      }
    }
  )
}
