/*
  Все пути в этом файле должны быть относительными
  иначе мы не сможем использовать этот компонент в компонентах из миксинов
*/
import { colors, modes, sizes } from '../../utils/constants'
import { eventHandlers } from '../../utils/mixins'
import { form } from '../../utils/mixins/props/form'

import render from './render'

const className = 'btn'

export default {
  name: 'Btn',

  mixins: [ form, eventHandlers ],

  props: {
    label: {
      type: [ String, Number, Boolean ],
      default: undefined
    },

    icon: String,
    iconSVG: String,
    iconLibrary: String,
    iconColor: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    prefix: String,
    suffix: String,
    tooltip: String,

    loading: Boolean,
    disabled: Boolean,

    fullWidth: Boolean,

    type: {
      type: String,
      default: 'button'
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },
    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    mode: {
      type: String,
      default: modes.filled,
      validator: value => {
        return Object.values(modes).includes(value)
      }
    }
  },

  computed: {
    $attrs() {
      return {
        type: this.type,
        form: this.form,
        formaction: this.formaction,
        formenctype: this.formenctype,
        formmethod: this.formmethod,
        formnovalidate: this.formnovalidate,
        formtarget: this.formtarget
      }
    },
    $class() {
      return {
        [`${className}`]: true,

        [`${className}--icon`]: this.hasIcon || !!this.$slots.icon,
        [`${className}--label`]: !!this.$label,

        [`${className}--prefix`]: !!this.prefix || !!this.icon,
        [`${className}--suffix`]: !!this.suffix,

        [`${className}--${this.size}`]: true,
        [`${className}--${this.mode}`]: true,

        [`${className}--${this.color}`]: !!this.color,

        [`${className}--loading`]: this.loading,
        [`${className}--disabled`]: this.disabled,

        [`${className}--full-width`]: this.fullWidth
      }
    },
    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip,
            placement: 'top'
          }
        })
      }

      return result
    },

    hasIcon() {
      return !!this.icon || !!this.iconSVG || !!this.$slots.icon || !!this.prefix
    },

    $icon() {
      return this.iconSVG || this.icon || this.$slots.icon || this.prefix
    },
    $iconSize() {
      switch (this.size) {
        case sizes.tiny: return 12
        case sizes.small: return 14
        case sizes.medium: return 20
      }
    },
    $iconColor() {
      let result = this.color
      if (this.mode === modes.filled) {
        if (this.color === colors.white) {
          result = colors.black
        } else {
          result = colors.white
        }
      }
      if (this.iconColor) {
        result = this.iconColor
      }

      return result
    },

    $label() {
      switch (typeof this.label) {
        case 'number':
        case 'boolean': return this.label.toString()
        case 'string': return this.label
      }
    },

    preloaderColor() {
      let result = this.color
      if (this.mode === modes.filled) {
        if (this.color === colors.white) {
          result = colors.black
        } else {
          result = colors.white
        }
      }

      return result
    },
    preloaderCount() {
      let result = 3
      if (this.icon || !this.$label || this.$label && this.$label.length < 3) {
        result = 2
      }

      return result
    }
  },

  render
}
