import { colors, modes } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (typeof this.html === 'string') {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        h(
          'g-checkbox',
          {
            style: { 'margin-left': '-14px' },
            props: { value: this.proxy },
            on: {
              input: event => {
                this.proxy = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'link link--dashed',
            on: {
              click: () => {
                this.showDialog = true
              }
            }
          },
          this.getTranslate('payments.hints.offer')
        ),

        h(
          'g-dialog',
          {
            props: {
              value: this.showDialog,
              rounded: true,
              maxWidth: 1000
            },
            on: {
              input: event => {
                this.showDialog = event
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'pa-3',
                domProps: { innerHTML: this.html }
              }
            ),

            h(
              'div',
              {
                class: 'fjcfe facfe grid-gap--8 pa-2',
                slot: 'footer'
              },
              [
                h(
                  button,
                  {
                    props: {
                      label: this.getTranslate('misc.buttons.close'),
                      mode: modes.flat

                    },
                    on: {
                      click: () => {
                        this.showDialog = false
                      }
                    }
                  }
                ),

                h(
                  button,
                  {
                    props: {
                      label: this.getTranslate('misc.buttons.accept'),
                      color: colors.primary
                    },
                    on: {
                      click: () => {
                        this.proxy = true
                        this.showDialog = false
                      }
                    }
                  }
                )
              ]
            )
          ]
        )
      ]
    )
  }
}
