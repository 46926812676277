export default {
  title: 'Financial report',

  advanced: 'Advanced',

  labels: {
    id: 'ID',
    owner: 'Owner',
    action: 'Action',
    source: 'Source',
    amount: 'Amount',
    multiplier: 'Multiplier',
    comment: 'Comment',
    preset: 'Preset',
    status: 'Status',
    tariff: 'Tariff',
    noRef: 'Non referralable',
    price: 'Price',
    OwnerId: 'Owner',
    TariffId: 'Tariff',

    ref: {
      true: 'Referralable',
      false: 'Non referralable'
    },

    data: {
      price: 'Price',
      multiplier: 'Multiplier'
    }
  },

  headers: {
    status: 'Status',
    owner: 'Owner',
    creator: 'Creator',
    source: 'Source',
    tariff: 'Tariff',
    comment: 'Comment',
    amount: 'Amount',
    total: 'Total',
    title: 'Title',
    frequency: 'Frequency',
    count_pending: 'Pending',
    sum_pending: 'Pending (sum)',
    count_refunded: 'Refunded',
    sum_refunded: 'Refunded (sum)',
    count_complete: 'Complete',
    sum_complete: 'Complete (sum)',
    count_invalid: 'Invalid',
    sum_invalid: 'Invalid (sum)',
    count_total: 'Total',
    sum_total: 'Total (sum)',
    referrer: 'Referrer'
  },

  dialogs: {
    create: 'Create new billing'
  },

  actions: {
    addFunds: 'Add funds',
    charge: 'Charge'
  },

  filter: {
    date: {
      from: 'Date from',
      by: 'Date by'
    },
    time: {
      from: 'Time from',
      by: 'Time by'
    }
  },

  hints: {
    amount: 'The default multiplier is 1. Edit it in extra.',
    date: {
      from: 'YYYY-MM-DD',
      by: 'YYYY-MM-DD'
    },
    time: {
      from: 'HH:MM',
      by: 'HH:MM'
    },
    empty: 'To get data use the filter.'
  },

  presets: {
    billings: {
      details: 'Billings details',
      group001: 'Group by source'
    }
  },

  report: {
    download: 'Download',
    get: 'Get report'
  },

  statuses: {
    pending: 'Pending',
    refunded: 'Refunded',
    complete: 'Complete',
    invalid: 'Invalid'
  },

  buttons: {
    clear: 'Clear filter',
    refresh: 'Refresh'
  },

  tooltips: {
    refresh: 'Refresh table data using the selected filter.',
    actions: 'Accrual or subtraction of funds'
  },

  tabs: {
    billings: 'Billings',
    tasks: 'Tasks'
  },

  sources: {
    subtract: 'Subtract',
    accrual: 'Accrual',
    sms: 'SMS',
    voice: 'Voice',
    email: 'E-mail',
    viber: 'Viber',
    vk: 'VK',
    whatsapp: 'WhatsApp',
    push: 'Push'
  },

  snackbars: {
    created: 'Billing successfully created.',
    updated: 'Billing successfully updated.',
    removed: 'Billing successfully removed.',
    funds: {
      success: 'Funds add success.',
      failed: 'Funds add failed.'
    }
  },

  currency: {
    RUB: '₽ russian rubles',
    USD: '$ US dollars',
    EUR: '€ euros'
  }
}
