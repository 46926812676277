import { cloneDeep } from 'lodash'

import { buttonModes, colors, components, services } from '@/utils'

import button from '@/components/button'
import form from '@/components/services/documentTemplates/generate/form/invoice'

function renderForm(h) {
  return h(
    form,
    {
      class: 'pt-2 px-2',
      props: {
        value: this.proxy,
        OwnerId: this.OwnerId,
        ResellerId: this.ResellerId
      },
      on: {
        input: event => {
          this.proxy = event
        },
        validation: event => {
          this.formValidation = event
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.close()
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.detach'),
            mode: buttonModes.flat,
            color: colors.error
          },
          on: {
            click: () => {
              this.inputHandler(false)
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            color: colors.primary,
            disabled: !this.validation
          },
          on: {
            click: () => {
              // здесь нужен cloneDeep чтобы когда форма GenerateDocumentForm
              // данные по ссылке снаружи не превратились в undefined
              // так как у этой формы есть цикл beforeDestroy
              // который обнуляет все поля
              this.inputHandler(cloneDeep(this.proxy))
            }
          }
        }
      )
    ]
  )
}
export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.invoices}.labels.create`),
        value: this.show,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: this.showHandler
      }
    },
    [
      renderForm.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
