import proxy from '@sigma-legacy-libs/g-proxy'

import { DOCUMENT_TEMPLATES_TYPES, REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, permissionPresets, services, toFloatOrUndefined } from '@/utils'
import { hasPermission } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'GenerateDocumentForm',

  mixins: [
    proxy({ type: 'object' }),

    generateServices([
      {
        name: services.requisites,

        find: {
          useCache: true,
          defaultFilter: {
            isActive: true,
            type: REQUISITE_TYPES.business,
            OwnerId: undefined
          }
        },
        get: false,
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      },
      {
        name: services.companies,

        find: {
          useCache: true,
          defaultFilter: {
            vat: undefined,
            $scope: [ 'documentTypes' ],
            OwnerId: undefined
          }
        },
        get: false,
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      }
    ]),
    hasPermission
  ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    allowedTypes: {
      type: Array,
      default: () => []
    },

    isNew: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },

    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      requisite: {
        vat: false
      }
    }
  },

  computed: {
    $types() {
      const company = this.restData.companies.find.data.find(item => item.id === this.proxy.companyId)
      if (company) {
        return DOCUMENT_TEMPLATES_TYPES.reduce((result, type) => {
          if (
            Array.isArray(company.DocumentTemplatesTypes) &&
            company.DocumentTemplatesTypes.length &&
            ~company.DocumentTemplatesTypes.indexOf(type) &&
            (!this.allowedTypes.length || ~this.allowedTypes.indexOf(type))
          ) {
            result.push(type)
          }

          return result
        }, [])
      }
    },

    $hasTemplate() {
      if (Array.isArray(this.$types) && this.$types.length) {
        return true
      }

      return false
    },

    validation() {
      if (!this.proxy.customerId) {
        return false
      }
      if (!this.proxy.requisiteId) {
        return false
      }
      if (!this.proxy.companyId) {
        return false
      }
      if (!this.$hasTemplate) {
        return false
      }
      if (!this.proxy.amount) {
        return false
      }

      return true
    }
  },

  watch: {
    $types() {
      if (this.$types && this.$types.length) {
        this.proxy.type = this.$types[0]
      }
    },

    validation() {
      this.$emit('validation', this.validation)
    },

    'proxy.customerId'() {
      this.getByCustomerId()
    },

    'proxy.requisiteId'(value) {
      this.proxy.companyId = undefined
      this.proxy.type = undefined
      if (value) {
        const requisite = this.restData.requisites.find.data.find(item => item.id === value)
        if (requisite.data.tax.vat !== this.requisite.vat) {
          this.requisite.vat = requisite.data.tax.vat
        }
        this.getCompanies()
      }
    }
  },

  mounted() {
    if (this.proxy.customerId === undefined) {
      this.proxy.customerId = this.OwnerId || this.account.id
    }
    this.getByCustomerId()
    this.proxy.amount = 1000
    if (this.$types && this.$types.length) {
      this.proxy.type = this.$types[0]
    }
  },

  beforeDestroy() {
    this.proxy.customerId = undefined
    this.proxy.requisiteId = undefined
    this.proxy.companyId = undefined
    this.proxy.type = undefined
    this.proxy.amount = 1000
  },

  methods: {
    _outputFilter(data) {
      if (data.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
        data.amount = toFloatOrUndefined(data.amount)
      }

      return data
    },

    async getRequisites() {
      this.restData.requisites.find.filter.OwnerId = this.proxy.customerId

      const requisites = await this.rest.requisites.find()
      if (Array.isArray(requisites) && requisites.length) {
        this.proxy.requisiteId = requisites[0].id
        this.requisite.vat = requisites[0].data.tax.vat
      } else {
        this.proxy.requisiteId = undefined
        this.requisite.vat = undefined
      }
    },

    async getCompanies() {
      this.restData.companies.find.filter.vat = this.requisite.vat
      this.restData.companies.find.filter.OwnerId = this.proxy.customerId

      if (this.checkPermissions(`advanced.${services.companies}.get`, permissionPresets.resellerUp)) {
        this.restData.companies.find.filter.OwnerId = this.ResellerId || '$global'
      }

      const companies = await this.rest.companies.find()
      if (Array.isArray(companies) && companies.length) {
        this.proxy.companyId = companies[0].id
      } else {
        this.proxy.companyId = undefined
      }
    },

    getByCustomerId() {
      this.proxy.requisiteId = undefined
      this.proxy.companyId = undefined
      this.getRequisites()
    }
  },

  render
}

