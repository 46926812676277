import { PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, icons } from '@/utils'

export const paymentStatusIcons = {
  [PAYMENTS_STATUSES.init]: icons.schedule,
  [PAYMENTS_STATUSES.completed]: icons.check_circle_outline,
  [PAYMENTS_STATUSES.refundRequested]: icons.replay,
  [PAYMENTS_STATUSES.refunded]: icons.reply_all,
  [PAYMENTS_STATUSES.partialRefunded]: icons.reply,
  [PAYMENTS_STATUSES.error]: icons.error_outline
}

export const paymentStatusColors = {
  [PAYMENTS_STATUSES.init]: colors.grey,
  [PAYMENTS_STATUSES.completed]: colors.success,
  [PAYMENTS_STATUSES.refundRequested]: colors.info,
  [PAYMENTS_STATUSES.refunded]: colors.info,
  [PAYMENTS_STATUSES.partialRefunded]: colors.info,
  [PAYMENTS_STATUSES.error]: colors.error
}
