import { modes, services } from '@/utils'

import dialog from '@/components/services/eventsHistory/dialog'
import button from '@/components/button'

function renderDialog(h) {
  return h(
    dialog,
    {
      props: {
        entityId: this.entityId,
        show: this.showDialog
      },
      on: {
        dialog: event => {
          this.showDialog = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: 'manage_history',
        label: this.label ? this.getTranslate(`${services.eventsHistory}.labels.eventsHistory`) : undefined,
        mode: modes.flat,
        disabled: this.disabled
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${services.eventsHistory}.tooltips.historyButton`),
            placement: 'top'
          }
        }
      ],
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
