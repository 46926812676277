import { icons, modes, states } from '@/utils'

import button from '@/components/button'

export default function(h) {
  switch (this.type) {
    case 'button': {
      return h(
        button,
        {
          class: this.classes,
          props: {
            label: !this.icon && (this.text || this.getTranslate('misc.buttons.upload')),
            icon: this.buttonIcon || icons.file_upload,
            mode: modes.flat,
            color: this.color,
            loading: this.loading || this.state === states.loading,
            disabled: this.$disabled
          },
          on: {
            click: async () => {
              const file = document.getElementById('file')
              await this.setCurrentAttributes()
              if (!this.$disabled && file) {
                file.click()
              }
            }
          }
        }
      )
    }
    case 'drop': {
      let style
      if (this.$disabled) {
        style = {
          opacity: 0.5,
          cursor: 'default',
          'user-select': 'none'
        }
      }

      return h(
        'div',
        {
          style,
          on: {
            dragover: this.dragover,
            dragleave: this.dragleave,
            dragend: this.dragleave,
            drop: this.drop
          }
        },
        [ this.$slots.default ]
      )
    }
  }
}
