import { services } from '@/utils'

import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import overflowString from '@/components/misc/overflowString'
import tag from '@/components/tag'

function renderIcon(h) {
  if (this.as === 'text') {
    return h(
      squircle,
      {
        props: {
          color: this.$iconSVG,
          tooltip: this.getTranslate(`${services.sendings}.types.${this.$iconSVG}`)
        }
      },
      [
        h(
          svgIcon,
          {
            props: {
              value: this.$iconSVG,
              color: this.$iconSVG,
              size: 18
            }
          }
        )
      ]
    )
  }
}
function renderTitle(h) {
  if (this.as === 'tag') {
    return h(
      tag,
      {
        props: {
          label: this.title,
          tooltip: this.getTranslate(`${services.sendings}.types.${this.$iconSVG}`),
          color: this.$iconSVG,
          as: this.dense ? 'select-dense' : 'select'
        }
      }
    )
  } else {
    return h(
      overflowString,
      {
        props: {
          value: this.title,
          font: {
            size: 13,
            lineHeight: 15
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      ...this.attributes
    },
    [
      renderIcon.call(this, h),
      renderTitle.call(this, h)
    ]
  )
}
