import proxy from '@sigma-legacy-libs/g-proxy'

import { mapGetters } from 'vuex'

import render from './render'
import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'
import { services } from '@/utils'

export default {
  name: 'PaymentsMethodSelect',

  mixins: [ proxy() ],

  props: {
    isFilter: {
      type: Boolean,
      default: false
    },

    dense: Boolean,
    clearable: Boolean,

    required: Boolean,
    disabled: Boolean
  },

  computed: {
    ...mapGetters({ paymentsMethods: 'payments/methods' }),

    items() {
      let methods = this.paymentsMethods
      if (this.isFilter) {
        methods = PAYMENTS_METHODS
      }

      return methods.map(method => ({
        title: this.getTranslate(`${services.payments}.methods.${method}`),
        value: method
      }))
    }
  },

  render
}
