import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, positiveNumberTwoSymbolsAfterDotRegex, services } from '@/utils'

// NOTE: Так как счета мы пока не умеем выставлять
// ни в какой другой валюте кроме RUB
// то и валюта суммы счета всегда RUB
export default function(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${services.invoices}.labels.amount`),
        suffix: this.getTranslate(`currency.${BILLING_CURRENCY.RUB}.symbol.unicode`),
        mode: inputModes['line-label'],
        disabled: this.disabled,
        rounded: true,
        required: true,
        autofocus: true,
        clearable: true,
        details: false,
        mask: positiveNumberTwoSymbolsAfterDotRegex,
        processValue: value => value?.toString().replace(',', '.')
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
