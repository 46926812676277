import { isPlainObject } from 'lodash'

import { colors, components, notificationsIcons, services } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import panel from '@/components/services/notifications/settingsUser/panel'
import squircle from '@/components/icon/squircle'
import preloader from '@/components/misc/preloader'

function renderPanel(h, path) {
  if (this.settings[path]) {
    return h(
      panel,
      {
        props: {
          path,
          value: this.settings[path],
          loading: this.loadings[path],
          options: this.options
        },
        key: path,
        on: {
          input: event => {
            this.setNewSetting(path, event)
          },
          click: event => {
            this.setLoading(path, event)
          }
        }
      }
    )
  }
}

function renderContent(h) {
  if (isPlainObject(this.schema)) {
    return h(
      components['expansion-panels'],
      {
        props: {
          outline: true,
          rounded: true
        }
      },
      Object.keys(this.schema).map(key => {
        return h(
          components['expansion-panel'],
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header faic grid-gap--8 pl-2 pr-0'
                  },
                  [
                    h(
                      squircle,
                      {
                        props: {
                          iconSVG: notificationsIcons[key],
                          color: colors.grey
                        }
                      }
                    ),

                    this.getTranslate(`${services.notifications}.labels.${services.settingsUser}.templates.${key}`),

                    h('div', { class: 'ff' }),

                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [ this.schema[key].map(path => renderPanel.call(this, h, path)) ]
        )
      })
    )
  } else if (Array.isArray(this.schema)) {
    return h(
      components.card,
      {
        props: {
          outline: true,
          rounded: true,
          overflow: true
        }
      },
      [ this.schema.map(path => renderPanel.call(this, h, path)) ]
    )
  }
}

export default function(h) {
  if (this.settings) {
    return h(
      'div',
      {
        class: `${services.notifications}-${services.settingsUser}`
      },
      [ renderContent.call(this, h) ]
    )
  } else {
    if (this.loading.find) {
      return h(
        preloader,
        {
          props: {
            value: true
          }
        }
      )
    }
  }
}
