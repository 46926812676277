import { components, isEmpty } from '@/utils'

function renderCaption(h) {
  if (this.caption) {
    return h(
      'caption',
      {
        class: `${components.table}__caption`
      },
      [ this.caption ]
    )
  }
}

function renderColgroup(h) {
  if (this.hasCols) {
    return h(
      'colgroup',
      {
        class: `${components.table}__colgroup`
      },
      [
        this.cols.map((col, index) => {
          return this.$scopedSlots.col ? this.$scopedSlots.col({
            col,
            index
          }) : this.$slots.col
        })
      ]
    )
  }
}

function renderHeader(h, header) {
  if (header) {
    const { value, title, sortable, align, width } = header

    return h(
      'th',
      {
        class: [
          `${components.table}__head-cell`,
          `${components.table}__head-cell--${align || 'center'}`,
          header.class
        ]
      },
      [
        h(
          'span',
          {
            class: {
              [`${components.table}__head-cell-title`]: true,
              [`${components.table}__head-cell-title--sort`]: sortable,
              [`${components.table}__head-cell-title--sort-desc`]: sortable && !isEmpty(this.proxy[value]) && !!~[ 'desc', 'DESC', '-1', -1 ].indexOf(this.proxy[value]),
              [`${components.table}__head-cell-title--sort-active`]: sortable && !isEmpty(this.proxy[value])
            },
            style: { 'min-width': typeof width === 'string' ? width : width || 0 + 'px' },
            domProps: { innerHTML: title },
            on: {
              click: () => {
                this.sorting(value, sortable)
              }
            }
          }
        )
      ]
    )
  }
}

function renderHead(h) {
  if (this.hasHead) {
    return h(
      'thead',
      {
        class: `${components.table}__head`
      },
      [
        h(
          'tr',
          {
            class: `${components.table}__head-row`
          },
          this.headers.map((header, index) => {
            return this.$scopedSlots.header ? this.$scopedSlots.header({
              header,
              index
            }) : this.$slots.header || renderHeader.call(this, h, header)
          })
        )
      ]
    )
  }
}

function renderBody(h) {
  if (this.hasBody) {
    return h(
      'tbody',
      {
        class: `${components.table}__body`
      },
      [
        this.computedItems.map((item, index) => {
          return this.$scopedSlots.items ? this.$scopedSlots.items({
            item,
            index
          }) : this.$slots.items
        })
      ]
    )
  } else {
    return h(
      'div',
      {
        class: `${components.table}__empty`
      },
      [ this.$slots.empty || h(components.empty) ]
    )
  }
}

function renderFoot(h) {
  if (this.hasFoot) {
    return h(
      'tfoot',
      {
        class: `${components.table}__foot`
      },
      [
        h(
          'tr',
          {
            class: `${components.table}__foot-row`
          },
          [ this.$slots.foot ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'table',
    {
      class: {
        [`${components.table}`]: true,
        [`${components.table}--dense`]: this.dense,
        [`${components.table}--tiny`]: this.tiny
      }
    },
    [
      renderCaption.call(this, h),
      renderColgroup.call(this, h),
      renderHead.call(this, h),
      renderBody.call(this, h),
      renderFoot.call(this, h)
    ]
  )
}
