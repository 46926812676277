import { buttonModes, components, services } from '@/utils'

import button from '@/components/button'
import formatTable from '@/components/format/table'

function renderBody(h) {
  return h(
    formatTable,
    {
      props: {
        value: this.$_data,
        service: services.notifications
      }
    }
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            label: this.getTranslate('misc.buttons.close')
          },
          on: {
            click: () => {
              this.proxy = false
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.notifications}.labels.notification`),
        value: this.proxy,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 600 : undefined
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
