import { colors, icons, modes, services } from '@/utils'

import button from '@/components/button'
import svgIcon from '@/components/icon/svg'
import squircle from '@/components/icon/squircle'

function renderProviderSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.providerId,
        service: 'providers',
        query: { isActive: true },
        label: this.getTranslate(`${services.sendingsModeration}.labels.defaultRoute`),
        autocomplete: true,
        clearable: true,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.providerId = event
        }
      }
    }
  )
}

function renderInfoButton(h) {
  return h(
    button,
    {
      props: {
        mode: modes.flat,
        color: colors.grey,
        icon: icons.question_mark,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.info`)
      }
    }
  )
}

function renderRejectButton(h) {
  return h(
    button,
    {
      props: {
        mode: modes.flat,
        icon: this.icon.rejected.icon,
        color: this.icon.rejected.color,
        loading: this.loading,
        disabled: this.disabled,
        tooltip: this.massive
          ? this.getTranslate(`${services.sendingsModeration}.actions.massive.rejected`)
          : this.getTranslate(`${services.sendingsModeration}.actions.rejected`)
      },
      slot: 'activator'
    }
  )
}

function renderRejectMenuItems(h) {
  return [
    h(
      'g-list-item',
      {
        props: {
          label: this.massive
            ? this.getTranslate(`${services.sendingsModeration}.actions.massive.rejected`)
            : this.getTranslate(`${services.sendingsModeration}.actions.rejected`),
          dense: true
        },
        scopedSlots: {
          prefix: () => {
            return h(
              squircle,
              {
                props: {
                  icon: this.icon.rejected.icon,
                  color: colors.error
                }
              }
            )
          }
        },
        on: {
          click: () => {
            this.createSendingsModerationTask('rejected')
          }
        }
      }
    ),

    h(
      'g-list-item',
      {
        props: {
          label: this.massive
            ? this.getTranslate(`${services.sendingsModeration}.actions.massive.rejectedRefound`)
            : this.getTranslate(`${services.sendingsModeration}.actions.rejectedRefound`),
          dense: true,
          color: colors.error
        },
        scopedSlots: {
          prefix: () => {
            return h(
              squircle,
              {
                props: {
                  color: colors.error
                }
              },
              [
                h(
                  svgIcon,
                  {
                    props: {
                      value: this.globalSettings.billings.currency,
                      color: colors.error,
                      size: 22
                    }
                  }
                )
              ]
            )
          }
        },
        on: {
          click: () => {
            this.refund = true
            this.createSendingsModerationTask('rejected')
          }
        }
      }
    )
  ]
}

function renderRejectMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        disabled: this.disabled,
        placement: 'bottom-end',
        width: this.massive ? 260 : 225
      }
    },
    [
      renderRejectButton.call(this, h),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [ renderRejectMenuItems.call(this, h) ]
      )
    ]
  )
}

function renderApproveButton(h) {
  return h(
    button,
    {
      props: {
        mode: modes.flat,
        icon: this.massive ? icons.done_all : icons.done,
        color: colors.success,
        loading: this.loading,
        disabled: this.disabled,
        tooltip: this.massive
          ? this.getTranslate(`${services.sendingsModeration}.actions.massive.approved`)
          : this.getTranslate(`${services.sendingsModeration}.actions.approved`)
      },
      on: {
        click: () => {
          this.createSendingsModerationTask('approved')
        }
      }
    }
  )
}

function renderButtons(h) {
  return [
    renderProviderSelect.call(this, h),

    h(
      'div',
      {
        class: `${services.sendingsModeration}-actions__buttons hover-child`
      },
      [
        renderInfoButton.call(this, h),
        renderApproveButton.call(this, h),
        renderRejectMenu.call(this, h)
      ]
    )
  ]
}

function renderApproveMenuItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate(`${services.sendingsModeration}.actions.approved`),
        color: colors.success,
        disabled: this.disabled,
        dense: true
      },
      scopedSlots: {
        prefix: () => {
          return h(
            squircle,
            {
              props: {
                icon: icons.done,
                color: colors.success
              }
            }
          )
        }
      },
      on: {
        click: () => {
          this.createSendingsModerationTask('approved')
        }
      }
    }
  )
}

function renderMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        closeOnContentClick: false,
        placement: 'bottom-end',
        width: 250
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: modes.flat,
            icon: icons.more_vert
          },
          slot: 'activator'
        }
      ),
      [
        h(
          'g-card',
          {
            props: {
              rounded: true,
              overflow: true
            }
          },
          [
            h(
              'div',
              {
                class: 'pa-2'
              },
              [ renderProviderSelect.call(this, h) ]
            ),
            renderApproveMenuItem.call(this, h),
            renderRejectMenuItems.call(this, h)
          ]
        )
      ]
    ]
  )
}

function renderContentByViewport(h) {
  if (this.viewport.breakpoint.xl || this.massive) {
    return renderButtons.call(this, h)
  } else {
    return renderMenu.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-actions`,
      style: {
        gridTemplateColumns: this.gridTemplateColumns
      }
    },
    [ renderContentByViewport.call(this, h) ]
  )
}

