import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Info',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [ 'auto', '200px' ]
      if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp) && this.viewport.breakpoint.smUp) {
        result.push('150px')
      }

      return result.join(' ')
    }
  },

  render
}
