export const services = {
  apisender: 'apisender',
  billings: 'billings',
  companies: 'companies',
  contacts: 'contacts',
  contactLists: 'contactLists',
  contracts: 'contracts',
  credentials: 'credentials',
  dadata: 'dadata',
  dictionaries: 'dictionaries',
  documentTemplates: 'documentTemplates',
  eventsHistory: 'eventsHistory',
  invoices: 'invoices',
  keywords: 'keywords',
  keywordLists: 'keywordLists',
  login: 'login',
  messageRules: 'messageRules',
  messageRuleGroups: 'messageRuleGroups',
  operatorGroups: 'operatorGroups',
  'otp-settings': 'otp-settings',
  'otp-settings/widgets': 'otp-settings/widgets',
  'otp-settings/channels': 'otp-settings/channels',
  'otp-settings/templates': 'otp-settings/templates',
  notifications: 'notifications',
  patterns: 'patterns',
  payments: 'payments',
  'message-payload-generator': 'message-payload-generator',
  permissions: 'permissions',
  providers: 'providers',
  providerGroups: 'providerGroups',
  providersPrices: 'providersPrices',
  recover: 'recover',
  recurrentPayments: 'recurrentPayments',
  registration: 'registration',
  reports: 'reports',
  routingGroups: 'routingGroups',
  routingRules: 'routingRules',
  requisites: 'requisites',
  sendernames: 'sendernames',
  sendings: 'sendings',
  sendingsIncoming: 'sendingsIncoming',
  sendingsModeration: 'sendingsModeration',
  servers: 'servers',
  settingsSite: 'settingsSite',
  settingsUser: 'settingsUser',
  storage: 'storage',
  tariffs: 'tariffs',
  tasks: 'tasks',
  telemetry: 'telemetry',
  templates: 'templates',
  tokens: 'tokens',
  userComments: 'userComments',
  users: 'users'
}

export const nestjsServices = {
  dadata: 'dadata',
  invoices: 'invoices',
  'otp-settings': 'otp-settings',
  'otp-settings/widgets': 'otp-settings/widgets',
  'otp-settings/channels': 'otp-settings/channels',
  'otp-settings/templates': 'otp-settings/templates',
  notifications: 'notifications'
}
