import { mapGetters } from 'vuex'

import { projectName } from '@/utils'

import render from './render'

const prefixKey = `${projectName}:notifications`

export default {
  name: 'Notifications',

  data() {
    return {
      notifications: [],
      showDialog: false
    }
  },

  computed: {
    ...mapGetters({
      localSnackbars: 'notifications/localSnackbars'
    })
  },

  watch: {
    localSnackbars: {
      handler() {
        this.getNotifications()
      },
      deep: true
    }
  },

  mounted() {
    this.getNotifications()
  },

  methods: {
    getNotifications() {
      this.notifications = JSON.parse(window.localStorage.getItem(prefixKey)) || []
    },

    clearNotifications() {
      window.localStorage.removeItem(prefixKey)
      this.notifications = []
      this.showDialog = false
    }
  },

  render
}
