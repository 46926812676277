import { colors, getString, icons } from '@/utils'

import render from './render'

export default {
  name: 'noData',

  props: {
    value: [ String, Number, Boolean ],
    label: [ String, Number, Boolean ],

    icon: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(icons).includes(value)
      }
    }
  },

  computed: {
    $_value() {
      return getString(this.value)
    },
    $_label() {
      return getString(this.label)
    },

    $props() {
      const result = {
        value: this.$_value ? this.$_value : this.getTranslate('misc.no.data.value'),
        label: this.$_label,
        icon: this.icon,
        iconSVG: this.iconSVG,
        color: colors.grey,
        disabled: true
      }

      return result
    }
  },

  render
}
