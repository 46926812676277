import { buttonModes, colors, components, icons, inputModes, services } from '@/utils'

import button from '@/components/button'

function renderRemoveFieldButton(h, item, index, length) {
  return h(
    button,
    {
      props: {
        icon: icons.remove,
        color: colors.error,
        mode: buttonModes.flat,
        disabled: length === 1
      },
      on: {
        click: () => {
          this.remove(index)
        }
      }
    }
  )
}
function renderKeyField(h, { path, serviceName }, item) {
  return h(
    components['text-field'],
    {
      props: {
        value: item.key,
        label: this.getTranslate(`${serviceName}.labels.${path}.domain`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          item.key = event
        }
      }
    }
  )
}
function renderValueField(h, { path, serviceName }, item) {
  return h(
    components.select,
    {
      props: {
        value: item.value,
        label: this.getTranslate(`${serviceName}.labels.${path}.user`),
        service: services.users,
        query: { isActive: true },
        itemTitle: 'username',
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          item.value = event
        }
      }
    }
  )
}
function renderRow(h, options, item, index, length) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': '1fr 1fr auto' }
    },
    [
      renderKeyField.call(this, h, options, item),
      renderValueField.call(this, h, options, item),
      renderRemoveFieldButton.call(this, h, item, index, length)
    ]
  )
}
function renderRows(h, options) {
  return this.proxy.map((item, index, items) => {
    return renderRow.call(this, h, options, item, index, items.length)
  })
}

function renderAddButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        icon: icons.add,
        color: colors.success,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}
function renderSaveButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.save'),
        icon: icons.save,
        color: colors.primary,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.$emit('input', this._outputFilter(this.proxy))
        }
      }
    }
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      renderAddButton.call(this, h),
      renderSaveButton.call(this, h)
    ]
  )
}

export default function(h, options) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`${options.serviceName}.subtitles.${options.path}`),
        outline: true,
        rounded: true
      }
    },
    [
      renderRows.call(this, h, options),
      renderFooter.call(this, h, options)
    ]
  )
}
