import Vue from 'vue'

import { cloneDeep, get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { RECURRENT_PAYMENTS_FILTER, fillDependence, generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default function(options = {}) {
  return {
    name: 'RecurrentPaymentsList',

    mixins: [
      generateServices({
        name: services.recurrentPayments,

        async inputFilter(result) {
          if (result.acceptOffer === undefined) {
            result.acceptOffer = false
          }

          if (!result.Owner) {
            await fillDependence.call(this, result, {
              service: services.users,
              permissions: permissionPresets.resellerUp,
              pathToId: 'OwnerId',
              pathToObject: 'Owner',
              outputPath: 'Owner',
              picks: PUBLIC_FIELDS_USER
            })
          }

          result.OwnerId = result.OwnerId || result.Owner && result.Owner.id || options.OwnerId

          return result
        },

        outputFilter(result) {
          delete result.acceptOffer

          return result
        },

        find: {
          defaultFilter: cloneDeep(RECURRENT_PAYMENTS_FILTER),
          alwaysCreateFromWebSocket: true,
          alwaysUpdateFromWebSocket: true,
          alwaysRemoveFromWebSocket: true,

          defaultPagination: {
            limit: 10
          }
        },

        get: false,
        update: false,

        remove: {
          manipulateData: false,
          redirect: false
        }
      })
    ],

    data() {
      return {
        showCreateDialog: false,
        showPaymentsDialog: false,
        showUnlockConfirmDialog: false,
        showRemoveConfirmDialog: false,

        unlockLoading: false,

        RecurrentPaymentId: undefined
      }
    },

    computed: {
      ...mapGetters(
        {
          paymentsMethods: 'payments/methods',
          showCreateRecurrentPaymentDialog: 'payments/showCreateRecurrentPaymentDialog'
        }
      )
    },

    watch: {
      showCreateRecurrentPaymentDialog() {
        this.showDialogFromVuex()
      }
    },

    mounted() {
      if (options.isPage) {
        this.rest[services.recurrentPayments].find()
      } else {
        this.restData[services.recurrentPayments].find.filter.OwnerId = options.OwnerId || this.account.id
      }
      this.showDialogFromVuex()
      const recurrentPaymentIdFromQuery = get(this.$route, 'query.recurrentPaymentId')
      if (recurrentPaymentIdFromQuery) {
        this.restData[services.recurrentPayments].find.filter.id = recurrentPaymentIdFromQuery
      }
    },

    methods: {
      ...mapActions({ setShowCreateRecurrentPaymentDialog: 'payments/setShowCreateRecurrentPaymentDialog' }),

      showDialogFromVuex() {
        if (this.showCreateRecurrentPaymentDialog) {
          setTimeout(() => {
            this.showCreateDialog = true
          }, 100)
        }
      },

      async createRecurrentPayment() {
        if (this.restData[services.recurrentPayments].create.isValid) {
          try {
            const { url } = await this.rest[services.recurrentPayments].create(this.restData[services.recurrentPayments].create.data)
            if (url) {
              window.location.href = url
              this.showCreateDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      },

      async unlockRecurrentPayment() {
        if (this.checkPermissions(`advanced.${services.recurrentPayments}.remove`, permissionPresets.true)) {
          try {
            this.unlockLoading = true
            await Vue.$GRequest.get(services.recurrentPayments, `${this.RecurrentPaymentId}/unlock`)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.unlockLoading = false
            this.showUnlockConfirmDialog = false
          }
        }
      },
      async removeRecurrentPayment() {
        if (this.checkPermissions(`advanced.${services.recurrentPayments}.remove`, permissionPresets.meUp)) {
          try {
            await this.rest[services.recurrentPayments].remove(this.RecurrentPaymentId)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.showRemoveConfirmDialog = false
          }
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
