import { buttonModes, colors, components, sizes } from '../../utils/constants'

import render from './render'

export default {
  name: components.tag,

  props: {
    label: [ String, Number, Boolean ],
    labelColor: String,

    tooltip: String,

    loading: Boolean,
    disabled: Boolean,
    monospace: Boolean,
    strikethrough: Boolean,

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },
    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    mode: {
      type: String,
      default: buttonModes.flat,
      validator: value => {
        return Object.values(buttonModes).includes(value)
      }
    },
    as: {
      type: String,
      default: undefined,
      validator: value => {
        return !!~[ 'button', 'select', 'select-dense' ].indexOf(value)
      }
    }
  },

  computed: {
    $label() {
      switch (typeof this.label) {
        case 'number':
        case 'boolean': return this.label.toString()
        case 'string': return this.label
      }
    },

    $class() {
      const result = {
        [`${components.tag}`]: true,

        [`${components.tag}--${this.mode}`]: true,
        [`${components.tag}-as--${this.as}`]: !!this.as,

        [`${components.tag}--loading`]: this.loading,
        [`${components.tag}--disabled`]: this.disabled,
        [`${components.tag}--monospace`]: this.monospace,
        [`${components.tag}--strikethrough`]: this.strikethrough,

        [`${components.tag}--${this.color}`]: !!this.color
      }
      let size = this.size
      if ([ 'select', 'select-dense' ].includes(this.as)) {
        size = this.as
      }
      result[`${components.tag}--${size}`] = true

      return result
    },

    $font() {
      const result = {}

      switch (this.size) {
        case sizes.tiny: {
          result.size = 10
          break
        }
        case sizes.small: {
          result.size = 11
          break
        }
        case sizes.medium: {
          result.size = 12
          break
        }
        case sizes.large: {
          result.size = 13
          break
        }
        case sizes.huge: {
          result.size = 14
          break
        }
      }

      if (this.as === 'button') {
        result.size = 14
        result.weight = 500
      }

      if (this.as === 'select') {
        result.size = 13
      }

      return result
    },

    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: components.tooltip,
          options: { value: this.tooltip }
        })
      }

      return result
    }
  },

  methods: {
    eventHandler(event) {
      this.$emit(event.type, event)
    }
  },

  render
}
