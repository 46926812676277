import { RECURRENT_PAYMENTS_TYPES, TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, inputModes, modes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'
import PaymentsForm from '@/components/services/payments/form'

function renderTypes(h) {
  return h(
    'div',
    {
      class: 'faic fjcc grid-gap--16'
    },
    RECURRENT_PAYMENTS_TYPES.map(type => {
      return h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.recurrentPayments}.types.${type}`),
            mode: this.proxy.type === type ? modes.filled : modes.flat,
            color: this.proxy.type === type ? colors.primary : undefined
          },
          on: {
            click: () => {
              this.proxy.type = type
            }
          }
        }
      )
    })
  )
}
function renderFieldByType(h) {
  switch (this.proxy.type) {
    case RECURRENT_PAYMENTS_TYPES.threshold: {
      this.proxy.frequency = undefined

      return h(
        'g-text-field',
        {
          props: {
            value: this.proxy.threshold,
            label: this.getTranslate(`${services.recurrentPayments}.labels.threshold`),
            error: this.localErrors.threshold,
            suffix: this.getTranslate('currency.RUB.symbol.unicode'),
            type: 'number',
            step: 'any',
            mode: inputModes['line-label'],
            rounded: true,
            required: true,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.threshold = event
            }
          }
        }
      )
    }

    case RECURRENT_PAYMENTS_TYPES.frequency: {
      this.proxy.threshold = undefined

      return h(
        'g-select',
        {
          props: {
            value: this.proxy.frequency,
            hint: this.getTranslate(`${services.recurrentPayments}.labels.frequency`),
            items: TASK_FREQUENCY.reduce((frequencies, frequency) => {
              if (!~[ TASK_FREQUENCY.once ].indexOf(frequency)) {
                frequencies.push({
                  title: this.getTranslate(`tasks.frequencies.${frequency}`),
                  value: frequency
                })
              }

              return frequencies
            }, []),
            mode: inputModes['line-label'],
            rounded: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.frequency = event
            }
          }
        }
      )
    }
  }
}
function renderTypesBlock(h) {
  return h(
    'div',
    {
      class: 'grid faic'
    },
    [
      renderTypes.call(this, h),
      renderFieldByType.call(this, h)
    ]
  )
}

function renderPaymentsForm(h) {
  return h(
    PaymentsForm,
    {
      props: { value: this.proxy },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderHint(h) {
  if (this.isNew) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.recurrentPayments}.hints.redirect`),
          color: colors.primary
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8 pa-3'
    },
    [
      renderTypesBlock.call(this, h),
      renderPaymentsForm.call(this, h),
      renderHint.call(this, h)
    ]
  )
}
