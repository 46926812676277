import Vue from 'vue'

import { cloneDeep, get, isEqual } from 'lodash'

import { allowedSendingTypes, generateServices, globalErrorHandler, globalErrorProcessor, ruleProcessor, services } from '@/utils'
import { localServices } from '@/components/trees/routes/utils'

import render from './render'

export default {
  name: 'RoutesTree',

  mixins: [
    generateServices([
      {
        name: 'routingGroups',

        find: false,
        create: false,
        update: false,
        remove: false
      },
      {
        name: 'routingRules',

        find: false,
        create: false,
        update: false,
        remove: false
      },
      {
        name: 'providerGroups',

        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    highlight: {
      type: Object,
      default: () => ({})
    },

    readonly: Boolean
  },

  data() {
    const showDialog = {
      full: {},
      direct: {}
    }
    const showAddRouteMenu = {}

    const RoutingGroups = {}
    const initRoutingGroups = {}
    for (const type of allowedSendingTypes) {
      showDialog.full[type] = false
      showDialog.direct[type] = false
      showAddRouteMenu[type] = false

      RoutingGroups[type] = []
      initRoutingGroups[type] = []
    }

    return {
      showDialog,
      showAddRouteMenu,
      showConfirmRemoveDialog: {},

      routeSelector: undefined,

      rowState: {},

      RoutingGroups,
      initRoutingGroups,

      RoutingRules: {},
      ProviderGroups: {},
      Providers: {},

      pendingClick: 0
    }
  },

  computed: {
    types() {
      return this.getSendingTypesByPermission('advanced.users.setRoutingGroups').map(type => {
        return {
          title: `sendings.types.${type}`,
          value: type
        }
      })
    }
  },

  watch: {
    value: {
      handler() {
        if (!isEqual(this.value, this.RoutingGroups)) {
          this.initRoutingGroups = cloneDeep(this.value)
          this.fillRoutingGroups()
        }
      },
      deep: true
    },
    RoutingGroups: {
      handler() {
        if (!isEqual(this.value, this.RoutingGroups)) {
          this.$emit('input', this.RoutingGroups)
        }
      },
      deep: true
    }
  },

  async mounted() {
    const filledRoutingGroups = await this.fillRoutingGroups()
    if (filledRoutingGroups && Object.keys(filledRoutingGroups).length) {
      this.initRoutingGroups = cloneDeep(await this.fillRoutingGroups())
    }

    await this.openingActiveRoute()
  },

  methods: {
    async fillRoutingGroups(inside = false) {
      const data = cloneDeep(inside ? this.RoutingGroups : this.value)

      for (const type in data) {
        if (~allowedSendingTypes.indexOf(type)) {
          const result = await Promise.all(data[type].map(async item => {
            const lastState = get(this.rowState, `${services.routingGroups}.${item.id}`, 'closed')
            Vue.set(this.rowState, `${services.routingGroups}.${item.id}`, 'loading')
            if (!item.title || !item.type || !item.description) {
              try {
                const routingGroup = await this.rest.routingGroups.get(item.id)
                Object.assign(item, routingGroup)
              } catch (error) {
                globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
              }
            }
            Vue.set(this.rowState, `${services.routingGroups}.${item.id}`, lastState)

            return item
          }))
          this.RoutingGroups[type].splice(0, data[type].length, ...result)
        }
      }

      return this.RoutingGroups
    },

    addRoutingGroup(id, type) {
      if (id) {
        if (!~this.RoutingGroups[type].findIndex(item => item.id === id)) {
          this.RoutingGroups[type].push({ id })
          this.fillRoutingGroups(true)
          this.routeSelector = null
          this.$nextTick(() => {
            this.showAddRouteMenu[type] = false
          })
        }
      }
    },
    removeRoutingGroup(id, type) {
      if (id) {
        const index = this.RoutingGroups[type].findIndex(item => item.id === id)
        if (index > -1) {
          Vue.set(this.showConfirmRemoveDialog, id, false)
          this.RoutingGroups[type].splice(index, 1)
          Vue.delete(this.rowState, `${services.routingGroups}.${id}`)
        }
      }
    },

    getRowState(service, id, defaults = 'closed') {
      return get(this.rowState, `${service}.${id}`, defaults)
    },

    closeRow(service, id, force = false) {
      if (force || this.getRowState(service, id) != 'closed') {
        Vue.set(this.rowState, `${service}.${id}`, 'closed')

        return this.getRowState(service, id)
      }
    },
    openRow(service, id, force = false) {
      if (force || this.getRowState(service, id) != 'opened') {
        Vue.set(this.rowState, `${service}.${id}`, 'opened')

        return this.getRowState(service, id)
      }
    },
    clickRow(event, options) {
      if (this.pendingClick) {
        clearTimeout(this.pendingClick)
        this.pendingClick = 0
      }

      switch (event.detail) {
        case 1: {
          this.pendingClick = setTimeout(() => {
            if (typeof options.click === 'function') {
              options.click()
            }
          }, 300)
          break
        }
        case 2: {
          if (typeof options.dblclick === 'function') {
            options.dblclick()
          }
          break
        }
      }
    },

    async getEntities(service, association, id) {
      if (service && id) {
        if (service === 'providers') {
          return
        }

        switch (this.getRowState(service, id)) {
          case 'closed': {
            try {
              Vue.set(this.rowState, `${service}.${id}`, 'loading')
              const response = await this.rest[service].get(id, { query: { $scope: association } })
              const data = response[association]
              if (!this[association][id]) {
                Vue.set(this[association], id, [])
              }
              this[association][id].splice(0, data.length, ...data)
              if (service === 'routingGroups') {
                this[association][id].map(item => {
                  if (Array.isArray(item.rules) && item.rules.length) {
                    for (const rule of item.rules) {
                      rule.value = ruleProcessor(rule.value, rule.type || rule.tag)
                    }
                  }
                })
              }
              this[association][id].sort((a, b) => a.priority - b.priority || a.percentage - b.percentage)

              return data
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            } finally {
              this.openRow(service, id)
            }
            break
          }
          case 'opened': {
            this.closeRow(service, id)
            break
          }
        }
      }
    },

    async getAllEntities(service, association, id) {
      if (service && id) {
        try {
          if (localServices[service].hasDeep) {
            const entities = await this.getEntities(service, association, id)
            if (entities) {
              await Promise.all(entities.map(entity => {
                this.closeRow(localServices[service].nextService, entity.id, true)

                return this.getAllEntities(localServices[service].nextService, localServices[localServices[service].nextService].association, entity.id)
              }))
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    async openingActiveRoute() {
      if (this.highlight && Object.keys(this.highlight).length) {
        for (const key in this.highlight) {
          if (Array.isArray(this.highlight[key]) && this.highlight[key].length && localServices[key].hasDeep) {
            for (const id of this.highlight[key]) {
              await this.getEntities(key, localServices[key].association, id)
            }
            if (this.readonly) {
              this.scrollToActiveGroup()
            }
          }
        }
      }
    },

    scrollToActiveGroup() {
      const activeGroupNode = document.getElementsByClassName(`${services.routingGroups}-tree-routingGroup--active`)[0]
      if (activeGroupNode) {
        const target = activeGroupNode.closest(`.${services.routingGroups}-tab__item`)
        if (target) {
          target.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'start'
            }
          )
        }
      }
    },

    clearShowAddRouteMenu(currentType) {
      for (const type of allowedSendingTypes) {
        if (currentType !== type) {
          this.showAddRouteMenu[type] = false
        }
      }
    }
  },

  render
}
