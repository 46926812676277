import Vue from 'vue'

import { colors, components, icons } from '@/utils'

function renderVerified(h, right) {
  if (this.data.$verified || this.verified) {
    return h(
      components.icon,
      {
        style: {
          'font-size': 'inherit',
          cursor: 'default'
        },
        props: {
          color: colors.info,
          value: icons.verified,
          right
        },
        directives: [
          {
            name: components.tooltip,
            options: { value: this.getTranslate('misc.verified') }
          }
        ]
      }
    )
  }
}

export default function(h) {
  if (this.data) {
    switch (this.$type) {
      case 'link': {
        return h(
          'div',
          {
            class: 'faic text-nowrap lh-n'
          },
          [
            h(
              'router-link',
              {
                attrs: { to: this.$to },
                class: 'link text-overflow mr-1'
              },
              [ this.title ]
            ),

            renderVerified.call(this, h)
          ]
        )
      }
      case 'button': {
        return h(
          components.button,
          {
            props: {
              flat: this.flat,
              rounded: this.rounded,
              small: this.small,
              large: this.large,
              depressed: this.depressed,
              color: this.color
            },
            on: {
              click: () => {
                Vue.router.push(this.$to)
              }
            }
          },
          [ this.title, renderVerified.call(this, h, true) ]
        )
      }
      case 'text':
      default: {
        return h(
          'div',
          {
            class: 'faic text-nowrap lh-n',
            on: { click: this.clickHandler }
          },
          [
            h('div', { class: 'text-overflow mr-1' }, this.title),
            renderVerified.call(this, h)
          ]
        )
      }
    }
  }
}
