import { permissionPresets, states } from '@/utils'
import { serviceName } from '@/components/services/users/utils'

import AccountForm from '@/components/services/account/form'
import DocumentTemplatesGenerateButton from '@/components/services/documentTemplates/generate/button'

function renderPreLoader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    ]
  )
}

function renderUsername(h) {
  return h(
    'div',
    {
      class: 'faic fjcfs headline'
    },
    [ this.username ]
  )
}

function renderHeader(h) {
  if (this.restData[serviceName].get.state === 'ready') {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: {
          gridTemplateColumns: '1fr auto',
          justifyContent: 'space-between'
        }
      },
      [
        renderUsername.call(this, h),
        h(DocumentTemplatesGenerateButton)
      ]
    )
  }
}

function renderCardContent(h) {
  if (this.restData[serviceName].get.state === states.loading) {
    return renderPreLoader.call(this, h)
  } else if (this.restData[serviceName].get.state === states.ready) {
    return h(
      AccountForm,
      {
        props: {
          value: this.restData[serviceName].get.data,
          errors: this.restData[serviceName].errors
        },
        on: {
          input: event => {
            this.restData[serviceName].get.data = event
          }
        }
      }
    )
  }

  return h('g-empty')
}

function renderCard(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [ renderCardContent.call(this, h) ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            label: this.getTranslate('misc.buttons.update'),
            rounded: true,
            depressed: true,
            loading: this.restData[serviceName].update.state === 'loading',
            disabled: this.restData[serviceName].update.state === 'loading',
            color: 'primary'
          },
          on: {
            click: () => {
              this.rest[serviceName].update(this.restData[serviceName].get.data.id, this.restData[serviceName].get.data)
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderCard.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: {
          'grid faifs': true,
          'grid-cols--2': this.viewport.breakpoint.lgUp
        }
      },
      [ renderContent.call(this, h) ]
    )
  }
}
