import { colors, sizes } from '@/utils'

import tag from '@/components/tag'
import overflowString from '@/components/misc/overflowString'
import FileOwner from '@/components/file/owner'

function renderTitle(h) {
  return h(
    overflowString,
    {
      props: {
        value: this.value.title || this.value.filename,
        font: { size: 12 }
      }
    }
  )
}

function renderTag(h, { label, tooltip, color }) {
  return h(
    tag,
    {
      props: {
        label,
        tooltip,
        size: sizes.small,
        color: color || colors.black
      }
    }
  )
}
function renderTags(h) {
  return h(
    'div',
    {
      class: 'file__info-tags'
    },
    [
      this.tags.map(item => renderTag.call(this, h, item)),
      h(FileOwner, { props: { value: this.value } })
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'file__info'
    },
    [
      renderTitle.call(this, h),
      renderTags.call(this, h)
    ]
  )
}
