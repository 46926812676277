import { components } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        [components.divider]: true,
        [`${components.divider}--${this.type}`]: true
      }
    }
  )
}
