import MarkdownIt from 'markdown-it'
import proxy from '@sigma-legacy-libs/g-proxy'

import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { backendServerIP } from '@/utils'

import render from './render'

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typography: true
})

export default {
  name: 'PaymentsOffer',

  mixins: [ proxy() ],

  props: {
    method: {
      type: String,
      validator: value => {
        return PAYMENTS_METHODS.includes(value)
      }
    }
  },

  data() {
    return {
      showDialog: false,

      html: undefined
    }
  },

  watch: {
    method() {
      this.getDocument()
    }
  },

  mounted() {
    this.getDocument()
  },

  methods: {
    async getDocument() {
      if (this.method === PAYMENTS_METHODS.mandarin) {
        this.html = undefined

        return
      }
      try {
        this.html = undefined
        if (this.method) {
          const response = await fetch(`${backendServerIP}/docs/${this.locale}/misc/${this.method}-payment-offer.md`, { cache: 'no-cache' })
          if (!response.ok) {
            throw new Error(response.status)
          }
          this.html = md.render(await response.text())
        }
      } catch (error) {
      }
    }
  },

  render
}
