import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'
import { debounce, defaultsDeep, upperFirst } from 'lodash'

import { generateServices, permissionPresets, projectName, services, states } from '@/utils'

import render from './render'

const cache = new Map()

export default function(options = {}) {
  options = Object.assign(
    {
      filterByIsActive: true,
      dense: false
    },
    options
  )

  const localStorageKeys = {
    isActive: `${projectName}:generator:search:${options.name}:isActive`,
    defaultType: `${projectName}:generator:search:${options.name}:defaultType`
  }

  const find = defaultsDeep(
    {
      defaultFilter: {
        $search: undefined,
        isActive: undefined,
        t: undefined
      },
      appendMode: true,
      abortPrevious: true
    },
    options.find
  )

  return {
    name: 'GeneratorSearch',

    mixins: [
      generateServices({
        name: options.name,

        inputFilter: options.inputFilter,
        outputFilter: options.outputFilter,

        find,

        cacher: options.cacher,

        create: false,
        update: false,
        remove: false
      })
    ],

    props: {
      maxWidth: {
        type: Number,
        default: 800
      }
    },

    data() {
      return {
        isActive: true,

        showFilter: false,

        visited: [],
        favorites: [],

        loading: {
          visited: false,
          favorites: false
        },
        loadingItem: {},

        type: this.getDefaultType()
      }
    },

    computed: {
      ...mapGetters({ favoriteItems: 'favorites/items' }),

      $show() {
        if (this.viewport.breakpoint.mdDown) {
          if (!this.$route.params.id) {
            return true
          } else if (this.$route.params.id) {
            return false
          }
        }

        return true
      },

      $loading() {
        let result = this.restData[options.name].find.state === states.loading
        switch (this.type) {
          case 'visited': {
            result = this.loading.visited
            break
          }
          case 'favorites': {
            result = this.loading.favorites
            break
          }
        }

        return result
      },

      showLoadMore() {
        return (
          this.restData[options.name].find.pagination.limit + this.restData[options.name].find.pagination.offset <
          this.restData[options.name].find.pagination.total
        )
      },

      hasFilter() {
        return !!this.$slots.filter || typeof this.$scopedSlots.filter === 'function'
      },
      hasReport() {
        return !!this.$slots.report || typeof this.$scopedSlots.report === 'function'
      },
      hasCreate() {
        return !options.excludeCreate || (this.$slots.create || typeof this.$scopedSlots.create === 'function')
      },

      gridTemplateRows() {
        const result = [ '38px' ]

        if (this.showFilter) {
          result.push('auto')
        }
        result.push('auto')
        result.push('1fr')

        return result.join(' ')
      }
    },

    watch: {
      [`restData.${options.name}.find.filter.$search`]() {
        this.rest[options.name].options.find.abort()
        this.restData[options.name].find.pagination.offset = 0
        this.type = 'all'
      },

      'account.id'() {
        this.find()
      },

      type(value) {
        switch (value) {
          case 'visited':
          case 'favorites': {
            this[`get${upperFirst(value)}`]()
            break
          }
        }
      },

      $route: {
        handler() {
          const name = this.$route.name
          if (name === `${options.name}.single` || name === `${services.contacts}.single`) {
            this.addVisited({
              service: options.name,
              id: this.$route.params.id
            })
          }
          this.getVisited()
        },
        deep: true
      }
    },

    created() {
      this.onScroll = debounce(this.onScroll, 500, {
        leading: false,
        trailing: true
      })
    },

    async mounted() {
      await this.getIsActive()
      await this.find()
      this.getVisited()
      this.getFavorites()
    },

    methods: {
      ...mapActions({
        getVisitedIds: 'visited/get',
        addVisited: 'visited/add',
        removeVisited: 'visited/remove',
        refreshFavorites: 'favorites/refresh',
        toggleFavorite: 'favorites/toggle'
      }),

      onScroll(event) {
        if (
          this.restData[options.name].find.state === states.ready &&
          event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop <= 160 &&
          this.showLoadMore
        ) {
          this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
        }
      },

      async find() {
        const accept = [ 'advanced', options.serviceForCheckPermissions || options.name, options.methodForCheckPermissions || 'find' ].join('.')
        if (this.checkPermissions(accept, permissionPresets.meUp)) {
          const params = {}
          if (options.avoid304) {
            params.query = { t: Date.now() }
          }
          await this.rest[options.name].find(params)
        }
      },

      async processItems(items, type) {
        try {
          this.loading[type] = true
          const prevToken = window.localStorage.getItem('prevToken')
          if (!prevToken) {
            const result = []
            if (Array.isArray(items) && items.length) {
              for (const id of items) {
                let item
                const data = this.restData[options.name].find.data
                if (Array.isArray(data) && data.length) {
                  item = data.find(el => el.id === id)
                }
                if (!item) {
                  if (cache.has(id)) {
                    item = cache.get(id)
                  } else {
                    try {
                      Vue.set(this.loadingItem, id, true)
                      const response = await Vue.$GRequest.get(options.name, id, { query: { $scope: [ 'full' ] } })
                      if (response?.data) {
                        item = response.data
                      }
                      Vue.set(this.loadingItem, id, false)
                    } catch (error) {
                      switch (type) {
                        case 'visited': {
                          this.removeVisited({
                            id,
                            service: options.name
                          })
                          break
                        }
                        case 'favorites': {
                          this.toggleFavorite({
                            id,
                            service: options.name
                          })
                          break
                        }
                      }
                    }
                  }
                }
                if (item) {
                  result.push(item)
                  cache.set(id, item)
                }
              }
            }

            return result
          }
        } catch (error) {
          return []
        } finally {
          this.loading[type] = false
        }
      },

      async getFavorites() {
        if (this.type !== 'favorites') {
          return
        }

        const items = await this.refreshFavorites(options.name)
        const result = await this.processItems(items, 'favorites')
        if (Array.isArray(result)) {
          this.favorites.splice(0, this.favorites.length, ...result)
        }
      },

      async getVisited() {
        if (this.type !== 'visited') {
          return
        }

        const items = await this.getVisitedIds(options.name)
        const result = await this.processItems(items, 'visited')
        if (Array.isArray(result)) {
          this.visited.splice(0, this.visited.length, ...result)
        }
      },

      async getIsActive() {
        let isActive = true
        try {
          isActive = await JSON.parse(window.localStorage.getItem(localStorageKeys.isActive))
        } catch (error) {
          isActive = true
        } finally {
          if (isActive === null || isActive === undefined) {
            isActive = true
          }
          this.setIsActive(isActive)
        }
      },

      setIsActive(event) {
        if (typeof event !== 'boolean') {
          event = true
        }
        window.localStorage.setItem(localStorageKeys.isActive, event)
        this.isActive = event
        this.toggleFilterByIsActive(event)
      },

      toggleFilterByIsActive(event) {
        if (event === false) {
          this.restData[options.name].find.filter.isActive = undefined
        } else {
          this.restData[options.name].find.filter.isActive = true
        }
      },

      setDefaultType(type) {
        window.localStorage.setItem(localStorageKeys.defaultType, type)
      },
      getDefaultType() {
        return window.localStorage.getItem(localStorageKeys.defaultType) || 'all'
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
