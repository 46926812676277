import { colors, modes, services, states } from '@/utils'

import button from '@/components/button'
import generatorRow from '@/components/generator/row'
import routesFormLink from '@/components/services/routes/form/link'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import info from '@/components/misc/info'
import preloader from '@/components/misc/preloader'

const serviceNameViaPoint = services['otp-settings/widgets'].split('/').join('.')

function renderWarning(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceNameViaPoint}.warnings.channels`),
        color: colors.warning
      }
    }
  )
}

function renderLinkToChannel(h, item) {
  if (this.checkPermissions(`advanced.${services['otp-settings/channels'].split('/').join('.')}.create`)) {
    return h(
      routesFormLink,
      {
        props: {
          value: { id: item.otpChannelSettingsId },
          service: services['otp-settings/channels'].split('/').join('.')
        }
      }
    )
  }
}
function renderChannelField(h, item) {
  return h(
    'g-select',
    {
      props: {
        value: item.otpChannelSettingsId,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.channels`),
        service: services['otp-settings/channels'],
        picks: [ 'id', 'name', 'channelType' ],
        itemTitle: 'name',
        itemsDisabled: this.otpChannelsSettings.map(({ otpChannelSettingsId }) => otpChannelSettingsId),
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        disabled: this.disabled || this.restData[services['otp-settings/channels']].find.pagination.total === 0
      },
      on: {
        input: event => {
          item.otpChannelSettingsId = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.channelType,
                title: item.name
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.channelType,
                title: item.name,
                as: 'text'
              }
            }
          )
        }
      }
    }
  )
}
function renderGeneratorRow(h) {
  return h(
    generatorRow,
    {
      props: {
        value: this.otpChannelsSettings,
        disabled: this.disabled || this.restData[services['otp-settings/channels']].find.pagination.total === 0,
        defaultValue: { otpChannelSettingsId: undefined },
        sortable: false
      },
      scopedSlots: {
        row: ({ item }) => {
          return h(
            'div',
            {
              class: 'grid grid-gap--8',
              style: { 'grid-template-columns': '1fr 38px' }
            },
            [
              renderChannelField.call(this, h, item),
              renderLinkToChannel.call(this, h, item)
            ]
          )
        }
      },
      on: {
        input: event => {
          this.otpChannelsSettings = event
        }
      }
    }
  )
}
function renderChannelsFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            icon: 'save',
            label: this.getTranslate('misc.buttons.save'),
            color: colors.primary,
            mode: modes.flat,
            loading: this.loading,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.attachChannels()
            }
          }
        }
      )
    ]
  )
}
function renderContent(h) {
  if (this.restData[services['otp-settings/channels']].find.state === states.loading) {
    return h(preloader, { props: { value: true } })
  }

  if (this.restData[services['otp-settings/channels']].find.state === states.ready) {
    if (this.restData[services['otp-settings/channels']].find.pagination.total === 0) {
      return renderWarning.call(this, h)
    }
  }

  return [
    renderGeneratorRow.call(this, h),
    renderChannelsFooter.call(this, h)
  ]
}
function renderChannelsField(h) {
  if (!this.isNew) {
    return h(
      'g-card',
      {
        class: 'grid grid-gap--8 pt-3 px-2 pb-2',
        props: {
          title: this.getTranslate(`${serviceNameViaPoint}.titles.channels`),
          rounded: true,
          outline: true,
          dashed: true
        }
      },
      [ renderContent.call(this, h) ]
    )
  }
}

function renderForm(h) {
  return h(
    'g-card',
    {
      class: 'pa-3',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-form',
        {
          class: 'grid',
          style: { 'grid-template-columns': '100%' }
        },
        [ renderChannelsField.call(this, h) ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-3'
    },
    [ renderForm.call(this, h) ]
  )
}
