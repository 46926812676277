import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'

export const name = 'utm'

export const sources = upgradeArray([ 'custom', 'google', 'yandex', 'vk' ])

export const utmParams = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  utm_term: 'utm_term'
}
export const requiredParams = [ utmParams.utm_source, utmParams.utm_medium, utmParams.utm_campaign ]
export const optionalParams = [ utmParams.utm_content, utmParams.utm_term ]

export const utmMedium = {
  sms: 'sms', // https://sigmasms.slack.com/archives/DRB2QURQF/p1732524393111959?thread_ts=1729507712.095899&cid=DRB2QURQF
  organic: 'organic',
  cpc: 'cpc',
  email: 'email',
  social: 'social',
  banner: 'banner',
  cpa: 'cpa'
}

export const paramsBySource = {
  [sources.custom]: {
    [utmParams.utm_source]: undefined,
    [utmParams.utm_medium]: utmMedium.cpc,
    [utmParams.utm_campaign]: undefined,
    [utmParams.utm_content]: undefined,
    [utmParams.utm_term]: undefined
  },
  [sources.google]: {
    [utmParams.utm_source]: sources.google,
    [utmParams.utm_medium]: utmMedium.cpc,
    [utmParams.utm_campaign]: '{network}',
    [utmParams.utm_content]: '{creative}',
    [utmParams.utm_term]: '{keyword}'
  },
  [sources.yandex]: {
    [utmParams.utm_source]: sources.yandex,
    [utmParams.utm_medium]: utmMedium.cpc,
    [utmParams.utm_campaign]: '{campaign_id}',
    [utmParams.utm_content]: '{ad_id}',
    [utmParams.utm_term]: '{keyword}'
  },
  [sources.vk]: {
    [utmParams.utm_source]: 'vk',
    [utmParams.utm_medium]: utmMedium.cpc,
    [utmParams.utm_campaign]: '{campaign_id}',
    [utmParams.utm_content]: '{ad_id}',
    [utmParams.utm_term]: undefined
  }
}
