import { services } from '@/utils'
import { invoiceStatues } from '@/components/services/invoices/utils'

import serviceActions from '@/components/serviceTemplate/item/actions'
import download from '@/components/services/invoices/item/buttons/download'
import moderation from '@/components/services/invoices/item/buttons/moderation'

function renderDownload(h) {
  return h(
    download,
    {
      props: {
        value: this.value,
        view: this.view
      }
    }
  )
}

function renderModeration(h, status) {
  return h(
    moderation,
    {
      props: {
        status,
        value: this.value,
        view: this.view
      }
    }
  )
}

function renderContentByPermission(h) {
  const result = [ renderDownload.call(this, h) ]
  if (this.hasPermission()) {
    result.push(renderModeration.call(this, h, invoiceStatues.paid))
    result.push(renderModeration.call(this, h, invoiceStatues.rejected))
  }

  return result
}

function renderButtons(h) {
  let columns = 1
  if (this.hasPermission()) {
    columns = 4
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { gridTemplateColumns: `repeat(${columns}, auto)` }
    },
    [ renderContentByPermission.call(this, h), renderServiceActions.call(this, h) ]
  )
}

function renderServiceActions(h, trigger = false) {
  return h(
    serviceActions,
    {
      props: {
        value: this.value,
        service: services.invoices
      },
      scopedSlots: {
        default: () => {
          if (trigger) {
            return renderContentByPermission.call(this, h)
          }
        }
      }
    }
  )
}

function renderContentByViewport(h) {
  if (this.viewport.breakpoint.lgUp) {
    return renderButtons.call(this, h)
  } else {
    return renderServiceActions.call(this, h, true)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.invoices}-panel__buttons`]: true,
        'hover-child': this.viewport.breakpoint.lgUp
      }
    },
    [ renderContentByViewport.call(this, h) ]
  )
}
