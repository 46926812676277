import { permissionPresets, services, tariffInputFilter, tariffOutputFilter } from '@/utils'

import SearchGenerator from '@/components/generator/search'
import GetCreateTemplate from '@/components/GetCreateTemplate'

import render from './render'

export default {
  name: services.tariffs,

  components: {
    'search-generator': SearchGenerator({
      name: services.tariffs,

      inputFilterForCreateButton: tariffInputFilter,
      outputFilterForCreateButton: tariffOutputFilter,

      find: {
        defaultFilter: {
          isActive: true,
          source: undefined
        }
      }
    }),

    'get-create-template': GetCreateTemplate({
      name: services.tariffs,

      inputFilter: tariffInputFilter,
      outputFilter: tariffOutputFilter,

      find: false,
      get: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        }
      },
      remove: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        },
        redirect: true
      }
    })
  },

  computed: {
    sourceItems() {
      const result = []
      const permission = `advanced.${services.tariffs}.find`
      if (this.checkPermissions(permission, permissionPresets.resellerUp)) {
        result.push(...this.getSendingTypesByPermission(permission).map(type => `sendings.${type}`))
        result.push('hlr')
      }

      return result
    }
  },

  render
}
