import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    OwnerId: String
  },

  computed: {
    validation() {
      if (this.proxy.description) {
        return true
      }

      return false
    }
  },

  watch: {
    OwnerId(value) {
      if (value) {
        this.proxy.OwnerId = value
      } else {
        this.proxy.OwnerId = this.account.id
      }
    },

    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.proxy.OwnerId = this.OwnerId || this.account.id
    this.$emit('validation', this.validation)
  },

  render
}
