import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, defaultsDeep } from 'lodash'

import { DEFAULT_INVOICE_PAYLOAD } from '@/utils'

import render from './render'

export default {
  name: 'InvoiceDialog',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    OwnerId: String,
    ResellerId: String,

    showForm: Boolean
  },

  computed: {
    isValid() {
      return (
        !!this.proxy.requisiteId &&
        !!this.proxy.companyId &&
        !!this.proxy.type &&
        !isNaN(this.proxy.amount) &&
        this.proxy.amount > 0
      )
    }
  },

  methods: {
    _inputFilter(data) {
      if (!data) {
        return cloneDeep(DEFAULT_INVOICE_PAYLOAD)
      }

      defaultsDeep(data, cloneDeep(DEFAULT_INVOICE_PAYLOAD))

      if (data.data) {
        data.amount = data?.data?.SERVICE_PRICE
      }

      return data
    }
  },

  render
}
