import { colors, icons } from '@/utils'

import render from './render'

export default {
  name: 'ButtonLink',

  props: {
    to: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: icons.link
    },

    color: {
      type: String,
      default: colors.grey
    },

    size: {
      type: Number,
      default: 24
    }
  },

  render
}
