import { mapGetters } from 'vuex'
import { MIME_TO_EXT, PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { FILE_TYPES, fillDependence, generateServices, numberToPxOrString, services, states } from '@/utils'

import render from './render'

export default {
  name: 'FileManager',

  mixins: [
    generateServices({
      name: services.storage,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          id: undefined,
          OwnerId: undefined,
          type: [],
          $search: undefined,
          $scope: [ 'Owner' ]
        },
        defaultOrder: {
          createdAt: 'desc',
          size: undefined
        },
        defaultPagination: {
          limit: 40
        },
        async websocketFindCreatedEvent(instance) {
          if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysCreateFromWebSocket) {
            return
          }

          if (this.findData.filterIsEqualToDefault || this.options.find.alwaysCreateFromWebSocket) {
            this.findData.pagination.total++
          }

          let workingArray = this.findData.data
          let limit = this.findData.pagination.limit

          if (this.options.find.bucketEnabled) {
            workingArray = this.bucket

            limit = this.options.find.bucketMaxLength
          } else {
            instance = await this.options.inputFilter.call(this.ctx, instance)
          }

          if (workingArray) {
            Object.keys(FILE_TYPES).forEach(title => {
              if (title !== 'file' && ~FILE_TYPES[title].extensions.indexOf(MIME_TO_EXT[instance.type])) {
                if (this.findData.filter.type && this.findData.filter.type.length) {
                  if (~this.findData.filter.type.indexOf(FILE_TYPES[title].value)) {
                    workingArray.unshift(instance)
                  }
                }

                if (this.findData.filter.type && !this.findData.filter.type.length) {
                  workingArray.unshift(instance)
                }
              }
            })

            if (workingArray.length > limit && !this.options.find.appendMode) {
              workingArray.splice(limit)
            }

            if (workingArray === this.bucket) {
              this.findData.bucketLength = this.bucket.length
            }
          }
        },

        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },
      create: false,

      remove: { redirect: false }
    })
  ],

  props: {
    show: Boolean,

    currentChosenFileId: String,

    readonly: Boolean,

    embedded: {
      type: Boolean,
      default: false
    },

    isPublic: Boolean,

    maxFileSize: Number,

    name: {
      type: [ String, Boolean ],
      default: 'file'
    },

    types: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      googleFileURL: undefined,
      currentEditFile: undefined,
      editDialogType: undefined,
      showEditDialog: false,
      showLoadingArea: false,

      searchTypes: [],

      showFilter: false
    }
  },

  computed: {
    ...mapGetters({ state: 'file/state' }),

    $types() {
      return this.types.reduce((result, current) => {
        if (typeof current === 'string' && current) {
          for (const key in FILE_TYPES) {
            const { extensions } = FILE_TYPES[key]
            if (key !== 'file' && extensions.includes(current) && !result.includes(key)) {
              result.push(key)
            }
          }
        }

        return result
      }, [])
    },

    filterTypes() {
      return this.searchTypes.concat(this.$types).map(type => FILE_TYPES[type].extensions).flat()
    },

    selectTypes() {
      const result = []
      for (const key in FILE_TYPES) {
        if (key !== 'file') {
          result.push({
            title: this.getTranslate(FILE_TYPES[key].title),
            value: key
          })
        }
      }

      return result
    },

    loading() {
      return this.state === states.loading || this.restData[services.storage].find.state === states.loading
    },
    disabled() {
      return this.types.length > 0
    }
  },

  watch: {
    types: {
      handler() {
        this.setSearchTypes()
      },
      deep: true
    },
    filterTypes: {
      handler() {
        this.restData[services.storage].find.filter.type = this.filterTypes
      },
      deep: true
    }
  },

  mounted() {
    this.setSearchTypes()
    this.rest[services.storage].find()
  },

  methods: {
    setSearchTypes() {
      if (this.$types.length) {
        this.searchTypes = this.$types
      }
    },

    showUploadArea() {
      const area = document.getElementById('loading-area')
      area.style.width = numberToPxOrString(area.parentElement.parentElement.offsetWidth)
      area.style.height = numberToPxOrString(area.parentElement.parentElement.offsetHeight)

      this.showLoadingArea = true
    }
  },

  render
}
