export const icons = {
  123: '123',
  360: '360',
  '1x_mobiledata': '1x_mobiledata',
  '1k_plus': '1k_plus',
  '1k': '1k',
  '19mp': '19mp',
  '18mp': '18mp',
  '18_up_rating': '18_up_rating',
  '17mp': '17mp',
  '16mp': '16mp',
  '15mp': '15mp',
  '14mp': '14mp',
  '13mp': '13mp',
  '12mp': '12mp',
  '11mp': '11mp',
  '10mp': '10mp',
  '10k': '10k',
  '2k_plus': '2k_plus',
  '24mp': '24mp',
  '23mp': '23mp',
  '22mp': '22mp',
  '21mp': '21mp',
  '20mp': '20mp',
  '2mp': '2mp',
  '2k': '2k',
  '3p': '3p',
  '3mp': '3mp',
  '3k_plus': '3k_plus',
  '3k': '3k',
  '3g_mobiledata': '3g_mobiledata',
  '3d_rotation': '3d_rotation',
  '30fps_select': '30fps_select',
  '30fps': '30fps',
  '4mp': '4mp',
  '4k_plus': '4k_plus',
  '4k': '4k',
  '4g_plus_mobiledata': '4g_plus_mobiledata',
  '4g_mobiledata': '4g_mobiledata',
  '5mp': '5mp',
  '5k_plus': '5k_plus',
  '5k': '5k',
  '5g': '5g',
  '6mp': '6mp',
  '6k_plus': '6k_plus',
  '6k': '6k',
  '6_ft_apart': '6_ft_apart',
  '60fps_select': '60fps_select',
  '60fps': '60fps',
  '7k_plus': '7k_plus',
  '7mp': '7mp',
  '7k': '7k',
  '8k_plus': '8k_plus',
  '8mp': '8mp',
  '8k': '8k',
  '9k_plus': '9k_plus',
  '9mp': '9mp',
  '9k': '9k',
  av_timer: 'av_timer',
  autorenew: 'autorenew',
  autofps_select: 'autofps_select',
  auto_stories: 'auto_stories',
  auto_mode: 'auto_mode',
  auto_graph: 'auto_graph',
  auto_fix_off: 'auto_fix_off',
  auto_fix_normal: 'auto_fix_normal',
  auto_fix_high: 'auto_fix_high',
  auto_delete: 'auto_delete',
  auto_awesome_motion: 'auto_awesome_motion',
  auto_awesome_mosaic: 'auto_awesome_mosaic',
  auto_awesome: 'auto_awesome',
  audiotrack: 'audiotrack',
  audio_file: 'audio_file',
  attribution: 'attribution',
  attractions: 'attractions',
  attachment: 'attachment',
  attach_money: 'attach_money',
  attach_file: 'attach_file',
  attach_email: 'attach_email',
  atm: 'atm',
  assured_workload: 'assured_workload',
  assistant_photo: 'assistant_photo',
  assistant_navigation: 'assistant_navigation',
  assistant_direction: 'assistant_direction',
  assistant: 'assistant',
  assignment_turned_in: 'assignment_turned_in',
  assignment_returned: 'assignment_returned',
  assignment_return: 'assignment_return',
  assignment_late: 'assignment_late',
  assignment_ind: 'assignment_ind',
  assignment: 'assignment',
  assessment: 'assessment',
  aspect_ratio: 'aspect_ratio',
  article: 'article',
  art_track: 'art_track',
  arrow_upward: 'arrow_upward',
  arrow_right_alt: 'arrow_right_alt',
  arrow_right: 'arrow_right',
  arrow_left: 'arrow_left',
  arrow_forward_ios: 'arrow_forward_ios',
  arrow_forward: 'arrow_forward',
  arrow_drop_up: 'arrow_drop_up',
  arrow_drop_down_circle: 'arrow_drop_down_circle',
  arrow_drop_down: 'arrow_drop_down',
  arrow_downward: 'arrow_downward',
  arrow_circle_up: 'arrow_circle_up',
  arrow_circle_right: 'arrow_circle_right',
  arrow_circle_left: 'arrow_circle_left',
  arrow_circle_down: 'arrow_circle_down',
  arrow_back_ios_new: 'arrow_back_ios_new',
  arrow_back_ios: 'arrow_back_ios',
  arrow_back: 'arrow_back',
  area_chart: 'area_chart',
  archive: 'archive',
  architecture: 'architecture',
  apps_outage: 'apps_outage',
  apps: 'apps',
  approval: 'approval',
  apple: 'apple',
  app_shortcut: 'app_shortcut',
  app_settings_alt: 'app_settings_alt',
  app_registration: 'app_registration',
  app_blocking: 'app_blocking',
  api: 'api',
  apartment: 'apartment',
  aod: 'aod',
  announcement: 'announcement',
  animation: 'animation',
  android: 'android',
  anchor: 'anchor',
  analytics: 'analytics',
  amp_stories: 'amp_stories',
  alternate_email: 'alternate_email',
  alt_route: 'alt_route',
  all_out: 'all_out',
  all_inclusive: 'all_inclusive',
  all_inbox: 'all_inbox',
  align_vertical_top: 'align_vertical_top',
  align_vertical_center: 'align_vertical_center',
  align_vertical_bottom: 'align_vertical_bottom',
  align_horizontal_right: 'align_horizontal_right',
  align_horizontal_left: 'align_horizontal_left',
  align_horizontal_center: 'align_horizontal_center',
  album: 'album',
  alarm_on: 'alarm_on',
  alarm_off: 'alarm_off',
  alarm_add: 'alarm_add',
  alarm: 'alarm',
  airport_shuttle: 'airport_shuttle',
  airplay: 'airplay',
  airplanemode_on: 'airplanemode_on',
  airplanemode_off: 'airplanemode_off',
  airplanemode_inactive: 'airplanemode_inactive',
  airplanemode_active: 'airplanemode_active',
  airplane_ticket: 'airplane_ticket',
  airlines: 'airlines',
  airline_stops: 'airline_stops',
  airline_seat_recline_normal: 'airline_seat_recline_normal',
  airline_seat_recline_extra: 'airline_seat_recline_extra',
  airline_seat_legroom_reduced: 'airline_seat_legroom_reduced',
  airline_seat_legroom_normal: 'airline_seat_legroom_normal',
  airline_seat_legroom_extra: 'airline_seat_legroom_extra',
  airline_seat_individual_suite: 'airline_seat_individual_suite',
  airline_seat_flat_angled: 'airline_seat_flat_angled',
  airline_seat_flat: 'airline_seat_flat',
  air: 'air',
  agriculture: 'agriculture',
  ads_click: 'ads_click',
  adobe: 'adobe',
  admin_panel_settings: 'admin_panel_settings',
  adjust: 'adjust',
  adf_scanner: 'adf_scanner',
  addchart: 'addchart',
  add_to_queue: 'add_to_queue',
  add_to_photos: 'add_to_photos',
  add_to_home_screen: 'add_to_home_screen',
  add_to_drive: 'add_to_drive',
  add_task: 'add_task',
  add_shopping_cart: 'add_shopping_cart',
  add_road: 'add_road',
  add_reaction: 'add_reaction',
  add_photo_alternate: 'add_photo_alternate',
  add_moderator: 'add_moderator',
  add_location_alt: 'add_location_alt',
  add_location: 'add_location',
  add_link: 'add_link',
  add_ic_call: 'add_ic_call',
  add_home_work: 'add_home_work',
  add_home: 'add_home',
  add_comment: 'add_comment',
  add_circle_outline: 'add_circle_outline',
  add_circle: 'add_circle',
  add_chart: 'add_chart',
  add_card: 'add_card',
  add_call: 'add_call',
  add_business: 'add_business',
  add_box: 'add_box',
  add_alert: 'add_alert',
  add_alarm: 'add_alarm',
  add_a_photo: 'add_a_photo',
  add: 'add',
  adb: 'adb',
  ad_units: 'ad_units',
  account_tree: 'account_tree',
  account_circle: 'account_circle',
  account_box: 'account_box',
  account_balance_wallet: 'account_balance_wallet',
  account_balance: 'account_balance',
  accessible_forward: 'accessible_forward',
  accessible: 'accessible',
  accessibility_new: 'accessibility_new',
  accessibility: 'accessibility',
  access_time_filled: 'access_time_filled',
  access_time: 'access_time',
  access_alarms: 'access_alarms',
  access_alarm: 'access_alarm',
  ac_unit: 'ac_unit',
  abc: 'abc',
  business_center: 'business_center',
  business: 'business',
  bus_alert: 'bus_alert',
  burst_mode: 'burst_mode',
  bungalow: 'bungalow',
  build_circle: 'build_circle',
  build: 'build',
  bug_report: 'bug_report',
  bubble_chart: 'bubble_chart',
  brush: 'brush',
  brunch_dining: 'brunch_dining',
  browser_updated: 'browser_updated',
  browser_not_supported: 'browser_not_supported',
  browse_gallery: 'browse_gallery',
  broken_image: 'broken_image',
  broadcast_on_personal: 'broadcast_on_personal',
  broadcast_on_home: 'broadcast_on_home',
  brightness_medium: 'brightness_medium',
  brightness_low: 'brightness_low',
  brightness_high: 'brightness_high',
  brightness_auto: 'brightness_auto',
  brightness_7: 'brightness_7',
  brightness_6: 'brightness_6',
  brightness_5: 'brightness_5',
  brightness_4: 'brightness_4',
  brightness_3: 'brightness_3',
  brightness_2: 'brightness_2',
  brightness_1: 'brightness_1',
  breakfast_dining: 'breakfast_dining',
  branding_watermark: 'branding_watermark',
  boy: 'boy',
  border_vertical: 'border_vertical',
  border_top: 'border_top',
  border_style: 'border_style',
  border_right: 'border_right',
  border_outer: 'border_outer',
  border_left: 'border_left',
  border_inner: 'border_inner',
  border_horizontal: 'border_horizontal',
  border_color: 'border_color',
  border_clear: 'border_clear',
  border_bottom: 'border_bottom',
  border_all: 'border_all',
  bookmarks: 'bookmarks',
  bookmark_remove: 'bookmark_remove',
  bookmark_outline: 'bookmark_outline',
  bookmark_border: 'bookmark_border',
  bookmark_added: 'bookmark_added',
  bookmark_add: 'bookmark_add',
  bookmark: 'bookmark',
  book_online: 'book_online',
  book: 'book',
  bolt: 'bolt',
  blur_on: 'blur_on',
  blur_off: 'blur_off',
  blur_linear: 'blur_linear',
  blur_circular: 'blur_circular',
  bluetooth_searching: 'bluetooth_searching',
  bluetooth_drive: 'bluetooth_drive',
  bluetooth_disabled: 'bluetooth_disabled',
  bluetooth_connected: 'bluetooth_connected',
  bluetooth_audio: 'bluetooth_audio',
  bluetooth: 'bluetooth',
  bloodtype: 'bloodtype',
  block_flipped: 'block_flipped',
  block: 'block',
  blinds_closed: 'blinds_closed',
  blinds: 'blinds',
  blender: 'blender',
  biotech: 'biotech',
  bike_scooter: 'bike_scooter',
  bento: 'bento',
  beenhere: 'beenhere',
  bedtime_off: 'bedtime_off',
  bedtime: 'bedtime',
  bedroom_parent: 'bedroom_parent',
  bedroom_child: 'bedroom_child',
  bedroom_baby: 'bedroom_baby',
  bed: 'bed',
  beach_access: 'beach_access',
  battery_unknown: 'battery_unknown',
  battery_std: 'battery_std',
  battery_saver: 'battery_saver',
  battery_full: 'battery_full',
  battery_charging_full: 'battery_charging_full',
  battery_alert: 'battery_alert',
  battery_6_bar: 'battery_6_bar',
  battery_5_bar: 'battery_5_bar',
  battery_4_bar: 'battery_4_bar',
  battery_3_bar: 'battery_3_bar',
  battery_2_bar: 'battery_2_bar',
  battery_1_bar: 'battery_1_bar',
  battery_0_bar: 'battery_0_bar',
  bathtub: 'bathtub',
  bathroom: 'bathroom',
  batch_prediction: 'batch_prediction',
  bar_chart: 'bar_chart',
  ballot: 'ballot',
  balcony: 'balcony',
  balance: 'balance',
  bakery_dining: 'bakery_dining',
  badge: 'badge',
  backup_table: 'backup_table',
  backup: 'backup',
  backspace: 'backspace',
  backpack: 'backpack',
  back_hand: 'back_hand',
  baby_changing_station: 'baby_changing_station',
  cyclone: 'cyclone',
  curtains_closed: 'curtains_closed',
  curtains: 'curtains',
  currency_yuan: 'currency_yuan',
  currency_yen: 'currency_yen',
  currency_rupee: 'currency_rupee',
  currency_ruble: 'currency_ruble',
  currency_pound: 'currency_pound',
  currency_lira: 'currency_lira',
  currency_franc: 'currency_franc',
  currency_exchange: 'currency_exchange',
  currency_bitcoin: 'currency_bitcoin',
  css: 'css',
  cruelty_free: 'cruelty_free',
  crop_square: 'crop_square',
  crop_rotate: 'crop_rotate',
  crop_portrait: 'crop_portrait',
  crop_original: 'crop_original',
  crop_landscape: 'crop_landscape',
  crop_free: 'crop_free',
  crop_din: 'crop_din',
  crop_7_5: 'crop_7_5',
  crop_5_4: 'crop_5_4',
  crop_3_2: 'crop_3_2',
  crop_16_9: 'crop_16_9',
  crop: 'crop',
  crisis_alert: 'crisis_alert',
  crib: 'crib',
  credit_score: 'credit_score',
  credit_card_off: 'credit_card_off',
  credit_card: 'credit_card',
  create_new_folder: 'create_new_folder',
  create: 'create',
  countertops: 'countertops',
  cottage: 'cottage',
  corporate_fare: 'corporate_fare',
  coronavirus: 'coronavirus',
  copyright: 'copyright',
  copy_all: 'copy_all',
  cookie: 'cookie',
  control_point_duplicate: 'control_point_duplicate',
  control_point: 'control_point',
  control_camera: 'control_camera',
  contrast: 'contrast',
  content_paste_search: 'content_paste_search',
  content_paste_off: 'content_paste_off',
  content_paste_go: 'content_paste_go',
  content_paste: 'content_paste',
  content_cut: 'content_cut',
  content_copy: 'content_copy',
  contacts: 'contacts',
  contactless: 'contactless',
  contact_support: 'contact_support',
  contact_phone: 'contact_phone',
  contact_page: 'contact_page',
  contact_mail: 'contact_mail',
  construction: 'construction',
  connecting_airports: 'connecting_airports',
  connected_tv: 'connected_tv',
  connect_without_contact: 'connect_without_contact',
  confirmation_number: 'confirmation_number',
  confirmation_num: 'confirmation_num',
  computer: 'computer',
  compress: 'compress',
  compost: 'compost',
  compass_calibration: 'compass_calibration',
  compare_arrows: 'compare_arrows',
  compare: 'compare',
  commute: 'commute',
  commit: 'commit',
  comments_disabled: 'comments_disabled',
  comment_bank: 'comment_bank',
  comment: 'comment',
  colorize: 'colorize',
  color_lens: 'color_lens',
  collections_bookmark: 'collections_bookmark',
  collections: 'collections',
  coffee_maker: 'coffee_maker',
  coffee: 'coffee',
  code_off: 'code_off',
  code: 'code',
  co_present: 'co_present',
  co2: 'co2',
  cloudy_snowing: 'cloudy_snowing',
  cloud_upload: 'cloud_upload',
  cloud_sync: 'cloud_sync',
  cloud_queue: 'cloud_queue',
  cloud_off: 'cloud_off',
  cloud_download: 'cloud_download',
  cloud_done: 'cloud_done',
  cloud_circle: 'cloud_circle',
  cloud: 'cloud',
  closed_caption_off: 'closed_caption_off',
  closed_caption_disabled: 'closed_caption_disabled',
  closed_caption: 'closed_caption',
  close_fullscreen: 'close_fullscreen',
  close: 'close',
  clear_all: 'clear_all',
  clear: 'clear',
  cleaning_services: 'cleaning_services',
  clean_hands: 'clean_hands',
  class: 'class',
  circle_notifications: 'circle_notifications',
  circle: 'circle',
  church: 'church',
  chrome_reader_mode: 'chrome_reader_mode',
  child_friendly: 'child_friendly',
  child_care: 'child_care',
  chevron_right: 'chevron_right',
  chevron_left: 'chevron_left',
  checkroom: 'checkroom',
  checklist_rtl: 'checklist_rtl',
  checklist: 'checklist',
  check_circle_outline: 'check_circle_outline',
  check_circle: 'check_circle',
  check_box_outline_blank: 'check_box_outline_blank',
  check_box: 'check_box',
  check: 'check',
  chat_bubble_outline: 'chat_bubble_outline',
  chat_bubble: 'chat_bubble',
  chat: 'chat',
  charging_station: 'charging_station',
  change_history: 'change_history',
  change_circle: 'change_circle',
  chalet: 'chalet',
  chair_alt: 'chair_alt',
  chair: 'chair',
  center_focus_weak: 'center_focus_weak',
  center_focus_strong: 'center_focus_strong',
  cell_wifi: 'cell_wifi',
  cell_tower: 'cell_tower',
  celebration: 'celebration',
  category: 'category',
  catching_pokemon: 'catching_pokemon',
  castle: 'castle',
  cast_for_education: 'cast_for_education',
  cast_connected: 'cast_connected',
  cast: 'cast',
  casino: 'casino',
  cases: 'cases',
  carpenter: 'carpenter',
  card_travel: 'card_travel',
  card_membership: 'card_membership',
  card_giftcard: 'card_giftcard',
  car_repair: 'car_repair',
  car_rental: 'car_rental',
  car_crash: 'car_crash',
  candlestick_chart: 'candlestick_chart',
  cancel_schedule_send: 'cancel_schedule_send',
  cancel_presentation: 'cancel_presentation',
  cancel: 'cancel',
  campaign: 'campaign',
  cameraswitch: 'cameraswitch',
  camera_roll: 'camera_roll',
  camera_rear: 'camera_rear',
  camera_outdoor: 'camera_outdoor',
  camera_indoor: 'camera_indoor',
  camera_front: 'camera_front',
  camera_enhance: 'camera_enhance',
  camera_alt: 'camera_alt',
  camera: 'camera',
  call_to_action: 'call_to_action',
  call_split: 'call_split',
  call_received: 'call_received',
  call_missed_outgoing: 'call_missed_outgoing',
  call_missed: 'call_missed',
  call_merge: 'call_merge',
  call_made: 'call_made',
  call_end: 'call_end',
  call: 'call',
  calendar_view_week: 'calendar_view_week',
  calendar_view_month: 'calendar_view_month',
  calendar_view_day: 'calendar_view_day',
  calendar_today: 'calendar_today',
  calendar_month: 'calendar_month',
  calculate: 'calculate',
  cake: 'cake',
  cached: 'cached',
  cable: 'cable',
  cabin: 'cabin',
  dynamic_form: 'dynamic_form',
  dynamic_feed: 'dynamic_feed',
  dvr: 'dvr',
  duo: 'duo',
  dry_cleaning: 'dry_cleaning',
  dry: 'dry',
  drive_folder_upload: 'drive_folder_upload',
  drive_file_rename_outline: 'drive_file_rename_outline',
  drive_file_move_rtl: 'drive_file_move_rtl',
  drive_file_move_outline: 'drive_file_move_outline',
  drive_file_move: 'drive_file_move',
  drive_eta: 'drive_eta',
  draw: 'draw',
  drag_indicator: 'drag_indicator',
  drag_handle: 'drag_handle',
  drafts: 'drafts',
  downloading: 'downloading',
  download_for_offline: 'download_for_offline',
  download_done: 'download_done',
  download: 'download',
  downhill_skiing: 'downhill_skiing',
  double_arrow: 'double_arrow',
  doorbell: 'doorbell',
  door_sliding: 'door_sliding',
  door_front: 'door_front',
  door_back: 'door_back',
  donut_small: 'donut_small',
  donut_large: 'donut_large',
  done_outline: 'done_outline',
  done_all: 'done_all',
  done: 'done',
  domain_verification: 'domain_verification',
  domain_disabled: 'domain_disabled',
  domain_add: 'domain_add',
  domain: 'domain',
  document_scanner: 'document_scanner',
  dock: 'dock',
  do_not_touch: 'do_not_touch',
  do_not_step: 'do_not_step',
  do_not_disturb_on_total_silence: 'do_not_disturb_on_total_silence',
  do_not_disturb_on: 'do_not_disturb_on',
  do_not_disturb_off: 'do_not_disturb_off',
  do_not_disturb_alt: 'do_not_disturb_alt',
  do_not_disturb: 'do_not_disturb',
  do_disturb_on: 'do_disturb_on',
  do_disturb_off: 'do_disturb_off',
  do_disturb_alt: 'do_disturb_alt',
  do_disturb: 'do_disturb',
  dns: 'dns',
  dnd_forwardslash: 'dnd_forwardslash',
  display_settings: 'display_settings',
  discount: 'discount',
  discord: 'discord',
  disc_full: 'disc_full',
  disabled_visible: 'disabled_visible',
  disabled_by_default: 'disabled_by_default',
  dirty_lens: 'dirty_lens',
  directions_walk: 'directions_walk',
  directions_transit_filled: 'directions_transit_filled',
  directions_transit: 'directions_transit',
  directions_train: 'directions_train',
  directions_subway_filled: 'directions_subway_filled',
  directions_subway: 'directions_subway',
  directions_run: 'directions_run',
  directions_railway_filled: 'directions_railway_filled',
  directions_railway: 'directions_railway',
  directions_off: 'directions_off',
  directions_ferry: 'directions_ferry',
  directions_car_filled: 'directions_car_filled',
  directions_car: 'directions_car',
  directions_bus_filled: 'directions_bus_filled',
  directions_bus: 'directions_bus',
  directions_boat_filled: 'directions_boat_filled',
  directions_boat: 'directions_boat',
  directions_bike: 'directions_bike',
  directions: 'directions',
  dinner_dining: 'dinner_dining',
  dining: 'dining',
  difference: 'difference',
  diamond: 'diamond',
  dialpad: 'dialpad',
  dialer_sip: 'dialer_sip',
  devices_other: 'devices_other',
  devices_fold: 'devices_fold',
  devices: 'devices',
  device_unknown: 'device_unknown',
  device_thermostat: 'device_thermostat',
  device_hub: 'device_hub',
  developer_mode: 'developer_mode',
  developer_board_off: 'developer_board_off',
  developer_board: 'developer_board',
  details: 'details',
  desktop_windows: 'desktop_windows',
  desktop_mac: 'desktop_mac',
  desktop_access_disabled: 'desktop_access_disabled',
  desk: 'desk',
  design_services: 'design_services',
  deselect: 'deselect',
  description: 'description',
  departure_board: 'departure_board',
  density_small: 'density_small',
  density_medium: 'density_medium',
  density_large: 'density_large',
  delivery_dining: 'delivery_dining',
  delete_sweep: 'delete_sweep',
  delete_outline: 'delete_outline',
  delete_forever: 'delete_forever',
  delete: 'delete',
  dehaze: 'dehaze',
  deck: 'deck',
  deblur: 'deblur',
  date_range: 'date_range',
  dataset_linked: 'dataset_linked',
  dataset: 'dataset',
  data_usage: 'data_usage',
  data_thresholding: 'data_thresholding',
  data_saver_on: 'data_saver_on',
  data_saver_off: 'data_saver_off',
  data_object: 'data_object',
  data_exploration: 'data_exploration',
  data_array: 'data_array',
  dashboard_customize: 'dashboard_customize',
  dashboard: 'dashboard',
  dark_mode: 'dark_mode',
  dangerous: 'dangerous',
  extension_off: 'extension_off',
  extension: 'extension',
  exposure_zero: 'exposure_zero',
  exposure_plus_2: 'exposure_plus_2',
  exposure_plus_1: 'exposure_plus_1',
  exposure_neg_2: 'exposure_neg_2',
  exposure_neg_1: 'exposure_neg_1',
  exposure_minus_2: 'exposure_minus_2',
  exposure_minus_1: 'exposure_minus_1',
  exposure: 'exposure',
  explore_off: 'explore_off',
  explore: 'explore',
  explicit: 'explicit',
  expand_more: 'expand_more',
  expand_less: 'expand_less',
  expand_circle_down: 'expand_circle_down',
  expand: 'expand',
  exit_to_app: 'exit_to_app',
  event_seat: 'event_seat',
  event_repeat: 'event_repeat',
  event_note: 'event_note',
  event_busy: 'event_busy',
  event_available: 'event_available',
  event: 'event',
  ev_station: 'ev_station',
  euro_symbol: 'euro_symbol',
  euro: 'euro',
  escalator_warning: 'escalator_warning',
  escalator: 'escalator',
  error_outline: 'error_outline',
  error: 'error',
  equalizer: 'equalizer',
  enhanced_encryption: 'enhanced_encryption',
  enhance_photo_translate: 'enhance_photo_translate',
  engineering: 'engineering',
  energy_savings_leaf: 'energy_savings_leaf',
  emoji_transportation: 'emoji_transportation',
  emoji_symbols: 'emoji_symbols',
  emoji_people: 'emoji_people',
  emoji_objects: 'emoji_objects',
  emoji_nature: 'emoji_nature',
  emoji_food_beverage: 'emoji_food_beverage',
  emoji_flags: 'emoji_flags',
  emoji_events: 'emoji_events',
  emoji_emotions: 'emoji_emotions',
  emergency_share: 'emergency_share',
  emergency_recording: 'emergency_recording',
  emergency: 'emergency',
  email: 'email',
  elevator: 'elevator',
  electrical_services: 'electrical_services',
  electric_scooter: 'electric_scooter',
  electric_rickshaw: 'electric_rickshaw',
  electric_moped: 'electric_moped',
  electric_meter: 'electric_meter',
  electric_car: 'electric_car',
  electric_bolt: 'electric_bolt',
  electric_bike: 'electric_bike',
  elderly_woman: 'elderly_woman',
  elderly: 'elderly',
  eject: 'eject',
  egg_alt: 'egg_alt',
  egg: 'egg',
  edit_road: 'edit_road',
  edit_off: 'edit_off',
  edit_notifications: 'edit_notifications',
  edit_note: 'edit_note',
  edit_location_alt: 'edit_location_alt',
  edit_location: 'edit_location',
  edit_calendar: 'edit_calendar',
  edit_attributes: 'edit_attributes',
  edit: 'edit',
  edgesensor_low: 'edgesensor_low',
  edgesensor_high: 'edgesensor_high',
  eco: 'eco',
  east: 'east',
  earbuds_battery: 'earbuds_battery',
  earbuds: 'earbuds',
  e_mobiledata: 'e_mobiledata',
  functions: 'functions',
  fullscreen_exit: 'fullscreen_exit',
  fullscreen: 'fullscreen',
  front_hand: 'front_hand',
  free_cancellation: 'free_cancellation',
  free_breakfast: 'free_breakfast',
  foundation: 'foundation',
  forward_to_inbox: 'forward_to_inbox',
  forward_5: 'forward_5',
  forward_30: 'forward_30',
  forward_10: 'forward_10',
  forward: 'forward',
  forum: 'forum',
  fort: 'fort',
  format_underlined: 'format_underlined',
  format_underline: 'format_underline',
  format_textdirection_r_to_l: 'format_textdirection_r_to_l',
  format_textdirection_l_to_r: 'format_textdirection_l_to_r',
  format_strikethrough: 'format_strikethrough',
  format_size: 'format_size',
  format_shapes: 'format_shapes',
  format_quote: 'format_quote',
  format_paint: 'format_paint',
  format_overline: 'format_overline',
  format_list_numbered_rtl: 'format_list_numbered_rtl',
  format_list_numbered: 'format_list_numbered',
  format_list_bulleted: 'format_list_bulleted',
  format_line_spacing: 'format_line_spacing',
  format_italic: 'format_italic',
  format_indent_increase: 'format_indent_increase',
  format_indent_decrease: 'format_indent_decrease',
  format_color_text: 'format_color_text',
  format_color_reset: 'format_color_reset',
  format_color_fill: 'format_color_fill',
  format_clear: 'format_clear',
  format_bold: 'format_bold',
  format_align_right: 'format_align_right',
  format_align_left: 'format_align_left',
  format_align_justify: 'format_align_justify',
  format_align_center: 'format_align_center',
  fork_right: 'fork_right',
  fork_left: 'fork_left',
  forest: 'forest',
  food_bank: 'food_bank',
  font_download_off: 'font_download_off',
  font_download: 'font_download',
  follow_the_signs: 'follow_the_signs',
  folder_zip: 'folder_zip',
  folder_special: 'folder_special',
  folder_shared: 'folder_shared',
  folder_open: 'folder_open',
  folder_off: 'folder_off',
  folder_delete: 'folder_delete',
  folder_copy: 'folder_copy',
  folder: 'folder',
  foggy: 'foggy',
  fmd_good: 'fmd_good',
  fmd_bad: 'fmd_bad',
  flutter_dash: 'flutter_dash',
  flourescent: 'flourescent',
  flood: 'flood',
  flip_to_front: 'flip_to_front',
  flip_to_back: 'flip_to_back',
  flip_camera_ios: 'flip_camera_ios',
  flip_camera_android: 'flip_camera_android',
  flip: 'flip',
  flight_takeoff: 'flight_takeoff',
  flight_land: 'flight_land',
  flight_class: 'flight_class',
  flight: 'flight',
  flatware: 'flatware',
  flashlight_on: 'flashlight_on',
  flashlight_off: 'flashlight_off',
  flash_on: 'flash_on',
  flash_off: 'flash_off',
  flash_auto: 'flash_auto',
  flare: 'flare',
  flaky: 'flaky',
  flag_circle: 'flag_circle',
  flag: 'flag',
  fitness_center: 'fitness_center',
  fitbit: 'fitbit',
  fit_screen: 'fit_screen',
  first_page: 'first_page',
  fireplace: 'fireplace',
  fire_truck: 'fire_truck',
  fire_hydrant_alt: 'fire_hydrant_alt',
  fire_hydrant: 'fire_hydrant',
  fire_extinguisher: 'fire_extinguisher',
  fingerprint: 'fingerprint',
  find_replace: 'find_replace',
  find_in_page: 'find_in_page',
  filter_vintage: 'filter_vintage',
  filter_tilt_shift: 'filter_tilt_shift',
  filter_none: 'filter_none',
  filter_list_off: 'filter_list_off',
  filter_list_alt: 'filter_list_alt',
  filter_list: 'filter_list',
  filter_hdr: 'filter_hdr',
  filter_frames: 'filter_frames',
  filter_drama: 'filter_drama',
  filter_center_focus: 'filter_center_focus',
  filter_b_and_w: 'filter_b_and_w',
  filter_alt_off: 'filter_alt_off',
  filter_alt: 'filter_alt',
  filter_9_plus: 'filter_9_plus',
  filter_9: 'filter_9',
  filter_8: 'filter_8',
  filter_7: 'filter_7',
  filter_6: 'filter_6',
  filter_5: 'filter_5',
  filter_4: 'filter_4',
  filter_3: 'filter_3',
  filter_2: 'filter_2',
  filter_1: 'filter_1',
  filter: 'filter',
  file_upload: 'file_upload',
  file_present: 'file_present',
  file_open: 'file_open',
  file_download_off: 'file_download_off',
  file_download_done: 'file_download_done',
  file_download: 'file_download',
  file_copy: 'file_copy',
  fiber_smart_record: 'fiber_smart_record',
  fiber_pin: 'fiber_pin',
  fiber_new: 'fiber_new',
  fiber_manual_record: 'fiber_manual_record',
  fiber_dvr: 'fiber_dvr',
  festival: 'festival',
  fence: 'fence',
  female: 'female',
  feedback: 'feedback',
  feed: 'feed',
  featured_video: 'featured_video',
  featured_play_list: 'featured_play_list',
  fax: 'fax',
  favorite_outline: 'favorite_outline',
  favorite_border: 'favorite_border',
  favorite: 'favorite',
  fastfood: 'fastfood',
  fast_rewind: 'fast_rewind',
  fast_forward: 'fast_forward',
  family_restroom: 'family_restroom',
  factory: 'factory',
  fact_check: 'fact_check',
  facebook: 'facebook',
  face_retouching_off: 'face_retouching_off',
  face_retouching_natural: 'face_retouching_natural',
  face: 'face',
  groups: 'groups',
  group_work: 'group_work',
  group_remove: 'group_remove',
  group_off: 'group_off',
  group_add: 'group_add',
  group: 'group',
  grid_view: 'grid_view',
  grid_on: 'grid_on',
  grid_off: 'grid_off',
  grid_goldenratio: 'grid_goldenratio',
  grid_4x4: 'grid_4x4',
  grid_3x3: 'grid_3x3',
  grass: 'grass',
  graphic_eq: 'graphic_eq',
  grain: 'grain',
  grading: 'grading',
  gradient: 'gradient',
  grade: 'grade',
  gps_off: 'gps_off',
  gps_not_fixed: 'gps_not_fixed',
  gps_fixed: 'gps_fixed',
  gpp_maybe: 'gpp_maybe',
  gpp_good: 'gpp_good',
  gpp_bad: 'gpp_bad',
  golf_course: 'golf_course',
  goat: 'goat',
  gite: 'gite',
  girl: 'girl',
  gif_box: 'gif_box',
  gif: 'gif',
  get_app: 'get_app',
  gesture: 'gesture',
  generating_tokens: 'generating_tokens',
  gavel: 'gavel',
  gas_meter: 'gas_meter',
  garage: 'garage',
  games: 'games',
  gamepad: 'gamepad',
  g_translate: 'g_translate',
  g_mobiledata: 'g_mobiledata',
  hvac: 'hvac',
  hub: 'hub',
  https: 'https',
  http: 'http',
  html: 'html',
  how_to_vote: 'how_to_vote',
  how_to_reg: 'how_to_reg',
  houseboat: 'houseboat',
  house_siding: 'house_siding',
  house: 'house',
  hourglass_top: 'hourglass_top',
  hourglass_full: 'hourglass_full',
  hourglass_empty: 'hourglass_empty',
  hourglass_disabled: 'hourglass_disabled',
  hourglass_bottom: 'hourglass_bottom',
  hotel_class: 'hotel_class',
  hotel: 'hotel',
  hot_tub: 'hot_tub',
  horizontal_split: 'horizontal_split',
  horizontal_rule: 'horizontal_rule',
  horizontal_distribute: 'horizontal_distribute',
  home_work: 'home_work',
  home_repair_service: 'home_repair_service',
  home_mini: 'home_mini',
  home_max: 'home_max',
  home_filled: 'home_filled',
  home: 'home',
  holiday_village: 'holiday_village',
  hls_off: 'hls_off',
  hls: 'hls',
  hive: 'hive',
  history_toggle_off: 'history_toggle_off',
  history_edu: 'history_edu',
  history: 'history',
  hiking: 'hiking',
  highlight_remove: 'highlight_remove',
  highlight_off: 'highlight_off',
  highlight_alt: 'highlight_alt',
  highlight: 'highlight',
  high_quality: 'high_quality',
  hide_source: 'hide_source',
  hide_image: 'hide_image',
  hexagon: 'hexagon',
  hevc: 'hevc',
  help_outline: 'help_outline',
  help_center: 'help_center',
  help: 'help',
  height: 'height',
  heat_pump: 'heat_pump',
  heart_broken: 'heart_broken',
  hearing_disabled: 'hearing_disabled',
  hearing: 'hearing',
  health_and_safety: 'health_and_safety',
  healing: 'healing',
  headset_off: 'headset_off',
  headset_mic: 'headset_mic',
  headset: 'headset',
  headphones_battery: 'headphones_battery',
  headphones: 'headphones',
  hdr_weak: 'hdr_weak',
  hdr_strong: 'hdr_strong',
  hdr_plus: 'hdr_plus',
  hdr_on_select: 'hdr_on_select',
  hdr_on: 'hdr_on',
  hdr_off_select: 'hdr_off_select',
  hdr_off: 'hdr_off',
  hdr_enhanced_select: 'hdr_enhanced_select',
  hdr_auto_select: 'hdr_auto_select',
  hdr_auto: 'hdr_auto',
  hd: 'hd',
  hardware: 'hardware',
  handyman: 'handyman',
  handshake: 'handshake',
  hail: 'hail',
  h_plus_mobiledata: 'h_plus_mobiledata',
  h_mobiledata: 'h_mobiledata',
  iso: 'iso',
  iron: 'iron',
  ios_share: 'ios_share',
  invert_colors_on: 'invert_colors_on',
  invert_colors_off: 'invert_colors_off',
  invert_colors: 'invert_colors',
  inventory_2: 'inventory_2',
  inventory: 'inventory',
  interpreter_mode: 'interpreter_mode',
  interests: 'interests',
  integration_instructions: 'integration_instructions',
  install_mobile: 'install_mobile',
  install_desktop: 'install_desktop',
  insights: 'insights',
  insert_photo: 'insert_photo',
  insert_page_break: 'insert_page_break',
  insert_link: 'insert_link',
  insert_invitation: 'insert_invitation',
  insert_emoticon: 'insert_emoticon',
  insert_drive_file: 'insert_drive_file',
  insert_comment: 'insert_comment',
  insert_chart_outlined: 'insert_chart_outlined',
  insert_chart: 'insert_chart',
  input: 'input',
  info_outline: 'info_outline',
  info: 'info',
  indeterminate_check_box: 'indeterminate_check_box',
  incomplete_circle: 'incomplete_circle',
  inbox: 'inbox',
  important_devices: 'important_devices',
  import_export: 'import_export',
  import_contacts: 'import_contacts',
  imagesearch_roller: 'imagesearch_roller',
  image_search: 'image_search',
  image_not_supported: 'image_not_supported',
  image_aspect_ratio: 'image_aspect_ratio',
  image: 'image',
  icecream: 'icecream',
  ice_skating: 'ice_skating',
  join_right: 'join_right',
  join_left: 'join_left',
  join_inner: 'join_inner',
  join_full: 'join_full',
  javascript: 'javascript',
  kitesurfing: 'kitesurfing',
  kitchen: 'kitchen',
  king_bed: 'king_bed',
  keyboard_voice: 'keyboard_voice',
  keyboard_tab: 'keyboard_tab',
  keyboard_return: 'keyboard_return',
  keyboard_option_key: 'keyboard_option_key',
  keyboard_option: 'keyboard_option',
  keyboard_hide: 'keyboard_hide',
  keyboard_double_arrow_up: 'keyboard_double_arrow_up',
  keyboard_double_arrow_right: 'keyboard_double_arrow_right',
  keyboard_double_arrow_left: 'keyboard_double_arrow_left',
  keyboard_double_arrow_down: 'keyboard_double_arrow_down',
  keyboard_control_key: 'keyboard_control_key',
  keyboard_control: 'keyboard_control',
  keyboard_command_key: 'keyboard_command_key',
  keyboard_command: 'keyboard_command',
  keyboard_capslock: 'keyboard_capslock',
  keyboard_backspace: 'keyboard_backspace',
  keyboard_arrow_up: 'keyboard_arrow_up',
  keyboard_arrow_right: 'keyboard_arrow_right',
  keyboard_arrow_left: 'keyboard_arrow_left',
  keyboard_arrow_down: 'keyboard_arrow_down',
  keyboard_alt: 'keyboard_alt',
  keyboard: 'keyboard',
  key_off: 'key_off',
  key: 'key',
  kebab_dining: 'kebab_dining',
  kayaking: 'kayaking',
  lyrics: 'lyrics',
  lunch_dining: 'lunch_dining',
  luggage: 'luggage',
  lte_plus_mobiledata: 'lte_plus_mobiledata',
  lte_mobiledata: 'lte_mobiledata',
  loyalty: 'loyalty',
  low_priority: 'low_priority',
  loupe: 'loupe',
  loop: 'loop',
  looks_two: 'looks_two',
  looks_one: 'looks_one',
  looks_6: 'looks_6',
  looks_5: 'looks_5',
  looks_4: 'looks_4',
  looks_3: 'looks_3',
  looks: 'looks',
  logout: 'logout',
  logo_dev: 'logo_dev',
  login: 'login',
  lock_reset: 'lock_reset',
  lock_person: 'lock_person',
  lock_outline: 'lock_outline',
  lock_open: 'lock_open',
  lock_clock: 'lock_clock',
  lock: 'lock',
  location_searching: 'location_searching',
  location_pin: 'location_pin',
  location_on: 'location_on',
  location_off: 'location_off',
  location_history: 'location_history',
  location_disabled: 'location_disabled',
  location_city: 'location_city',
  local_taxi: 'local_taxi',
  local_shipping: 'local_shipping',
  local_see: 'local_see',
  local_restaurant: 'local_restaurant',
  local_printshop: 'local_printshop',
  local_print_shop: 'local_print_shop',
  local_post_office: 'local_post_office',
  local_police: 'local_police',
  local_play: 'local_play',
  local_pizza: 'local_pizza',
  local_phone: 'local_phone',
  local_pharmacy: 'local_pharmacy',
  local_parking: 'local_parking',
  local_offer: 'local_offer',
  local_movies: 'local_movies',
  local_mall: 'local_mall',
  local_library: 'local_library',
  local_laundry_service: 'local_laundry_service',
  local_hotel: 'local_hotel',
  local_hospital: 'local_hospital',
  local_grocery_store: 'local_grocery_store',
  local_gas_station: 'local_gas_station',
  local_florist: 'local_florist',
  local_fire_department: 'local_fire_department',
  local_drink: 'local_drink',
  local_dining: 'local_dining',
  local_convenience_store: 'local_convenience_store',
  local_car_wash: 'local_car_wash',
  local_cafe: 'local_cafe',
  local_bar: 'local_bar',
  local_attraction: 'local_attraction',
  local_atm: 'local_atm',
  local_airport: 'local_airport',
  local_activity: 'local_activity',
  living: 'living',
  live_tv: 'live_tv',
  live_help: 'live_help',
  list_alt: 'list_alt',
  list: 'list',
  liquor: 'liquor',
  linked_camera: 'linked_camera',
  link_off: 'link_off',
  link: 'link',
  linear_scale: 'linear_scale',
  line_weight: 'line_weight',
  line_style: 'line_style',
  line_axis: 'line_axis',
  lightbulb_outline: 'lightbulb_outline',
  lightbulb_circle: 'lightbulb_circle',
  lightbulb: 'lightbulb',
  light_mode: 'light_mode',
  light: 'light',
  library_music: 'library_music',
  library_books: 'library_books',
  library_add_check: 'library_add_check',
  library_add: 'library_add',
  lens_blur: 'lens_blur',
  lens: 'lens',
  legend_toggle: 'legend_toggle',
  leave_bags_at_home: 'leave_bags_at_home',
  leak_remove: 'leak_remove',
  leak_add: 'leak_add',
  leaderboard: 'leaderboard',
  layers_clear: 'layers_clear',
  layers: 'layers',
  launch: 'launch',
  last_page: 'last_page',
  laptop_windows: 'laptop_windows',
  laptop_mac: 'laptop_mac',
  laptop_chromebook: 'laptop_chromebook',
  laptop: 'laptop',
  language: 'language',
  landslide: 'landslide',
  landscape: 'landscape',
  lan: 'lan',
  label_outline: 'label_outline',
  label_off: 'label_off',
  label_important_outline: 'label_important_outline',
  label_important: 'label_important',
  label: 'label',
  my_location: 'my_location',
  my_library_music: 'my_library_music',
  my_library_books: 'my_library_books',
  my_library_add: 'my_library_add',
  music_video: 'music_video',
  music_off: 'music_off',
  music_note: 'music_note',
  museum: 'museum',
  multitrack_audio: 'multitrack_audio',
  multiple_stop: 'multiple_stop',
  multiline_chart: 'multiline_chart',
  mp: 'mp',
  moving: 'moving',
  movie_filter: 'movie_filter',
  movie_creation: 'movie_creation',
  movie: 'movie',
  move_up: 'move_up',
  move_to_inbox: 'move_to_inbox',
  move_down: 'move_down',
  mouse: 'mouse',
  motorcycle: 'motorcycle',
  motion_photos_paused: 'motion_photos_paused',
  motion_photos_pause: 'motion_photos_pause',
  motion_photos_on: 'motion_photos_on',
  motion_photos_off: 'motion_photos_off',
  motion_photos_auto: 'motion_photos_auto',
  mosque: 'mosque',
  more_vert: 'more_vert',
  more_time: 'more_time',
  more_horiz: 'more_horiz',
  more: 'more',
  moped: 'moped',
  mood_bad: 'mood_bad',
  mood: 'mood',
  monochrome_photos: 'monochrome_photos',
  monitor_weight: 'monitor_weight',
  monitor_heart: 'monitor_heart',
  monitor: 'monitor',
  money_off_csred: 'money_off_csred',
  money_off: 'money_off',
  money: 'money',
  monetization_on: 'monetization_on',
  model_training: 'model_training',
  mode_standby: 'mode_standby',
  mode_of_travel: 'mode_of_travel',
  mode_night: 'mode_night',
  mode_fan_off: 'mode_fan_off',
  mode_edit_outline: 'mode_edit_outline',
  mode_edit: 'mode_edit',
  mode_comment: 'mode_comment',
  mode: 'mode',
  mobiledata_off: 'mobiledata_off',
  mobile_screen_share: 'mobile_screen_share',
  mobile_off: 'mobile_off',
  mobile_friendly: 'mobile_friendly',
  missed_video_call: 'missed_video_call',
  miscellaneous_services: 'miscellaneous_services',
  minor_crash: 'minor_crash',
  minimize: 'minimize',
  military_tech: 'military_tech',
  microwave: 'microwave',
  mic_off: 'mic_off',
  mic_none: 'mic_none',
  mic_external_on: 'mic_external_on',
  mic_external_off: 'mic_external_off',
  mic: 'mic',
  messenger_outline: 'messenger_outline',
  messenger: 'messenger',
  message: 'message',
  merge_type: 'merge_type',
  merge: 'merge',
  menu_open: 'menu_open',
  menu_book: 'menu_book',
  menu: 'menu',
  memory: 'memory',
  meeting_room: 'meeting_room',
  medication_liquid: 'medication_liquid',
  medication: 'medication',
  medical_services: 'medical_services',
  medical_information: 'medical_information',
  mediation: 'mediation',
  media_bluetooth_on: 'media_bluetooth_on',
  media_bluetooth_off: 'media_bluetooth_off',
  maximize: 'maximize',
  masks: 'masks',
  markunread_mailbox: 'markunread_mailbox',
  markunread: 'markunread',
  mark_unread_chat_alt: 'mark_unread_chat_alt',
  mark_email_unread: 'mark_email_unread',
  mark_email_read: 'mark_email_read',
  mark_chat_unread: 'mark_chat_unread',
  mark_chat_read: 'mark_chat_read',
  mark_as_unread: 'mark_as_unread',
  margin: 'margin',
  maps_ugc: 'maps_ugc',
  maps_home_work: 'maps_home_work',
  map: 'map',
  manage_search: 'manage_search',
  manage_history: 'manage_history',
  manage_accounts: 'manage_accounts',
  man: 'man',
  male: 'male',
  mail_outline: 'mail_outline',
  mail_lock: 'mail_lock',
  mail: 'mail',
  numbers: 'numbers',
  now_widgets: 'now_widgets',
  now_wallpaper: 'now_wallpaper',
  notifications_paused: 'notifications_paused',
  notifications_on: 'notifications_on',
  notifications_off: 'notifications_off',
  notifications_none: 'notifications_none',
  notifications_active: 'notifications_active',
  notifications: 'notifications',
  notification_important: 'notification_important',
  notification_add: 'notification_add',
  notes: 'notes',
  note_alt: 'note_alt',
  note_add: 'note_add',
  note: 'note',
  not_started: 'not_started',
  not_listed_location: 'not_listed_location',
  not_interested: 'not_interested',
  not_accessible: 'not_accessible',
  north_west: 'north_west',
  north_east: 'north_east',
  north: 'north',
  nordic_walking: 'nordic_walking',
  noise_control_off: 'noise_control_off',
  noise_aware: 'noise_aware',
  no_transfer: 'no_transfer',
  no_stroller: 'no_stroller',
  no_sim: 'no_sim',
  no_photography: 'no_photography',
  no_meeting_room: 'no_meeting_room',
  no_meals_ouline: 'no_meals_ouline',
  no_meals: 'no_meals',
  no_luggage: 'no_luggage',
  no_food: 'no_food',
  no_flash: 'no_flash',
  no_encryption_gmailerrorred: 'no_encryption_gmailerrorred',
  no_encryption: 'no_encryption',
  no_drinks: 'no_drinks',
  no_crash: 'no_crash',
  no_cell: 'no_cell',
  no_backpack: 'no_backpack',
  no_adult_content: 'no_adult_content',
  no_accounts: 'no_accounts',
  nights_stay: 'nights_stay',
  nightlight_round: 'nightlight_round',
  nightlight: 'nightlight',
  nightlife: 'nightlife',
  night_shelter: 'night_shelter',
  nfc: 'nfc',
  next_week: 'next_week',
  next_plan: 'next_plan',
  newspaper: 'newspaper',
  new_releases: 'new_releases',
  new_label: 'new_label',
  network_wifi_3_bar: 'network_wifi_3_bar',
  network_wifi_2_bar: 'network_wifi_2_bar',
  network_wifi_1_bar: 'network_wifi_1_bar',
  network_wifi: 'network_wifi',
  network_ping: 'network_ping',
  network_locked: 'network_locked',
  network_check: 'network_check',
  network_cell: 'network_cell',
  nest_cam_wired_stand: 'nest_cam_wired_stand',
  nearby_off: 'nearby_off',
  nearby_error: 'nearby_error',
  near_me_disabled: 'near_me_disabled',
  near_me: 'near_me',
  navigation: 'navigation',
  navigate_next: 'navigate_next',
  navigate_before: 'navigate_before',
  nature_people: 'nature_people',
  nature: 'nature',
  nat: 'nat',
  output: 'output',
  outlined_flag: 'outlined_flag',
  outlet: 'outlet',
  outgoing_mail: 'outgoing_mail',
  outdoor_grill: 'outdoor_grill',
  outbox: 'outbox',
  outbound: 'outbound',
  outbond: 'outbond',
  other_houses: 'other_houses',
  open_with: 'open_with',
  open_in_new_off: 'open_in_new_off',
  open_in_new: 'open_in_new',
  open_in_full: 'open_in_full',
  open_in_browser: 'open_in_browser',
  opacity: 'opacity',
  online_prediction: 'online_prediction',
  ondemand_video: 'ondemand_video',
  on_device_training: 'on_device_training',
  oil_barrel: 'oil_barrel',
  offline_share: 'offline_share',
  offline_pin: 'offline_pin',
  offline_bolt: 'offline_bolt',
  push_pin: 'push_pin',
  punch_clock: 'punch_clock',
  published_with_changes: 'published_with_changes',
  publish: 'publish',
  public_off: 'public_off',
  public: 'public',
  psychology_alt: 'psychology_alt',
  psychology: 'psychology',
  propane_tank: 'propane_tank',
  propane: 'propane',
  production_quantity_limits: 'production_quantity_limits',
  private_connectivity: 'private_connectivity',
  privacy_tip: 'privacy_tip',
  priority_high: 'priority_high',
  print_disabled: 'print_disabled',
  print: 'print',
  price_check: 'price_check',
  price_change: 'price_change',
  preview: 'preview',
  present_to_all: 'present_to_all',
  pregnant_woman: 'pregnant_woman',
  precision_manufacturing: 'precision_manufacturing',
  power_settings_new: 'power_settings_new',
  power_off: 'power_off',
  power_input: 'power_input',
  power: 'power',
  post_add: 'post_add',
  portrait: 'portrait',
  portable_wifi_off: 'portable_wifi_off',
  pool: 'pool',
  polymer: 'polymer',
  polyline: 'polyline',
  poll: 'poll',
  policy: 'policy',
  point_of_sale: 'point_of_sale',
  podcasts: 'podcasts',
  plus_one: 'plus_one',
  plumbing: 'plumbing',
  playlist_remove: 'playlist_remove',
  playlist_play: 'playlist_play',
  playlist_add_circle: 'playlist_add_circle',
  playlist_add_check_circle: 'playlist_add_check_circle',
  playlist_add_check: 'playlist_add_check',
  playlist_add: 'playlist_add',
  play_lesson: 'play_lesson',
  play_for_work: 'play_for_work',
  play_disabled: 'play_disabled',
  play_circle_outline: 'play_circle_outline',
  play_circle_filled: 'play_circle_filled',
  play_circle_fill: 'play_circle_fill',
  play_circle: 'play_circle',
  play_arrow: 'play_arrow',
  plagiarism: 'plagiarism',
  place: 'place',
  pix: 'pix',
  pivot_table_chart: 'pivot_table_chart',
  pinch: 'pinch',
  pin_invoke: 'pin_invoke',
  pin_end: 'pin_end',
  pin_drop: 'pin_drop',
  pin: 'pin',
  pie_chart_outlined: 'pie_chart_outlined',
  pie_chart_outline: 'pie_chart_outline',
  pie_chart: 'pie_chart',
  picture_in_picture_alt: 'picture_in_picture_alt',
  picture_in_picture: 'picture_in_picture',
  picture_as_pdf: 'picture_as_pdf',
  piano_off: 'piano_off',
  piano: 'piano',
  php: 'php',
  photo_size_select_small: 'photo_size_select_small',
  photo_size_select_large: 'photo_size_select_large',
  photo_size_select_actual: 'photo_size_select_actual',
  photo_library: 'photo_library',
  photo_filter: 'photo_filter',
  photo_camera_front: 'photo_camera_front',
  photo_camera_back: 'photo_camera_back',
  photo_camera: 'photo_camera',
  photo_album: 'photo_album',
  photo: 'photo',
  phonelink_setup: 'phonelink_setup',
  phonelink_ring: 'phonelink_ring',
  phonelink_off: 'phonelink_off',
  phonelink_lock: 'phonelink_lock',
  phonelink_erase: 'phonelink_erase',
  phonelink: 'phonelink',
  phone_paused: 'phone_paused',
  phone_missed: 'phone_missed',
  phone_locked: 'phone_locked',
  phone_iphone: 'phone_iphone',
  phone_in_talk: 'phone_in_talk',
  phone_forwarded: 'phone_forwarded',
  phone_enabled: 'phone_enabled',
  phone_disabled: 'phone_disabled',
  phone_callback: 'phone_callback',
  phone_bluetooth_speaker: 'phone_bluetooth_speaker',
  phone_android: 'phone_android',
  phone: 'phone',
  phishing: 'phishing',
  pets: 'pets',
  pest_control_rodent: 'pest_control_rodent',
  pest_control: 'pest_control',
  personal_video: 'personal_video',
  personal_injury: 'personal_injury',
  person_search: 'person_search',
  person_remove_alt_1: 'person_remove_alt_1',
  person_remove: 'person_remove',
  person_pin_circle: 'person_pin_circle',
  person_pin: 'person_pin',
  person_outline: 'person_outline',
  person_off: 'person_off',
  person_add_disabled: 'person_add_disabled',
  person_add_alt_1: 'person_add_alt_1',
  person_add_alt: 'person_add_alt',
  person_add: 'person_add',
  person: 'person',
  perm_scan_wifi: 'perm_scan_wifi',
  perm_phone_msg: 'perm_phone_msg',
  perm_media: 'perm_media',
  perm_identity: 'perm_identity',
  perm_device_information: 'perm_device_information',
  perm_device_info: 'perm_device_info',
  perm_data_setting: 'perm_data_setting',
  perm_contact_calendar: 'perm_contact_calendar',
  perm_contact_cal: 'perm_contact_cal',
  perm_camera_mic: 'perm_camera_mic',
  percent: 'percent',
  people_outline: 'people_outline',
  people_alt: 'people_alt',
  people: 'people',
  pentagon: 'pentagon',
  pending_actions: 'pending_actions',
  pending: 'pending',
  pedal_bike: 'pedal_bike',
  paypal: 'paypal',
  payments: 'payments',
  payment: 'payment',
  pause_presentation: 'pause_presentation',
  pause_circle_outline: 'pause_circle_outline',
  pause_circle_filled: 'pause_circle_filled',
  pause_circle: 'pause_circle',
  pause: 'pause',
  pattern: 'pattern',
  password: 'password',
  party_mode: 'party_mode',
  park: 'park',
  paragliding: 'paragliding',
  panorama_wide_angle_select: 'panorama_wide_angle_select',
  panorama_wide_angle: 'panorama_wide_angle',
  panorama_vertical_select: 'panorama_vertical_select',
  panorama_vertical: 'panorama_vertical',
  panorama_photosphere_select: 'panorama_photosphere_select',
  panorama_photosphere: 'panorama_photosphere',
  panorama_horizontal_select: 'panorama_horizontal_select',
  panorama_horizontal: 'panorama_horizontal',
  panorama_fisheye: 'panorama_fisheye',
  panorama_fish_eye: 'panorama_fish_eye',
  panorama: 'panorama',
  pan_tool_alt: 'pan_tool_alt',
  pan_tool: 'pan_tool',
  palette: 'palette',
  paid: 'paid',
  pageview: 'pageview',
  pages: 'pages',
  padding: 'padding',
  quora: 'quora',
  quiz: 'quiz',
  quickreply: 'quickreply',
  quick_contacts_mail: 'quick_contacts_mail',
  quick_contacts_dialer: 'quick_contacts_dialer',
  queue_play_next: 'queue_play_next',
  queue_music: 'queue_music',
  queue: 'queue',
  question_mark: 'question_mark',
  question_answer: 'question_answer',
  query_stats: 'query_stats',
  query_builder: 'query_builder',
  qr_code_scanner: 'qr_code_scanner',
  qr_code_2: 'qr_code_2',
  qr_code: 'qr_code',
  rv_hookup: 'rv_hookup',
  running_with_errors: 'running_with_errors',
  run_circle: 'run_circle',
  rule_folder: 'rule_folder',
  rule: 'rule',
  rtt: 'rtt',
  rsvp: 'rsvp',
  rss_feed: 'rss_feed',
  rowing: 'rowing',
  router: 'router',
  route: 'route',
  rounded_corner: 'rounded_corner',
  roundabout_right: 'roundabout_right',
  roundabout_left: 'roundabout_left',
  rotate_right: 'rotate_right',
  rotate_left: 'rotate_left',
  rotate_90_degrees_cw: 'rotate_90_degrees_cw',
  rotate_90_degrees_ccw: 'rotate_90_degrees_ccw',
  room_service: 'room_service',
  room_preferences: 'room_preferences',
  room: 'room',
  roofing: 'roofing',
  roller_skating: 'roller_skating',
  roller_shades_closed: 'roller_shades_closed',
  roller_shades: 'roller_shades',
  rocket_launch: 'rocket_launch',
  rocket: 'rocket',
  ring_volume: 'ring_volume',
  rice_bowl: 'rice_bowl',
  reviews: 'reviews',
  restore_page: 'restore_page',
  restore_from_trash: 'restore_from_trash',
  restore: 'restore',
  restaurant_menu: 'restaurant_menu',
  restaurant: 'restaurant',
  restart_alt: 'restart_alt',
  reset_tv: 'reset_tv',
  request_quote: 'request_quote',
  request_page: 'request_page',
  report_problem: 'report_problem',
  report_off: 'report_off',
  report_gmailerrorred: 'report_gmailerrorred',
  report: 'report',
  reply_all: 'reply_all',
  reply: 'reply',
  replay_circle_filled: 'replay_circle_filled',
  replay_5: 'replay_5',
  replay_30: 'replay_30',
  replay_10: 'replay_10',
  replay: 'replay',
  repeat_one_on: 'repeat_one_on',
  repeat_one: 'repeat_one',
  repeat_on: 'repeat_on',
  repeat: 'repeat',
  repartition: 'repartition',
  reorder: 'reorder',
  remove_shopping_cart: 'remove_shopping_cart',
  remove_road: 'remove_road',
  remove_red_eye: 'remove_red_eye',
  remove_moderator: 'remove_moderator',
  remove_from_queue: 'remove_from_queue',
  remove_done: 'remove_done',
  remove_circle_outline: 'remove_circle_outline',
  remove_circle: 'remove_circle',
  remove: 'remove',
  remember_me: 'remember_me',
  refresh: 'refresh',
  reduce_capacity: 'reduce_capacity',
  redo: 'redo',
  redeem: 'redeem',
  reddit: 'reddit',
  recycling: 'recycling',
  rectangle: 'rectangle',
  record_voice_over: 'record_voice_over',
  recommend: 'recommend',
  recent_actors: 'recent_actors',
  receipt_long: 'receipt_long',
  receipt: 'receipt',
  real_estate_agent: 'real_estate_agent',
  read_more: 'read_more',
  raw_on: 'raw_on',
  raw_off: 'raw_off',
  rate_review: 'rate_review',
  ramp_right: 'ramp_right',
  ramp_left: 'ramp_left',
  ramen_dining: 'ramen_dining',
  railway_alert: 'railway_alert',
  radio_button_unchecked: 'radio_button_unchecked',
  radio_button_on: 'radio_button_on',
  radio_button_off: 'radio_button_off',
  radio_button_checked: 'radio_button_checked',
  radio: 'radio',
  radar: 'radar',
  r_mobiledata: 'r_mobiledata',
  system_update_tv: 'system_update_tv',
  system_update_alt: 'system_update_alt',
  system_update: 'system_update',
  system_security_update_warning: 'system_security_update_warning',
  system_security_update_good: 'system_security_update_good',
  system_security_update: 'system_security_update',
  sync_problem: 'sync_problem',
  sync_lock: 'sync_lock',
  sync_disabled: 'sync_disabled',
  sync_alt: 'sync_alt',
  sync: 'sync',
  synagogue: 'synagogue',
  switch_video: 'switch_video',
  switch_right: 'switch_right',
  switch_left: 'switch_left',
  switch_camera: 'switch_camera',
  switch_account: 'switch_account',
  switch_access_shortcut_add: 'switch_access_shortcut_add',
  switch_access_shortcut: 'switch_access_shortcut',
  swipe_vertical: 'swipe_vertical',
  swipe_up_alt: 'swipe_up_alt',
  swipe_up: 'swipe_up',
  swipe_right_alt: 'swipe_right_alt',
  swipe_right: 'swipe_right',
  swipe_left_alt: 'swipe_left_alt',
  swipe_left: 'swipe_left',
  swipe_down_alt: 'swipe_down_alt',
  swipe_down: 'swipe_down',
  swipe: 'swipe',
  swap_vertical_circle: 'swap_vertical_circle',
  swap_vert_circle: 'swap_vert_circle',
  swap_vert: 'swap_vert',
  swap_horizontal_circle: 'swap_horizontal_circle',
  swap_horiz: 'swap_horiz',
  swap_calls: 'swap_calls',
  surround_sound: 'surround_sound',
  surfing: 'surfing',
  support_agent: 'support_agent',
  support: 'support',
  supervisor_account: 'supervisor_account',
  supervised_user_circle: 'supervised_user_circle',
  superscript: 'superscript',
  sunny_snowing: 'sunny_snowing',
  sunny: 'sunny',
  summarize: 'summarize',
  subway: 'subway',
  subtitles_off: 'subtitles_off',
  subtitles: 'subtitles',
  subscriptions: 'subscriptions',
  subscript: 'subscript',
  subject: 'subject',
  subdirectory_arrow_right: 'subdirectory_arrow_right',
  subdirectory_arrow_left: 'subdirectory_arrow_left',
  style: 'style',
  stroller: 'stroller',
  strikethrough_s: 'strikethrough_s',
  streetview: 'streetview',
  stream: 'stream',
  straighten: 'straighten',
  straight: 'straight',
  storm: 'storm',
  storefront: 'storefront',
  store_mall_directory: 'store_mall_directory',
  store: 'store',
  storage: 'storage',
  stop_screen_share: 'stop_screen_share',
  stop_circle: 'stop_circle',
  stop: 'stop',
  sticky_note_2: 'sticky_note_2',
  stay_primary_portrait: 'stay_primary_portrait',
  stay_primary_landscape: 'stay_primary_landscape',
  stay_current_portrait: 'stay_current_portrait',
  stay_current_landscape: 'stay_current_landscape',
  start: 'start',
  stars: 'stars',
  star_rate: 'star_rate',
  star_purple500: 'star_purple500',
  star_outline: 'star_outline',
  star_half: 'star_half',
  star_border_purple500: 'star_border_purple500',
  star_border: 'star_border',
  star: 'star',
  stairs: 'stairs',
  stadium: 'stadium',
  stacked_line_chart: 'stacked_line_chart',
  stacked_bar_chart: 'stacked_bar_chart',
  ssid_chart: 'ssid_chart',
  square_foot: 'square_foot',
  square: 'square',
  sports_volleyball: 'sports_volleyball',
  sports_tennis: 'sports_tennis',
  sports_soccer: 'sports_soccer',
  sports_score: 'sports_score',
  sports_rugby: 'sports_rugby',
  sports_motorsports: 'sports_motorsports',
  sports_mma: 'sports_mma',
  sports_martial_arts: 'sports_martial_arts',
  sports_kabaddi: 'sports_kabaddi',
  sports_hockey: 'sports_hockey',
  sports_handball: 'sports_handball',
  sports_gymnastics: 'sports_gymnastics',
  sports_golf: 'sports_golf',
  sports_football: 'sports_football',
  sports_esports: 'sports_esports',
  sports_cricket: 'sports_cricket',
  sports_basketball: 'sports_basketball',
  sports_baseball: 'sports_baseball',
  sports_bar: 'sports_bar',
  sports: 'sports',
  spoke: 'spoke',
  splitscreen: 'splitscreen',
  spellcheck: 'spellcheck',
  speed: 'speed',
  speaker_phone: 'speaker_phone',
  speaker_notes_off: 'speaker_notes_off',
  speaker_notes: 'speaker_notes',
  speaker_group: 'speaker_group',
  speaker: 'speaker',
  spatial_tracking: 'spatial_tracking',
  spatial_audio_off: 'spatial_audio_off',
  spatial_audio: 'spatial_audio',
  space_dashboard: 'space_dashboard',
  space_bar: 'space_bar',
  spa: 'spa',
  south_west: 'south_west',
  south_east: 'south_east',
  south_america: 'south_america',
  south: 'south',
  source: 'source',
  soup_kitchen: 'soup_kitchen',
  sos: 'sos',
  sort_by_alpha: 'sort_by_alpha',
  sort: 'sort',
  solar_power: 'solar_power',
  social_distance: 'social_distance',
  soap: 'soap',
  snowshoeing: 'snowshoeing',
  snowmobile: 'snowmobile',
  snowing: 'snowing',
  snowboarding: 'snowboarding',
  snooze: 'snooze',
  snippet_folder: 'snippet_folder',
  snapchat: 'snapchat',
  sms_failed: 'sms_failed',
  sms: 'sms',
  smoking_rooms: 'smoking_rooms',
  smoke_free: 'smoke_free',
  smartphone: 'smartphone',
  smart_toy: 'smart_toy',
  smart_screen: 'smart_screen',
  smart_display: 'smart_display',
  smart_button: 'smart_button',
  slow_motion_video: 'slow_motion_video',
  slideshow: 'slideshow',
  sledding: 'sledding',
  skip_previous: 'skip_previous',
  skip_next: 'skip_next',
  skateboarding: 'skateboarding',
  sip: 'sip',
  single_bed: 'single_bed',
  sim_card_download: 'sim_card_download',
  sim_card_alert: 'sim_card_alert',
  sim_card: 'sim_card',
  signpost: 'signpost',
  signal_wifi_statusbar_null: 'signal_wifi_statusbar_null',
  signal_wifi_statusbar_connected_no_internet_4: 'signal_wifi_statusbar_connected_no_internet_4',
  signal_wifi_statusbar_4_bar: 'signal_wifi_statusbar_4_bar',
  signal_wifi_off: 'signal_wifi_off',
  signal_wifi_connected_no_internet_4: 'signal_wifi_connected_no_internet_4',
  signal_wifi_bad: 'signal_wifi_bad',
  signal_wifi_4_bar_lock: 'signal_wifi_4_bar_lock',
  signal_wifi_4_bar: 'signal_wifi_4_bar',
  signal_wifi_0_bar: 'signal_wifi_0_bar',
  signal_cellular_off: 'signal_cellular_off',
  signal_cellular_null: 'signal_cellular_null',
  signal_cellular_nodata: 'signal_cellular_nodata',
  signal_cellular_no_sim: 'signal_cellular_no_sim',
  signal_cellular_connected_no_internet_4_bar: 'signal_cellular_connected_no_internet_4_bar',
  signal_cellular_connected_no_internet_0_bar: 'signal_cellular_connected_no_internet_0_bar',
  signal_cellular_alt_2_bar: 'signal_cellular_alt_2_bar',
  signal_cellular_alt_1_bar: 'signal_cellular_alt_1_bar',
  signal_cellular_alt: 'signal_cellular_alt',
  signal_cellular_4_bar: 'signal_cellular_4_bar',
  signal_cellular_0_bar: 'signal_cellular_0_bar',
  sign_language: 'sign_language',
  sick: 'sick',
  shutter_speed: 'shutter_speed',
  shuffle_on: 'shuffle_on',
  shuffle: 'shuffle',
  shower: 'shower',
  show_chart: 'show_chart',
  shortcut: 'shortcut',
  short_text: 'short_text',
  shopping_cart_checkout: 'shopping_cart_checkout',
  shopping_cart: 'shopping_cart',
  shopping_basket: 'shopping_basket',
  shopping_bag: 'shopping_bag',
  shopify: 'shopify',
  shop_two: 'shop_two',
  shop_2: 'shop_2',
  shop: 'shop',
  shield_moon: 'shield_moon',
  shield: 'shield',
  share_location: 'share_location',
  share_arrival_time: 'share_arrival_time',
  share: 'share',
  severe_cold: 'severe_cold',
  settings_voice: 'settings_voice',
  settings_system_daydream: 'settings_system_daydream',
  settings_suggest: 'settings_suggest',
  settings_remote: 'settings_remote',
  settings_power: 'settings_power',
  settings_phone: 'settings_phone',
  settings_overscan: 'settings_overscan',
  settings_input_svideo: 'settings_input_svideo',
  settings_input_hdmi: 'settings_input_hdmi',
  settings_input_composite: 'settings_input_composite',
  settings_input_component: 'settings_input_component',
  settings_input_antenna: 'settings_input_antenna',
  settings_ethernet: 'settings_ethernet',
  settings_display: 'settings_display',
  settings_cell: 'settings_cell',
  settings_brightness: 'settings_brightness',
  settings_bluetooth: 'settings_bluetooth',
  settings_backup_restore: 'settings_backup_restore',
  settings_applications: 'settings_applications',
  settings_accessibility: 'settings_accessibility',
  settings: 'settings',
  set_meal: 'set_meal',
  sentiment_very_satisfied: 'sentiment_very_satisfied',
  sentiment_very_dissatisfied: 'sentiment_very_dissatisfied',
  sentiment_satisfied_alt: 'sentiment_satisfied_alt',
  sentiment_satisfied: 'sentiment_satisfied',
  sentiment_neutral: 'sentiment_neutral',
  sentiment_dissatisfied: 'sentiment_dissatisfied',
  sensors_off: 'sensors_off',
  sensors: 'sensors',
  sensor_window: 'sensor_window',
  sensor_occupied: 'sensor_occupied',
  sensor_door: 'sensor_door',
  send_to_mobile: 'send_to_mobile',
  send_time_extension: 'send_time_extension',
  send_and_archive: 'send_and_archive',
  send: 'send',
  sell: 'sell',
  self_improvement: 'self_improvement',
  select_all: 'select_all',
  segment: 'segment',
  security_update_warning: 'security_update_warning',
  security_update_good: 'security_update_good',
  security_update: 'security_update',
  security: 'security',
  search_off: 'search_off',
  search: 'search',
  sd_storage: 'sd_storage',
  sd_card_alert: 'sd_card_alert',
  sd_card: 'sd_card',
  sd: 'sd',
  scuba_diving: 'scuba_diving',
  screenshot_monitor: 'screenshot_monitor',
  screenshot: 'screenshot',
  screen_share: 'screen_share',
  screen_search_desktop: 'screen_search_desktop',
  screen_rotation_alt: 'screen_rotation_alt',
  screen_rotation: 'screen_rotation',
  screen_lock_rotation: 'screen_lock_rotation',
  screen_lock_portrait: 'screen_lock_portrait',
  screen_lock_landscape: 'screen_lock_landscape',
  scoreboard: 'scoreboard',
  score: 'score',
  science: 'science',
  school: 'school',
  schema: 'schema',
  schedule_send: 'schedule_send',
  schedule: 'schedule',
  scatter_plot: 'scatter_plot',
  scanner: 'scanner',
  scale: 'scale',
  savings: 'savings',
  saved_search: 'saved_search',
  save_as: 'save_as',
  save_alt: 'save_alt',
  save: 'save',
  satellite_alt: 'satellite_alt',
  satellite: 'satellite',
  sanitizer: 'sanitizer',
  sailing: 'sailing',
  safety_divider: 'safety_divider',
  safety_check: 'safety_check',
  type_specimen: 'type_specimen',
  two_wheeler: 'two_wheeler',
  tv_off: 'tv_off',
  tv: 'tv',
  turned_in_not: 'turned_in_not',
  turned_in: 'turned_in',
  turn_slight_right: 'turn_slight_right',
  turn_slight_left: 'turn_slight_left',
  turn_sharp_right: 'turn_sharp_right',
  turn_sharp_left: 'turn_sharp_left',
  turn_right: 'turn_right',
  turn_left: 'turn_left',
  tungsten: 'tungsten',
  tune: 'tune',
  tty: 'tty',
  tsunami: 'tsunami',
  try: 'try',
  troubleshoot: 'troubleshoot',
  trip_origin: 'trip_origin',
  trending_up: 'trending_up',
  trending_neutral: 'trending_neutral',
  trending_flat: 'trending_flat',
  trending_down: 'trending_down',
  travel_explore: 'travel_explore',
  translate: 'translate',
  transit_enterexit: 'transit_enterexit',
  transgender: 'transgender',
  transform: 'transform',
  transfer_within_a_station: 'transfer_within_a_station',
  transcribe: 'transcribe',
  tram: 'tram',
  train: 'train',
  traffic: 'traffic',
  track_changes: 'track_changes',
  toys: 'toys',
  tour: 'tour',
  touch_app: 'touch_app',
  tornado: 'tornado',
  topic: 'topic',
  tonality: 'tonality',
  toll: 'toll',
  token: 'token',
  toggle_on: 'toggle_on',
  toggle_off: 'toggle_off',
  today: 'today',
  toc: 'toc',
  title: 'title',
  tire_repair: 'tire_repair',
  tips_and_updates: 'tips_and_updates',
  timer_off: 'timer_off',
  timer_3_select: 'timer_3_select',
  timer_3: 'timer_3',
  timer_10_select: 'timer_10_select',
  timer_10: 'timer_10',
  timer: 'timer',
  timeline: 'timeline',
  timelapse: 'timelapse',
  time_to_leave: 'time_to_leave',
  tiktok: 'tiktok',
  thunderstorm: 'thunderstorm',
  thumbs_up_down: 'thumbs_up_down',
  thumb_up_off_alt: 'thumb_up_off_alt',
  thumb_up_alt: 'thumb_up_alt',
  thumb_up: 'thumb_up',
  thumb_down_off_alt: 'thumb_down_off_alt',
  thumb_down_alt: 'thumb_down_alt',
  thumb_down: 'thumb_down',
  thermostat_auto: 'thermostat_auto',
  thermostat: 'thermostat',
  theaters: 'theaters',
  theater_comedy: 'theater_comedy',
  texture: 'texture',
  textsms: 'textsms',
  text_snippet: 'text_snippet',
  text_rotation_none: 'text_rotation_none',
  text_rotation_down: 'text_rotation_down',
  text_rotation_angleup: 'text_rotation_angleup',
  text_rotation_angledown: 'text_rotation_angledown',
  text_rotate_vertical: 'text_rotate_vertical',
  text_rotate_up: 'text_rotate_up',
  text_increase: 'text_increase',
  text_format: 'text_format',
  text_fields: 'text_fields',
  text_decrease: 'text_decrease',
  terrain: 'terrain',
  terminal: 'terminal',
  temple_hindu: 'temple_hindu',
  temple_buddhist: 'temple_buddhist',
  telegram: 'telegram',
  taxi_alert: 'taxi_alert',
  task_alt: 'task_alt',
  task: 'task',
  tapas: 'tapas',
  tap_and_play: 'tap_and_play',
  takeout_dining: 'takeout_dining',
  tag_faces: 'tag_faces',
  tag: 'tag',
  tablet_mac: 'tablet_mac',
  tablet_android: 'tablet_android',
  tablet: 'tablet',
  table_view: 'table_view',
  table_rows: 'table_rows',
  table_restaurant: 'table_restaurant',
  table_chart: 'table_chart',
  table_bar: 'table_bar',
  tab_unselected: 'tab_unselected',
  tab: 'tab',
  usb_off: 'usb_off',
  usb: 'usb',
  upload_file: 'upload_file',
  upload: 'upload',
  upgrade: 'upgrade',
  update_disabled: 'update_disabled',
  update: 'update',
  upcoming: 'upcoming',
  unsubscribe: 'unsubscribe',
  unpublished: 'unpublished',
  unfold_more: 'unfold_more',
  unfold_less: 'unfold_less',
  undo: 'undo',
  unarchive: 'unarchive',
  umbrella: 'umbrella',
  u_turn_right: 'u_turn_right',
  u_turn_left: 'u_turn_left',
  vrpano: 'vrpano',
  vpn_lock: 'vpn_lock',
  vpn_key_off: 'vpn_key_off',
  vpn_key: 'vpn_key',
  volunteer_activism: 'volunteer_activism',
  volume_up: 'volume_up',
  volume_off: 'volume_off',
  volume_mute: 'volume_mute',
  volume_down_alt: 'volume_down_alt',
  volume_down: 'volume_down',
  volcano: 'volcano',
  voicemail: 'voicemail',
  voice_over_off: 'voice_over_off',
  voice_chat: 'voice_chat',
  visibility_off: 'visibility_off',
  visibility: 'visibility',
  villa: 'villa',
  vignette: 'vignette',
  view_week: 'view_week',
  view_timeline: 'view_timeline',
  view_stream: 'view_stream',
  view_sidebar: 'view_sidebar',
  view_quilt: 'view_quilt',
  view_module: 'view_module',
  view_list: 'view_list',
  view_kanban: 'view_kanban',
  view_in_ar: 'view_in_ar',
  view_headline: 'view_headline',
  view_day: 'view_day',
  view_cozy: 'view_cozy',
  view_compact_alt: 'view_compact_alt',
  view_compact: 'view_compact',
  view_comfy_alt: 'view_comfy_alt',
  view_comfy: 'view_comfy',
  view_comfortable: 'view_comfortable',
  view_column: 'view_column',
  view_carousel: 'view_carousel',
  view_array: 'view_array',
  view_agenda: 'view_agenda',
  videogame_asset_off: 'videogame_asset_off',
  videogame_asset: 'videogame_asset',
  videocam_off: 'videocam_off',
  videocam: 'videocam',
  video_stable: 'video_stable',
  video_settings: 'video_settings',
  video_library: 'video_library',
  video_label: 'video_label',
  video_file: 'video_file',
  video_collection: 'video_collection',
  video_camera_front: 'video_camera_front',
  video_camera_back: 'video_camera_back',
  video_call: 'video_call',
  vibration: 'vibration',
  vertical_split: 'vertical_split',
  vertical_shades_closed: 'vertical_shades_closed',
  vertical_shades: 'vertical_shades',
  vertical_distribute: 'vertical_distribute',
  vertical_align_top: 'vertical_align_top',
  vertical_align_center: 'vertical_align_center',
  vertical_align_bottom: 'vertical_align_bottom',
  verified_user: 'verified_user',
  verified: 'verified',
  vaping_rooms: 'vaping_rooms',
  vape_free: 'vape_free',
  vaccines: 'vaccines',
  wysiwyg: 'wysiwyg',
  wrong_location: 'wrong_location',
  wrap_text: 'wrap_text',
  workspaces_outline: 'workspaces_outline',
  workspaces_filled: 'workspaces_filled',
  workspaces: 'workspaces',
  workspace_premium: 'workspace_premium',
  work_outline: 'work_outline',
  work_off: 'work_off',
  work_history: 'work_history',
  work: 'work',
  wordpress: 'wordpress',
  woo_commerce: 'woo_commerce',
  woman: 'woman',
  wine_bar: 'wine_bar',
  window: 'window',
  wind_power: 'wind_power',
  wifi_tethering_off: 'wifi_tethering_off',
  wifi_tethering_error_rounded: 'wifi_tethering_error_rounded',
  wifi_tethering_error: 'wifi_tethering_error',
  wifi_tethering: 'wifi_tethering',
  wifi_protected_setup: 'wifi_protected_setup',
  wifi_password: 'wifi_password',
  wifi_off: 'wifi_off',
  wifi_lock: 'wifi_lock',
  wifi_find: 'wifi_find',
  wifi_channel: 'wifi_channel',
  wifi_calling_3: 'wifi_calling_3',
  wifi_calling: 'wifi_calling',
  wifi_2_bar: 'wifi_2_bar',
  wifi_1_bar: 'wifi_1_bar',
  wifi: 'wifi',
  width_wide: 'width_wide',
  width_normal: 'width_normal',
  width_full: 'width_full',
  widgets: 'widgets',
  where_to_vote: 'where_to_vote',
  wheelchair_pickup: 'wheelchair_pickup',
  whatshot: 'whatshot',
  whatsapp: 'whatsapp',
  west: 'west',
  weekend: 'weekend',
  wechat: 'wechat',
  webhook: 'webhook',
  web_stories: 'web_stories',
  web_asset_off: 'web_asset_off',
  web_asset: 'web_asset',
  web: 'web',
  wc: 'wc',
  wb_twilight: 'wb_twilight',
  wb_twighlight: 'wb_twighlight',
  wb_sunny: 'wb_sunny',
  wb_shade: 'wb_shade',
  wb_iridescent: 'wb_iridescent',
  wb_incandescent: 'wb_incandescent',
  wb_cloudy: 'wb_cloudy',
  wb_auto: 'wb_auto',
  waving_hand: 'waving_hand',
  waves: 'waves',
  waterfall_chart: 'waterfall_chart',
  water_drop: 'water_drop',
  water_damage: 'water_damage',
  water: 'water',
  watch_off: 'watch_off',
  watch_later: 'watch_later',
  watch: 'watch',
  wash: 'wash',
  warning_amber: 'warning_amber',
  warning: 'warning',
  warehouse: 'warehouse',
  wallpaper: 'wallpaper',
  wallet_travel: 'wallet_travel',
  wallet_membership: 'wallet_membership',
  wallet_giftcard: 'wallet_giftcard',
  wallet: 'wallet',
  youtube_searched_for: 'youtube_searched_for',
  yard: 'yard',
  zoom_out_map: 'zoom_out_map',
  zoom_out: 'zoom_out',
  zoom_in_map: 'zoom_in_map',
  zoom_in: 'zoom_in'
}
