import { colors, components, eventHandlers, popperPlacements } from '@/utils'

import render from './render'

export default {
  name: 'ButtonToolbar',

  mixins: [ eventHandlers ],

  props: {
    icon: String,
    iconSVG: String,
    tooltip: String,

    color: {
      type: String,
      default: colors.white,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    }
  },

  computed: {
    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: components.tooltip,
          options: {
            value: this.tooltip,
            placement: popperPlacements.bottom
          }
        })
      }

      return result
    }
  },

  render
}
