import Vue from 'vue'

import { get } from 'lodash'
import { Macro } from '@sigma-legacy-libs/cache'
import { isUUID } from '@sigma-legacy-libs/essentials/lib/validators'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, id) => {
  return await Vue.$GRequest.get(services.contactLists, id)
})

export default {
  name: 'TasksAnalytics',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      analytics: {},

      showDialog: false,
      loading: false
    }
  },

  methods: {
    async analysis() {
      try {
        this.loading = true
        this.clearAnalytics()

        const data = get(this.value, 'data.recipients', {})
        for (const type of [ 'include', 'exclude' ]) {
          const { lists, recipients } = data[type] || {}
          if (Array.isArray(lists) && lists.length) {
            this.analytics[type] = []
            await Promise.all(
              lists.map(async id => {
                if (isUUID(id)) {
                  const { data } = await cachedGet(`${services.contactLists}:${id}`, id)
                  if (data) {
                    this.analytics[type].push({
                      title: data.title,
                      id
                    })
                  }
                }
              })
            )
          }
          if (Array.isArray(recipients) && recipients.length) {
            for (const item of recipients) {
              this.analytics[type].push({ title: item })
            }
          }
        }

        const errors = get(this.value, 'meta.errors.$fatals')
        if (errors) {
          for (const key in errors) {
            this.analytics.error = []
            this.analytics.error.push({
              title: key,
              error: errors[key]
            })
          }
        }

        if (Object.keys(this.analytics).length) {
          this.showDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    clearAnalytics() {
      this.analytics = {}
    }
  },

  render
}
