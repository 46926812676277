import { colors, getPrice, services } from '@/utils'

import buttonRefresh from '@/components/button/refresh'
import TwoRowString from '@/components/misc/TwoRowString'
import SingleLink from '@/components/misc/SingleLink'
import squircle from '@/components/icon/squircle'

function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        fw: this.viewport.breakpoint.smDown
      }
    },
    [
      this.$slots.title,

      h('div', { class: 'ff' }),

      h(
        buttonRefresh,
        {
          props: {
            disabled: this.downloadable,
            label: this.getTranslate('sendings.buttons.refresh'),
            tooltip: this.getTranslate('sendings.tooltips.refresh'),
            state: this.loading,
            shortClickMethod: () => this.getReport(),
            longClickMethod: () => this.getReport()
          }
        }
      )
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderFirstIndexTr(h, index) {
  if (index === 0) {
    return h(
      'tr',
      {
        style: {
          fontWeight: 'bold',
          borderBottom: '1px solid darkgrey'
        },
        key: 'billings-groups-001-total-up'
      },
      renderFirstLastIndexTrContent.call(this, h)
    )
  }
}
function renderLastIndexTr(h, index) {
  if (index === this.report.length - 1) {
    return h(
      'tr',
      {
        style: {
          fontWeight: 'bold',
          borderTop: '1px solid darkgrey'
        },
        key: 'billings-groups-001-total-down'
      },
      renderFirstLastIndexTrContent.call(this, h)
    )
  }
}
function renderFirstLastIndexTrContent(h) {
  return [
    h(
      'td',
      {
        class: 'pl-2 pr-2 w--100',
        attrs: { colspan: this.colspan }
      },
      this.getTranslate('misc.total')
    ),

    h(
      'td',
      {
        class: 'pl-2 pr-2 text-right'
      },
      [
        h(
          TwoRowString,
          {
            props: {
              first: `${this.total.countPending}`,
              second: getPrice(this.total.sumPending)
            }
          }
        )
      ]
    ),

    h(
      'td',
      {
        class: 'pl-2 pr-2 text-right'
      },
      [
        h(
          TwoRowString,
          {
            props: {
              first: `${this.total.countComplete}`,
              second: getPrice(this.total.sumComplete)
            }
          }
        )
      ]
    ),

    h(
      'td',
      {
        class: 'pl-2 pr-2 text-right'
      },
      [
        h(
          TwoRowString,
          {
            props: {
              first: `${this.total.countTotal}`,
              second: getPrice(this.total.sumTotal)
            }
          }
        )
      ]
    ),

    h(
      'td',
      {
        class: 'pl-2 pr-2 text-right'
      },
      [
        h(
          TwoRowString,
          {
            props: {
              first: `${this.total.countRefunded}`,
              second: getPrice(this.total.sumRefunded)
            }
          }
        )
      ]
    ),

    h(
      'td',
      {
        class: 'pl-2 pr-2 text-right'
      },
      [
        h(
          TwoRowString,
          {
            props: {
              first: `${this.total.countInvalid}`,
              second: getPrice(this.total.sumInvalid)
            }
          }
        )
      ]
    )
  ]
}

function renderOwnerTd(h, item) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'pl-2 pr-2 w--100'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: item.Owner
            }
          }
        )
      ]
    )
  }
}
function renderTariffTd(h, item) {
  if (this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'pl-2 pr-2'
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: item.Tariff,
              service: 'tariffs'
            }
          }
        )
      ]
    )
  }
}

function renderTable(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          value: this.order,
          items: this.report,
          headers: this.headers,
          hideHead: this.report.length < 1,
          sortLocal: true,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.order = event
          }
        },
        scopedSlots: {
          items: ({ item, index }) => {
            const [ source, type ] = item.source.split('.')

            return [
              renderFirstIndexTr.call(this, h, index),

              h(
                'tr',
                {
                  key: `billings-groups-001-${index}`
                },
                [
                  h(
                    'td',
                    {
                      class: {
                        'w--100': this.checkPermissions(`advanced.${services.users}.get`, [ false, 'me' ]),
                        'px-2': true
                      }
                    },
                    [
                      h(
                        squircle,
                        {
                          props: {
                            iconSVG: type || source,
                            color: colors[type || source],
                            tooltip: this.getTranslate(`${services.billings}.sources.${type || source}`)
                          }
                        }
                      )
                    ]
                  ),

                  renderOwnerTd.call(this, h, item),
                  renderTariffTd.call(this, h, item),

                  h(
                    'td',
                    {
                      class: 'px-2 text-right'
                    },
                    [
                      h(
                        TwoRowString,
                        {
                          props: {
                            first: `${item.count_multiplier_pending}`,
                            second: getPrice(item.sum_pending)
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    [
                      h(
                        TwoRowString,
                        {
                          props: {
                            first: `${item.count_multiplier_complete}`,
                            second: getPrice(item.sum_complete)
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    [
                      h(
                        TwoRowString,
                        {
                          props: {
                            first: `${item.count_multiplier_total}`,
                            second: getPrice(item.sum_total)
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    [
                      h(
                        TwoRowString,
                        {
                          props: {
                            first: `${item.count_multiplier_refunded}`,
                            second: getPrice(item.sum_refunded)
                          }
                        }
                      )
                    ]
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    [
                      h(
                        TwoRowString,
                        {
                          props: {
                            first: `${item.count_multiplier_invalid}`,
                            second: getPrice(item.sum_invalid)
                          }
                        }
                      )
                    ]
                  )
                ]
              ),

              renderLastIndexTr.call(this, h, index)
            ]
          }
        }
      }
    )
  }
}

function renderPanels(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.report.map(item => {
        return h(
          'g-expansion-panel',
          {
            key: item.id
          },
          [
            h(
              'div',
              {
                class: 'default-expansion-panel-header',
                slot: 'header'
              },
              [
                h(
                  'div',
                  {
                    class: 'fc'
                  },
                  [
                    h(
                      SingleLink,
                      {
                        props: {
                          value: item.Tariff,
                          service: 'tariffs'
                        }
                      }
                    ),
                    item.source
                  ]
                ),

                h('div', { class: 'ff' }),

                h(
                  TwoRowString,
                  {
                    class: 'text-right',
                    props: {
                      first: getPrice(item.sum_total),
                      second: `${this.getTranslate('billings.headers.count_total')}: ${item.count_multiplier_total}`
                    }
                  }
                )
              ]
            ),

            h(
              'div',
              {
                class: 'ff body-1 pt-2 pb-2 pl-3 pr-3'
              },
              [
                h(
                  'table',
                  {
                    class: 'w--100'
                  },
                  [
                    h('thead', {}, [
                      h('tr', {}, [
                        h('th', {}, this.getTranslate('billings.headers.count_pending')),
                        h('th', {}, this.getTranslate('billings.headers.count_complete')),
                        h('th', {}, this.getTranslate('billings.headers.count_refunded')),
                        h('th', {}, this.getTranslate('billings.headers.count_invalid'))
                      ])
                    ]),

                    h('tbody', {}, [
                      h(
                        'tr',
                        {
                          class: 'caption'
                        },
                        [
                          h(
                            'td',
                            {
                              class: 'text-center'
                            },
                            [ item.count_multiplier_pending, getPrice(item.sum_pending) ].join(' | ')
                          ),
                          h(
                            'td',
                            {
                              class: 'text-center'
                            },
                            [ item.count_multiplier_complete, getPrice(item.sum_complete) ].join(' | ')
                          ),
                          h(
                            'td',
                            {
                              class: 'text-center'
                            },
                            [ item.count_multiplier_refunded, getPrice(item.sum_refunded) ].join(' | ')
                          ),
                          h(
                            'td',
                            {
                              class: 'text-center'
                            },
                            [ item.count_multiplier_invalid, getPrice(item.sum_invalid) ].join(' | ')
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          ]
        )
      })
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderTable.call(this, h),
      renderPanels.call(this, h)
    ]
  )
}
