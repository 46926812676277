import proxy from '@sigma-legacy-libs/g-proxy'

import { isEqual } from 'lodash'
import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'NotificationsEnabled',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    serviceName: String
  },

  methods: {
    _inputFilter(data) {
      return NOTIFICATIONS_AVAILABLE_TYPES.reduce((result, key) => {
        result[key] = !!~data.indexOf(key)

        return result
      }, {})
    },

    _outputFilter(data) {
      return Object.keys(data).reduce((result, key) => {
        if (data[key] === true) {
          result.push(key)
        }

        return result
      }, [])
    },

    receiveValue() {
      const value = this._inputFilter(this.value)
      if (!isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
