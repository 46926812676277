import { cloneDeep, isEqual } from 'lodash'
import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { balance } from '@/utils'

import render from './render'

const defaultValue = {
  balance: 0,
  types: [ NOTIFICATIONS_AVAILABLE_TYPES.email ],
  includeOverdraft: false,
  invoice: false
}

export default function(options) {
  return {
    name: 'NotificationsEventsBillingsLimit',

    mixins: [ balance({ UserId: options.UserId }) ],

    props: {
      value: {
        type: Array,
        default: () => []
      },

      serviceName: String,

      options: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        proxy: [],

        showInvoiceDialog: false,

        limitIndex: 0
      }
    },

    mounted() {
      this.inputFilter()
    },

    methods: {
      add() {
        this.proxy.push(cloneDeep(defaultValue))
      },
      remove(index) {
        this.proxy.splice(index, 1)
        if (Array.isArray(this.proxy) && this.proxy.length === 0) {
          this.add()
        }
      },

      inputFilter() {
        if (Array.isArray(this.value) && this.value.length === 0) {
          this.add()
        } else {
          if (!isEqual(this.proxy, this.value)) {
            this.proxy = cloneDeep(this.value)
          }
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
