import { NOTIFICATIONS_SETTINGS_MY } from '@sigma-legacy-libs/essentials/lib/schemas/notifications'

import { colors } from '../colors'

export const notificationsColors = {
  email: colors.email,
  sms: colors.sms,
  web: colors.web,
  tgBot: colors.telegram,
  push: colors.push
}

export const notificationsIcons = {
  my: 'user',
  users: 'users',
  managers: 'user-verified'
}

export const notificationsTypes = []
Object.keys(NOTIFICATIONS_SETTINGS_MY).map(key => {
  notificationsTypes.push(key.split('.').slice(2).join('.'))
})
