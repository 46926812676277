import MarkdownIt from 'markdown-it'
import proxy from '@sigma-legacy-libs/g-proxy'

import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { backendServerIP } from '@/utils'

import render from './render'

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typography: true
})

export default {
  name: 'PaymentsOffer',

  mixins: [ proxy({ defaultValue: true }) ],

  props: {
    method: {
      type: String,
      validator: value => {
        return PAYMENTS_METHODS.includes(value)
      }
    }
  },

  data() {
    return {
      showDialog: false,

      html: undefined
    }
  },

  watch: {
    method() {
      this.getDocument()
      this.proxy = true
    }
  },

  mounted() {
    this.getDocument()
  },

  methods: {
    async getDocument() {
      this.html = undefined
      if ([ PAYMENTS_METHODS.mandarin, PAYMENTS_METHODS.stripe ].includes(this.method)) {
        return
      }
      if (this.method) {
        const response = await fetch(`${backendServerIP}/docs/${this.locale}/misc/${this.method}-payment-offer.md`, { cache: 'no-cache' })
        if (!response.ok) {
          throw new Error(response.status)
        }
        this.html = md.render(await response.text())
      }
    }
  },

  render
}
