import { borderStyles, buttonModes, colors, icons, services, states } from '@/utils'

import button from '@/components/button'
import entityInfo from '@/components/entity/info'
import ExpandArrow from '@/components/misc/ExpandArrow'
import TemplatesForm from '@/components/services/templates/form'
import buttonConfirmRemove from '@/components/button/confirm/remove'
import EventsHistoryButton from '@/components/services/eventsHistory/button'
import sticker from '@/components/misc/sticker'

function renderApplyTemplateButton(h) {
  return h(
    button,
    {
      class: { 'hover-child': this.viewport.size.width > 1832 },
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate(`${services.templates}.buttons.apply`) : undefined,
        icon: icons.insert_drive_file,
        mode: buttonModes.flat,
        color: colors.primary,
        disabled: !~this.sendingTypes.findIndex(value => value === this.proxy.type)
      },
      on: {
        click: () => {
          this.$emit('template', this.proxy.id)
        }
      }
    }
  )
}
function renderTemplateTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.templates}.labels.title`),
        width: this.viewport.breakpoint.smUp ? 150 : 'auto',
        iconColor: this.proxy.type,
        iconSVG: this.proxy.type,
        iconTooltip: this.getTranslate(`${services.sendings}.types.${this.proxy.type}`)
      }
    }
  )
}
function renderTemplateText(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      sticker,
      {
        props: {
          value: this.proxy.payload.text,
          label: this.getTranslate(`${services.templates}.labels.text`),
          width: 'fit-content',
          borderStyle: borderStyles.dashed
        }
      }
    )
  }
}
function renderPanelHeader(h, expanded, toggle) {
  return h(
    'div',
    {
      class: 'fjcsb px-0 hover-parent hover-parent--opacity'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 pl-2 faic fjcfe w--100',
          style: {
            gridTemplateColumns: this.viewport.breakpoint.smUp ? '150px 1fr auto' : '1fr auto'
          }
        },
        [
          renderTemplateTitle.call(this, h),
          renderTemplateText.call(this, h),

          h(
            'div',
            {
              class: 'faic grid-gap--8'
            },
            [
              renderApplyTemplateButton.call(this, h, expanded),

              h(
                ExpandArrow,
                {
                  props: {
                    expanded,
                    toggle
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderEventsHistoryButton(h) {
  if (this.checkPermissions(`advanced.${services.eventsHistory}.get`)) {
    return h(
      EventsHistoryButton,
      {
        props: {
          entityId: this.proxy.id
        }
      }
    )
  }
}
function renderPanelFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe grid-gap--8 pa-2'
    },
    [
      renderEventsHistoryButton.call(this, h),

      h(
        buttonConfirmRemove,
        {
          props: {
            labeled: false,
            callback: () => {
              this.removeTemplate()
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.update'),
            loading: this.restData[services.templates].update.state === states.loading,
            disabled: this.restData[services.templates].update.state === states.loading,
            color: colors.primary
          },
          on: {
            click: () => {
              this.updateTemplate()
            }
          }
        }
      )
    ]
  )
}
function renderTemplatePanel(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h(
        TemplatesForm,
        {
          props: {
            value: this.proxy
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(entityInfo, { props: { value: this.proxy } }),

      renderPanelFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-expansion-panel',
    {
      props: { preventClick: true },
      scopedSlots: {
        header: ({ toggle, expanded }) => renderPanelHeader.call(this, h, expanded, toggle),
        default: () => renderTemplatePanel.call(this, h)
      }
    }
  )
}
