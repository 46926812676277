export const generateUniqueKey = () => Math.random().toString(36).substring(2, 9)
export const leadZero = value => value !== undefined ? value.toString().padStart(2, '0') : value
export const fillStringZeros = (value, targetLength = 2, padString = '0') => {
  value = '' + value
  if (value.length > targetLength) {
    value = value.slice(-targetLength)
  }
  value = value.padStart(targetLength, padString)

  return value
}
export const getString = value => {
  switch (typeof value) {
    case 'boolean':
    case 'number': return value.toString()
    case 'string':
    default: return value
  }
}
