export default {
  title: 'Токены',

  titles: {
    create: 'Создать токен',
    update: 'Изменить название токена',
    remove: 'Удалить токен',
    copy: 'Скопировать токен'
  },

  labels: {
    token: 'Токен',
    description: 'Название',
    search: 'Поиск',
    owner: 'Владелец',
    noData: {
      description: 'Нет названия',
      expiredAt: 'Нет даты',
      createAt: 'Нет даты'
    },
    expiredAt: 'Истекает в'
  },

  hints: {
    notFound: 'Токены не найдены',
    description: 'Максимум 250 символов'
  },

  tooltips: {
    favorites: 'Сделать токен избранным'
  },

  buttons: {
    create: 'Создать новый токен',
    add: 'Добавить токен'
  },

  info: {
    token: 'Пожалуйста, скопируйте и сохраните токен. После закрытия этого окна доступ к токену будет ограничен.'
  },

  snackbars: {
    created: 'Токен успешно создан.',
    removed: 'Токен успешно удалён.',
    copy: 'Токен скопирован в буфер обмена.'
  }
}
