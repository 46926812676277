import { components, inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

function renderDescriptionField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.description,
        label: this.getTranslate(`${services.tokens}.labels.description`),
        hint: this.getTranslate(`${services.tokens}.hints.description`),
        mode: inputModes['line-label'],
        required: true,
        maxlength: 250,
        count: this.proxy.description ? this.proxy.description.length : 0,
        rounded: true,
        dense: true,
        grow: true,
        clearable: true,
        details: true,
        autofocus: true,
        mask: /^.{0,250}$/
      },
      on: {
        input: event => {
          this.proxy.description = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  if (!this.OwnerId) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.OwnerId,
          dense: true
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    components.form,
    {
      class: `${services.tokens}-form`
    },
    [
      renderDescriptionField.call(this, h),
      renderOwnerField.call(this, h)
    ]
  )
}
