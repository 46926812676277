import { buttonModes, colors, components, icons } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'
import squircle from '@/components/icon/squircle'

function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate('commons.titles.confirm.remove'),
        value: this.showDialog,
        rounded: true,
        closeOnClick: true,
        closeOnEsc: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'px-2'
        },
        [
          h(
            info,
            {
              props: {
                value: this.getTranslate('commons.contents.confirm.confirm'),
                color: colors.error
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'fjcfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                color: colors.error,
                loading: this.loading,
                disabled: this.loading || this.disabled
              },
              on: {
                click: async () => {
                  await this.callback()
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderPrefix(h) {
  if (this.component === components['list-item']) {
    return h(
      squircle,
      {
        props: {
          icon: icons.delete,
          color: this.disabled || this.loading ? colors.grey : colors.error
        }
      }
    )
  }
}

export default function(h) {
  return h(
    this.component === 'btn' ? button : this.component,
    {
      class: this.component === components.button ? 'ma-0' : undefined,
      props: this.$props,
      on: { click: event => this.onClick(event) },
      scopedSlots: {
        prefix: () => renderPrefix.call(this, h),
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
