import proxy from '@sigma-legacy-libs/g-proxy'

import quill from 'quill/core'

import Snow from 'quill/themes/snow'
import Toolbar from 'quill/modules/toolbar'

import Bold from 'quill/formats/bold'
import Italic from 'quill/formats/italic'
import Header from 'quill/formats/header'
import Underline from 'quill/formats/underline'
import Link from 'quill/formats/link'
import List from 'quill/formats/list'
import CodeBlock from 'quill/formats/code'

import 'quill/dist/quill.snow.css'

import { generateUniqueKey } from '@/utils'

import render from './render'

quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/header': Header,
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/underline': Underline,
  'formats/link': Link,
  'formats/list': List,
  'formats/code': CodeBlock
})

const toolbarOptions = [
  { header: [] },
  'bold',
  'italic',
  'underline',
  { list: 'ordered' },
  { list: 'bullet' },
  'link',
  'code-block',
  'clean'
]

export default {
  name: 'TextEditor',

  mixins: [ proxy() ],

  props: {
    placeholder: String,

    grow: Boolean,
    rows: Number,

    readonly: Boolean
  },

  data() {
    return {
      quill: undefined
    }
  },

  computed: {
    $id() {
      return generateUniqueKey()
    }
  },

  watch: {
    quill() {
      this.setValue()
      this.getValue()
    }
  },

  mounted() {
    this.initQuill()
    this._delayedResize()
  },

  beforeDestroy() {
    this.quill = undefined
  },

  methods: {
    initQuill() {
      const container = document.getElementById(this.$id)
      if (container) {
        this.quill = new quill(container, {
          theme: 'snow',
          bounds: container,
          placeholder: this.placeholder,
          readOnly: this.readonly,
          modules: {
            toolbar: toolbarOptions
          }
        })
      }
    },

    setValue() {
      if (this.value) {
        const delta = this.quill.clipboard.convert({ html: this.value })
        this.quill.setContents(delta, 'silent')
      }
    },

    getValue() {
      this.quill.on('text-change', () => {
        const text = this.quill.getSemanticHTML()
        this.proxy = text
      })
    },

    _resizeHeight() {
      if (this.grow) {
        const textarea = document.getElementById(this.$id)
        if (textarea) {
          let height = 'auto'
          if (this.rows) {
            height = 18 * this.rows
          }
          textarea.style.height = typeof height === 'number' ? height + 'px' : height

          let scrollHeight = textarea.scrollHeight
          if (scrollHeight) {
            if (this.rows === 1 && scrollHeight === 19) {
              scrollHeight = 18
            }
            textarea.style.height = typeof scrollHeight === 'number' ? scrollHeight + 'px' : scrollHeight
          }
        }
      }
    },

    _delayedResize() {
      this.$nextTick(this._resizeHeight)
    }
  },

  render
}
