import { colors, modes, services, states } from '@/utils'

import tokensForm from '@/components/services/tokens/form'
import button from '@/components/button'

function renderBody(h) {
  switch (this.type) {
    case 'create': {
      return h(
        tokensForm,
        {
          props: {
            value: this.restData[services.tokens].create.data,
            OwnerId: this.OwnerId,
            isNew: true
          },
          on: {
            input: event => {
              this.restData[services.tokens].create.data = event
            },
            submit: () => {
              this.create()
            },
            validation: event => {
              this.restData[services.tokens].create.isValid = event
            }
          }
        }
      )
    }
    case 'update': {
      if (this.restData[services.tokens].get.state === states.ready) {
        return h(
          tokensForm,
          {
            props: {
              value: this.restData[services.tokens].get.data
            },
            on: {
              input: event => {
                this.restData[services.tokens].get.data = event
              },
              submit: () => {
                this.update()
              },
              validation: event => {
                this.restData[services.tokens].update.isValid = event
              }
            }
          }
        )
      }

      break
    }
    case 'remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate(`${services.tokens}.contents.confirm.remove`)
      )
    }
  }
}
function renderFooter(h) {
  if (this.type) {
    return h(
      'div',
      {
        class: 'faic fjcfe grid-gap--8 pa-2',
        slot: 'footer'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              mode: modes.flat,
              disabled: this.restData[services.tokens][this.type].state === states.loading
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              label: this.getTranslate(`misc.buttons.${this.type}`),
              color: this.type === 'remove' ? colors.error : colors.primary,
              loading: this.restData[services.tokens][this.type].state === states.loading,
              disabled: this.restData[services.tokens][this.type].state === states.loading
              || this.type !== 'remove' && !this.restData[services.tokens][this.type].isValid
            },
            on: {
              click: () => {
                this[this.type](services.tokens)
              }
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  return h(
    'g-dialog',
    {
      props: {
        title: this.type ? this.getTranslate(`${services.tokens}.titles.${this.type}`) : undefined,
        rounded: true,
        value: this.show,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.show) {
    return renderContent.call(this, h)
  }
}
