import { colors, modes } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'

function renderConfirmDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        width: 400
      },
      on: {
        input: value => {
          this.showDialog = value
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate('misc.titles.confirm.clone')
          },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('misc.contents.confirm.clone')
      ),

      h(
        'div',
        {
          class: 'pa-2 faic fjcfe grid-gap--8',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: modes.flat,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.clone'),
                color: colors.primary

              },
              on: {
                click: () => {
                  this._clone()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: 'copy_all',
        mode: modes.flat,
        color: colors.secondary,
        loading: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate('misc.tooltips.clone')
      },
      scopedSlots: {
        dialog: () => renderConfirmDialog.call(this, h)
      },
      key: this.value.id,
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}
