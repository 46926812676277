import { inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { gridTemplateColumns: '250px' }
      },
      [ renderOwnerSelect.call(this, h) ]
    )
  }
}
