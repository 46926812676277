import { getLocaleDateString, getLocaleTimeString } from '@/utils'

import HeadTitle from '../../misc/HeadTitle'

const className = 'notifications'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog
      },
      on: {
        input: value => {
          this.showDialog = value
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate('commons.titles.notifications'),
            slot: 'title'
          }
        }
      ),

      h(
        'div',
        {
          class: `${className}__items`
        },
        this.notifications.map(notification => {
          const dateTime = [ getLocaleDateString(notification.timestamp), getLocaleTimeString(notification.timestamp) ].join(' ')

          return h(
            'div',
            {
              class: {
                [`${className}__item`]: true,
                [`${className}__item--${notification.type}`]: true
              }
            },
            [
              h(
                'div',
                {
                  class: `${className}__item-date-time`
                },
                [ dateTime ]
              ),

              h(
                'div',
                {
                  class: `${className}__item-text`
                },
                notification.text
              )
            ]
          )
        })
      ),

      h(
        'div',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.clear'),
                flat: true,
                rounded: true,
                color: 'error',
                disabled: !this.notifications.length
              },
              on: {
                click: () => {
                  this.clearNotifications()
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: this.notifications.length > 0 ? 'notifications' : 'notifications_none',
        flat: true,
        color: 'white'
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}
