import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

import { colors, isObjectEmpty, modes, sizes } from '@/utils'

import gTag from '@/components/tag'
import button from '@/components/button'
import simpleValue from '@/components/trees/routes/simpleValue'
import rulesActionsDialogGeneratorsFallbacksFallback from '@/components/misc/rules/actions/dialog/generators/fallbacks/fallback'
import rulesActionsDialogGeneratorsButtonsButton from '@/components/misc/rules/actions/dialog/generators/buttons/button'

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'routes-conditions__title'
    },
    [
      h(
        gTag,
        {
          props: {
            label: this.getTranslate('conditions.titles.actions'),
            size: sizes.tiny,
            color: colors.grey
          }
        }
      )
    ]
  )
}

function renderDialogContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    this.dialogData.map(value => {
      switch (this.dialogType) {
        case 'text': {
          return h(
            'g-textarea',
            {
              props: {
                value,
                label: this.getTranslate('messageRules.labels.text'),
                mode: 'outline',
                details: false,
                dense: true,
                rounded: true,
                readonly: true,
                clearable: true
              }
            }
          )
        }
        case 'fallbacks': {
          return h(
            rulesActionsDialogGeneratorsFallbacksFallback,
            {
              props: {
                value,
                readonly: true
              }
            }
          )
        }

        case 'buttons': {
          return h(
            rulesActionsDialogGeneratorsButtonsButton,
            {
              props: {
                value,
                disabled: true
              }
            }
          )
        }
      }
    })
  )
}
function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          if (event === false) {
            this.dialogData = []
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-3'
        },
        [ renderDialogContent.call(this, h) ]
      ),

      h(
        'div',
        {
          class: 'fjcfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                mode: modes.flat,
                label: this.getTranslate('misc.buttons.close')

              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderType(h, tag) {
  return h(
    'div',
    {
      class: 'routes-conditions__condition-type'
    },
    [ this.getTranslate(`conditions.tags.${tag}`) ]
  )
}

function renderValue(h, tag, value) {
  if (value) {
    if (Array.isArray(value) && value.length <= 0) {
      return
    }
    if (isObjectEmpty(value)) {
      return
    }

    switch (tag) {
      case 'type': {
        return h(
          gTag,
          {
            props: {
              label: this.getTranslate(`sendings.types.${value}`),
              size: sizes.tiny
            }
          }
        )
      }

      case 'moderation': {
        return h(
          gTag,
          {
            props: {
              label: value,
              size: sizes.tiny
            }
          }
        )
      }

      case 'sender': {
        return h(
          simpleValue,
          {
            props: {
              value,
              count: this.viewport.breakpoint.mdUp ? 3 : this.viewport.breakpoint.sm ? 2 : 1
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'simple'
                this.showDialog = true
              }
            }
          }
        )
      }

      case 'text':
      case 'buttons':
      case 'fallbacks': {
        return h(
          'div',
          {
            class: 'routes-conditions__condition-value link',
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = tag
                this.showDialog = true
              }
            }
          },
          this.getTranslate('misc.buttons.show')
        )
      }
    }
  }
}

function renderAction(h, tag, value) {
  return h(
    'div',
    {
      class: 'routes-conditions__condition'
    },
    [
      renderType.call(this, h, tag),
      renderValue.call(this, h, tag, value)
    ]
  )
}

function renderActions(h) {
  const actions = this.value.reduce((result, { type, path, tag, value }) => {
    const tagMeta = Actions.tagsMeta[tag || type || path]
    if (tagMeta) {
      result.push(renderAction.call(this, h, tagMeta.tag, value))
    }

    return result
  }, [])

  return h(
    'div',
    {
      class: 'routes-conditions__items'
    },
    [ actions ]
  )
}

export default function(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return h(
      'div',
      {
        class: 'routes-conditions'
      },
      [
        renderTitle.call(this, h),
        renderActions.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
