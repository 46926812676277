import { SENDING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { inputModes, permissionPresets, prioritizeAccountInList, services } from '@/utils'

import dateTimeFilter from '@/components/filter/date'
import OwnerSelect from '@/components/misc/OwnerSelect'
import SendingTypes from '@/components/misc/SendingTypes'
import createdAtPreset from '@/components/misc/createdAtPreset'

function renderFieldProvider(h) {
  if (this.checkPermissions(`advanced.${services.providers}.find`, permissionPresets.resellerUp)) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.meta._routing.provider,
          label: this.getTranslate(`${services.sendings}.filter.meta._routing.provider`),
          service: 'providers',
          query: { $order: [ [ 'isActive', 'desc' ] ] },
          pick: [ 'id', 'title', 'isActive' ],
          autocomplete: true,
          clearable: true,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.meta._routing.provider = event
          }
        },
        scopedSlots: {
          listItem: ({ item, toggleByValue, addByValue }) => {
            const { id, title, isActive } = item._item || {}

            return h(
              'g-list-item',
              {
                props: {
                  color: isActive ? 'success' : 'error',
                  dense: true
                },
                on: {
                  click: event => {
                    if (!item._disabled) {
                      if (this.multiple) {
                        event.preventDefault()
                        event.stopPropagation()

                        toggleByValue(id)
                      } else {
                        addByValue(id)
                      }
                    }
                  }
                }
              },
              [ title ]
            )
          }
        }
      }
    )
  }
}

function renderFirstColumn(h) {
  if (this.checkPermissions([ `advanced.${services.users}.find`, `advanced.${services.providers}.find` ], permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        h(
          OwnerSelect,
          {
            props: {
              value: this.proxy.meta.user.id,
              clearItems: prioritizeAccountInList,
              query: { isActive: null },
              mode: inputModes.outline,
              dense: true
            },
            on: {
              input: event => {
                this.proxy.meta.user.id = event
              }
            }
          }
        ),

        renderFieldProvider.call(this, h)
      ]
    )
  }
}

function renderSecondColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        SendingTypes,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.sendings}.filter.type`),
            permission: `advanced.${services.sendings}.find`,
            multiple: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.state.status,
            label: this.getTranslate(`${services.sendings}.filter.state.status`),
            items: SENDING_STATUS.map(status => ({
              title: this.getTranslate(`${services.sendings}.statuses.${status}`),
              value: status
            })),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.state.status = event
            }
          }
        }
      )
    ]
  )
}

function renderThirdColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.payload.recipient,
            label: this.getTranslate(`${services.sendings}.filter.payload.recipient`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.payload.recipient = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.payload.sender,
            label: this.getTranslate(`${services.sendings}.filter.payload.sender`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.payload.sender = event
            }
          }
        }
      )
    ]
  )
}

function renderFourthColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.id,
            label: this.getTranslate(`${services.sendings}.filter.id`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.id = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.groupId,
            label: this.getTranslate(`${services.sendings}.filter.groupId`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.groupId = event
            }
          }
        }
      )
    ]
  )
}

function renderFifthColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        createdAtPreset,
        {
          props: {
            value: this.proxy.createdAtPreset
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAt = {
                  $gt: undefined,
                  $lt: undefined
                }
              }

              this.proxy.createdAtPreset = event
            }
          }
        }
      ),

      h(
        dateTimeFilter,
        {
          props: {
            value: this.proxy.createdAt,
            min: this.minMax.min,
            max: this.minMax.max
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAtPreset = undefined
              }

              this.proxy.createdAt = event
            },
            pick: event => {
              if (event) {
                this.firstDate = event
              }
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--5': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm,
        'grid-cols--1': this.viewport.breakpoint.xs
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),
      renderThirdColumn.call(this, h),
      renderFourthColumn.call(this, h),
      renderFifthColumn.call(this, h)

    ]
  )
}
