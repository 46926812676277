import { serviceName } from '@/components/services/sendings/utils'
import { buttonModes, colors, components } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'

function renderInfo(h) {
  if (!this.asOwner) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${serviceName}.hints.resend`),
          color: colors.info
        }
      }
    )
  }
}

function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        [ this.getTranslate(`${serviceName}.titles.resend`) ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2 mx-2 border border--rounded border--dashed'
        },
        [
          h(
            'g-switch',
            {
              props: {
                value: this.asOwner,
                label: this.getTranslate(`${serviceName}.labels.asOwner`),
                details: false
              },
              on: {
                input: event => {
                  this.asOwner = event
                }
              }
            }
          ),

          renderInfo.call(this, h)
        ]
      ),

      h(
        'div',
        {
          class: 'fjcfe grid grid-gap--8 pa-2',
          style: { gridTemplateColumns: 'repeat(2, auto)' },
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.flat,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.send'),
                color: colors.primary,
                mode: buttonModes.flat,
                loading: this.loading,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.resend()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: 'send',
        color: colors.primary,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
