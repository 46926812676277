import Vue from 'vue'

import { colors, components, icons, modes, popperPlacements } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import removeItem from '@/components/button/confirm/remove'

function renderActivator(h) {
  return h(
    button,
    {
      props: {
        icon: icons.more_vert,
        mode: modes.flat,
        disabled: this.$disabled || this.loading
      },
      slot: 'activator'
    }
  )
}

function renderEditItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate('misc.buttons.edit'),
        dense: true,
        disabled: !this.checkPermissions(`advanced.${this.$serviceViaPoint}.update`, this.accept) || this.loading
      },
      scopedSlots: {
        prefix: () => {
          return h(
            squircle,
            {
              props: {
                icon: icons.edit,
                color: this.checkPermissions(`advanced.${this.$serviceViaPoint}.update`, this.accept) ? colors.black : colors.grey
              }
            }
          )
        }
      },
      on: {
        click: () => {
          Vue.router.push({
            name: `${this.$serviceViaPoint}.single`,
            params: { id: this.value.id }
          })
        }
      }
    }
  )
}
function renderDefaultSlot() {
  if (typeof this.$scopedSlots.default === 'function') {
    return this.$scopedSlots.default()
  }

  return this.$slots.default
}
function renderRemoveItem(h) {
  return h(
    removeItem,
    {
      props: {
        labeled: true,
        component: components['list-item'],
        disabled: !this.checkPermissions(`advanced.${this.$serviceViaPoint}.remove`, this.accept) || this.loading,
        callback: () => this.remove()
      }
    }
  )
}
function renderMenu(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        overflow: true
      }
    },
    [
      renderEditItem.call(this, h),
      renderDefaultSlot.call(this, h),
      renderRemoveItem.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.value.id) {
    return h(
      'g-menu',
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp && !this.showMenu },
        props: {
          value: this.showMenu,
          rounded: true,
          disabled: this.$disabled,
          placement: popperPlacements['bottom-end'],
          offsetDistance: 8
        },
        on: {
          input: event => {
            this.showMenu = event
          }
        }
      },
      [
        renderActivator.call(this, h),
        renderMenu.call(this, h)
      ]
    )
  }
}
