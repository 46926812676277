import { inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '250px 36px' }
    },
    [
      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      h(
        'g-switch',
        {
          props: {
            value: this.proxy.isActive
          },
          on: {
            input: event => {
              this.proxy.isActive = event
            }
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${services.requisites}.tooltips.filter.isActive`)
              }
            }
          ]
        }
      )
    ]
  )
}
