import { colors } from './enums'

export const FILE_TYPES = {
  doc: {
    value: 'doc',
    title: 'misc.fileTypes.documents',
    color: colors['file-document'],
    ext: 'doc, docx',
    extensions: [ 'doc', 'docx' ]
  },
  csv: {
    value: 'csv',
    title: 'misc.fileTypes.tables',
    color: colors['file-sheet'],
    ext: 'xls, xlsx, csv',
    extensions: [ 'xls', 'xlsx', 'csv' ]
  },
  pdf: {
    value: 'pdf',
    title: 'misc.fileTypes.pdf',
    color: colors['file-pdf'],
    ext: 'pdf',
    extensions: [ 'pdf' ]
  },
  jpg: {
    value: 'jpg',
    title: 'misc.fileTypes.images',
    color: colors['file-image'],
    ext: 'jpg, jpeg, png, gif',
    extensions: [ 'jpg', 'jpeg', 'png', 'gif' ]
  },
  wav: {
    value: 'wav',
    title: 'misc.fileTypes.audio',
    color: colors['file-audio'],
    ext: 'wav, mp3',
    extensions: [ 'wav', 'mp3' ]
  },
  mp4: {
    value: 'mp4',
    title: 'misc.fileTypes.video',
    color: colors['file-video'],
    ext: 'mp4',
    extensions: [ 'mp4' ]
  },
  file: {
    value: 'file',
    color: colors.file,
    ext: '*',
    extensions: []
  }
}
