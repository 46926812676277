import { services } from '@/utils'

import buttonToolbar from '@/components/button/toolbar'

export default function(h) {
  return h(
    buttonToolbar,
    {
      props: {
        iconSVG: 'wallet-add',
        tooltip: this.getTranslate(`${services.payments}.buttons.add.funds`)
      },
      on: {
        click: () => {
          this.setShowPaymentDialog(true)
        }
      }
    }
  )
}
