import { get } from 'lodash'

import render from './render'

export default {
  name: 'FileOwner',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    readonly: Boolean
  },

  computed: {
    username() {
      return get(this.value, 'Owner.username')
    },
    name() {
      const { firstName, lastName } = get(this.value, 'Owner.data', {})

      return [ firstName, lastName ].filter(Boolean).join(' ')
    },
    verified() {
      return get(this.value, 'Owner.$verified')
    }
  },

  render
}
