import { services } from '@/utils'

import squircle from '@/components/icon/squircle'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        iconSVG: this.$icon,
        color: this.$icon,
        tooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`)
      }
    }
  )
}
