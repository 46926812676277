export default function(h) {
  if (this.checkPermissions(this.permission, this.accept)) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy,
          label: this.label || this.getTranslate('commons.labels.owner'),
          hint: this.hint,
          service: 'users',
          query: this.computedQuery,
          itemTitle: 'username',
          mode: this.mode,
          flat: this.flat,
          dense: this.dense,
          itemsDisabled: this.itemsDisabled,
          rounded: this.rounded,
          details: !!this.hint || this.details,
          required: this.required,
          clearable: this.clearable,
          autocomplete: this.autocomplete,
          clearItems: this.clearItems,
          disabled: this.disabled
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}
