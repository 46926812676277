import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { inputModes, permissionPresets, prioritizeAccountInList } from '@/utils'
import { serviceName } from '@/components/services/billings/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import dateTimeFilter from '@/components/filter/date'
import createdAtPreset from '@/components/misc/createdAtPreset'
import SendingTypes from '@/components/misc/SendingTypes'

function renderTariffIdField(h) {
  if (this.checkPermissions('advanced.tariffs.find', permissionPresets.resellerUp)) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.TariffId,
          label: this.getTranslate(`${serviceName}.labels.tariff`),
          service: 'tariffs',
          autocomplete: true,
          clearable: true,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.TariffId = event
          }
        }
      }
    )
  }
}

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: prioritizeAccountInList,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderFirstColumn(h) {
  if (this.checkPermissions([ 'advanced.users.find', 'advanced.tariffs.find' ], permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderOwnerSelect.call(this, h),
        renderTariffIdField.call(this, h)
      ]
    )
  }
}

function renderSecondColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy.status,
            label: this.getTranslate(`${serviceName}.labels.status`),
            items: BILLING_STATUS.map(status => ({
              title: this.getTranslate(`${serviceName}.statuses.${status}`),
              value: status
            })),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.status = event
            }
          }
        }
      ),

      h(
        SendingTypes,
        {
          props: {
            value: this.proxy.source,
            label: this.getTranslate(`${serviceName}.labels.source`),
            items: this.sourceItems,
            format: value => value.replace(/sendings\.|subscription\./g, '')
          },
          on: {
            input: event => {
              this.proxy.source = event
            }
          }
        }
      )
    ]
  )
}

function renderThirdColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.id,
            label: this.getTranslate(`${serviceName}.labels.id`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.id = event
            }
          }
        }
      )
    ]
  )
}

function renderFourthColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        createdAtPreset,
        {
          props: {
            value: this.proxy.createdAtPreset
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAt = {
                  $gt: undefined,
                  $lt: undefined
                }
              }

              this.proxy.createdAtPreset = event
            }
          }
        }
      ),

      h(
        dateTimeFilter,
        {
          props: {
            value: this.proxy.createdAt,
            min: this.minMax.min,
            max: this.minMax.max
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAtPreset = undefined
              }

              this.proxy.createdAt = event
            },
            pick: event => {
              if (event) {
                this.firstDate = event
              }
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),
      renderThirdColumn.call(this, h),
      renderFourthColumn.call(this, h)
    ]
  )
}
