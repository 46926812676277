import { buttonModes, components, icons, inputModes, integerRegex, permissionPresets, services } from '@/utils'
import { invoiceStatues, statusColors, statusIcons } from '@/components/services/invoices/utils'

import dateTimeFilter from '@/components/filter/date'
import squircle from '@/components/icon/squircle'
import OwnerSelect from '@/components/misc/OwnerSelect'
import button from '@/components/button'

function renderDateFilter(h) {
  return h(
    dateTimeFilter,
    {
      props: {
        value: this.proxy.createdAt,
        preset: this.proxy.createdAtPreset,
        presets: true,
        backendGeneration: 'nest'
      },
      on: {
        input: event => {
          this.proxy.createdAt = event
        },
        preset: event => {
          this.proxy.createdAtPreset = event
        }
      }
    }
  )
}

function renderDocNumber(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.docNumber,
        label: this.getTranslate(`${services.invoices}.labels.docNumber`),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        details: false,
        mask: integerRegex
      },
      on: {
        input: event => {
          this.proxy.docNumber = event
        }
      }
    }
  )
}

function renderStatus(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.status,
        label: this.getTranslate(`${services.invoices}.labels.status`),
        items: invoiceStatues.map(status => {
          return {
            title: this.getTranslate(`${services.invoices}.labels.statuses.${status}`),
            value: status
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      scopedSlots: {
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'grid grid-gap--8 faic',
              style: { 'grid-template-columns': 'auto 1fr' }
            },
            [
              h(
                squircle,
                {
                  props: {
                    icon: statusIcons[item.value],
                    color: statusColors[item.value]
                  }
                }
              ),
              this.getTranslate(`${services.invoices}.labels.statuses.${item.value}`)
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.status = event
        }
      }
    }
  )
}

function renderCustomer(h) {
  if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.customerId,
          label: this.getTranslate(`${services.invoices}.labels.customer`),
          mode: inputModes.outline,
          dense: true
        },
        on: {
          input: event => {
            this.proxy.customerId = event
          }
        }
      }
    )
  }
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.ownerId,
          label: this.getTranslate(`${services.invoices}.labels.owner`),
          mode: inputModes.outline,
          dense: true
        },
        on: {
          input: event => {
            this.proxy.ownerId = event
          }
        }
      }
    )
  }
}

function renderRequisite(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.requisiteId,
        label: this.getTranslate(`${services.invoices}.labels.requisite`),
        service: services.requisites,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.requisiteId = event
        }
      }
    }
  )
}

function renderCompany(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.companyId,
        label: this.getTranslate(`${services.invoices}.labels.company`),
        service: services.companies,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.companyId = event
        }
      }
    }
  )
}

function renderAdditionalFilters(h) {
  return [
    renderStatus.call(this, h),
    renderCustomer.call(this, h),
    renderOwner.call(this, h),
    renderRequisite.call(this, h),
    renderCompany.call(this, h)
  ]
}

function renderMenu(h) {
  return h(
    components.menu,
    {
      props: {
        closeOnContentClick: false,
        offsetDistance: 8,
        placement: 'bottom-end',
        rounded: true,
        maxWidth: 304,
        width: '100%'
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            icon: icons.filter_list
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2'
        },
        [ renderAdditionalFilters.call(this, h) ]
      )
    ]
  )
}

function renderContentByViewport(h) {
  if (this.viewport.breakpoint.lgUp) {
    return renderMenu.call(this, h)
  } else {
    return renderAdditionalFilters.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.invoices}-filter`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderDateFilter.call(this, h),
      renderDocNumber.call(this, h),
      renderContentByViewport.call(this, h)
    ]
  )
}
