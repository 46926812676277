import { components } from '@/utils'

function renderIcon(h, icon) {
  if (icon) {
    return h(
      'div',
      {
        class: `${components['list-item']}__icon`
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: icon,
              color: this.computedIconColor,
              size: this.computedIconSize
            }
          }
        )
      ]
    )
  }
}

function renderLabel(h) {
  return h(
    'div',
    {
      class: `${components['list-item']}__label`
    },
    [ this.label || this.$slots.default ]
  )
}

function renderScopedSlot(h, slotName) {
  if (this[slotName]) {
    return renderIcon.call(this, h, this[slotName])
  }

  if (typeof this.$scopedSlots[slotName] === 'function') {
    return h(
      'div',
      {
        class: `${components['list-item']}__${slotName}`
      },
      this.$scopedSlots[slotName]()
    )
  }

  return this.$slots[slotName]
}

function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['list-item']}__holder`
    },
    [
      renderScopedSlot.call(this, h, 'icon'),
      renderScopedSlot.call(this, h, 'prefix'),
      renderLabel.call(this, h),
      renderScopedSlot.call(this, h, 'suffix')
    ]
  )
}

function renderButton(h) {
  if (this.hasButton) {
    return h(
      'div',
      {
        class: {
          [`${components['list-item']}__button`]: true,
          [`${components['list-item']}__button--disabled`]: this.button.disabled || this.disabled,
          [`${components['list-item']}__button--${this.button.color || this.color}`]: !!this.button.color || !!this.color
        },
        on: {
          click: event => {
            event.stopPropagation()
            if (this.button.callback) {
              this.button.callback()
            }
          }
        },

        directives: this.computedButtonTooltip
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: this.button.icon,
              color: this.button.iconColor || this.computedIconColor,
              size: this.button.iconSize || this.computedIconSize
            }
          }
        )
      ]
    )
  }
}

function renderDialogSlot() {
  if (typeof this.$scopedSlots.dialog === 'function') {
    return this.$scopedSlots.dialog()
  }

  return this.$slots.dialog
}

export default function(h) {
  return h(
    this.to ? 'router-link' : 'div',
    {
      attrs: { role: 'listitem' },
      class: {
        [`${components['list-item']}`]: true,

        [`${components['list-item']}--active`]: this.active,
        [`${components['list-item']}--hovered`]: this.hovered,
        [`${components['list-item']}--dense`]: this.dense,
        [`${components['list-item']}--outline`]: this.outline,
        [`${components['list-item']}--rounded`]: this.rounded,
        [`${components['list-item']}--disabled`]: this.disabled,

        [`${components['list-item']}--icon`]: this.hasIcon,
        [`${components['list-item']}--prefix`]: this.hasPrefix,
        [`${components['list-item']}--suffix`]: !!this.hasSuffix,
        [`${components['list-item']}--button`]: this.hasButton,

        [`${components['list-item']}--${this.color}`]: !!this.color
      },
      props: { to: this.to },
      on: {
        click: event => {
          this.$emit('click', event)
        }
      }
    },
    [
      renderHolder.call(this, h),
      renderButton.call(this, h),
      renderDialogSlot.call(this, h)
    ]
  )
}
