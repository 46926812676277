import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { catchSymbolsMenus, inputModes, moderationColors, moderationIcons, permissionPresets, prioritizeAccountInList, sizes } from '@/utils'
import { serviceName } from '@/components/services/patterns/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import SendingsCreateForm from '@/components/services/sendingsCreate/form'
import ModerationStatusBarWithIcon from '@/components/misc/ModerationStatusBarWithIcon'
import OwnerSelect from '@/components/misc/OwnerSelect'
import squircle from '@/components/icon/squircle'

function renderIsActiveField(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.isActive
        },
        on: {
          input: event => {
            this.proxy.isActive = event
          }
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`${serviceName}.labels.isActive`)
            }
          }
        ]
      }
    )
  }
}

function renderTitleField(h) {
  const columns = [ '1fr' ]
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    columns.push('38px')
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': columns.join(' ') }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate(`${serviceName}.labels.title`),
            mode: inputModes['line-label'],
            rounded: true,
            required: true,
            autofocus: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      renderIsActiveField.call(this, h)
    ]
  )
}

function renderModerationField(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faifs',
        style: { 'grid-template-columns': '1fr 38px' }
      },
      [
        h(
          'g-select',
          {
            props: {
              value: this.proxy.moderation,
              label: this.getTranslate(`${serviceName}.labels.moderation`),
              items: MODERATION_VALUES.map(moderation => {
                return {
                  title: this.getTranslate(`misc.moderation.${moderation}`),
                  value: moderation
                }
              }),
              mode: inputModes['line-label'],
              rounded: true,
              required: true,
              details: false
            },
            on: {
              input: event => {
                this.proxy.moderation = event
              }
            },
            scopedSlots: {
              selection: ({ item }) => {
                return h(
                  'g-chip',
                  {
                    props: {
                      small: true,
                      label: item.title,
                      color: moderationColors[item.value]
                    }
                  }
                )
              },
              item: ({ item }) => {
                return h(
                  ModerationStatusBarWithIcon,
                  {
                    props: {
                      type: item.value,
                      title: item.title
                    }
                  }
                )
              }
            }
          }
        ),

        h(
          squircle,
          {
            props: {
              iconSize: 30,
              size: sizes.medium,
              icon: moderationIcons[this.proxy.moderation],
              color: moderationColors[this.proxy.moderation]
            }
          }
        )
      ]
    )
  }
}

function renderPatternFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      renderTitleField.call(this, h),
      renderModerationField.call(this, h)
    ]
  )
}

function renderSendingForm(h) {
  return h(
    SendingsCreateForm,
    {
      props: {
        value: this.proxy,
        types: this.getSendingTypesByPermission(`advanced.${serviceName}.create`),
        isTemplate: true,
        tags: catchSymbolsMenus['#'],
        customMenu: catchSymbolsMenus.custom['#'],
        isPattern: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderCheckPatternField(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faifs',
      style: { gridTemplateColumns: '1fr 38px' }
    },
    [
      h(
        'g-textarea',
        {
          props: {
            value: this.checkPattern,
            label: this.getTranslate(`${serviceName}.labels.checkPattern`),
            hint: this.getTranslate(`${serviceName}.hints.checkPattern`),
            disabled: !this.proxy.payload.text,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: true
          },
          on: {
            input: event => {
              this.checkPattern = event
            }
          }
        }
      ),

      h(
        squircle,
        {
          props: {
            iconSize: 30,
            size: sizes.medium,
            icon: this.patternValidation === undefined ? 'block' : this.patternValidation ? 'check' : 'close',
            color: this.patternValidation === undefined ? 'grey' : this.patternValidation ? 'success' : 'error'
          }
        }
      )
    ]
  )
}

function renderCommentField(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, 'me')) {
    return h(
      'g-textarea',
      {
        props: {
          value: this.proxy.meta.comment,
          label: this.getTranslate(`${serviceName}.labels.meta.comment`),
          mode: 'outline',
          grow: true,
          dense: true,
          rounded: true,
          clearable: true
        },
        on: {
          input: event => {
            this.proxy.meta.comment = event
          }
        }
      }
    )
  }
}

function renderOwnerField(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, permissionPresets.resellerUp)) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.OwnerId,
          clearItems: prioritizeAccountInList,
          mode: inputModes.outline,
          dense: true
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

function renderAdvancedComment(h) {
  switch (true) {
    case this.checkPermissions(`advanced.${serviceName}.create`, 'reseller'): {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.resellerComment,
                label: this.getTranslate(`${serviceName}.labels.meta.resellerComment`),
                mode: 'outline',
                grow: true,
                dense: true,
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerComment = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.resellerCommentPrivate,
                label: this.getTranslate(`${serviceName}.labels.meta.resellerCommentPrivate`),
                mode: 'outline',
                grow: true,
                dense: true,
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
    case this.checkPermissions(`advanced.${serviceName}.create`): {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.adminComment,
                label: this.getTranslate(`${serviceName}.labels.meta.adminComment`),
                mode: 'outline',
                grow: true,
                dense: true,
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminComment = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.adminCommentPrivate,
                label: this.getTranslate(`${serviceName}.labels.meta.adminCommentPrivate`),
                mode: 'outline',
                grow: true,
                dense: true,
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderAdvanced(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '1fr 38px' }
      },
      [
        h(
          'g-expansion-panels',
          {
            props: {
              outline: true,
              rounded: true
            }
          },
          [
            h(
              'g-expansion-panel',
              {
                scopedSlots: {
                  header: ({ expanded }) => {
                    return h(
                      'div',
                      {
                        class: 'default-expansion-panel-header pr-0'
                      },
                      [
                        h('div', {}, this.getTranslate('misc.advanced')),
                        h('div', { class: 'ff' }),
                        h(ExpandArrow, { props: { expanded } })
                      ]
                    )
                  },
                  default: () => {
                    return h(
                      'div',
                      { class: 'grid grid-gap--8 pa-2' },
                      [
                        renderOwnerField.call(this, h),
                        renderAdvancedComment.call(this, h)
                      ]
                    )
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderPatternFields.call(this, h),
      renderSendingForm.call(this, h),
      renderCheckPatternField.call(this, h),
      renderCommentField.call(this, h),
      renderAdvanced.call(this, h)
    ]
  )
}
