import { get } from 'lodash'

import { colors, modes, services, sizes } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import replacementsFrom from '@/components/services/providers/replacements/form'
import draggable from '@/components/misc/draggable'
import button from '@/components/button'
import squircle from '@/components/icon/squircle'

function renderRemoveButton(h, index) {
  return h(
    button,
    {
      class: { 'hover-child': this.viewport.breakpoint.mdUp },
      props: {
        icon: 'remove',
        mode: modes.flat,
        color: colors.error
      },
      on: {
        click: () => {
          this.remove(index)
        }
      }
    }
  )
}

function renderValueRow(h, data, path) {
  const value = get(data, path)
  if (value) {
    return h(
      'div',
      {
        class: 'text-overflow caption'
      },
      `${value}`
    )
  }
}

function renderRules(h, rules) {
  if (Array.isArray(rules) && rules.length) {
    return h(
      'div',
      {
        class: 'faic text--grey small'
      },
      [
        h('div', { class: 'mr-1' }, this.getTranslate(`${services.providers}.hints.replacements.rules`)),
        h('div', { class: '' }, rules.map(rule => this.getTranslate(`conditions.tags.${rule.tag}`).toLowerCase()).join(', '))
      ]
    )
  }
}

function renderValue(h, { rules, replacement }) {
  return h(
    'div',
    {
      class: 'grid grid-gap--0',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderValueRow.call(this, h, replacement, 'payload.sender'),
      renderValueRow.call(this, h, replacement, 'payload.text'),
      renderRules.call(this, h, rules)
    ]
  )
}

function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: 'faic grid grid-gap--8 hover-parent hover-parent--opacity',
      style: { 'grid-template-columns': '24px 1fr 36px' }
    },
    [
      h(
        'g-icon',
        {
          class: 'drag_handle',
          props: {
            value: 'drag_handle',
            color: 'grey'
          }
        }
      ),

      h(
        'div',
        {
          class: 'faic hover-background cursor-pointer br--16 py-2 px-3',
          on: {
            click: () => {
              this.edit(index)
            }
          }
        },
        [ renderValue.call(this, h, item) ]
      ),

      renderRemoveButton.call(this, h, index)
    ]
  )
}

function renderItems(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      draggable,
      {
        class: 'grid grid-cols--1 grid-gap--8',
        props: {
          value: this.proxy,
          handle: 'drag_handle'
        },
        scopedSlots: {
          item: (item, index) => {
            return renderItem.call(this, h, item, index)
          }
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { 'grid-template-columns': 'auto 1fr' }
      },
      [
        h(
          squircle,
          {
            props: {
              icon: 'search_off',
              color: colors.grey,
              size: sizes.medium
            }
          }
        ),

        h(
          'div',
          {
            class: 'grid grid-gap--2 ff'
          },
          [
            h('div', {}, this.getTranslate(`${services.providers}.titles.replacements.notFound`)),
            h('div', { class: 'small text--grey' }, this.getTranslate(`${services.providers}.hints.replacements.notFound`))
          ]
        )
      ]
    )
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        maxWidth: 400,
        rounded: true
      },
      on: {
        input: event => {
          if (event === false) {
            this.cancel()
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`providers.titles.replacements.${this.editMode ? 'edit' : 'add'}`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        [
          h(
            replacementsFrom,
            {
              props: {
                value: this.payload
              },
              on: {
                input: event => {
                  this.payload = event
                },
                validation: event => {
                  this.disabled = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 fjcfe pa-2',
          style: { 'grid-template-columns': 'repeat(2, auto)' },
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                mode: modes.flat,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.cancel()
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                mode: modes.flat,
                color: colors.primary,
                disabled: this.disabled,
                label: this.getTranslate(`misc.buttons.${this.editMode ? 'update' : 'add'}`)
              },
              on: {
                click: () => {
                  if (this.editMode) {
                    this.update()
                  } else {
                    this.add()
                  }
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderAddButton(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            icon: 'add',
            mode: modes.flat,
            color: colors.primary,
            label: this.getTranslate(`${services.providers}.buttons.add.replacement`)
          },
          on: {
            click: () => {
              this.showDialog = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${services.providers}.hints.replacements.hint`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderItems.call(this, h),
      renderAddButton.call(this, h)
    ]
  )
}
