import render from './render'

export default {
  name: 'TitleDivider',

  props: {
    value: {
      type: [ String, Number, Boolean ],
      default: undefined
    }
  },

  computed: {
    $title() {
      switch (typeof this.value) {
        case 'boolean':
        case 'number': return this.value.toString()
        case 'string':
        default: return this.value
      }
    }
  },

  render
}
