import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.hasPermissions) {
    if (this.hasOwner) {
      return h(
        sticker,
        {
          props: this.$props
        }
      )
    }
  }
}
