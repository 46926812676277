import { colors, icons, inputModes, modes } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import settingRow from '@/components/services/settings/row'
import textEditor from '@/components/text/editor'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: 600
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${this.service}.dialogs.headers.${this.path}.${this.prop}`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 px-2 pt-2'
        },
        [
          h(
            'g-text-field',
            {
              props: {
                value: this.proxy[this.prop].subject,
                label: this.getTranslate(`${this.service}.labels.${this.path}.subject`),
                clearable: true,
                mode: inputModes['line-label'],
                rounded: true,
                dense: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[this.prop].subject = event
                }
              }
            }
          ),

          h(
            textEditor,
            {
              props: {
                value: this.proxy[this.prop].body,
                placeholder: this.getTranslate(`${this.service}.labels.${this.path}.body`),
                grow: true,
                rows: 18
              },
              on: {
                input: event => {
                  this.proxy[this.prop].body = event
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'fjcsb faic pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: modes.flat
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                icon: icons.save,
                color: colors.primary
              },
              on: {
                click: () => {
                  this.$emit('input', this.proxy)
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    settingRow,
    {
      props: {
        title: this.getTranslate(`${this.service}.contents.${this.path}.${this.prop}`),
        label: this.getTranslate(`${this.service}.hints.${this.path}.${this.prop}`),
        icon: this.$icon
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => {
          return renderDialog.call(this, h)
        }
      }
    }
  )
}
