const sources = {
  utm_source: 'Источник кампании ({param})',
  utm_medium: 'Тип ({param})',
  utm_campaign: 'Название кампании ({param})',
  utm_content: 'Идентификатор объявления ({param})',
  utm_term: 'Ключевое слово ({param})'
}

export default {
  title: 'Генератор UTM-меток',

  titles: {
    generate: 'Сгенерировать UTM-метку',

    params: {
      required: 'Обязательные параметры',
      optional: 'Опциональные параметры'
    },

    ...sources
  },

  labels: {
    protocol: 'Протокол',
    url: 'Адрес вашей страницы',

    ...sources,

    custom: 'Свои значения',
    google: 'Google Ads',
    yandex: 'Яндекс.Директ',
    vk: 'Вконтакте',

    links: {
      full: 'Полная ссылка',
      short: 'Короткая ссылка'
    }
  },

  hints: {
    custom: {
      utm_source: 'Рекламная площадка',
      utm_medium: 'Тип кампании или рекламы',
      utm_campaign: 'Позволяет различать кампании в статистике',
      utm_content: 'Дополнительный параметр для отслеживания',
      utm_term: 'Слово, с которого начался показ объявления'
    },
    google: {
      utm_source: 'Рекламная площадка',
      utm_medium: 'Тип кампании или рекламы',
      utm_campaign: 'Вместо {network} Google Ads подставит "g" (поиск), "s" (поисковый партнер) или "d" (КМС)',
      utm_content: 'Вместо {creative} Google Ads подставит ID объявления',
      utm_term: 'Вместо {keyword} Google Ads подставит ключевое слово'
    },
    yandex: {
      utm_source: 'Рекламная площадка',
      utm_medium: 'Тип кампании или рекламы',
      utm_campaign: 'Вместо {campaign_id} Яндекс.Директ подставит ID кампании',
      utm_content: 'Вместо {ad_id} Яндекс.Директ подставит ID объявления',
      utm_term: 'Вместо {keyword} Яндекс.Директ подставит ключевое слово'
    },
    vk: {
      utm_source: 'Рекламная площадка',
      utm_medium: 'Тип кампании или рекламы',
      utm_campaign: 'Вместо {campaign_id} Вконтакте подставит ID объявления',
      utm_content: 'Вместо {ad_id} Вконтакте подставит ID объявления',
      utm_term: 'Слово, с которого начался показ объявления'
    }
  },

  references: {
    utm_source: '<strong>utm_source</strong> — название рекламной площадки.<br/><br/>\
                <strong>Зачем нужен:</strong><br/>Чтобы указать название источника трафика.<br/><br/>\
                <strong>Примеры:</strong>\
                <ul class="ma-0">\
                  <li>utm_source=google – контекстная реклама в Google Ads.</li>\
                  <li>utm_source=yandex — контекстная реклама в Яндекс.Директ.</li>\
                  <li>utm_source=vk — контекстная реклама в Вконтакте.</li><ul/>',

    utm_medium: '<strong>utm_medium</strong> — тип рекламы.<br/><br/>\
                В этом параметре рекомендуется использовать устоявшиеся значения, например:\
                cpc (cost per click) — это контекстная реклама, display — баннерная реклама с оплатой за показы,\
                social_cpc — реклама в соцсетях с оплатой за клик.<br/><br/>\
                <strong>Зачем нужен:</strong><br/>Чтобы определить типа кампании или рекламы.<br/><br/>\
                <strong>Примеры:</strong>\
                <ul class="ma-0">\
                  <li>utm_medium=organic – бесплатный переход.</li>\
                  <li>utm_medium=cpc – контекстная реклама (cost per click, плата за клик).</li>\
                  <li>utm_medium=email — рассылка.</li>\
                  <li>utm_medium=social — социальные сети.</li>\
                  <li>utm_medium=banner — медийная реклама.</li>\
                  <li>utm_medium=cpa — другая реклама (cost per action, плата за действие).</li><ul/>',

    utm_campaign: '<strong>utm_campaign</strong> — название кампании.<br/><br/>\
                  Этот обязательный параметр можно задать произвольно.<br/><br/>\
                  <strong>Зачем нужен:</strong><br/>Позволит вам отличить одну рекламную кампанию от другой в статистике.<br/><br/>\
                  <strong>Примеры:</strong>\
                  <ul class="ma-0">\
                  <li>utm_campaign=mebel_dlya_doma – рекламная кампания мебели для дома.</li><ul/>',

    utm_content: '<strong>utm_content</strong> — дополнительная информация, которую можно отслеживать, если совпадают другие параметры.<br/><br/>\
                  <strong>Зачем нужен:</strong></br>Часто используется как пометка для объявления внутри рекламной кампании.\
                  Название можно задать произвольно, удобнее всего использовать важные характеристики объявления —\
                  подкатегория товара или услуги, тип самого объявления и т. п..<br/><br/>\
                  <strong>Примеры:</strong>\
                  <ul class="ma-0">\
                  <li>utm_content=leadmagnet_webinar0404 — реклама продукта в лидмагните, который дарили пользователям на вебинаре 4 апреля.</li><ul/>',

    utm_term: '<strong>utm_term</strong> — ключевое слово, с которого начался показ объявления.<br/><br/>\
              Этот обязательный параметр можно задать произвольно.<br/><br/>\
              <strong>Зачем нужен:</strong></br>Позволит вам отличить одну рекламную кампанию от другой в статистике.<br/><br/>\
              <strong>Примеры:</strong>\
              <ul class="ma-0">\
              <li>utm_term=kupit_krossovki — рекламируем пользователю кроссовки.</li><ul/>'
  },

  tooltips: {
    generate: 'Сгенерировать UTM-метку',
    reference: 'Справка по {param}'
  },

  snackbars: {
    error: 'Не удалось создать короткую ссылку'
  }
}
