import dateTimeFilter from '@/components/filter/date'

export default function(h) {
  return h(
    dateTimeFilter,
    {
      class: {
        'fjcc facc': true,
        'mt-2': this.viewport.breakpoint.xs
      },
      props: {
        value: this.proxy.createdAt
      },
      on: {
        input: event => {
          this.proxy.createdAt = event
        }
      }
    }
  )
}
