import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'UsersTokens',

  mixins: [
    generateServices({
      name: services.tokens,

      find: {
        defaultPagination: {
          limit: 8
        },
        defaultFilter: {
          OwnerId: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },

      get: false,
      update: false,

      remove: {
        redirect: false
      }
    })
  ],

  props: {
    OwnerId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showCreateDialog: false,
      showCopyDialog: false,

      loading: false
    }
  },

  async mounted() {
    this.restData[services.tokens].find.filter.OwnerId = this.OwnerId
    await this.rest[services.tokens].find()
  },

  methods: {
    async create() {
      this.loading = true
      try {
        const result = await this.rest[services.tokens].create(this.restData[services.tokens].create.data)
        if (result) {
          this.data = result
          this.showCreateDialog = false
          this.$nextTick(() => this.showCopyDialog = true)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
