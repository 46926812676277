import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'Amount',

  mixins: [ proxy() ],

  props: {
    disabled: Boolean
  },

  render
}
