import { colors, permissionPresets, services, sizes } from '@/utils'

import tag from '@/components/tag'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    if (this.username) {
      return h(
        tag,
        {
          props: {
            label: this.username,
            size: sizes.small,
            color: this.verified ? colors.info : colors.black,
            tooltip: this.name
          }
        }
      )
    }
  }
}
