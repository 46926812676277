import { PAYMENTS_METHODS, PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import dateTimeFilter from '@/components/filter/date'
import PaymentsMethodSelect from '@/components/services/payments/method'

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderStatusSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.status,
        label: this.getTranslate(`${services.payments}.labels.status`),
        items: PAYMENTS_STATUSES.map(status => {
          return {
            title: this.getTranslate(`${services.payments}.statuses.${status}`),
            value: status
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.status = event
        }
      }
    }
  )
}

function renderMethodSelect(h) {
  return h(
    PaymentsMethodSelect,
    {
      props: {
        value: this.proxy.method,
        items: PAYMENTS_METHODS,
        dense: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.method = event
        }
      }
    }
  )
}

function renderDateTimeFilter(h) {
  return h(
    dateTimeFilter,
    {
      props: {
        value: this.proxy.createdAt,
        preset: this.proxy.createdAtPreset,
        presets: true
      },
      on: {
        input: event => {
          if (event) {
            this.proxy.createdAtPreset = undefined
          }

          this.proxy.createdAt = event
        },
        preset: event => {
          this.proxy.createdAtPreset = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid faic grid-gap--8': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'grid-cols--2': this.viewport.breakpoint.smUp
      }
    },
    [
      renderOwnerSelect.call(this, h),
      renderStatusSelect.call(this, h),
      renderMethodSelect.call(this, h),
      renderDateTimeFilter.call(this, h)
    ]
  )
}
