import { colors, modes, sizes } from '@/utils'

import Clipboard from 'clipboard'

import render from './render'

export default {
  name: 'ButtonCopy',

  props: {
    value: [ String, Number ],

    mode: {
      type: String,
      default: modes.flat,
      validator: value => {
        return Object.values(modes).includes(value)
      }
    },

    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },

    color: {
      type: String,
      default: colors.primary,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    icon: {
      type: String,
      default: 'content_copy'
    },

    iconLibrary: String,

    tooltip: String,

    small: Boolean,
    large: Boolean,
    marginless: Boolean,

    label: String,

    view: {
      type: String,
      default: 'btn',
      validator: value => {
        return ~[ 'btn', 'g-list-item' ].indexOf(value)
      }
    },

    snackbar: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      cp: undefined
    }
  },

  computed: {
    iconSize() {
      return this.small ? 16 : this.large ? 22 : 18
    }

  },

  mounted() {
    this.getCopy()
  },

  beforeDestroy() {
    this.cp.destroy()
  },

  methods: {
    getCopy() {
      if (this.view === 'g-list-item') {
        this.$el.setAttribute('data-clipboard-text', this.value)
        this.cp = new Clipboard(this.$el)
      } else {
        this.cp = new Clipboard(`.copy-button-${this._uid}`)
      }
      this.cp.on('success', () => {
        if (this.snackbar.text || this.snackbar.type) {
          this.addSnackbar(this.snackbar)
        } else {
          this.addSnackbar({
            text: this.getTranslate('misc.copied'),
            type: 'success'
          })
        }
      })
    }
  },

  render
}
