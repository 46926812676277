import { SENDING_TYPES, TTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { AUDIO_STATUS, allowedSendingTypesForImage, colors, icons, modes } from '@/utils'

import button from '@/components/button'
import FileManager from '@/components/file/manager'
import FileItemCard from '@/components/file/item/card'
import FileItemPlayer from '@/components/file/item/player'
import sticker from '@/components/misc/sticker'

function renderAttachFileInfo(h) {
  if (
    ~this.attachFileTypes.indexOf(this.attachFileType) &&
    this.proxy.payload[this.attachFileType]
  ) {
    return h(
      FileItemCard,
      {
        props: {
          outline: false,
          value: this.proxy.payload[this.attachFileType],
          maxWidth: 300,
          displayMenu: false,
          flat: true,
          paddingAll: false
        }
      },
      [
        h(
          'g-button',
          {
            props: {
              flat: true,
              icon: 'cancel',
              color: 'error'
            },
            on: {
              click: () => {
                this.cleanAttach()
              }
            }
          }
        )
      ]
    )
  }
}

function renderAttachFileButton(h) {
  if (this.checkPermissions('advanced.storage.upload', [ 'me', 'reseller', true ])) {
    if (this.showAttachButton) {
      return h(
        'g-menu',
        {
          props: {
            placement: 'bottom',
            minWidth: 150,
            rounded: true
          }
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('sendingsCreate.attachMenu.attach'),
                mode: modes.flat,
                icon: icons.attach_file
              },
              slot: 'activator'
            }
          ),

          h(
            'g-list',
            {
              props: {
                dense: true,
                rounded: true
              }
            },
            this.attachFileTypes.map(item => {
              return h(
                'g-list-item',
                {
                  props: {
                    label: this.getTranslate(`sendingsCreate.attachMenu.items.${item}`),
                    dense: true
                  },
                  on: {
                    click: () => {
                      this.attachFileType = item
                      this.showFileManager = true
                    }
                  }
                }
              )
            })
          )
        ]
      )
    }
  }
}

function renderAttachFile(h) {
  if (~allowedSendingTypesForImage.indexOf(this.proxy.type)) {
    return h(
      'div',
      {
        class: 'fc fjcc facc faic ff'
      },
      [
        renderAttachFileButton.call(this, h),
        renderAttachFileInfo.call(this, h)
      ]
    )
  }
}

function renderTTSMenu(h) {
  if (!this.proxy.payload.tts) {
    this.proxy.payload.tts = TTS_TYPES[0]
  }

  return h(
    'g-menu',
    {
      props: {
        maxHeight: 200,
        minWidth: 150,
        overflowY: 'auto',
        placement: 'bottom-end',
        disabled: this.timerCount > 0
      }
    },
    [
      h(
        sticker,
        {
          props: {
            value: this.getTranslate(`sendingsCreate.voice.names.${this.proxy.payload.tts}`),
            label: this.getTranslate(`sendingsCreate.voice.company.${this.proxy.payload.tts.split(':')[0]}`),
            icon: icons.record_voice_over,
            color: colors.grey,
            width: 150,
            disabled: this.timerCount > 0,
            to: { service: undefined }
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true
          }
        },
        TTS_TYPES.map((type, index) => {
          return h(
            'g-list-item',
            {
              props: { dense: true },
              on: {
                click: () => {
                  this.generatedAudioFile = undefined
                  this.proxy.payload.tts = type
                  this.generateAudioFile()
                }
              },
              key: index
            },
            [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  this.getTranslate(`sendingsCreate.voice.names.${type}`),

                  h(
                    'div',
                    {
                      class: 'small text--grey'
                    },
                    this.getTranslate(`sendingsCreate.voice.company.${type.split(':')[0]}`)
                  )
                ]
              )
            ]
          )
        })
      )
    ]
  )
}

function renderUploadButton(h) {
  if (this.proxy.payload.audio) {
    return renderAttachFileInfo.call(this, h)
  }

  return h(
    'g-card-actions',
    {
      class: 'px-0'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('sendingsCreate.voice.upload'),
            color: colors.primary,
            mode: modes.flat,
            icon: icons.file_upload
          },
          on: {
            click: () => {
              this.showFileManager = true
            }
          }
        }
      )
    ]
  )
}

function renderGenerateButtonOrFileItemPlayer(h) {
  if (this.generatedAudioFile) {
    return h(
      FileItemPlayer,
      {
        props: {
          disabled: this.timerCount > 0,
          value: this.generatedAudioFile.data,
          autoplay: this.isGeneratedFile
        }
      }
    )
  }

  return h(
    'div',
    {
      class: 'file__type position-relative'
    },
    [
      h(
        'g-progress',
        {
          style: { position: 'absolute' },
          props: {
            indeterminate: this.isGenerate,
            size: 36
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: icons.play_arrow,
            flat: true,
            outline: true,
            disabled: !this.proxy.payload.text || this.timerCount > 0,
            color: colors.primary
          },
          on: {
            click: () => {
              this.generateAudioFile()
            }
          }
        }
      )
    ]
  )
}

function renderVoiceActions(h) {
  if (this.isGeneratedFile) {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        renderGenerateButtonOrFileItemPlayer.call(this, h),
        renderTTSMenu.call(this, h)
      ]
    )
  }

  return renderUploadButton.call(this, h)
}

function renderVoiceInfo(h) {
  return h(
    'div',
    {
      class: 'text--grey caption mt-2'
    },
    this.isGeneratedFile ? this.getTranslate('sendingsCreate.voice.misc.generatedFile') : this.getTranslate('sendingsCreate.voice.misc.uploadedFile')
  )
}

function renderVoiceInfoSwitch(h) {
  // Это условие необходимо для проверки того есть ли у пользователя тариф с посекундной тарификацией
  // Если нет, у него не должно быть возможности переключиться на режим загрузки собственного файла
  if (this.hasPerSecondTariff) {
    return h(
      'div',
      {
        class: 'text--grey caption'
      },
      [
        this.getTranslate('sendingsCreate.voice.misc.can'),
        h(
          'span',
          {
            class: 'ml-1 link link--dashed text--primary',
            on: {
              click: () => {
                this.setStatus(AUDIO_STATUS.pause)
                this.isGeneratedFile = !this.isGeneratedFile
                if (this.proxy.payload.audio) {
                  delete this.proxy.payload.audio
                }
              }
            }
          },
          this.isGeneratedFile ? this.getTranslate('sendingsCreate.voice.misc.ownFile') : this.getTranslate('sendingsCreate.voice.misc.textFile')
        )
      ]
    )
  }
}

function renderChoiceVoiceSendings(h) {
  if (this.proxy.type === SENDING_TYPES.voice) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderVoiceActions.call(this, h),
        renderVoiceInfo.call(this, h),
        renderVoiceInfoSwitch.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderContentByType(h) {
  if (this.proxy.type === SENDING_TYPES.voice) {
    return renderChoiceVoiceSendings.call(this, h)
  } else {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp,
          'grid-cols--1': this.viewport.breakpoint.smDown
        }
      },
      [
        renderAttachFile.call(this, h),

        this.$slots.default
      ]
    )
  }
}

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        currentChosenFileId: this.proxy.payload.audio || this.proxy.payload.file || this.proxy.payload.image,
        flat: true,
        readonly: true,
        disabled: this.disabled || this.readonly,
        embedded: true,
        show: this.showFileManager,
        types: this.computedFileType,
        isPublic: true
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.setPayload(event)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderContentByType.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
