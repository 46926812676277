import Vue from 'vue'

import { cloneDeep, get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { BILLING_CURRENCY, DOCUMENT_TEMPLATES_TYPES, PAYMENTS_METHODS, RECURRENT_PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_INVOICE_PAYLOAD, DEFAULT_PAYMENT_PAYLOAD, backendServerIP, getUrlToStorage, globalErrorHandler, globalErrorProcessor, hostnames, services } from '@/utils'

import render from './render'

export default {
  name: 'GlobalPaymentDialog',

  data() {
    return {
      step: 'first',

      show: false,

      loading: false,

      payload: {
        legal: cloneDeep(DEFAULT_INVOICE_PAYLOAD),
        individual: cloneDeep(DEFAULT_PAYMENT_PAYLOAD)
      }
    }
  },

  computed: {
    ...mapGetters({
      paymentsMethods: 'payments/methods',
      showPaymentDialog: 'currentTab/showPaymentDialog'
    }),

    validation() {
      let isValidType = false
      if (this.payload.legal.type === DOCUMENT_TEMPLATES_TYPES.contract) {
        isValidType = true
      } else if (this.payload.legal.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
        isValidType = this.payload.legal.amount > 0
      }

      return !!(this.payload.legal.requisiteId && this.payload.legal.companyId && isValidType)
    },

    paymentTypes() {
      const result = [
        {
          name: 'legal',
          priority: 2
        }
      ]
      if (
        Array.isArray(this.$paymentsMethods) &&
        this.$paymentsMethods.length &&
        this.globalSettings.billings.currency === BILLING_CURRENCY.RUB
      ) {
        result.push({
          name: 'individual',
          priority: 1
        })
      }

      return result.sort((a, b) => a.priority - b.priority)
    },

    $paymentsMethods() {
      const result = []
      if (this.paymentsMethods.some(method => ~PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'cards',
          priority: 1
        })
      }
      if (~hostnames.indexOf(window.location.hostname)) {
        result.push({
          name: 'yooMoney',
          priority: 3
        })
      }
      if (this.paymentsMethods.some(method => ~RECURRENT_PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'recurrent',
          priority: 9
        })
      }

      return result.sort((a, b) => a.priority - b.priority)
    }
  },

  mounted() {
    this.payload.individual.OwnerId = this.account.id
  },

  methods: {
    ...mapActions({
      setShowPaymentDialog: 'currentTab/setShowPaymentDialog',
      setShowCreateRecurrentPaymentDialog: 'currentTab/setShowCreateRecurrentPaymentDialog'
    }),

    async requestPayment() {
      const amount = this.payload.individual.amount
      if (amount && this.payload.individual.acceptOffer) {
        try {
          this.loading = true

          const method = this.payload.individual.method

          const { data } = await Vue.$GRequest.create('payments', {
            method,
            amount,
            urlSuccess: `${backendServerIP}/api/payments/webhook/${method}/success/`,
            urlFail: `${backendServerIP}/api/payments/webhook/${method}/fail/`
          })

          if (data && data.url) {
            window.location.href = data.url
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    async requestDocument() {
      try {
        this.loading = true
        delete this.payload.type
        const { data } = await Vue.$GRequest.create(`/n/${services.invoices}`, this.payload.legal, { query: { scopes: [ 'awaitDocument' ] } })
        if (data) {
          window.location.href = getUrlToStorage([ get(data, 'Owner.id', data.OwnerId), data.documentId ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
