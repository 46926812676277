import Vue from 'vue'

import { DICTIONARIES, DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { getLocaleDateString, getLocaleTimeString } from '@/utils'

import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'
import ExpandArrow from '@/components/misc/ExpandArrow'
import OperatorGroup from '@/components/services/operatorGroups/template'
import localSearch from '@/components/services/dictionaries/localSearch'
import InfoButton from '@/components/services/dictionaries/infoButton'
import HLR from '@/components/services/dictionaries/hlr'
import FileManager from '@/components/file/manager'
import SingleLink from '@/components/misc/SingleLink'
import copyIcon from '@/components/copy/icon'
import HeadTitle from '@/components/misc/HeadTitle'

const name = 'dictionaries'

const filters = {
  OwnerId: {
    itemTitle: 'username',
    service: 'users',
    itemsPrepend: []
  },
  type: {
    items: DICTIONARIES,
    multiple: true
  },
  status: {
    items: DICTIONARIES_STATUSES,
    multiple: true
  }
}
const icons = {
  [DICTIONARIES_STATUSES.created]: {
    icon: 'bookmark_border',
    color: 'grey'
  },
  [DICTIONARIES_STATUSES.ready]: {
    icon: 'bookmark',
    color: 'primary'
  },
  [DICTIONARIES_STATUSES.importing]: {
    icon: 'history',
    color: 'grey'
  },
  [DICTIONARIES_STATUSES.compiling]: {
    icon: 'autorenew',
    color: 'grey'
  },
  [DICTIONARIES_STATUSES.compiled]: {
    icon: 'check_circle_outline',
    color: 'primary'
  },
  [DICTIONARIES_STATUSES.importError]: {
    icon: 'highlight_off',
    color: 'error'
  },
  [DICTIONARIES_STATUSES.compileError]: {
    icon: 'highlight_off',
    color: 'error'
  },
  [DICTIONARIES_STATUSES.activated]: {
    icon: 'check_circle',
    color: 'success'
  }
}

function renderFilter(h) {
  if (this.checkPermissions(`advanced.${name}.get`, [ 'reseller', true ])) {
    let scopedSlots

    return h(
      'div',
      {
        class: {
          fr: this.viewport.breakpoint.mdUp,
          fc: this.viewport.breakpoint.smDown
        }
      },
      Object.keys(filters).map(value => {
        switch (value) {
          case 'OwnerId': {
            filters[value].itemsPrepend.push({
              id: '$default',
              username: this.getTranslate(`${name}.filters.items.default`)
            })
            break
          }
          case 'type': {
            filters[value].items = DICTIONARIES.map(dictionary => ({
              value: dictionary,
              title: this.getTranslate(`${name}.titles.${dictionary}`)
            }))
            break
          }
        }

        return h(
          'g-select',
          {
            props: {
              value: this.restData[name].find.filter[value],
              label: this.getTranslate(`${name}.labels.${value}`),
              items: filters[value].items,
              itemsPrepend: filters[value].itemsPrepend,
              mode: 'solo',
              flat: true,
              details: false,
              clearable: true,
              backgroundColor: 'transparent',
              multiple: filters[value].multiple,
              autocomplete: filters[value].autocomplete,
              service: filters[value].service,
              itemTitle: filters[value].itemTitle
            },
            scopedSlots,
            on: {
              input: event => {
                this.restData[name].find.filter[value] = event
              }
            }
          }
        )
      })
    )
  }
}

function renderButtons(h, dictionaryType) {
  if (this.checkPermissions(`advanced.${name}.create`, [ 'reseller', true ]) && this.restData[name].find.filterIsEqualToDefault) {
    return [
      h(InfoButton, { props: { dictionaryType } }),

      h(
        'g-button',
        {
          props: {
            icon: 'get_app',
            flat: true,
            color: 'secondary',
            disabled: dictionaryType === DICTIONARIES['phone:mnp'] ? this.importIsDelayed : this.disableUploadButton(dictionaryType)
          },
          on: {
            click: () => {
              this.processDictionaryDataRequest({
                operation: 'import',
                dictionaryType,
                mix: dictionaryType === DICTIONARIES['phone:ranges'] ? true : undefined
              })
            }
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${name}.tooltips.upload.intis`),
                placement: 'top'
              }
            }
          ]
        }
      ),

      h(
        'g-button',
        {
          props: {
            icon: 'file_upload',
            flat: true,
            color: 'primary',
            disabled: this.disableUploadButton(dictionaryType)
          },
          on: {
            click: () => {
              this.currentDictionaryType = dictionaryType
              switch (dictionaryType) {
                case DICTIONARIES['phone:ranges']:
                  this.showCompileDialog = true
                  break
                default:
                  this.showFileManager = true
                  break
              }
            }
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${name}.tooltips.upload.filemanager`),
                placement: 'top'
              }
            }
          ]
        }
      )
    ]
  }
}

function renderDictionaryHeader(h, dictionaryType) {
  return h(
    'div',
    {
      class: 'faic',
      style: {
        'padding-left': '12px',
        'min-height': '48px'
      }
    },
    [
      h('div', { class: 'text-upper' }, this.getTranslate(`${name}.titles.${dictionaryType}`)),

      h('div', { class: 'ff' }),

      renderButtons.call(this, h, dictionaryType)
    ]
  )
}

function renderStatusIcon(h, { status, selected, OwnerId }) {
  return h(
    'g-icon',
    {
      style: {
        'min-width': !selected ? '48px' : undefined,
        'min-height': !selected ? '48px' : undefined,
        'border-left': OwnerId === null ? '2px solid #1976d2' : undefined
      },
      props: {
        value: icons[status].icon,
        color: icons[status].color
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${name}.statuses.${status}`),
            placement: 'left'
          }
        }
      ]
    }
  )
}

function renderCreatedAtCaption(h, dictionary) {
  const tooltip = [
    this.getTranslate(`${name}.captions.createdAt`),
    getLocaleDateString(dictionary.createdAt),
    this.getTranslate('misc.in'),
    getLocaleTimeString(dictionary.createdAt)
  ]

  return h(
    'div',
    {
      class: 'faic',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: tooltip.join(' '),
            placement: 'right'
          }
        }
      ]
    },
    [
      h(
        'g-icon',
        {
          class: 'mr-1',
          props: {
            value: 'calendar_today',
            color: 'grey',
            size: 10
          }
        }
      ),

      getLocaleDateString(dictionary.createdAt),

      h('span', { class: 'pr-1 pl-1' }, this.getTranslate('misc.in')),

      getLocaleTimeString(dictionary.createdAt)
    ]
  )
}

function renderTotalCaption(h, dictionary) {
  if (dictionary.type !== DICTIONARIES['phone:opgroups']) {
    return h(
      'div',
      {
        class: 'faic pl-2',
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: `${this.getTranslate(`${name}.captions.total`)} ${Vue.$n(this.currentCompiledDictionaryTotal[dictionary.id] || dictionary.total).format('0[,]')}`,
              placement: 'right'
            }
          }
        ]
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-1',
            props: {
              value: 'dehaze',
              color: 'grey',
              size: 11
            }
          }
        ),

        Vue.$n(this.currentCompiledDictionaryTotal[dictionary.id] || dictionary.total).format('0a.[0]')
      ]
    )
  }
}

function renderDescription(h, dictionary) {
  const isOpGroups = dictionary.type === DICTIONARIES['phone:opgroups']

  return h(
    'div',
    {
      class: {
        'fc text-overflow': true,
        'mr-3': this.viewport.breakpoint.mdUp
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pb-1'
        },
        [
          h(
            'span',
            {
              class: {
                'text-overflow': true,
                link: isOpGroups
              },
              on: {
                click: () => {
                  if (isOpGroups) {
                    this.currentOperatorGroupsDictionary = dictionary
                  }
                }
              }
            },
            dictionary.id
          ),
          h(
            copyIcon,
            {
              class: 'hover-child',
              props: {
                value: dictionary.id,
                size: 11,
                snackbar: {
                  text: this.getTranslate('commons.snackbars.copied'),
                  type: 'success'
                },
                tooltip: this.getTranslate('commons.tooltips.copy')
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'faic text--grey small'
        },
        [ renderCreatedAtCaption.call(this, h, dictionary), renderTotalCaption.call(this, h, dictionary) ]
      )
    ]
  )
}

function renderDictionaryLoader(h, dictionary) {
  if (~[ DICTIONARIES_STATUSES.compiling, DICTIONARIES_STATUSES.importing ].indexOf(dictionary.status)) {
    return h(
      'g-progress',
      {
        style: {
          position: 'absolute',
          bottom: 0,
          left: 0
        },
        props: {
          type: 'linear',
          indeterminate: true
        }
      }
    )
  }
}

function renderStatusChangeButtons(h, dictionary) {
  if (this.checkPermissions(`advanced.${name}.update`, [ 'reseller', true ])) {
    switch (dictionary.status) {
      case DICTIONARIES_STATUSES.ready: {
        return h(
          'g-button',
          {
            class: {
              'hover-child': true,
              'mr-0': this.viewport.breakpoint.mdUp,
              'mr-2': this.viewport.breakpoint.smDown
            },
            props: {
              label: this.getTranslate(`${name}.buttons.compile`),
              rounded: true,
              color: 'primary',
              small: true,
              flat: true
            },
            on: {
              click: () => {
                this.processDictionaryDataRequest({
                  operation: 'compile',
                  dictionaryType: dictionary.type,
                  id: dictionary.id,
                  OwnerId: dictionary.OwnerId
                })
              }
            }
          }
        )
      }

      case DICTIONARIES_STATUSES.compiled: {
        return h(
          'g-button',
          {
            class: {
              'hover-child': true,
              'mr-0': this.viewport.breakpoint.mdUp,
              'mr-2': this.viewport.breakpoint.smDown
            },
            props: {
              label: this.getTranslate(`${name}.buttons.activate`),
              rounded: true,
              color: 'primary',
              small: true,
              flat: true
            },
            on: {
              click: () => {
                this.processDictionaryDataRequest({
                  operation: 'set',
                  dictionaryType: dictionary.type,
                  id: dictionary.id,
                  OwnerId: dictionary.OwnerId
                })
              }
            }
          }
        )
      }

      case DICTIONARIES_STATUSES.activated: {
        if (this.checkPermissions(`advanced.${name}.update`) && dictionary.OwnerId) {
          return h(
            'g-button',
            {
              class: {
                'hover-child': true,
                'mr-0': this.viewport.breakpoint.mdUp,
                'mr-2': this.viewport.breakpoint.smDown
              },
              props: {
                label: this.getTranslate(`${name}.buttons.deactivate`),
                rounded: true,
                color: 'primary',
                small: true,
                flat: true
              },
              on: {
                click: () => {
                  this.processDictionaryDataRequest({
                    operation: 'unset',
                    dictionaryType: dictionary.type,
                    id: dictionary.id,
                    OwnerId: dictionary.OwnerId
                  })
                }
              }
            }
          )
        }
      }
    }
  }
}

function renderArrowOrRemoveButtons(h, dictionary, expanded, toggle) {
  if (this.viewport.breakpoint.mdUp && dictionary.type === DICTIONARIES['phone:opgroups']) {
    if (
      this.checkPermissions(`advanced.${name}.remove`, [ 'reseller', true ]) &&
      dictionary.status !== DICTIONARIES_STATUSES.activated
    ) {
      return h(
        ConfirmRemoveButton,
        {
          class: 'hover-child ml-2',
          props: {
            content: this.getTranslate(`${name}.contents.confirm.remove.dictionary`, { title: dictionary.id }),
            stopPropagation: true,
            callback: () => {
              this.removeOperatorGroupsDictionary(dictionary)
            }
          }
        }
      )
    }
  } else {
    return h(
      ExpandArrow,
      {
        props: {
          expanded,
          toggle: this.viewport.breakpoint.mdUp ? toggle : undefined
        }
      }
    )
  }
}

function renderDesktopButtons(h, dictionary) {
  if (this.viewport.breakpoint.mdUp) {
    return renderStatusChangeButtons.call(this, h, dictionary)
  }
}

function renderMobileButtons(h, dictionary) {
  if (this.viewport.breakpoint.smDown) {
    return renderStatusChangeButtons.call(this, h, dictionary)
  }
}

function renderCompileDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showCompileDialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showCompileDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pa-3'
        },
        this.getTranslate(`${name}.titles.mix`)
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe pt-0',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.no'),
                flat: true,
                rounded: true,
                disabled: !this.checkPermissions(`advanced.${name}.update`, [ 'reseller', true ])
              },
              on: {
                click: () => {
                  this.mix = false
                  this.showFileManager = true
                  this.showCompileDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.yes'),
                rounded: true,
                depressed: true,
                disabled: !this.checkPermissions(`advanced.${name}.update`, [ 'reseller', true ]),
                color: 'primary'
              },
              on: {
                click: () => {
                  this.mix = true
                  this.showFileManager = true
                  this.showCompileDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderRemoveDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showRemoveDialog,
        maxWidth: 600
      },
      on: {
        input: event => {
          if (event === false) {
            this.currentRemoveDictionary = {}
          }
          this.showRemoveDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${name}.dialogs.titles.remove`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate(`${name}.contents.confirm.remove.dictionary`, { title: this.currentRemoveDictionary.id })
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showRemoveDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                rounded: true,
                depressed: true,
                color: 'error'
              },
              on: {
                click: () => {
                  this.processDictionaryDataRequest({
                    operation: 'remove',
                    dictionaryType: this.currentRemoveDictionary.type,
                    id: this.currentRemoveDictionary.id,
                    OwnerId: this.currentRemoveDictionary.OwnerId
                  })
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderRemoveButton(h, dictionary) {
  if (this.checkPermissions(`advanced.${name}.remove`, [ 'reseller', true ])) {
    if (dictionary.status !== DICTIONARIES_STATUSES.activated) {
      return h(
        'g-button',
        {
          props: {
            label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.remove') : undefined,
            icon: 'delete',
            flat: true,
            rounded: true,
            color: 'error'
          },
          on: {
            click: () => {
              this.currentRemoveDictionary = dictionary
              this.showRemoveDialog = true
            }
          }
        }
      )
    }
  }
}

function renderExpansionPanels(h, dictionary) {
  switch (dictionary.type) {
    case DICTIONARIES['phone:mnp']:
    case DICTIONARIES['phone:ranges']: {
      return h(
        'div',
        {
          class: 'fc'
        },
        [
          h(localSearch, { props: { dictionary } }),

          h(
            'div',
            {
              class: 'fjcsb faic'
            },
            [
              h(
                SingleLink, {
                  class: 'pl-3 pr-2 body-2',
                  props: { value: dictionary.Owner }
                }
              ),

              h('div', { class: 'ff' }),

              renderMobileButtons.call(this, h, dictionary),
              renderRemoveButton.call(this, h, dictionary)
            ]
          )
        ]
      )
    }
    case DICTIONARIES['phone:opgroups']: {
      if (this.viewport.breakpoint.smDown) {
        return h(
          OperatorGroup,
          {
            props: {
              dictionary,
              removeCallback: () => {
                this.removeOperatorGroupsDictionary(dictionary)
              }
            }
          },
          [ renderMobileButtons.call(this, h, dictionary) ]
        )
      }
    }
  }
}

function renderItem(h, dictionaryType) {
  return this.restData[name].find.data.reduce((result, dictionary) => {
    if (dictionary.type === dictionaryType) {
      result.push(
        h(
          'g-expansion-panel',
          {
            props: { preventClick: this.viewport.breakpoint.mdUp },
            key: dictionary.id,
            scopedSlots: {
              header: ({ expanded, toggle }) => {
                return h(
                  'div',
                  {
                    class: {
                      'pl-0 pr-0 default-expansion-panel-header default-expansion-panel-header--no-click lh-n': true,
                      'hover-parent--opacity': this.viewport.breakpoint.mdUp
                    }
                  },
                  [
                    renderStatusIcon.call(this, h, dictionary),

                    renderDescription.call(this, h, dictionary),

                    h('div', { class: 'ff' }),

                    renderDesktopButtons.call(this, h, dictionary),

                    renderArrowOrRemoveButtons.call(this, h, dictionary, expanded, toggle),

                    renderDictionaryLoader.call(this, h, dictionary)
                  ]
                )
              },
              default: () => {
                return renderExpansionPanels.call(this, h, dictionary)
              }
            }
          }
        )
      )
    }

    return result
  }, [])
}

function renderItems(h, dictionaryType) {
  let defaultSlot = renderItem.call(this, h, dictionaryType)
  if (Array.isArray(defaultSlot) && !defaultSlot.length) {
    defaultSlot = [
      h(
        'g-list-item',
        {
          props: { color: 'grey' }
        },
        this.getTranslate(`${name}.hints.noItems`)
      )
    ]
  }

  return h(
    'g-expansion-panels',
    {
      props: { flat: true },
      key: dictionaryType
    },
    defaultSlot
  )
}

function renderScrollableContainer(h) {
  let style
  if (this.viewport.breakpoint.mdUp) {
    style = {
      overflow: 'hidden auto',
      height: 'calc(100% - 96px)',
      position: 'fixed',
      'min-width': '630px',
      'max-width': '630px'
    }
  }
  if (this.restData[name].find.state === 'ready') {
    return h(
      'div',
      {
        class: {
          fc: true,
          'scrollbar pa-1': this.viewport.breakpoint.mdUp
        },
        style
      },
      [
        h(HLR),

        renderFilter.call(this, h),

        ...DICTIONARIES.map(dictionaryType => {
          return h(
            'div',
            {
              class: 'fc'
            },
            [
              renderDictionaryHeader.call(this, h, dictionaryType),

              h(
                'g-card',
                {
                  props: { outline: true }
                },
                [ renderItems.call(this, h, dictionaryType) ]
              )
            ]
          )
        })
      ]
    )
  } else if (this.restData[name].find.state === 'loading') {
    return h(
      'div',
      {
        class: 'fjcc facc ff pa-5'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

function renderDictionariesContent(h) {
  return h(
    'div',
    {
      class: 'fc',
      style: {
        position: 'relative',
        'min-width': this.viewport.breakpoint.mdUp ? '630px' : '100%'
      }
    },
    [ renderScrollableContainer.call(this, h) ]
  )
}

function renderOperatorGroupContent(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(OperatorGroup, { props: { dictionary: this.currentOperatorGroupsDictionary } })
  }

  return h('div')
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        fr: this.viewport.breakpoint.mdUp,
        fc: this.viewport.breakpoint.smDown
      }
    },
    [
      renderDictionariesContent.call(this, h),
      renderOperatorGroupContent.call(this, h),

      renderCompileDialog.call(this, h),
      renderRemoveDialog.call(this, h),

      h(FileManager, {
        props: {
          show: this.showFileManager,
          flat: true,
          readonly: true,
          embedded: true,
          name: false,
          maxFileSize: 524288000, // 500 mb in binary
          types: [ 'csv', 'xls', 'xlsx' ]
        },
        on: {
          show: event => {
            this.showFileManager = event
          },
          input: event => {
            this.uploadHandler(event.id)
          }
        }
      })
    ]
  )
}
