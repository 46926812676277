import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep } from 'lodash'

import { inputModes, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'OwnerSelect',

  mixins: [ proxy() ],

  props: {
    permission: {
      type: String,
      default: `advanced.${services.users}.find`
    },
    accept: {
      type: Array,
      default: () => permissionPresets.resellerUp
    },

    itemsDisabled: {
      type: Array,
      default: () => []
    },

    label: String,
    hint: String,

    query: {
      type: Object,
      default: () => ({ isActive: true })
    },

    mode: {
      type: String,
      default: inputModes['line-label'],
      validator: value => {
        return Object.values(inputModes).includes(value)
      }
    },

    flat: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    details: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },

    clearItems: {
      type: Function,
      default: v => v
    },

    disabled: Boolean
  },

  computed: {
    computedQuery() {
      const result = cloneDeep(this.query)
      for (const key in result) {
        if (result[key] === undefined || result[key] === null) {
          delete result[key]
        }
      }

      return result
    }
  },

  render
}
