import pkg from '../../../../package.json'

import { get } from 'lodash'

import { colors } from '@/utils'

import Copy from '@/components/misc/Copy'
import overflowString from '@/components/misc/overflowString'

export default function(h) {
  return h(
    'div',
    {
      class: 'flex grid-gap--4 caption text--grey'
    },
    [
      h(
        Copy,
        {
          class: 'link link--passive',
          props: { value: `v${pkg.version}` }
        }
      ),
      h(
        overflowString,
        {
          props: {
            value: [ '©', get(this.globalSettings, 'frontend.title'), new Date().getFullYear() ].join(' '),
            font: {
              size: 12,
              lineHeight: 16
            },
            color: colors.grey
          }
        }
      )
    ]
  )
}
