import Vue from 'vue'
import Clipboard from 'clipboard'

import { Macro } from '@sigma-legacy-libs/cache'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, service, id, params) => await Vue.$GRequest.get(service, id, params))

export default {
  name: 'SingleLink',

  props: {
    value: {
      type: [ String, Object ],
      default: () => undefined
    },

    params: {
      type: Object,
      default: () => ({})
    },

    length: {
      type: Number,
      default: 50
    },

    service: {
      type: String,
      default: 'users'
    },

    pathToId: {
      type: String,
      default: 'id'
    },
    pathToTitle: {
      type: String,
      default: 'title'
    },

    verified: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'link',
      validator: value => !!~[ 'text', 'link', 'button' ].indexOf(value)
    },

    to: {
      type: Object,
      default: () => undefined
    },

    copy: {
      type: Boolean,
      default: false
    },

    flat: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    large: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: 'primary'
    }
  },

  data() {
    return {
      cp: undefined,
      data: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.get()
      },
      deep: true
    }
  },

  mounted() {
    this.get()
    if (this.copy) {
      this.cp = new Clipboard(`.copy-single-link-${this._uid}`)
      this.cp.on('success', () => {
        this.addSnackbar({
          text: this.getTranslate('misc.copied'),
          type: 'success'
        })
      })
    }
  },

  beforeDestroy() {
    if (this.cp && typeof this.cp.destroy === 'function') {
      this.cp.destroy()
    }
  },

  methods: {
    async get() {
      if (typeof this.value === 'string') {
        if (this.value) {
          const { data } = await cachedGet(`${this.service}:${this.value}`, this.service, this.value, this.params)
          if (data) {
            this.data = data
          }
        }
      } else {
        this.data = this.value
      }
    }
  },

  render
}
