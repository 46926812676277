import { colors, modes, services } from '@/utils'

import button from '@/components/button'

function renderButton(h) {
  return h(
    button,
    {
      props: {
        icon: 'file_download',
        mode: modes.flat,
        color: colors.primary,
        disabled: !this.value.documentId,
        tooltip: this.getTranslate(`${services.invoices}.tooltips.download`)
      },
      on: {
        click: () => {
          this.download()
        }
      }
    }
  )
}

function renderListItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate(`${services.invoices}.labels.download`),
        prefix: 'file_download',
        iconColor: colors.primary,
        disabled: !this.value.documentId,
        dense: true
      },
      on: {
        click: () => {
          this.download()
        }
      }
    }
  )
}

export default function(h) {
  if (this.view === 'panel') {
    if (this.viewport.breakpoint.lgUp) {
      return renderButton.call(this, h)
    } else {
      return renderListItem.call(this, h)
    }
  }

  return renderButton.call(this, h)
}
