import { EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

export default function(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}-filter`
    },
    [
      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.userId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.userId = event
            }
          }
        }
      ),
      h(
        'g-select',
        {
          props: {
            value: this.proxy.service,
            label: this.getTranslate(`${services.eventsHistory}.labels.service`),
            items: this.services.map(service =>
              ({
                title: this.getTranslate(`${service.title}.title`),
                value: service.value
              })),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.service = event
            }
          }
        }
      ),
      h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.eventsHistory}.labels.type`),
            items: EVENTSHISTORY_TYPES.map(type => ({
              title: this.getTranslate(`${services.eventsHistory}.types.${type}`),
              value: type
            })),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      )
    ]
  )
}
