import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { getUrlToStorage, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'FileMenu',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    readonly: Boolean,

    include: {
      type: Array,
      default: () => [ 'edit', 'update', 'download', 'export', 'remove' ]
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    editable() {
      return (
        this.checkPermissions([ `advanced.${services.storage}.update`, `advanced.${services.storage}.remove` ], permissionPresets.meUp) &&
        !this.readonly
      )
    }
  },

  methods: {
    async editFile() {
      this.loading = true

      try {
        const { data } = await Vue.$GRequest.get(`/${services.storage}/edit`, this.value.id, {
          query: {
            OwnerId: this.value.OwnerId || this.value.Owner && this.value.Owner.id
          }
        })
        if (data) {
          this.$emit('dialog', {
            googleFileURL: data.result,
            currentEditFile: cloneDeep(this.value),
            editDialogType: 'edit'
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    removeFile() {
      this.$emit('dialog', {
        currentEditFile: cloneDeep(this.value),
        editDialogType: 'remove'
      })
    },

    async exportFile() {
      this.$emit('dialog', {
        currentEditFile: cloneDeep(this.value),
        editDialogType: 'export'
      })

      try {
        const { data } = await Vue.$GRequest.get('storage/export', this.value.id)
        window.location.href = getUrlToStorage([ data.OwnerId, data.id ])
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }

      this.$emit('dialog', { close: true })
    },

    downloadFile() {
      window.location.href = getUrlToStorage([ this.value.OwnerId || this.value.Owner && this.value.Owner.id, this.value.id ])
    },

    editMetaFile() {
      this.$emit('dialog', {
        currentEditFile: cloneDeep(this.value),
        editDialogType: 'editMeta'
      })
    }
  },

  render
}
