import { colors, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'

function renderIcon(h) {
  return h(
    squircle,
    {
      props: {
        icon: this.icon,
        iconSVG: this.iconSVG,
        iconSize: 30,
        size: sizes.medium,
        color: this.color || colors.white
      }
    }
  )
}

function renderDialog() {
  if (this.$scopedSlots.dialog) {
    return this.$scopedSlots.dialog()
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'button-toolbar',
      directives: this.$directives,
      on: this.$eventHandlers
    },
    [
      renderIcon.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
