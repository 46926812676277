import FileItemCard from '@/components/file/item/card'

function renderItem(h, item) {
  return h(
    FileItemCard,
    {
      props: {
        value: item,
        readonly: this.readonly,
        currentChosenFileId: this.currentChosenFileId
      },
      on: {
        input: event => {
          this.$emit('input', event)
        },
        dialog: event => {
          this.$emit('dialog', event)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$_class
    },
    [ this.items.map(item => renderItem.call(this, h, item)) ]
  )
}
