import Vue from 'vue'

import { get } from 'lodash'

import { services } from '@/utils'

export default {
  namespaced: true,

  state: {
    methods: [],

    showPaymentDialog: false,
    showCreateRecurrentPaymentDialog: false
  },

  mutations: {
    setPaymentsMethods(state, value) {
      state.methods = value
    },

    setShowPaymentDialog(state, value) {
      state.showPaymentDialog = value
    },
    setShowCreateRecurrentPaymentDialog(state, value) {
      state.showCreateRecurrentPaymentDialog = value
    }
  },

  actions: {
    async getPaymentsMethods({ commit, rootState }) {
      const UserId = get(rootState.account, 'account.id')
      if (UserId) {
        const { data } = await Vue.$GRequest.get(services.payments, 'methods', { query: { UserId } })
        if (data) {
          commit('setPaymentsMethods', data)

          return data
        }
      }
    },

    setPaymentsMethods({ commit }, value) {
      commit('setPaymentsMethods', value)
    },

    setShowPaymentDialog({ commit }, value) {
      commit('setShowPaymentDialog', value)
    },
    setShowCreateRecurrentPaymentDialog({ commit }, value) {
      commit('setShowCreateRecurrentPaymentDialog', value)
    }
  },

  getters: {
    methods: state => state.methods,

    showPaymentDialog: state => state.showPaymentDialog,
    showCreateRecurrentPaymentDialog: state => state.showCreateRecurrentPaymentDialog
  }
}
