import Vue from 'vue'

import { get } from 'lodash'

import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, inputModes, modes, permissionPresets, positiveNumberTwoSymbolsAfterDotRegex, services, sizes, states } from '@/utils'

import { opfListShort } from '@/components/services/requisites/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import info from '@/components/misc/info'
import OwnerSelect from '@/components/misc/OwnerSelect'

function renderOwner(h) {
  if (!this.OwnerId) {
    if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
      return h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.customerId,
            details: false,
            mode: inputModes['line-label'],
            disabled: this.disabled,
            combobox: true,
            clearable: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.customerId = event
            }
          }
        }
      )
    }
  }
}

function renderRequisite(h) {
  if (this.restData.requisites.find.state === states.ready && this.restData.requisites.find.pagination.total === 0) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        h(
          info,
          {
            props: {
              value: this.proxy.customerId === this.account.id
                ? this.getTranslate(`${services.invoices}.warnings.notFound.requisites`)
                : this.getTranslate(`${services.invoices}.warnings.notFound.requisitesForAdmin`),
              color: colors.error
            }
          }
        ),
        h(
          button,
          {
            props: {
              label: this.getTranslate(`${services.invoices}.labels.requisites`),
              mode: modes.flat,
              color: colors.primary
            },
            on: {
              click: () => {
                Vue.router.push({
                  name: 'account',
                  query: { currentTab: 'requisites' }
                })
                this.$emit('show', false)
              }
            }
          }
        )
      ]
    )
  }

  return h(
    'g-select',
    {
      props: {
        value: this.proxy.requisiteId,
        label: this.getTranslate(`${services.invoices}.labels.requisite`),
        items: this.restData.requisites.find.data,
        itemValue: 'id',
        picks: [ 'id', 'data' ],
        mode: inputModes['line-label'],
        loading: this.restData.requisites.find.state === states.loading,
        disabled: this.restData.requisites.find.state === states.loading || this.disabled,
        details: false,
        rounded: true,
        required: true
      },
      scopedSlots: {
        selection: ({ item }) => {
          const opf = get(item, 'data.legal.opf')
          const name = get(item, 'data.legal.name')

          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: 'repeat(2, auto)' }
            },
            [
              h(
                tag,
                {
                  props: {
                    label: [ opfListShort[opf], name ].filter(Boolean).join(' '),
                    size: sizes.small,
                    as: 'select'
                  }
                }
              ),
              h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`requisites.vat.${this.requisite.vat}`),
                    size: sizes.small,
                    color: colors.primary
                  }
                }
              )
            ]
          )
        },
        item: ({ item }) => {
          const opf = get(item, 'data.legal.opf')
          const name = get(item, 'data.legal.name')

          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: '1fr auto' }
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-gap--0'
                },
                [
                  h('div', { class: 'text-overflow' }, name),
                  h('div', { class: 'text-overflow fz-11 text--grey' }, opf)
                ]
              ),
              h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`requisites.vat.${item.data.tax.vat}`),
                    size: sizes.small,
                    color: this.proxy.requisiteId === item.id ? colors.primary : colors.grey
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.requisiteId = event
        }
      }
    }
  )
}

function renderCompany(h) {
  if (this.proxy.requisiteId) {
    if (this.restData.companies.find.state === states.ready && this.restData.companies.find.pagination.total === 0) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            info,
            {
              props: {
                value: this.getTranslate(`${services.invoices}.warnings.notFound.companies`),
                color: colors.error
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.support'),
                mode: modes.flat,
                color: colors.primary
              },
              on: {
                click: () => {
                  Vue.router.push({ name: 'support' })
                  this.$emit('show', false)
                }
              }
            }
          )
        ]
      )
    }

    return h(
      'g-select',
      {
        props: {
          value: this.proxy.companyId,
          label: this.getTranslate(`${services.invoices}.labels.company`),
          items: this.restData.companies.find.data.map(item => {
            return {
              title: item.title,
              value: item.id
            }
          }),
          mode: inputModes['line-label'],
          loading: this.restData.companies.find.state === states.loading,
          disabled: this.restData.companies.find.state === states.loading || this.disabled,
          details: false,
          rounded: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.companyId = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            return h(
              tag,
              {
                props: {
                  label: item.title,
                  size: sizes.small,
                  as: 'select'
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderTypes(h) {
  if (this.proxy.companyId && this.allowedTypes.length !== 1) {
    if (this.$hasTemplate) {
      return h(
        'g-select',
        {
          props: {
            value: this.proxy.type,
            items: this.$types.map(item => {
              return {
                title: this.getTranslate(`${services.invoices}.labels.${item}`),
                value: item
              }
            }),
            label: this.getTranslate(`${services.invoices}.labels.type`),
            mode: inputModes['line-label'],
            disabled: this.disabled,
            rounded: true,
            details: false,
            required: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                tag,
                {
                  props: {
                    label: item.title,
                    size: sizes.small,
                    as: 'select'
                  }
                }
              )
            }
          }
        }
      )
    } else {
      return h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.invoices}.warnings.notFound.template`),
            color: colors.error
          }
        }
      )
    }
  }
}

function renderAmount(h) {
  if (this.proxy.customerId && this.proxy.requisiteId && this.proxy.companyId) {
    if (this.proxy.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
      if (this.$hasTemplate) {
        return h(
          'g-text-field',
          {
            props: {
              value: this.proxy.amount,
              label: this.getTranslate(`${services.invoices}.labels.amount`),
              suffix: this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`),
              error: this.errors.amount,
              mode: inputModes['line-label'],
              disabled: this.disabled,
              autofocus: true,
              rounded: true,
              clearable: true,
              required: true,
              details: false,
              mask: positiveNumberTwoSymbolsAfterDotRegex,
              processValue: value => value?.toString().replace(',', '.')
            },
            on: {
              input: event => {
                this.proxy.amount = event
              }
            }
          }
        )
      }
    }
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '100%' }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--16 pa-2'
        },
        [
          renderOwner.call(this, h),
          renderRequisite.call(this, h),
          renderCompany.call(this, h),
          renderTypes.call(this, h),
          renderAmount.call(this, h)
        ]
      )
    ]
  )
}
