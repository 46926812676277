import buttonToolbar from '@/components/button/toolbar'

export default function(h) {
  return h(
    buttonToolbar,
    {
      props: {
        iconSVG: 'question',
        tooltip: this.getTranslate('misc.buttons.support')
      },
      on: {
        click: () => {
          this.openSupport()
        }
      }
    }
  )
}
