import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { moderationColors, moderationIcons, modes } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    'div',
    {
      class: 'fw grid-gap--8'
    },
    MODERATION_VALUES.map(moderation => {
      return h(
        button,
        {
          props: {
            label: this.getTranslate(`misc.moderation.${moderation}`),
            icon: moderationIcons[moderation],
            mode: this.proxy !== moderation ? modes.flat : modes.filled,
            color: moderationColors[moderation]
          },
          on: {
            click: () => {
              this.proxy = moderation
            }
          }
        }
      )
    })
  )
}

