import { colors, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'

export default function(h) {
  return h(
    'div',
    {
      class: 'button-toolbar',
      directives: this.$directives,
      on: { click: event => this.$emit('click', event) }
    },
    [
      h(
        squircle,
        {
          props: {
            color: colors.white,
            size: sizes.medium
          }
        },
        [
          h(
            svgIcon,
            {
              props: {
                value: this.icon,
                size: this.size,
                color: this.color
              }
            }
          )
        ]
      )
    ]
  )
}
