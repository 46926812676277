import { permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import RefreshButton from '@/components/button/refresh'
import pagination from '@/components/misc/pagination'
import UsersRequisitesItem from '@/components/services/users/requisites/item'
import UsersRequisitesDialogs from '@/components/services/users/requisites/dialogs'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderCreateButton(h, trigger = true) {
  if (this.checkPermissions(`advanced.${services.requisites}.create`, permissionPresets.meUp)) {
    if (trigger) {
      return h(
        button,
        {
          props: {
            label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.requisites}.buttons.add`),
            icon: 'add',
            color: 'secondary'
          },
          on: {
            click: () => {
              this.dialogType = 'create'
              this.showDialog = true
            }
          }
        }
      )
    }
  }

  return h('div')
}
function renderRefreshButton(h, trigger) {
  if (trigger) {
    return h(
      RefreshButton,
      {
        props: {
          state: this.restData[services.requisites].find.state,
          shortClickMethod: () => this.rest[services.requisites].find(),
          longClickMethod: () => this.rest[services.requisites].find({}, { noCache: true })
        }
      }
    )
  }
}
function renderPagination(h, place) {
  let trigger = false
  switch (place) {
    case 'top':
      trigger = this.restData[services.requisites].find.pagination.total > 0
      break
    case 'bottom':
      trigger = this.restData[services.requisites].find.pagination.total >= 10
      break
  }

  if (trigger) {
    return h(
      'div',
      {
        class: 'faic grid-gap--8 pa-2'
      },
      [
        renderCreateButton.call(this, h, trigger),

        h('div', { class: 'ff' }),

        h(
          pagination,
          {
            props: {
              value: this.restData[services.requisites].find.pagination,
              show: this.restData[services.requisites].find.pagination.total >= this.restData[services.requisites].find.pagination.limit,
              service: services.requisites
            },
            on: {
              input: event => {
                this.restData[services.requisites].find.pagination = event
              }
            }
          }
        ),

        renderRefreshButton.call(this, h, trigger)
      ]
    )
  }
}

function renderItems(h) {
  return this.restData[services.requisites].find.data.map(item => {
    return h(
      UsersRequisitesItem,
      {
        props: {
          value: item
        },
        key: item.id,
        on: {
          showDialog: event => {
            this.requisiteId = event
            this.dialogType = 'update'
            this.showDialog = true
          },
          remove: event => {
            this.requisiteId = event
            this.dialogType = 'remove'
            this.showDialog = true
          }
        }
      }
    )
  })
}
function renderList(h) {
  return h(
    'div',
    {
      class: 'gird grid-gap--0'
    },
    [
      renderPagination.call(this, h, 'top'),
      h('g-divider'),
      renderItems.call(this, h),
      h('g-divider'),
      renderPagination.call(this, h, 'bottom')
    ]
  )
}
function renderEmptyState(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic fjcc fjic'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'bookmark_border',
            size: 48,
            color: 'grey'
          }
        }
      ),

      h(
        'span',
        {
          class: 'headline text-center'
        },
        this.getTranslate(`${services.requisites}.titles.empty.title`)
      ),

      h(
        'span',
        {
          class: 'subheading text-center text--grey'
        },
        this.getTranslate(`${services.requisites}.titles.empty.sub`)
      ),

      h(
        'g-icon',
        {
          props: {
            value: 'keyboard_arrow_down',
            size: 48,
            color: 'grey'
          }
        }
      ),

      renderCreateButton.call(this, h)
    ]
  )
}
function renderContent(h) {
  if (this.restData[services.requisites].find.state === states.loading) {
    return renderPreloader.call(this, h)
  } else if (this.restData[services.requisites].find.pagination.total > 0) {
    return renderList.call(this, h)
  } else {
    return renderEmptyState.call(this, h)
  }
}

function renderDialogs(h) {
  return h(
    UsersRequisitesDialogs,
    {
      props: {
        id: this.requisiteId,
        type: this.dialogType,
        show: this.showDialog,
        OwnerId: this.OwnerId,
        User: this.User
      },
      on: {
        show: event => {
          if (event === false) {
            this.requisiteId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid pa-2'
    },
    [
      renderContent.call(this, h),
      renderDialogs.call(this, h)
    ]
  )
}
