import { colors, services } from '@/utils'

import moderationChip from '@/components/moderation/chip'
import ExpandArrow from '@/components/misc/ExpandArrow'
import sendingsCreateForm from '@/components/services/sendingsCreate/form'
import tag from '@/components/tag'

const className = 'replacement'

function renderStepDescription(h, index) {
  let translatePath = 'replace'
  if (index === 0) {
    translatePath = 'original'
  } else if (index === this.value.length - 1) {
    translatePath = 'final'
  }

  return h(
    'div',
    {
      class: `${className}-stepper__step-description`
    },
    this.getTranslate(`replacement.hints.replacement.${translatePath}`)
  )
}

function renderFallbacks(h, fallbacks) {
  if (fallbacks.length) {
    return h(
      'g-expansion-panels',
      {
        class: 'mt-2',
        props: {
          outline: true,
          rounded: true
        }
      },
      fallbacks.map(fallback => {
        return h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  { class: 'default-expansion-panel-header pr-0' },
                  [
                    h('div', {}, this.getTranslate(`${className}.labels.fallback`)),
                    h('div', { class: 'ff' }),
                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              },
              default: () => {
                return h(
                  'div',
                  { class: 'grid grid-gap--8 pa-2' },
                  [
                    h(
                      sendingsCreateForm,
                      {
                        props: {
                          value: fallback,
                          fallback: true,
                          disabled: true,
                          readonly: true
                        }
                      }
                    )
                  ]
                )
              }
            }
          }
        )
      })
    )
  } else {
    return h('div', {}, [ this.getTranslate('replacement.hints.fallbacks') ])
  }
}

function renderStepContentByType(h, value) {
  switch (this.type) {
    case 'type': {
      return h(
        tag,
        {
          props: {
            label: this.getTranslate(`${services.sendings}.types.${value}`),
            color: colors[value]
          }
        }
      )
    }

    case 'state.moderation': {
      return h(
        moderationChip,
        {
          props: {
            value
          }
        }
      )
    }

    case 'fallbacks': return renderFallbacks.call(this, h, value)
    default: return h('pre', {}, value)
  }
}

function renderStepContent(h, item) {
  return h(
    'div',
    {
      class: `${className}-stepper__step-content`
    },
    [ renderStepContentByType.call(this, h, item) ]
  )
}

function renderStep(h, item, index) {
  if (item) {
    return h(
      'div',
      {
        class: {
          [`${className}-stepper__step`]: true,
          [`${className}-stepper__step--first`]: index === 0,
          [`${className}-stepper__step--last`]: index === this.value.length - 1
        }
      },
      [
        renderStepDescription.call(this, h, index),
        renderStepContent.call(this, h, item)
      ]
    )
  }
}

function renderSteps(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return h(
      'div',
      {
        class: `${className}-stepper__steps`
      },
      this.value.map((item, index) => renderStep.call(this, h, item, index))
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${className}-stepper`
    },
    [ renderSteps.call(this, h) ]
  )
}
