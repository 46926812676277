import { colors, icons, services } from '@/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'
import buttons from '@/components/services/providers/item/buttons'
import state from '@/components/services/providers/state'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.providers}.provider`),
        service: services.providers,
        copy: true
      },
      scopedSlots: {
        icon: () => {
          return h(
            state,
            {
              props: { id: this.value.id }
            }
          )
        }
      }
    }
  )
}
function renderServer(h) {
  if (this.viewport.size.width > 600) {
    if (this.value.Server || this.value.ServerId) {
      return h(
        sticker,
        {
          props: {
            value: this.value.Server || this.value.ServerId,
            icon: icons.vpn_lock,
            color: colors.grey,
            label: this.getTranslate(`${services.providers}.labels.server`),
            service: services.servers,
            copy: true
          }
        }
      )
    }
  }
}
function renderCredentials(h) {
  if (this.viewport.size.width > 806) {
    if (this.value.Credentials || this.value.CredentialsId) {
      return h(
        sticker,
        {
          props: {
            value: this.value.Credentials || this.value.CredentialsId,
            icon: icons.vpn_key,
            color: colors.grey,
            label: this.getTranslate(`${services.providers}.labels.credentials`),
            service: services.credentials,
            copy: true
          }
        }
      )
    }
  }
}
function renderComment(h) {
  const comment = this.value.comment
  if (comment && this.viewport.breakpoint.xl) {
    return h(
      sticker,
      {
        props: {
          icon: icons.comment,
          color: colors.grey,
          value: comment,
          label: this.getTranslate(`${services.providers}.labels.comment`),
          copy: true
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.providers}-item__info`]: true,
        [`${services.providers}-item__info--comment`]: !!this.value.comment && this.viewport.breakpoint.xl
      }
    },
    [
      renderTitle.call(this, h),
      renderServer.call(this, h),
      renderCredentials.call(this, h),
      renderComment.call(this, h)
    ]
  )
}

function renderButtons(h) {
  return h(
    buttons,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderUpdatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value,
          pathToDate: 'updatedAt'
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.providers}-panel service-template-panel hover-parent hover-parent--opacity`,
      key: this.value.id
    },
    [
      renderInfo.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h),
      renderUpdatedAt.call(this, h)
    ]
  )
}
