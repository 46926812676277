import { get } from 'lodash'

import { billingsSources, colors, inputModes, modes, services } from '@/utils'

import generatorRow from '@/components/generator/row'
import info from '@/components/misc/info'
import button from '@/components/button'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderLimitInfo(h, { path, serviceName }) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceName}.hints.${path}.limits`),
        color: colors.primary
      }
    }
  )
}

function renderGeneratorRow(h, { path, serviceName, payload }) {
  return h(
    generatorRow,
    {
      props: {
        value: this.proxy,
        defaultValue: {
          source: undefined,
          percent: 0,
          limitMin: 0,
          limitMax: 0
        },
        addDisabled: this.proxy.map(item => item.source).length >= Object.values(billingsSources).length
      },
      scopedSlots: {
        row: ({ item }) => {
          return h(
            'div',
            {
              class: 'grid border border--dashed border--rounded pa-3'
            },
            [
              h(
                'div',
                {
                  class: {
                    grid: true,
                    'grid-cols--2': this.viewport.breakpoint.mdUp
                  }
                },
                [
                  h(
                    'g-select',
                    {
                      props: {
                        value: item.source,
                        label: this.getTranslate(`${serviceName}.labels.${path}.source`),
                        items: Object.values(billingsSources).map(value => {
                          return {
                            title: this.getTranslate(`${services.billings}.sources.${value}`),
                            value
                          }
                        }),
                        itemsDisabled: this.proxy.map(item => item.source),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        required: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          item.source = event
                        }
                      },
                      scopedSlots: {
                        selection: ({ item }) => {
                          return h(
                            RowWithMessageTypeIcon,
                            {
                              props: {
                                value: item.value,
                                title: item.title,
                                dense: this.dense
                              }
                            }
                          )
                        },
                        item: ({ item }) => {
                          return h(
                            RowWithMessageTypeIcon,
                            {
                              props: {
                                value: item.value,
                                title: item.title,
                                as: 'text'
                              }
                            }
                          )
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: item.percent,
                        label: this.getTranslate(`${serviceName}.labels.${path}.percent`),
                        suffix: '%',
                        type: 'number',
                        min: 0,
                        step: 'any',
                        mode: inputModes['line-label'],
                        rounded: true,
                        details: false,
                        dense: true
                      },
                      on: {
                        input: event => {
                          item.percent = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                'div',
                {
                  class: {
                    grid: true,
                    'grid-cols--2': this.viewport.breakpoint.mdUp
                  }
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: item.limitMin,
                        label: this.getTranslate(`${serviceName}.labels.${path}.limitMin`),
                        suffix: this.getTranslate(`currency.${get(payload.settings, 'billings.currency')}.symbol.unicode`),
                        type: 'number',
                        min: 0,
                        step: 'any',
                        mode: inputModes['line-label'],
                        rounded: true,
                        details: false,
                        dense: true
                      },
                      on: {
                        input: event => {
                          item.limitMin = event
                        }
                      }
                    }
                  ),
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: item.limitMax,
                        label: this.getTranslate(`${serviceName}.labels.${path}.limitMax`),
                        suffix: this.getTranslate(`currency.${get(payload.settings, 'billings.currency')}.symbol.unicode`),
                        type: 'number',
                        min: 0,
                        step: 'any',
                        mode: inputModes['line-label'],
                        rounded: true,
                        details: false,
                        dense: true
                      },
                      on: {
                        input: event => {
                          item.limitMax = event
                        }
                      }
                    }
                  )
                ]
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderConfirmButton(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            mode: modes.flat,
            color: colors.primary,
            loading: this.loading.update,
            disabled: this.loading.update || this.disabled
          },
          on: {
            click: () => {
              this.save()
            }
          }
        }
      )
    ]
  )
}

export default function(h, options) {
  return h(
    'g-card',
    {
      class: 'grid px-2 pb-2 pt-3',
      props: {
        title: this.getTranslate(`${options.serviceName}.subtitles.${options.path}`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderLimitInfo.call(this, h, options),
      renderGeneratorRow.call(this, h, options),
      renderConfirmButton.call(this, h)
    ]
  )
}
