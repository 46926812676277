import { eventHandlers } from '../../../utils/mixins'

import { colors, components, numberToPxOrString } from '@/utils'

import render from './render'

export default {
  name: 'OverflowString',

  mixins: [ eventHandlers ],

  props: {
    value: {
      type: [ Number, String, Boolean ],
      default: undefined
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    tooltip: {
      type: String,
      default: undefined
    },

    font: {
      type: Object,
      default: undefined
    },

    showTooltip: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      overflow: false,
      directives: undefined
    }
  },

  computed: {
    $class() {
      return {
        ['overflow-string']: true,
        [`overflow-string--${this.color}`]: !!this.color
      }
    },

    $font() {
      const result = {
        size: 11,
        weight: 400
      }

      if (this.font) {
        result.size = numberToPxOrString(this.font.size || result.size)
        result.weight = this.font.weight || result.weight
        result.lineHeight = numberToPxOrString(this.font.lineHeight || result.size + 2)
      }

      return result
    }
  },

  watch: {
    overflow() {
      this.setProperties()
      this.setTooltip()
    }
  },

  mounted() {
    this.checkOverflow()
    this.setProperties()
    this.setTooltip()
  },

  updated() {
    this.checkOverflow()
  },

  beforeDestroy() {
    this.directives = undefined
  },

  methods: {
    checkOverflow() {
      if (this.$el) {
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            window.requestAnimationFrame(() => {
              if (entry.target.clientHeight > 0) {
                this.overflow = true
              } else {
                this.overflow = false
              }
            })
          }
        })
        resizeObserver.observe(this.$el)
      }
    },

    setProperties() {
      if (this.$el) {
        this.$el.style.fontSize = this.$font.size
        this.$el.style.fontWeight = this.$font.weight
        this.$el.style.lineHeight = this.$font.lineHeight
      }
    },

    setTooltip() {
      if (this.showTooltip) {
        if (parseInt(this.$el.offsetWidth) < parseInt(this.$el.scrollWidth)) {
          this.directives = [
            {
              name: components.tooltip,
              options: { value: this.tooltip || this.value }
            }
          ]
        }
      }
    }
  },

  render
}
