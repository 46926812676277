import { components, states } from '@/utils'
import { serviceName } from '@/components/services/sendings/incoming/utils'
import { renderReleaseBucketButton } from '@/components/misc'

import RefreshButton from '@/components/button/refresh'
import ButtonReport from '@/components/button/report'
import filter from '@/components/services/sendings/incoming/filter'
import panel from '@/components/services/sendings/incoming/item/panel'
import pagination from '@/components/misc/pagination'
import sourceBox from '@/components/source/box'

function renderReportButton(h) {
  return h(
    ButtonReport,
    {
      class: 'ma-0',
      props: {
        preset: `${serviceName}.details`,
        filter: this.restData[serviceName].find.filter
      }
    }
  )
}
function renderRefreshButton(h) {
  return h(
    RefreshButton,
    {
      props: {
        disabled: this.downloadable,
        label: this.getTranslate('sendings.buttons.refresh'),
        tooltip: this.getTranslate('sendings.tooltips.refresh'),
        state: this.restData[serviceName].find.state,
        shortClickMethod: () => this.rest[serviceName].find(),
        longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
      }
    }
  )
}
function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        fw: this.viewport.breakpoint.smDown
      }
    },
    [
      renderReportButton.call(this, h),
      h('div', { class: 'ff' }),
      renderReleaseBucketButton.call(this, h, { name: serviceName }),
      renderRefreshButton.call(this, h)
    ]
  )
}
function renderFilter(h) {
  return h(
    filter,
    {
      props: {
        preset: `${serviceName}.details`,
        value: this.restData[serviceName].find.filter
      },
      on: {
        input: event => {
          this.restData[serviceName].find.filter = event
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      renderFilter.call(this, h)
    ]
  )
}

function renderItem(h, item) {
  return h(
    panel,
    {
      props: { value: item },
      on: { source: this.sourceDataProcess },
      key: item.id
    }
  )
}

function renderItems(h) {
  if (this.restData[serviceName].find.pagination.total > 0) {
    return this.restData[serviceName].find.data.map(item => {
      return renderItem.call(this, h, item)
    })
  }

  return h('g-empty')
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    ]
  )
}

function renderContentByState(h) {
  if (this.restData[serviceName].find.state === states.ready) {
    return renderItems.call(this, h)
  } else if (this.restData[serviceName].find.state === states.loading) {
    return renderPreloader.call(this, h)
  }

  return h('g-empty')
}

function renderContent(h) {
  return h(
    'div',
    {
      class: `${serviceName}__content`
    },
    [ renderContentByState.call(this, h) ]
  )
}

function renderDivider(h) {
  if (Array.isArray(this.restData[serviceName].find.data) && this.restData[serviceName].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[serviceName].find.pagination,
            service: serviceName
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderSourceDataDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showSourceDataDialog,
        rounded: this.viewport.breakpoint.mdUp,
        close: true,
        maxWidth: 1000
      },
      on: {
        input: event => {
          if (event === false) {
            this.sourceData = undefined
          }
          this.showSourceDataDialog = event
        }
      }
    },
    [
      h(
        sourceBox,
        {
          props: { value: this.sourceData },
          on: {
            close: event => {
              this.showSourceDataDialog = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showSourceDataDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderContent.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderSourceDataDialog.call(this, h)
    ]
  )
}
