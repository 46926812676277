export default {
  title: 'Tokens',

  titles: {
    create: 'Create token',
    update: 'Edit title token',
    remove: 'Remove token',
    copy: 'Copy token'
  },

  labels: {
    token: 'Token',
    description: 'Title',
    search: 'Search',
    owner: 'Owner',
    noData: {
      description: 'No title',
      expiredAt: 'No date',
      createAt: 'No date'
    },
    expiredAt: 'Expired at'
  },

  hints: {
    notFound: 'Tokens not found',
    description: 'Maximum 250 characters'
  },

  tooltips: {
    favorites: 'Make the token a favorite'
  },

  buttons: {
    create: 'Create new token',
    add: 'Add token'
  },

  info: {
    token: 'Please copy and save the token. After closing this window, access to the token will no longer be available.'
  },

  snackbars: {
    created: 'Token successfully created.',
    removed: 'Token successfully removed.',
    copy: 'Token copied to clipboard.'
  }
}
