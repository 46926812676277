import { GENDERS } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, phoneRegexSoft, services, sizes } from '@/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import ExpandArrow from '@/components/misc/ExpandArrow'

function renderFieldPhone(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.phone,
        label: this.getTranslate(`${services.contacts}.labels.phone`),
        error: this.errors.phone,
        required: this.required.phone,
        mode: inputModes['line-label'],
        rounded: true,
        clearable: true,
        trim: true,
        autofocus: !this.isFilter,
        dense: true,
        details: !this.isFilter,
        maxlength: 25,
        mask: phoneRegexSoft
      },
      on: {
        input: event => {
          this.proxy.phone = event
        }
      }
    }
  )
}

function renderFieldEmail(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.email,
        label: this.getTranslate(`${services.contacts}.labels.email`),
        error: this.errors.email,
        required: this.required.email,
        mode: inputModes['line-label'],
        rounded: true,
        clearable: true,
        trim: true,
        dense: true,
        details: !this.isFilter
      },
      on: {
        input: event => {
          this.proxy.email = event
        }
      }
    }
  )
}

function renderFieldLastName(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.lastName,
        label: this.getTranslate(`${services.contacts}.labels.lastName`),
        mode: inputModes['line-label'],
        rounded: true,
        clearable: true,
        trim: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.lastName = event
        }
      }
    }
  )
}
function renderFieldFirstName(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.firstName,
        label: this.getTranslate(`${services.contacts}.labels.firstName`),
        mode: inputModes['line-label'],
        rounded: true,
        clearable: true,
        trim: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.firstName = event
        }
      }
    }
  )
}
function renderFieldMiddleName(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.middleName,
        label: this.getTranslate(`${services.contacts}.labels.middleName`),
        mode: inputModes['line-label'],
        rounded: true,
        clearable: true,
        trim: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.middleName = event
        }
      }
    }
  )
}

function renderFieldGender(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.gender || undefined,
        label: this.getTranslate(`${services.contacts}.labels.gender`),
        items: GENDERS.map(gender => ({
          title: this.getTranslate(`misc.genders.${gender}`),
          value: gender
        })),
        mode: inputModes['line-label'],
        rounded: true,
        clearable: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.gender = event
        }
      }
    }
  )
}
function renderFieldDate(h) {
  return h(
    components.menu,
    {
      class: 'w--100',
      props: {
        value: this.show,
        closeOnContentClick: false,
        rounded: true,
        maxWidth: 282
      },
      on: {
        show: event => {
          this.show = event
        }
      }
    },
    [
      h(
        sticker,
        {
          props: {
            value: this.computedDate,
            label: this.getTranslate(`${services.contacts}.labels.date`),
            icon: icons.event,
            color: colors.grey,
            width: '100%',
            to: { service: undefined }
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            components['date-picker'],
            {
              props: {
                value: this.proxy.date,
                localeTag: this.locale,
                range: false
              },
              on: {
                input: event => {
                  this.proxy.date = event
                }
              }
            }
          ),

          h(
            'div',
            {
              class: 'fjcfe facfe grid-gap--8 pa-2'
            },
            [
              h(
                button,
                {
                  props: {
                    label: this.getTranslate('misc.buttons.clear'),
                    mode: buttonModes.flat,
                    disabled: !this.proxy.date,
                    size: sizes.small
                  },
                  on: {
                    click: () => {
                      this.proxy.date = null
                    }
                  }
                }
              ),

              h(
                button,
                {
                  props: {
                    label: this.getTranslate('misc.buttons.close'),
                    color: colors.primary,
                    size: sizes.small
                  },
                  on: {
                    click: () => {
                      this.show = false
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderFieldCustom(h) {
  if (!this.isFilter) {
    return h(
      components['expansion-panels'],
      {
        props: {
          flat: true,
          outline: true,
          rounded: true
        }
      },
      [
        h(
          components['expansion-panel'],
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header pr-0'
                  },
                  [
                    this.getTranslate(`${services.contacts}.titles.custom.fields`),

                    h('div', { class: 'ff' }),

                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'grid pa-2'
              },
              this.customFields.map((field, index) => {
                return h(
                  components['text-field'],
                  {
                    props: {
                      value: this.proxy[field],
                      label: `${this.getTranslate(`${services.contacts}.labels.custom`)} ${index + 1}`,
                      mode: inputModes['line-label'],
                      rounded: true,
                      clearable: true,
                      trim: true,
                      dense: true,
                      details: false
                    },
                    on: {
                      input: event => {
                        this.proxy[field] = event
                      }
                    },
                    key: `field-${index}`
                  }
                )
              })
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid'
    },
    [
      renderFieldPhone.call(this, h),
      renderFieldEmail.call(this, h),
      renderFieldLastName.call(this, h),
      renderFieldFirstName.call(this, h),
      renderFieldMiddleName.call(this, h),
      renderFieldGender.call(this, h),
      renderFieldDate.call(this, h),
      renderFieldCustom.call(this, h)
    ]
  )
}
