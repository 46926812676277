import { colors, icons, inputModes, modes, services } from '@/utils'

import Button from '@/components/button'
import FileDialog from '@/components/file/dialog'
import FileCard from '@/components/file/item/card'
import FileManager from '@/components/file/manager'
import OwnerSelect from '@/components/misc/OwnerSelect'

function renderFileDialog(h) {
  if (this.showFileDialog) {
    return h(
      FileDialog,
      {
        props: {
          value: this.dialogData.currentEditFile,
          googleFileURL: this.dialogData.googleFileURL,
          type: this.dialogData.editDialogType,
          show: this.showFileDialog
        },
        on: {
          show: event => {
            this.showFileDialog = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.contracts}.labels.title`),
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        required: true,
        clearable: true,
        autofocus: true
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        disabled: !this.isNew && !!this.value.OwnerId
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderRequisiteSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.RequisiteId,
        label: this.getTranslate(`${services.contracts}.labels.requisite`),
        service: 'requisites',
        query: {
          OwnerId: this.proxy.OwnerId
        },
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        clearable: true,
        disabled: !this.proxy.OwnerId || !this.isNew && !!this.value.Requisite
      },
      scopedSlots: {
        selection: ({ item }) => {
          this.vat = item._item._item.data.tax.vat

          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: 'repeat(2, auto)' }
            },
            [
              h(
                'div',
                {
                  class: 'g-select__chip'
                },
                [
                  h(
                    'div',
                    {
                      class: 'g-select__chip-title'
                    },
                    [ item.title ]
                  )
                ]
              ),
              h(
                'g-chip',
                {
                  props: {
                    label: this.getTranslate(`requisites.vat.${this.vat}`),
                    tiny: true,
                    color: 'primary'
                  }
                }
              )
            ]
          )
        },
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: {
                gridTemplateColumns: '1fr auto'
              }
            },
            [
              h('div', { class: 'text-overflow ' }, item.title),
              h(
                'g-chip',
                {
                  props: {
                    label: this.getTranslate(`requisites.vat.${item._item.data.tax.vat}`),
                    tiny: true,
                    disabled: this.proxy.RequisiteId !== item.id,
                    color: this.proxy.RequisiteId === item.id ? 'primary' : undefined
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.RequisiteId = event
        }
      }
    }
  )
}

function renderCompanySelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.CompanyId,
        label: this.getTranslate(`${services.contracts}.labels.company`),
        service: 'companies',
        query: {
          OwnerId: '$global',
          vat: this.vat
        },
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        autocomplete: true,
        clearable: true,
        disabled: !this.proxy.OwnerId || !this.isNew && !!this.value.Company
      },
      on: {
        input: event => {
          this.proxy.CompanyId = event
        }
      }
    }
  )
}

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        show: this.showFileManager,
        currentChosenFileId: this.proxy.FileId,
        flat: true,
        embedded: true,
        readonly: true,
        types: [ 'doc', 'docx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.proxy.FileId = event.id
        }
      }
    }
  )
}

function renderClearFileIdButton(h) {
  if (this.isNew) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: icons.clear,
          color: colors.grey,
          flat: true
        },
        on: {
          click: () => {
            this.proxy.FileId = undefined
          }
        }
      }
    )
  }
}

function renderFileCard(h) {
  if (this.proxy.FileId) {
    return h(
      'div',
      {
        class: 'faic fjcfs grid-gap--8'
      },
      [
        h(
          FileCard,
          {
            class: 'pa-0',
            style: {
              borderRadius: '12px'
            },
            props: {
              value: this.proxy.FileId,
              outline: true,
              displayMenu: !this.isNew,
              flat: true,
              maxWidth: '100%',
              small: true,
              include: [ 'edit', 'download', 'export' ]
            },
            on: {
              dialog: event => {
                this.dialogData = event
                this.showFileDialog = !event.close
              }
            }
          }
        ),

        renderClearFileIdButton.call(this, h)
      ]
    )
  }

  return h(
    'div',
    {
      class: 'fjcfs'
    },
    [
      h(
        Button,
        {
          props: {
            label: this.getTranslate(`${services.contracts}.buttons.attach`),
            icon: 'attach_file',
            mode: modes.flat,
            color: 'primary'
          },
          on: {
            click: () => {
              this.showFileManager = true
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: {
        'grid grid-cols--1': true,
        'pa-3': !this.isNew,
        'pa-2': this.isNew
      }
    },
    [
      renderTitleField.call(this, h),
      renderOwnerSelect.call(this, h),
      renderRequisiteSelect.call(this, h),
      renderCompanySelect.call(this, h),
      renderFileCard.call(this, h),
      renderFileManager.call(this, h),
      renderFileDialog.call(this, h)
    ]
  )
}
