import { datePresets } from '../../enums'

export const PAYMENTS_FILTER = {
  method: undefined,
  status: undefined,
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: datePresets.current.month,
  OwnerId: undefined,
  RecurrentPaymentId: undefined,
  $scope: [ 'full' ]
}
