import { buttonModes, colors, icons, notificationsColors, services, sizes } from '@/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import squircle from '@/components/icon/squircle'
import date from '@/components/serviceTemplate/item/date'
import dialog from '@/components/services/notifications/dialog/details'

function renderChannel(h) {
  if (this.value.channel) {
    return h(
      squircle,
      {
        props: {
          size: sizes.small,
          color: notificationsColors[this.value.channel],
          iconSVG: this.value.channel,
          iconTooltip: this.getTranslate(`${services.sendings}.types.${this.value.channel}`)
        }
      }
    )
  }

  return h('div')
}
function renderType(h) {
  if (this.value.type) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.notifications}.events.${this.value.type}`),
          label: this.getTranslate(`${services.notifications}.labels.type`),
          color: this.value.isRead ? colors.success : colors.grey,
          icon: this.value.isRead ? icons.done_all : icons.check,
          iconTooltip: this.iconIsReadTooltip
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-template-panel__info`
    },
    [
      renderChannel.call(this, h),
      renderType.call(this, h)
    ]
  )
}
function renderText(h) {
  if (this.viewport.size.width > 600) {
    if (this.value.text) {
      return h(
        sticker,
        {
          props: {
            value: this.value.text,
            label: this.getTranslate(`${services.notifications}.labels.text`)
          }
        }
      )
    }

    return h('div')
  }
}
function renderTargetUser(h) {
  if (this.value.targetUser || this.value.targetUserId) {
    if (this.viewport.size.width > 768) {
      return h(
        sticker,
        {
          props: {
            value: this.value.targetUser || this.value.targetUserId,
            label: this.getTranslate(`${services.notifications}.labels.owner`),
            pathToTitle: 'username',
            maxWidth: 200,
            width: '100%'
          }
        }
      )
    }
  }
}
function renderSourceButton(h) {
  return h(
    button,
    {
      class: { 'hover-child': this.viewport.size.width > 768 },
      props: {
        icon: icons.code,
        mode: buttonModes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}
function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderDialog(h) {
  return h(
    dialog,
    {
      props: {
        value: this.showDialog,
        data: this.value
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-panel ${services.notifications}-template-panel hover-parent hover-parent--opacity`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderInfo.call(this, h),
      renderText.call(this, h),
      renderTargetUser.call(this, h),
      renderSourceButton.call(this, h),
      renderCreatedAt.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
