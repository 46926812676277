import { cloneDeep, isEmpty } from 'lodash'

import { BILLINGS_FILTER, billingsSources, dateFilter, minMax, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ dateFilter, minMax ],

  computed: {
    sourceItems() {
      const result = [ ...Object.values(billingsSources) ]
      const permission = `advanced.${services.billings}.find`
      if (this.checkPermissions(permission, permissionPresets.resellerUp)) {
        result.push(...this.getSendingTypesByPermission(permission).map(type => `sendings.${type}`))
        result.push('hlr')
      }

      return result
    }
  },

  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.proxy.createdAt = this.$route.params.createdAt
    }
  },

  methods: {
    getDefaultFilter() {
      return cloneDeep(BILLINGS_FILTER)
    },

    clearFilter() {
      this.proxy = cloneDeep(this._inputFilter(this.getDefaultFilter()))
    }
  },

  render
}
