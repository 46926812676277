import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import buttonConfirm from '@/components/button/confirm'

export default function(h, { path, serviceName }) {
  return h(
    buttonConfirm,
    {
      props: { value: this.proxy },
      on: {
        input: event => {
          this.$emit('input', event)
        }
      }
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.proxy,
            items: BILLING_CURRENCY.map(item => {
              return {
                value: item,
                title: this.getTranslate(`${path}.${item}`)
              }
            }),
            hint: this.getTranslate(`${serviceName}.labels.${path}`),
            mode: 'outline',
            rounded: true,
            dense: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      )
    ]
  )
}
