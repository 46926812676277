export default {
  namespaced: true,

  state: {
    tab: {
      name: undefined,
      to: {}
    }
  },

  mutations: {
    setCurrentTab(state, value) {
      state.tab = value
    }
  },

  actions: {
    setCurrentTab({ commit }, value) {
      commit('setCurrentTab', value)
    }
  },

  getters: {
    tab: state => state.tab
  }
}
