import { components } from '@/utils'

import render from './render'

export default {
  name: components['list-item'],

  props: {
    label: [ String, Number ],

    active: Boolean,
    hovered: Boolean,
    dense: Boolean,
    rounded: Boolean,
    outline: Boolean,
    disabled: Boolean,

    color: String,
    icon: String,
    iconColor: String,
    iconSize: Number,
    prefix: String,
    suffix: String,

    to: {
      type: [ Object, String ],
      default: undefined
    },

    button: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    hasIcon() {
      return !!this.icon || typeof this.$scopedSlots.icon === 'function' || this.$slots.icon
    },

    hasPrefix() {
      return !!this.prefix || typeof this.$scopedSlots.prefix === 'function' || this.$slots.prefix
    },

    hasSuffix() {
      return !!this.suffix || typeof this.$scopedSlots.suffix === 'function' || this.$slots.suffix
    },

    hasButton() {
      return this.button && !!this.button.icon && !!this.button.callback
    },

    computedIconColor() {
      let result = this.iconColor || this.color

      if (this.active) {
        result = 'primary'
      } else if (this.disabled) {
        result = 'grey'
      }

      return result
    },

    computedIconSize() {
      return this.iconSize || (this.dense ? 21 : 24)
    },

    computedButtonTooltip() {
      const result = []
      if (this.button && this.button.tooltip) {
        result.push(this.button.tooltip)
      }

      return result
    }
  },

  render
}
