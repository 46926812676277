import { get } from 'lodash'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'FormatTable',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    service: {
      type: String,
      default: services.sendings
    }
  },

  computed: {
    file() {
      const result = {
        value: this.value.File,
        type: 'file'
      }

      if (!result.value) {
        for (const path of [ 'image', 'audio', 'video', 'file' ]) {
          result.value = get(this.value, `payload.${path}`, this.value[path])
          if (result.value) {
            result.type = path
            break
          }
        }
      }

      return result
    },

    items() {
      const result = [
        'id',
        'chainId',
        'meta.billings.ids',
        'state.detailed.ids',
        'groupId',
        'state.status',
        'type',
        'channel',
        'source',
        'payload.sender',
        'payload.recipient',
        'text',
        'payload.text',
        'payload.tts',
        'payload.button.url',
        'payload.button.text',
        'meta.stats.segments',
        'meta.stats.characters',
        'meta.billing.amount',
        'dursec',
        'billsec',
        'amount',
        'data.price',
        'data.multiplier',
        '$options',
        'meta._recipientData',
        'meta.patternId',
        'isRead',
        'title',
        'notification'
      ]

      if (this.file) {
        result.push(`payload.${this.file.type}`)
      }

      if (this.checkPermissions(`advanced.${services.users}.get`)) {
        switch (this.service) {
          case services.sendings: {
            result.push('Owner')
            break
          }
          case services.billings: {
            result.push('OwnerId')
            break
          }
          case services.notifications: {
            result.push('targetUser')
            break
          }
        }
      }

      if (this.checkPermissions(`advanced.${services.tariffs}.get`)) {
        result.push('TariffId')
      }

      if (this.checkPermissions(`advanced.${services.providers}.get`)) {
        result.push('meta._routing.provider')
      }

      result.push('createdAt', 'updatedAt', 'sentAt', 'deliveredAt')

      return result
    }
  },

  render
}
