import { get } from 'lodash'

import { colors, services } from '@/utils'

import squircle from '@/components/icon/squircle'

export default function(h) {
  const type = get(this.value, 'data.sending.type')

  return h(
    'div',
    {
      class: {
        [`${services.sendings}-tasks__type`]: true,
        [`${services.sendings}-tasks__type--${type}`]: true
      }
    },
    [
      h(
        squircle,
        {
          props: {
            iconSVG: type,
            color: colors[type],
            tooltip: this.getTranslate(`${services.sendings}.types.${type}`)
          }
        }
      )
    ]
  )
}
