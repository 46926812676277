import { RECURRENT_PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, getPrice, icons, permissionPresets, popperPlacements, services } from '@/utils'
import { recurrentPaymentIcons, recurrentPaymentsStatusColors } from '@/components/services/recurrentPayments/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'
import button from '@/components/button'
import owner from '@/components/serviceTemplate/item/owner'

function renderStatus(h) {
  return h(
    sticker,
    {
      props: {
        value: this.viewport.size.width > 500 ? this.status : undefined,
        label: this.viewport.size.width > 500 ? this.getTranslate(`${services.recurrentPayments}.labels.status`) : undefined,
        icon: recurrentPaymentIcons.status[this.value.status],
        iconTooltip: this.status,
        color: recurrentPaymentsStatusColors[this.value.status]
      }
    }
  )
}
function renderAmount(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount, { currency: this.currency }),
        label: this.amountLabel,
        iconSVG: this.currency,
        color: colors.grey
      }
    }
  )
}
function renderMethod(h) {
  if (this.viewport.size.width > 610) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          label: this.getTranslate(`${services.recurrentPayments}.labels.method`),
          iconSVG: this.value.method,
          iconTooltip: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          color: colors[this.value.method]
        }
      }
    )
  }
}
function renderPeriod(h) {
  if (this.viewport.size.width > 820 && this.isPage) {
    return h(
      sticker,
      {
        props: {
          value: this.period,
          label: this.getTranslate(`${services.recurrentPayments}.labels.type`)
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.viewport.size.width > 1280 && this.isPage) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.recurrentPayments}-panel__info`]: true,
        [`${services.recurrentPayments}-panel__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp),
        [`${services.recurrentPayments}-panel__info--page`]: this.isPage
      }
    },
    [
      renderStatus.call(this, h),
      renderAmount.call(this, h),
      renderMethod.call(this, h),
      renderPeriod.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderListItem(h, { show = true, permission, accept = permissionPresets.meUp, validation, callback, icon, color, pathToTranslate }) {
  if (this.checkPermissions(permission, accept)) {
    if (show) {
      if (validation) {
        return h(
          components['list-item'],
          {
            props: {
              icon,
              color,
              label: this.getTranslate(pathToTranslate),
              dense: true
            },
            on: {
              click: callback
            }
          }
        )
      }
    }
  }
}
function renderMenu(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
    return h(
      components.menu,
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp },
        props: {
          rounded: true,
          placement: popperPlacements['bottom-end'],
          offsetDistance: 8
        }
      },
      [
        h(
          button,
          {
            props: {
              icon: icons.more_vert,
              mode: buttonModes.flat
            },
            slot: 'activator'
          }
        ),

        h(
          components.list,
          {
            props: {
              rounded: true,
              dense: true
            }
          },
          [
            renderListItem.call(this, h, {
              show: this.value.OwnerId === this.account.id,
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: ~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status),
              callback: this.repeatRecurrentPayment,
              icon: icons.verified_user,
              color: colors.success,
              pathToTranslate: `${services.recurrentPayments}.buttons.confirm`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.payments}.get`,
              validation: true,
              callback: () => this.$emit(services.payments, this.value.id),
              icon: icons.history,
              pathToTranslate: `${services.recurrentPayments}.buttons.${services.payments}`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: !~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status),
              callback: this.activateRecurrentPayment,
              icon: icons[this.isPaused ? 'play_arrow' : 'pause'],
              color: colors.primary,
              pathToTranslate: `${services.recurrentPayments}.buttons.${this.isPaused ? 'resume' : 'pause'}`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: this.value.status === RECURRENT_PAYMENTS_STATUSES.block,
              callback: () => this.$emit('unlockRecurrentPayment', this.value.id),
              icon: icons.done,
              color: colors.success,
              pathToTranslate: 'misc.buttons.unlock'
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: true,
              callback: () => this.$emit('removeRecurrentPayment', this.value.id),
              icon: icons.delete,
              color: colors.error,
              pathToTranslate: 'misc.buttons.remove'
            })
          ]
        )
      ]
    )
  }
}
function renderActions(h) {
  return h(
    'div',
    {
      class: 'ff fjcfe w--100'
    },
    [ renderMenu.call(this, h) ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-panel']: true,
        [`${services.recurrentPayments}-panel`]: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp
      },
      key: this.value.id
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
