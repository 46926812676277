import button from '@/components/button'
import { colors, modes } from '@/utils'
import Vue from 'vue'

function renderTexts(h) {
  return this.proxy.map((text, index) => {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { 'grid-template-columns': '1fr 36px' }
      },
      [
        h(
          'g-textarea',
          {
            props: {
              value: text,
              label: this.getTranslate('conditions.labels.text'),
              mode: 'outline',
              details: false,
              grow: true,
              dense: true,
              rounded: true,
              clearable: true
            },
            on: {
              input: event => {
                Vue.set(this.proxy, index, event)
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              icon: 'remove',
              mode: modes.flat,
              color: colors.error
            },
            on: {
              click: () => {
                this.remove(index)
              }
            }
          }
        )
      ]
    )
  })
}

export default function(h) {
  if (Array.isArray(this.proxy)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 grid-gap--8'
      },
      [
        renderTexts.call(this, h),

        h(
          'div',
          {
            class: 'faic fjcfe ff'
          },
          [
            h(
              button,
              {
                props: {
                  icon: 'add',
                  mode: modes.flat,
                  color: colors.primary
                },
                on: {
                  click: () => {
                    this.add()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
