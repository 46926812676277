import { services } from '@/utils'

import SpacerCell from '@/components/misc/SpacerCell'
import EntityInfo from '@/components/misc/EntityInfo'
import SendingTypes from '@/components/misc/SendingTypes'
import TariffsThirdCol from '@/components/services/tariffs/cols/third'
import TariffsDoubleButtons from '@/components/services/tariffs/buttons/double'
import FormTitle from '@/components/misc/FormTitle'
import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'

export default function(h) {
  return h(
    'div',
    {
      class: {
        faifs: this.viewport.breakpoint.mdUp,
        'fc ff': this.viewport.breakpoint.smDown
      }
    },
    [
      h(
        'search-generator',
        {
          scopedSlots: {
            filter: ({ filter }) => {
              return h(
                SendingTypes,
                {
                  props: {
                    value: filter.source,
                    label: this.getTranslate(`${services.tariffs}.labels.source`),
                    items: this.sourceItems,
                    format: value => value.replace(/sendings\.|subscription\./g, '')
                  },
                  on: {
                    input: event => {
                      filter.source = event
                    }
                  }
                }
              )
            },
            default: ({ item }) => {
              const [ source, type ] = item.source && item.source.split('.') || []
              const sourceOrType = type || source

              return h(
                'g-list-item',
                {
                  props: {
                    active: item.id === this.$route.params.id,
                    to: {
                      name: `${services.tariffs}.single`,
                      params: { id: item.id }
                    }
                  }
                },
                [
                  h(
                    'div',
                    {
                      class: 'faic grid-gap--8'
                    },
                    [
                      h(
                        'div',
                        {
                          class: 'fc'
                        },
                        [
                          h(SpacerCell, { props: { value: item.title } }),
                          h('div', { class: 'text--grey caption' }, this.getTranslate(`${services.tariffs}.sources.${sourceOrType}`))
                        ]
                      ),

                      h(
                        squircle,
                        {
                          props: { color: sourceOrType }
                        },
                        [
                          h(
                            svgIcon,
                            {
                              props: {
                                value: sourceOrType,
                                color: sourceOrType,
                                size: 18
                              }
                            }
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }
          }
        }
      ),

      h(
        'card-template',
        {
          scopedSlots: {
            title: ({ data }) => {
              return h(
                'div',
                {
                  class: `${services.tariffs}-title`
                },
                [
                  h(
                    FormTitle,
                    {
                      props: {
                        value: data
                      },
                      on: {
                        input: event => {
                          data = event
                        }
                      }
                    }
                  ),

                  h(TariffsDoubleButtons, { props: { value: data } })
                ]
              )
            },
            'after-form': ({ data }) => h(EntityInfo, { props: { value: data } }),
            right: () => h(TariffsThirdCol)
          }
        }
      )
    ]
  )
}
