import { getPrice } from '@/utils'

function renderIconOrLoading(h) {
  if (this.balanceLoading) {
    return h(
      'g-progress',
      {
        props: {
          indeterminate: true,
          size: this.viewport.breakpoint.smDown ? 16 : 20,
          color: 'grey'
        }
      }
    )
  } else {
    return h(
      'g-icon',
      {
        props: {
          value: 'autorenew',
          size: this.viewport.breakpoint.smDown ? 20 : 24
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        rounded: true,
        disabled: this.balanceLoading
      },
      on: {
        click: () => {
          this.getBalance()
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fjcc facc faic square--24 mr-1',
          style: { 'margin-left': '-8px' }
        },
        [ renderIconOrLoading.call(this, h) ]
      ),

      h(
        'div',
        {
          class: 'fc faife'
        },
        [
          h(
            'div',
            {
              class: {
                caption: this.viewport.breakpoint.smDown
              }
            },
            [ getPrice(this.balance, { currency: this.currency || this.globalSettings.billings.currency }) ]
          ),

          h(
            'div',
            {
              class: {
                'text-nowrap text--grey': true,
                'fz-9': this.viewport.breakpoint.mdUp,
                'fz-8': this.viewport.breakpoint.smDown
              }
            },
            [ getPrice(this.overdraft + this.balance, { currency: this.currency || this.globalSettings.billings.currency }) ]
          )
        ]
      )
    ]
  )
}
