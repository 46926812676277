import { get } from 'lodash'

import { getLocaleDateString, getLocaleTimeString, getPrice } from '@/utils'
import { serviceName } from '@/components/services/sendings/utils'

import SingleLink from '@/components/misc/SingleLink'
import EntityLink from '@/components/misc/EntityLink'
import copyIcon from '@/components/copy/icon'
import fileItemCard from '@/components/file/item/card'

const className = 'format-table'

function renderCopyIcon(h, value) {
  return h(
    copyIcon,
    {
      props: {
        value,
        size: 14,
        marginless: true
      }
    }
  )
}
function renderCopy(h, path, value) {
  let pathToValue = 'id'
  switch (path) {
    case 'meta._recipientData': {
      pathToValue = 'operator'
      break
    }
  }

  let data
  switch (true) {
    case typeof value === 'object': {
      data = get(value, pathToValue)
      if (Array.isArray(value) && value.length) {
        value.map(item => {
          if (typeof item !== 'string') {
            data = item
          } else {
            data = item.split(':').at(-1)
          }
        })
      }
      break
    }
    case typeof value === 'string':
    case typeof value === 'number': {
      data = value
      break
    }
    default: {
      data = ''
      break
    }
  }

  return h(
    'div',
    {
      class: `${className}__row-copy square--20`
    },
    [ renderCopyIcon.call(this, h, data) ]
  )
}

function renderLabel(h, label) {
  if (label) {
    return h(
      'div',
      {
        class: `${className}__row-label`
      },
      this.getTranslate(`${serviceName}.labels.${label}`)
    )
  }
}

function renderRouterLink(h, value, to) {
  return h(
    'router-link',
    {
      class: 'link',
      props: { to }
    },
    value
  )
}

function renderSingleLink(h, service, value) {
  return h(
    SingleLink,
    {
      props: {
        service,
        value
      }
    }
  )
}

function renderValueByPath(h, path, value) {
  switch (path) {
    case 'id': {
      const to = {}
      switch (this.service) {
        case 'sendings':
        case 'billings': {
          to.name = this.service
          to.query = { id: this.id }
          break
        }
        default: {
          to.name = `${this.service}.single`,
          to.params = { id: this.id }
          break
        }
      }

      return renderRouterLink.call(this, h, value, to)
    }

    case 'meta.billings.ids': {
      if (Array.isArray(value) && value.length) {
        return value.map(id => {
          return renderRouterLink.call(this, h, id, {
            name: 'billings',
            params: { id }
          })
        })
      }
      break
    }

    case 'state.detailed.ids': {
      if (Array.isArray(value) && value.length) {
        return value.map(id => {
          if (typeof id !== 'string') {
            return id
          } else {
            return id.split(':').map((id, index) => {
              return h(
                'div',
                {
                  style: { fontWeight: index === 1 ? 'bold' : undefined }
                },
                id
              )
            })
          }
        })
      }
      break
    }

    case 'state.status': {
      return this.getTranslate(`${this.service !== 'sendingsFallbacks' ? this.service : serviceName}.statuses.${value}`)
    }

    case 'type': {
      return this.getTranslate(`${serviceName}.types.${value}`)
    }

    case 'source': {
      if (this.service === 'billings') {
        const [ source, type ] = value.split('.')

        return this.getTranslate(`${this.service}.sources.${type || source}`)
      }
      break
    }

    case 'payload.text': {
      return h('pre', value)
    }

    case `payload.${this.file.type}`: {
      return h(
        'div',
        {},
        [
          h(
            fileItemCard,
            {
              props: {
                value: this.file.value,
                rounded: true,
                outline: false,
                paddingAll: false,
                flat: true
              }
            }
          )
        ]
      )
    }

    case 'dursec': {
      return `${value} ${this.getTranslate('misc.measure.time.sec')}`
    }

    case 'billsec': {
      return `${value} ${this.getTranslate('misc.measure.time.sec')}`
    }

    case 'amount':
    case 'data.price':
    case 'meta.billing.amount': {
      return getPrice(value)
    }

    case 'meta._recipientData': {
      const { group, operator, mcc, mnc } = value
      const result = [ group ]

      if (this.checkPermissions('advanced.operatorGroups.get')) {
        result.push(operator, [ mcc, mnc ].filter(Boolean).join(':'))
      }

      const values = result.filter(Boolean)

      if (Array.isArray(values) && values.length) {
        return values.join(' > ')
      }
      break
    }

    case 'meta.patternId': {
      return h(
        EntityLink,
        {
          props: {
            id: value,
            service: 'patterns',
            to: {
              name: 'patterns',
              params: {
                id: value
              }
            }
          }
        }
      )
    }

    case 'Owner':
    case 'OwnerId': {
      return renderSingleLink.call(this, h, 'users', value)
    }

    case 'TariffId': {
      return renderSingleLink.call(this, h, 'tariffs', value)
    }

    case 'meta._routing.provider': {
      return renderSingleLink.call(this, h, 'providers', value)
    }

    case 'createdAt':
    case 'updatedAt': {
      return [ getLocaleDateString(value), getLocaleTimeString(value) ].join(' ')
    }

    default: {
      return value
    }
  }
}

function renderValue(h, path, value) {
  return h(
    'div',
    {
      class: `${className}__row-value`
    },
    [ renderValueByPath.call(this, h, path, value) ]
  )
}

function renderItem(h, path) {
  const value = get(this.value, path)

  if (value && (typeof value === 'string' || typeof value === 'number' || Object.keys(value).length || Array.isArray(value) && value.length)) {
    switch (path) {
      case '$options': {
        if (this.service === 'sendingsFallbacks') {
          const convertStatuses = statuses => {
            return statuses.map(status => this.getTranslate(`${serviceName}.statuses.${status}`).toLowerCase()).join(', ')
          }

          return Object.keys(value).map(key => {
            const option = value[key]
            let content
            switch (key) {
              case 'onStatus': {
                content = convertStatuses(option)
                break
              }
              case 'onTimeout': {
                content = `${this.getTranslate(`${serviceName}.labels.options.timeout`).toLowerCase() + ': ' + option.timeout + ' ' + this.getTranslate('misc.measure.time.seconds').toLowerCase()}`
                content += ', '
                content += `${this.getTranslate(`${serviceName}.labels.options.except`).toLowerCase() + ': ' + convertStatuses(option.except)}`
                break
              }
              case 'onIncomingPayload': {
                content = option.text
                break
              }
            }

            return h(
              'div',
              {
                class: `${className}__row`
              },
              [
                renderLabel.call(this, h, `${path}.${key}`),
                renderCopy.call(this, h, '', content),
                renderValue.call(this, h, `${path}`, content)
              ]
            )
          })
        }
        break
      }

      default: {
        return h(
          'div',
          {
            class: `${className}__row`
          },
          [
            renderLabel.call(this, h, path),
            renderCopy.call(this, h, path, value),
            renderValue.call(this, h, path, value)
          ]
        )
      }
    }
  }
}

function renderItems(h) {
  if (this.items) {
    if (Array.isArray(this.items) && this.items.length) {
      return this.items.map(item => {
        return renderItem.call(this, h, item)
      })
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: className
    },
    [ renderItems.call(this, h) ]
  )
}
