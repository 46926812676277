import { colors, inputModes, modes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'

function renderWarning(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.payments}.yooMoney.warning`),
        color: colors.info
      }
    }
  )
}

function renderSumField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.sum,
        label: this.getTranslate(`${services.payments}.labels.sum`),
        hint: this.getTranslate(`${services.payments}.hints.sum`),
        suffix: this.getTranslate(),
        type: 'number',
        step: 1,
        min: 0,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        autofocus: true,
        details: true
      },
      on: {
        input: event => {
          this.sum = event
        }
      }
    }
  )
}

function renderPaymentTypeField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.paymentType,
        label: this.getTranslate(`${services.payments}.labels.paymentType`),
        items: [ 'PC', 'AC' ].map(value => {
          return {
            title: this.getTranslate(`${services.payments}.yooMoney.paymentTypes.${value}`),
            value
          }
        }),
        hint: this.getTranslate(`${services.payments}.hints.fee.${this.paymentType}`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: true
      },
      on: {
        input: event => {
          this.paymentType = event
        }
      }
    }
  )
}

function renderHiddenFields(h) {
  return Object.keys(this.payload).map(field => {
    return h(
      'input',
      {
        attrs: {
          hidden: true,
          value: this.payload[field],
          name: field
        }
      }
    )
  })
}

function renderRequestButton(h) {
  return h(
    button,
    {
      props: {
        mode: modes.filled,
        color: colors.primary,
        label: this.getTranslate('misc.buttons.pay'),
        loading: this.loading,
        disabled: !this.validation || this.loading,
        type: 'submit'
      }
    }
  )
}

export default function(h) {
  return h(
    'form',
    {
      class: 'grid pa-2',
      attrs: {
        action: 'https://yoomoney.ru/quickpay/confirm.xml',
        method: 'post'
      }
    },
    [
      renderHiddenFields.call(this, h),
      renderSumField.call(this, h),
      renderPaymentTypeField.call(this, h),
      renderWarning.call(this, h),

      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ renderRequestButton.call(this, h) ]
      )
    ]
  )
}
