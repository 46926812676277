export default {
  title: 'Финансовый отчёт',

  advanced: 'Дополнительно',

  labels: {
    id: 'ID',
    owner: 'Владелец',
    action: 'Действие',
    source: 'Источник',
    amount: 'Сумма',
    multiplier: 'Множитель',
    comment: 'Комментарий',
    preset: 'Пресет',
    status: 'Статус',
    tariff: 'Тариф',
    noRef: 'Не реферальный',
    price: 'Цена',
    OwnerId: 'Владелец',
    TariffId: 'Тариф',

    ref: {
      true: 'Рефеальный',
      false: 'Не реферальный'
    },

    data: {
      price: 'Цена',
      multiplier: 'Множитель'
    }
  },

  headers: {
    status: 'Статус',
    owner: 'Владелец',
    creator: 'Создатель',
    source: 'Тип',
    tariff: 'Тариф',
    comment: 'Комментарий',
    amount: 'Сумма',
    total: 'Всего',
    title: 'Заголовок',
    frequency: 'Периодичность',
    count_pending: 'В ожидании',
    sum_pending: 'В ожидании (сумма)',
    count_refunded: 'Возмещён',
    sum_refunded: 'Возмещён (сумма)',
    count_complete: 'Завершён',
    sum_complete: 'Завершён (сумма)',
    count_invalid: 'Ошибочный',
    sum_invalid: 'Ошибочный (сумма)',
    count_total: 'Всего',
    sum_total: 'Всего (сумма)',
    referrer: 'Реферал'
  },

  dialogs: {
    create: 'Создать новую расчётную операцию'
  },

  actions: {
    addFunds: 'Добавить средств',
    charge: 'Списать средства'
  },

  hints: {
    amount: 'По умолчанию множитель равен 1. Изменить его можно в разделе дополнительно.',
    date: {
      from: 'ГГГГ-ММ-ДД',
      by: 'ГГГГ-ММ-ДД'
    },
    time: {
      from: 'ЧЧ:ММ',
      by: 'ЧЧ:MM'
    },
    empty: 'Чтобы получить данные воспользуйтесь фильтром.'
  },

  presets: {
    billings: {
      details: 'Детальный отчёт по расчётным операциям',
      group001: 'Группировка по источнику'
    }
  },

  report: {
    download: 'Скачать в файл',
    get: 'Получить отчёт'
  },

  statuses: {
    pending: 'В ожидании',
    refunded: 'Возмещён',
    complete: 'Завершён',
    invalid: 'Ошибочный'
  },

  buttons: {
    clear: 'Очистить фильтр',
    refresh: 'Обновить'
  },

  tooltips: {
    refresh: 'Обновление данных таблицы с применением выбранного фильтра.',
    actions: 'Пополнить или списать баланс'
  },

  tabs: {
    billings: 'Финансовый отчёт',
    tasks: 'Задачи'
  },

  sources: {
    subtract: 'Списание',
    accrual: 'Пополнение',
    sms: 'SMS',
    voice: 'Voice',
    email: 'E-mail',
    viber: 'Viber',
    vk: 'VK',
    whatsapp: 'WhatsApp',
    push: 'Push'
  },

  snackbars: {
    created: 'Расчётная операция успешно создана.',
    updated: 'Расчётная операция успешно обновлена.',
    removed: 'Расчётная операция успешно удалена.',
    funds: {
      success: 'Средства успешно зачислены.',
      failed: 'Ошибка при зачислении средств.'
    }
  },

  currency: {
    RUB: '₽ российские рубли',
    USD: '$ доллары США',
    EUR: '€ евро'
  }
}
