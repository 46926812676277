import { inputModes, prioritizeAccountInList, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import dateTimeFilter from '@/components/filter/date'
import createdAtPreset from '@/components/misc/createdAtPreset'
import SendingTypes from '@/components/misc/SendingTypes'

function renderFirstColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        SendingTypes,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.sendings}.filter.type`),
            permission: `advanced.${services.sendingsIncoming}.get`,
            multiple: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        'g-text-field', {
          props: {
            value: this.proxy.from,
            label: this.getTranslate(`${services.sendings}.filter.from`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.from = event
            }
          }
        }
      )
    ]
  )
}

function renderSecondColumn(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: prioritizeAccountInList,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderThirdColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        createdAtPreset,
        {
          props: {
            value: this.proxy.createdAtPreset
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAt = {
                  $gt: undefined,
                  $lt: undefined
                }
              }

              this.proxy.createdAtPreset = event
            }
          }
        }
      ),

      h(
        dateTimeFilter,
        {
          props: {
            value: this.proxy.createdAt
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAtPreset = undefined
              }

              this.proxy.createdAt = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--3': this.viewport.breakpoint.lgUp,
        'grid-cols--2': this.viewport.breakpoint.md
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),
      renderThirdColumn.call(this, h)
    ]
  )
}
