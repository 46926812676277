import colors from './colors'
import sendings from './sendings'

export default {
  title: 'Настройки сайта',

  titles: {
    settings: 'Настройки сайта',
    reseller: 'Настройки сайта реселлера',
    resellers: 'Реселлеры',
    auth: 'Авторизация',
    frontend: 'Внешний вид',
    notifications: 'Уведомления',
    payments: 'Платежи',
    globalBlacklistManager: 'Глобальный черный список',
    apisender: 'API Sender',
    integrations: 'Интеграции',
    misc: 'Разное'
  },

  dialogs: {
    headers: {
      notifications: {
        email: {
          smtp: 'Настройки SMTP подключения',
          templates: {
            billings: {
              limit: 'Уведомление о критическом балансе'
            },

            auth: {
              registration: 'Уведомление при регистрации',
              recover: 'Уведомление восстановления пароля'
            },

            manager: {
              billings: {
                limit: 'Уведомление о критическом балансе дочернего пользователя'
              },

              providers: {
                health: 'Уведомление о статусе провайдера'
              }
            }
          }
        },

        sms: {
          templates: {
            otp: {
              generate: {
                sendernames: {
                  moderation: 'Шаблон SMS-подтверждений'
                }
              }
            }
          }
        }
      }
    }
  },

  subtitles: {
    resellers: {
      domains: 'Домены'
    },

    auth: {
      registration: {
        defaults: 'Значения по умолчанию при регистрации'
      }
    },

    frontend: {
      colors: 'Цвета сайта',
      documents: 'Публичные документы',
      jivosite: 'Настройки Jivosite'
    },

    notifications: {
      enabled: 'Включить уведомления по каналам',
      email: 'E-mail уведомления',
      sms: 'SMS уведомления',
      events: 'События',
      extras: 'Дополнительно'
    },

    payments: {
      methods: 'Методы платежей'
    },

    apisender: {
      settings: {
        readChatSettings: 'Read chat settings',
        sendMessageSettings: 'Send message settings',
        webhookSettings: 'Webhook settings'
      }
    },

    integrations: {
      yclients: {
        applicationCredentials: 'Данные для подключения к YClients'
      }
    }
  },

  labels: {
    chooseReseller: 'Выбрать реселлера',
    privacyPolicy: 'Политика конфиденциальности',
    publicOffer: 'Публичная оферта',

    resellers: {
      domains: {
        domain: 'Домен',
        user: 'Пользователь'
      }
    },

    auth: {
      registration: {
        defaults: {
          allowDuplicates: 'Разрешить дубликаты сообщений',
          sendernamesCheck: 'Ограничить имена отправителей для типов',
          patternsCheck: 'Обязательная проверка по шаблону модерации для типов',
          balance: 'Баланс по умолчанию',
          tariffs: 'Тариф по умолчанию',
          routingGroups: 'Группа маршрутизаций',
          messageRuleGroups: 'Группа правил обработки сообщений',
          sendernames: 'Имена отправителей',
          patterns: 'Паттерны'
        }
      }
    },

    frontend: {
      title: 'Загловок сайта',
      colors: colors.labels,
      jivosite: 'Jivosite ID',

      documents: {
        privacyPolicy: 'Политика конфиденциальности',
        publicOffer: 'Публичная оферта'
      }
    },

    notifications: {
      enabled: {
        types: sendings.types
      },

      email: {
        smtp: {
          host: 'Хост',
          port: 'Порт',
          from: 'От',
          login: 'Имя пользователя',
          password: 'Пароль'
        },

        templates: {
          subject: 'Тема',
          body: 'Тело шаблона',

          manager: {
            subject: 'Тема',
            body: 'Тело шаблона'
          }
        }
      },

      sms: {
        sendername: 'Имя отправителя',
        senderUserId: 'Отправитель',

        templates: {
          subject: 'Тема',
          body: 'Тело шаблона',

          manager: {
            subject: 'Тема',
            body: 'Тело шаблона'
          }
        }
      },

      events: {
        billings: {
          limit: {
            host: 'Хост',
            port: 'Порт',
            from: 'От',
            login: 'Имя пользователя',
            password: 'Пароль',
            limit: 'Порог',

            include: {
              overdraft: 'Учитывая овердрафт',
              invoice: 'отправлять счёт'
            },

            notInclude: {
              overdraft: 'Без учёта овердрафта',
              invoice: ''
            }
          }
        },

        providers: {
          health: {
            recipients: 'Получатели уведомлений о состоянии провайдеров'
          }
        }
      },

      extraRecipients: 'Дополнительные получатели уведомлений'
    },

    payments: {
      allowedGlobalMethods: 'Разрешённые глобальные методы оплаты'
    },

    globalBlacklistManager: {
      mainListId: 'Основной список',
      temporaryAvailableListId: 'Список временно разрешенных номеров'
    },

    apisender: {
      userApiKey: 'User API key',

      settings: {
        readChatSettings: {
          eventOnIncomingMessage: 'Event on incoming message'
        },

        sendMessageSettings: {
          delayOnSendMessageInMs: 'Delay on send message in ms'
        },

        webhookSettings: {
          webhookUrl: 'Webhook URL',
          webhookOnNewMessage: 'Webhook on new message',
          webhookOnStatusMessage: 'Webhook on status message',
          webhookOnCheckPhoneState: 'Webhook on check phone state',
          webhookOnChangeStateAccount: 'Webhook on change state account',
          webhookOnIncomingCall: 'Webhook on incoming call',
          webhookOnIncomingVideoCall: 'Webhook on incoming video call',
          webhookOnFailedUserProxy: 'Webhook on failed user proxy'
        }
      }
    },

    integrations: {
      yclients: {
        applicationCredentials: {
          id: 'ID приложения',
          token: 'Токен приложения'
        }
      }
    },

    misc: {
      rootId: 'Root ID'
    }
  },

  contents: {
    notifications: {
      events: {
        billings: {
          limit: 'Пороги уведомлений о балансе'
        },

        providers: {
          health: {
            recipients: 'Получатели уведомлений о событиях провайдеров'
          }
        }
      },

      email: {
        templates: {
          title: 'Шаблоны уведомлений',

          billings: {
            limit: 'Уведомление о критическом балансе'
          },

          auth: {
            registration: 'Уведомление при регистрации',
            recover: 'Уведомление восстановления пароля'
          },

          manager: {
            title: 'Шаблоны уведомлений для менеджеров',

            billings: {
              limit: 'Уведомление о критическом балансе дочернего пользователя'
            },

            providers: {
              health: 'Уведомление о статусе провайдера'
            }
          }
        }
      },

      sms: {
        templates: {
          title: 'Шаблоны SMS',
          otp: {
            generate: {
              sendernames: {
                moderation: 'Шаблон SMS-подтверждений'
              }
            }
          }
        }
      }
    },

    payments: {
      methods: 'Настройки способов оплаты'
    }
  },

  hints: {
    warning: 'Вы редактируете настройки сайта выбранного пользователя.',

    frontend: {
      colors: colors.hints,
      jivosite: 'Установите персональный Jivosite ID, чтобы активировать модуль поддержи на сайте',

      documents: {
        privacyPolicy: 'Файл, который будет предлагаться пользователям для ознакомления',
        publicOffer: 'Файл, который будет предлагаться пользователям для ознакомления'
      }
    },

    notifications: {
      email: {
        templates: {
          billings: {
            limit: 'Сообщение, которое будет отправлено пользователю при достижении одного из пороговых значений баланса'
          },

          auth: {
            registration: 'Сообщение, которое будет отправлено пользователю при регистрации',
            recover: 'Сообщение, которое будет отправлено пользователю при попытке восстановить пароль'
          },

          manager: {
            billings: {
              limit: 'Сообщение, которое будет отправлено менеджеру или администратору при достижении одного из пороговых значений баланса у дочерних пользователей'
            },

            providers: {
              health: 'Сообщение будет отправлено в случае, если провайдер отключится по какой-либо причине'
            }
          }
        }
      },

      sms: {
        templates: {
          otp: {
            generate: {
              sendernames: {
                moderation: 'Сообщение будет отправлено при SMS-подтверждении номера отправителя'
              }
            }
          }
        }
      },

      events: {
        billings: {
          limit: {
            title: 'Уведомление сработает при балансе {amount}',
            onLimit: 'При балансе',
            noLimit: '<не указан порог>',
            notifyBy: 'уведомлять по',
            noNotifications: '<не уведомлять>',
            activator: 'Уведомление сработает при балансе {limit}',
            overdraft: ' + овердрафт пользователя',
            invoice: 'Приложить счёт на сумму {amount}'
          }
        }
      },

      extraRecipients: 'Уведомления о состоянии баланса всех пользователей системы'
    },

    payments: {
      methods: 'Настройте различные способы пополнения баланса используя предустановленные интеграции с системами оплаты'
    }
  },

  buttons: {
    notifications: {
      email: {
        smtp: 'SMTP подключение'
      },

      events: {
        billings: {
          limit: {
            add: 'Добавить лимит',
            invoice: {
              attach: 'Приложить счёт',
              detach: 'Открепить счёт',
              attached: 'Будет выставлен счёт на сумму {amount}'
            }
          }
        }
      }
    }
  },

  misc: {
    globalSettings: 'Глобальные настройки'
  },

  snackbars: {
    updated: 'Настройка успешно обновлена.'
  },

  patterns: {
    username: 'Имя пользователя',
    email: 'Email',
    phone: 'Телефон',
    url: 'Url',
    domain: 'Домен',
    title: 'Заголовок',

    recipient: {
      username: 'Имя пользователя',
      email: 'Email',
      phone: 'Телефон'
    }
  }
}
