import Vue from 'vue'

import { get } from 'lodash'
import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, hostnames, icons, modes, permissionPresets } from '@/utils'

import HintRow from '@/components/misc/HintRow'
import GenerateDocumentForm from '@/components/services/documentTemplates/generate/form'
import paymentsForm from '@/components/services/payments/form'
import yooMoneyForm from '@/components/global/dialog/payment/yooMoneyForm'
import button from '@/components/button'
import info from '@/components/misc/info'

function renderArrowLeft(h) {
  if (this.step !== 'first') {
    return h(
      button,
      {
        props: {
          icon: icons.keyboard_arrow_left,
          mode: modes.flat
        },
        on: {
          click: () => {
            switch (this.step) {
              case 'legal':
              case 'individual': {
                this.step = 'first'
                break
              }
              case 'yooMoney':
              case 'cards': {
                this.step = 'individual'
                break
              }
            }
          }
        }
      }
    )
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8 pa-2',
      slot: 'header'
    },
    [
      renderArrowLeft.call(this, h),

      h(
        'div',
        {
          class: {
            'faic ff title text-center px-2': true,
            'fjcc facc': this.step !== 'first'
          }
        },
        [
          h(
            'div',
            {
              class: 'subheading'
            },
            this.getTranslate(`payments.contents.step.${this.step}`)
          )
        ]
      ),

      h(
        button,
        {
          props: {
            icon: icons.clear,
            mode: modes.flat
          },
          on: {
            click: () => {
              this.step = 'first'
              this.amount = undefined
              this.setShowPaymentDialog(false)
            }
          }
        }
      )
    ]
  )
}

function renderUseYooMoney(h) {
  if (~hostnames.indexOf(window.location.hostname)) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate('payments.hints.yooMoney'),
          color: colors.info
        }
      }
    )
  }
}
function renderGoToYooMoney(h) {
  if (~hostnames.indexOf(window.location.hostname)) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('payments.contents.step.yooMoney'),
          mode: modes.flat
        },
        on: {
          click: () => {
            this.step = 'yooMoney'
          }
        }
      }
    )
  }
}

function renderSteps(h) {
  switch (this.step) {
    case 'first': {
      return h(
        'g-list',
        {
          props: { rounded: this.viewport.breakpoint.mdUp }
        },
        this.paymentTypes.map(type => {
          return h(
            'g-list-item',
            {
              props: { rounded: this.viewport.breakpoint.mdUp },
              on: {
                click: () => {
                  this.step = type.name
                }
              }
            },
            [ this.getTranslate(`payments.types.${type.name}`) ]
          )
        })
      )
    }

    case 'individual': {
      if (this.checkPermissions('advanced.payments.create', permissionPresets.meUp)) {
        return h(
          'g-list',
          {
            props: { rounded: this.viewport.breakpoint.mdUp }
          },
          this.$paymentsMethods.map(method => {
            return h(
              'g-list-item',
              {
                on: {
                  click: () => {
                    switch (method.name) {
                      case 'recurrent': {
                        Vue.router.push({
                          name: 'account',
                          hash: 'recurrentPayments'
                        })
                        this.setShowPaymentDialog(false)
                        this.setShowCreateRecurrentPaymentDialog(true)
                        break
                      }

                      default: {
                        this.step = method.name
                        break
                      }
                    }
                  }
                }
              },
              [
                h(
                  'div',
                  {
                    class: {
                      'fjcfs facfs faic': true,
                      'pl-5 pr-5': !this.viewport.breakpoint.smDown
                    }
                  },
                  [
                    h(
                      'img',
                      {
                        attrs: { src: `/logos/payments/${method.name}-padding.svg` },
                        class: 'mr-3 square--40'
                      }
                    ),

                    h(
                      'div',
                      {
                        class: 'fc'
                      },
                      [ this.getTranslate(`payments.methods.${method.name}`) ]
                    )
                  ]
                )
              ]
            )
          })
        )
      } else {
        const contacts = get(this.globalSettings, 'contacts.phones', [])
        const emails = get(this.globalSettings, 'contacts.emails', [])

        return h(
          'div',
          {
            class: 'grid'
          },
          [
            h(HintRow, { props: { value: this.getTranslate('payments.hints.individual') } }),

            h(
              'div',
              {
                class: 'grid grid-gap--4 px-3'
              },
              [
                h(
                  'div',
                  {
                    class: 'grid grid-gap--2'
                  },
                  contacts.map(({ value }) => {
                    return h(
                      'a',
                      {
                        attrs: {
                          target: '_blank',
                          href: `tel:${value}`
                        },
                        class: 'link'
                      },
                      value
                    )
                  })
                ),
                h(
                  'div',
                  {
                    class: 'grid grid-gap--2'
                  },
                  emails.map(({ value }) => {
                    return h(
                      'a',
                      {
                        attrs: {
                          target: '_blank',
                          href: `mailto:${value}`
                        },
                        class: 'link'
                      },
                      value
                    )
                  })
                )
              ]
            )
          ]
        )
      }
    }
    case 'legal': {
      return h(
        GenerateDocumentForm,
        {
          props: {
            value: this.payload.legal,
            allowedTypes: [ DOCUMENT_TEMPLATES_TYPES.invoice ]
          },
          on: {
            input: event => {
              this.payload.legal = event
            },
            show: event => {
              this.setShowPaymentDialog(event)
            }
          }
        }
      )
    }

    case 'cards': {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2'
        },
        [
          h(
            paymentsForm,
            {
              props: { value: this.payload.individual },
              on: {
                input: event => {
                  this.payload.individual = event
                }
              }
            }
          ),

          renderUseYooMoney.call(this, h),

          h(
            'div',
            {
              class: 'fjcfe facfe grid-gap--8'
            },
            [
              renderGoToYooMoney.call(this, h),
              h(
                button,
                {
                  props: {
                    label: this.getTranslate('misc.buttons.pay'),
                    loading: this.loading,
                    disabled: !this.payload.individual.amount || !this.payload.individual.acceptOffer || this.loading,
                    color: colors.primary,
                    mode: modes.filled
                  },
                  on: {
                    click: () => this.requestPayment()
                  }
                }
              )
            ]
          )
        ]
      )
    }

    case 'yooMoney': {
      return h(yooMoneyForm)
    }
  }
}

function renderFooter(h) {
  switch (this.step) {
    case 'legal': {
      return h(
        'div',
        {
          class: 'fjcfe facfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.bill'),
                loading: this.loading,
                disabled: this.loading || !this.validation,
                color: colors.primary,
                mode: modes.filled
              },
              on: {
                click: () => {
                  this.requestDocument()
                }
              }
            }
          )
        ]
      )
    }
  }
}

export default function(h) {
  return h(
    'g-dialog',
    {
      style: { 'margin-bottom': this.viewport.breakpoint.smDown ? '70px' : undefined },
      props: {
        value: this.showPaymentDialog,
        rounded: this.viewport.breakpoint.mdUp,
        closeOnEsc: false,
        closeOnClick: true,
        maxWidth: this.viewport.breakpoint.smDown ? '100vw' : 400,
        fullscreenMobile: false,
        align: this.viewport.breakpoint.smDown ? 'bottom' : undefined
      },
      on: {
        input: event => {
          if (event === false) {
            this.step = 'first'
          }
          this.setShowPaymentDialog(event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderSteps.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
