import { components, services, states } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import readonly from '@/components/services/invoices/form/readonly'
import requisite from '@/components/services/documentTemplates/generate/form/common/requisite'
import company from '@/components/services/documentTemplates/generate/form/common/company'
import amount from '@/components/services/documentTemplates/generate/form/common/amount'

function renderOwner(h) {
  if (!this.OwnerId) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.customerId,
          disabled: this.disabled,
          combobox: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.customerId = event
          }
        }
      }
    )
  }
}

function renderRequisite(h) {
  if (this.customerId) {
    return h(
      requisite,
      {
        props: {
          value: this.proxy.requisiteId,
          items: this.restData[services.requisites].find.data,
          loading: this.restData[services.requisites].find.state === states.loading,
          disabled: this.restData[services.requisites].find.state === states.loading || this.disabled
        },
        on: {
          input: event => {
            this.proxy.requisiteId = event
          }
        }
      }
    )
  }
}

function renderCompany(h) {
  if (this.customerId && this.proxy.requisiteId) {
    return h(
      company,
      {
        props: {
          value: this.proxy.companyId,
          items: this.restData[services.companies].find.data,
          loading: this.restData[services.companies].find.state === states.loading,
          disabled: this.restData[services.companies].find.state === states.loading || this.disabled
        },
        on: {
          input: event => {
            this.proxy.companyId = event
          }
        }
      }
    )
  }
}

function renderAmount(h) {
  if (this.customerId && this.proxy.requisiteId && this.proxy.companyId) {
    return h(
      amount,
      {
        props: {
          value: this.proxy.amount,
          disabled: this.disabled
        },
        on: {
          input: event => {
            this.proxy.amount = event
          }
        }
      }
    )
  }
}

export default function(h) {
  if (this.isNew) {
    return h(
      components.form,
      {
        class: {
          grid: true,
          'pt-2 px-2': !this.paddless
        }
      },
      [
        renderOwner.call(this, h),
        renderRequisite.call(this, h),
        renderCompany.call(this, h),
        renderAmount.call(this, h)
      ]
    )
  }

  return h(
    readonly,
    {
      props: {
        value: this.proxy
      }
    }
  )
}
