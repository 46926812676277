import { get } from 'lodash'

import { colors, components, formatDateWithCheckYear, getHammerTime, getPrice, getRUDate, icons, modes, services, sizes } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import preloader from '@/components/misc/preloader'
import removeButton from '@/components/button/confirm/remove'
import providersPricesImport from '@/components/services/providers/prices/import'
import overflowString from '@/components/misc/overflowString'

function renderProvidersPricesImport(h) {
  return h(
    providersPricesImport,
    {
      props: {
        ProviderId: this.ProviderId,
        disabled: this.versions.length >= 3 || this.$loading
      },
      on: {
        finish: () => {
          this.getVersions()
        }
      }
    }
  )
}

function renderVersion(h, value, type = 'list') {
  switch (type) {
    case 'select': {
      return h(
        tag,
        {
          props: {
            size: sizes.small,
            label: [ getRUDate(value), getHammerTime(value) ].join(', ')
          }
        }
      )
    }
    case 'list':
    default: {
      return h(
        sticker,
        {
          props: {
            value: formatDateWithCheckYear.call(this, value),
            label: getHammerTime(value),
            icon: icons.date_range,
            color: this.version === value ? colors.primary : colors.grey,
            borderColor: this.version === value ? colors.primary : undefined,
            disabled: this.$loading,
            onClick: () => {
              this.version = value
            }
          }
        }
      )
    }
  }
}
function renderVersions(h) {
  if (Array.isArray(this.versions) && this.versions.length) {
    if (this.viewport.breakpoint.mdUp) {
      return h(
        'div',
        {
          class: 'providers-prices__versions'
        },
        [
          this.versions.map(value => {
            return renderVersion.call(this, h, value)
          })
        ]
      )
    } else {
      return h(
        components.select,
        {
          props: {
            value: this.version,
            label: this.getTranslate(`${services.providersPrices}.labels.version`),
            items: this.versions,
            mode: modes.outline,
            disabled: this.$loading,
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.version = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => renderVersion.call(this, h, item.value, 'select'),
            item: ({ item }) => renderVersion.call(this, h, item.value, 'select')
          }
        }
      )
    }
  }

  return h('div')
}
function renderRefreshButton(h) {
  return h(
    button,
    {
      props: {
        mode: modes.flat,
        icon: icons.refresh,
        loading: this.$loading,
        disabled: this.$loading
      },
      on: {
        click: () => {
          this.getVersions()
        }
      }
    }
  )
}
function renderRemoveButton(h) {
  return h(
    removeButton,
    {
      props: {
        disabled: !this.version,
        labeled: false,
        callback: () => this.removeVersion()
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: 'providers-prices__actions'
    },
    [
      renderProvidersPricesImport.call(this, h),
      renderVersions.call(this, h),
      renderRemoveButton.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}
function renderSearch(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.restData[services.providersPrices].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        suffix: this.restData[services.providersPrices].find.pagination.total,
        loading: this.$loading,
        disabled: this.$loading,
        mode: modes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.restData[services.providersPrices].find.filter.$search = event
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'providers-prices__header'
    },
    [
      renderActions.call(this, h),
      renderSearch.call(this, h)
    ]
  )
}

function renderInfo(h, value) {
  return h(
    'div',
    {
      class: 'providers-prices__row-info'
    },
    [ 'mcc', 'mnc', 'country', 'operator' ].map(key => {
      const text = get(value, key)
      switch (key) {
        case 'country':
        case 'operator': return h(
          overflowString,
          {
            props: {
              value: text,
              font: {
                size: 12,
                lineHeight: 14
              }
            }
          }
        )
        case 'mcc':
        case 'mnc':
        default: return h('div', [ text ])
      }
    })
  )
}
function renderPrice(h, value, previous, plus) {
  return h(
    'div',
    {
      class: this.getClasses(value, previous)
    },
    [ plus ? '+' : '', getPrice(value.price) ]
  )
}
export function renderPreviousPrice(h, value) {
  const { price, previousPrice } = value
  if (previousPrice && price !== previousPrice) {
    return renderPrice.call(this, h, { price: previousPrice }, true)
  }
}
export function renderDiffPrice(h, value) {
  const { price, previousPrice } = value
  if (previousPrice && price !== previousPrice) {
    const diff = this.getDiff(price, previousPrice)

    return renderPrice.call(this, h, {
      price: diff,
      direction: price > previousPrice ? 'up' : 'down'
    }, true, diff > 0)
  }
}
function renderRow(h, value) {
  const { price, previousPrice } = value

  return h(
    'div',
    {
      class: {
        'providers-prices__row': true,
        'providers-prices__row--previous': previousPrice && price !== previousPrice
      },
      key: value.id
    },
    [
      renderInfo.call(this, h, value),
      renderPreviousPrice.call(this, h, value),
      renderDiffPrice.call(this, h, value),
      renderPrice.call(this, h, value)
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'providers-prices__loading'
    },
    [
      h(
        preloader,
        {
          props: {
            value: true
          }
        }
      )
    ]
  )
}
function renderList(h) {
  return h(
    'div',
    {
      class: 'providers-prices__list',
      on: { scroll: this.onScroll },
      ref: 'list'
    },
    [
      this.restData[services.providersPrices].find.data.map(value => {
        return renderRow.call(this, h, value)
      })
    ]
  )
}
function renderContent(h) {
  if (this.$loading) {
    return renderPreloader.call(this, h)
  } else if (Array.isArray(this.restData[services.providersPrices].find.data)) {
    if (this.restData[services.providersPrices].find.data.length) {
      return renderList.call(this, h)
    }
  }

  return h(components.empty)
}

export default function(h) {
  return h(
    'div',
    {
      class: 'providers-prices'
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
