import { buttonModes, colors, components, icons, inputModes, popperPlacements, sizes, states } from '@/utils'

import button from '@/components/button'
import create from '@/components/generator/search/create'
import preloader from '@/components/misc/preloader'

const className = 'search-generator'

function renderSearchField(h, options) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.restData[options.name].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        afterIcon: icons.search,
        name: 'search',
        suffix: this.restData[options.name].find.pagination.total,
        loading: this.restData[options.name].find.state === states.loading,
        clearable: true,
        mode: inputModes.outline,
        rounded: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.restData[options.name].find.filter.$search = event
        }
      }
    }
  )
}

function renderActionTitle(h, path) {
  return h(
    'div',
    {
      class: `${className}__action-title`
    },
    [ this.getTranslate(path) ]
  )
}
function renderActionSwitch(h, value) {
  return h(
    components.switch,
    {
      props: {
        value
      }
    }
  )
}
function renderIsActiveAction(h, options) {
  if (options.filterByIsActive) {
    return h(
      'div',
      {
        class: `${className}__action`,
        on: {
          click: () => {
            this.setIsActive(!this.isActive)
          }
        }
      },
      [
        renderActionTitle.call(this, h, 'commons.filters.isActive.hide'),
        renderActionSwitch.call(this, h, this.isActive)
      ]
    )
  }
}

function renderFilterAction(h) {
  if (this.$slots.filter || typeof this.$scopedSlots.filter === 'function') {
    return h(
      'div',
      {
        class: `${className}__action`,
        on: {
          click: () => {
            this.showFilter = !this.showFilter
          }
        }
      },
      [
        renderActionTitle.call(this, h, 'commons.filters.filter.show'),
        renderActionSwitch.call(this, h, this.showFilter)
      ]
    )
  }
}
function renderCreateSlot(h, options) {
  if (typeof this.$scopedSlots.create === 'function') {
    return this.$scopedSlots.create()
  } else if (this.$slots.create) {
    return this.$slots.create
  } else {
    return h(
      create(options),
      {
        props: { maxWidth: this.maxWidth },
        scopedSlots: { titleCreate: this.$scopedSlots.titleCreate }
      }
    )
  }
}
function renderReportSlot() {
  if (typeof this.$scopedSlots.report === 'function') {
    return this.$scopedSlots.report()
  } else {
    return this.$slots.report
  }
}
function renderActionMenu(h, options) {
  return h(
    components.menu,
    {
      props: {
        rounded: true,
        offsetDistance: -37,
        placement: popperPlacements['bottom-end']
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.more_vert,
            mode: buttonModes.flat
          },
          slot: 'activator'
        }
      ),

      h(
        components.list,
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderIsActiveAction.call(this, h, options),
          renderFilterAction.call(this, h, options),
          renderCreateSlot.call(this, h, options),
          renderReportSlot.call(this, h, options)
        ]
      )
    ]
  )
}

function renderSearch(h, options) {
  return h(
    'div',
    {
      class: `${className}__search`
    },
    [
      renderSearchField.call(this, h, options),
      renderActionMenu.call(this, h, options)
    ]
  )
}

function renderFilterSlot(h, options) {
  if (typeof this.$scopedSlots.filter === 'function') {
    return this.$scopedSlots.filter({ filter: this.restData[options.name].find.filter })
  }

  return this.$slots.filter
}
function renderFilter(h, options) {
  if (this.showFilter) {
    return h(
      'div',
      {
        class: `${className}__filter`
      },
      [ renderFilterSlot.call(this, h, options) ]
    )
  }
}

function renderType(h, type) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${className}.types.${type}`),
        size: sizes.small,
        mode: this.type === type ? buttonModes.flat : buttonModes.clear,
        color: this.type === type ? colors.primary : undefined
      },
      on: {
        click: () => {
          this.type = type
        },
        dblclick: event => {
          event.preventDefault()
          this.setDefaultType(type)
        }
      }
    }
  )
}
function renderTypes(h, options) {
  return h(
    'div',
    {
      class: `${className}__types`
    },
    [ 'all', 'visited', 'favorites' ].map(type => {
      return renderType.call(this, h, type, options)
    })
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [
      h(
        preloader,
        {
          props: {
            value: true
          }
        }
      )
    ]
  )
}
function renderItem(h, item) {
  if (typeof this.$scopedSlots.default === 'function') {
    return this.$scopedSlots.default({
      item,
      loading: this.loadingItem[item.id]
    })
  }
}
function renderItems(h, options, items) {
  return h(
    components.list,
    {
      class: 'grid-gap--4',
      props: {
        dense: options.dense,
        transparent: true
      },
      key: this.type
    },
    [ items.map(item => renderItem.call(this, h, item)) ]
  )
}
function renderBody(h, options, items, trigger) {
  if (trigger) {
    return renderItems.call(this, h, options, items)
  } else {
    switch (this.type) {
      case 'visited':
      case 'favorites': {
        if (this.loading[this.type]) {
          return renderPreloader.call(this, h)
        }

        return h(components.empty)
      }
      case 'all':
      default: {
        switch (this.restData[options.name].find.state) {
          case states.loading: return renderPreloader.call(this, h)
          case states.ready:
          default: return h(components.empty)
        }
      }
    }
  }
}

function renderLoadMoreButton(h, options) {
  if (this.type === 'all') {
    if (this.showLoadMore) {
      return h(
        'div',
        {
          class: 'fjcc facc'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.loadMore'),
                mode: buttonModes.flat,
                color: colors.primary,
                loading: this.restData[options.name].find.state === states.loading,
                disabled: this.restData[options.name].find.state === states.loading || !this.showLoadMore
              },
              on: {
                click: () => {
                  if (this.restData[options.name].find.state === states.ready && this.showLoadMore) {
                    this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
                  }
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderContentByType(h, options) {
  const { data, pagination } = this.restData[options.name].find
  switch (this.type) {
    case 'visited':
    case 'favorites':
      return renderBody.call(this, h, options, this[this.type], this[this.type].length > 0)
    case 'all':
    default:
      return renderBody.call(this, h, options, data, pagination.total > 0)
  }
}
function renderScrollContent(h, options) {
  return h(
    'div',
    {
      class: `${className}__scroll`,
      on: { scroll: this.onScroll }
    },
    [
      renderContentByType.call(this, h, options),
      renderLoadMoreButton.call(this, h, options)
    ]
  )
}

function renderContent(h, options) {
  return h(
    'div',
    {
      class: `${className}__content`,
      style: { gridTemplateRows: this.gridTemplateRows }
    },
    [
      renderSearch.call(this, h, options),
      renderFilter.call(this, h, options),
      renderTypes.call(this, h, options),
      renderScrollContent.call(this, h, options)
    ]
  )
}

export default function(h, options) {
  if (options.show || this.$show) {
    return h(
      'div',
      {
        class: className
      },
      [ renderContent.call(this, h, options) ]
    )
  }
}
