import { inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

const serviceNameViaPoint = services['otp-settings/widgets'].split('/').join('.')

function renderNameField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.name,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        disabled: this.disabled,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.name = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.ownerId,
        disabled: this.disabled || !this.isNew,
        dense: true,
        combobox: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.ownerId = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.isActive,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.isActive`),
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}
function renderIsBlockedField(h) {
  if (this.checkPermissions(`advanced.${serviceNameViaPoint}.update`)) {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.isBlocked,
          label: this.getTranslate(`${serviceNameViaPoint}.labels.isBlocked`),
          disabled: this.disabled
        },
        on: {
          input: event => {
            this.proxy.isBlocked = event
          }
        }
      }
    )
  }
}
function renderCaptchaField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.captchaIsRequired,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.captchaIsRequired`),
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.captchaIsRequired = event
        }
      }
    }
  )
}
function renderFlags(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${serviceNameViaPoint}.titles.settings`),
        rounded: true,
        outline: true,
        dashed: true
      }
    },
    [
      renderIsActiveField.call(this, h),
      renderIsBlockedField.call(this, h),
      renderCaptchaField.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderNameField.call(this, h),
      renderOwnerField.call(this, h),
      renderFlags.call(this, h)
    ]
  )
}
