import { cloneDeep } from 'lodash'

import { routerQueryReplace } from '@/utils'
import { serviceName } from '@/components/services/users/utils'

import SettingsUser from '@/components/services/settingsUser/template'
import usersTokens from '@/components/services/users/tokens/template'
import ReferralPayments from '@/components/misc/ReferralPayments'
import UsersRequisites from '@/components/services/users/requisites/template'
import RecurrentPaymentsList from '@/components/services/recurrentPayments/list'
import usersBasicForm from '@/components/services/users/form/basic'
import tariffsGenerator from '@/components/services/tariffs/generator'

function renderTabItem(h, key) {
  return h(
    'div',
    {
      key
    },
    [ renderTabItemContent.call(this, h, key) ]
  )
}

function renderTabItemContent(h, key) {
  switch (key) {
    case 'general': {
      return h(
        usersBasicForm,
        {
          props: {
            value: this.proxy,
            errors: this.errors
          },
          on: {
            input: event => {
              this.proxy = event
            },
            submit: () => {
              this.$emit('submit', this.proxy)
            },
            validation: event => {
              this.$emit('validation', event)
            }
          }
        }
      )
    }

    case 'tariffs': {
      return h(
        tariffsGenerator,
        {
          props: {
            value: this.proxy.Tariffs,
            readonly: true,
            forUsers: true
          },
          on: {
            input: event => {
              this.proxy.Tariffs = event
            }
          }
        }
      )
    }

    case 'settingsUser': {
      return h(
        SettingsUser(
          {
            payload: cloneDeep(this.proxy),
            UserId: this.account.id,
            ResellerId: this.account.ResellerId || this.account.Reseller && this.account.Reseller.id
          }
        ),
        {
          class: 'pa-2'
        }
      )
    }

    case 'tokens': {
      return h(usersTokens, { props: { OwnerId: this.account.id } })
    }

    case 'requisites': {
      return h(
        'div',
        {
          class: 'pt-3 pb-3'
        },
        [
          h(
            UsersRequisites,
            {
              props: {
                OwnerId: this.account.id,
                User: cloneDeep(this.proxy)
              }
            }
          )
        ]
      )
    }

    case 'recurrentPayments': {
      return h(RecurrentPaymentsList())
    }

    case 'referral': {
      return h(ReferralPayments, { props: { OwnerId: this.proxy.id } })
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${serviceName}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              routerQueryReplace({
                currentTab: event,
                settingKey: undefined // Нужно чтобы сбросить под уровень при переходи из вкладки настроек
              })
            }
          }
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => this.tabs.map(tab => {
              return renderTabItem.call(this, h, tab)
            })
          }
        }
      )
    ]
  )
}
