import { colors, icons, modes, permissionPresets } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import sticker from '@/components/misc/sticker'

import { serviceName } from '@/components/services/tariffs/utils'

function renderWarningDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showWarningDialog,
        maxWidth: this.viewport.breakpoint.mdUp ? '400px' : '100%'
      },
      on: {
        input: event => {
          if (event === false) {
            this.similarTariffs = []
          }
          this.showWarningDialog = event
        }
      }
    },
    [
      h(
        HeadTitle, {
          props: { value: this.getTranslate(`${serviceName}.titles.similar`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--16 pa-3'
        },
        [
          h(
            'div',
            {
              class: 'body-2'
            },
            this.getTranslate(`${serviceName}.contents.similar`)
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--8 grid-cols--2'
            },
            this.similarTariffs.map(tariff => {
              return h(
                sticker,
                {
                  props: {
                    value: tariff.title,
                    label: this.getTranslate(`${serviceName}.tariff`),
                    to: {
                      name: `${serviceName}.single`,
                      params: { id: tariff.id }
                    },
                    target: '_blank'
                  }
                }
              )
            })
          )
        ]
      ),

      h(
        'div',
        {
          class: 'pa-2 faic fjcfe grid-gap--8',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: modes.flat,
                rounded: true
              },
              on: {
                click: () => {
                  this.showWarningDialog = false
                  this.similarTariffs = []
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                color: colors.primary
              },
              on: {
                click: () => {
                  this.createTariff(this.processPayload(this.divideByHundred))
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, permissionPresets.true)) {
    return h(
      'div',
      {
        class: `${serviceName}-buttons-double`
      },
      [
        h(
          button,
          {
            props: {
              icon: icons.add,
              color: colors.primary,
              mode: modes.flat,
              loading: this.loading,
              disabled: this.loading
            },
            on: {
              click: () => {
                this.cloneTariff()
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              label: this.getTranslate('tariffs.buttons.clone.divided'),
              mode: modes.flat,
              icon: icons.add,
              color: colors.primary,
              loading: this.loading,
              disabled: this.loading
            },
            on: {
              click: () => {
                this.divideByHundred = true
                this.cloneTariff(true)
              }
            }
          }
        ),

        renderWarningDialog.call(this, h)
      ]
    )
  }
}
