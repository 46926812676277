import proxy from '@sigma-legacy-libs/g-proxy'

import { getRUDate, isValidEmail, isValidPhone, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    ListId: String,

    isFilter: Boolean
  },

  data() {
    return {
      show: false
    }
  },

  computed: {
    computedDate() {
      if (this.proxy.date) {
        return getRUDate(this.proxy.date)
      }

      return this.getTranslate(`${services.contacts}.subheaders.date`)
    },

    customFields() {
      const result = []
      const count = 20
      for (let index = 1; index <= count; index++) {
        const current = index + ''
        result.push(`custom${'0'.repeat(`${count}`.length - current.length) + current}`)
      }

      return result
    },

    errors() {
      const result = {
        phone: undefined,
        email: undefined
      }

      if (!this.isFilter) {
        if (this.proxy.phone && !isValidPhone(this.proxy.phone)) {
          result.phone = this.getTranslate(`${services.contacts}.errors.phone`)
        }

        if (this.proxy.email && !isValidEmail(this.proxy.email)) {
          result.email = this.getTranslate(`${services.contacts}.errors.email`)
        }
      }

      return result
    },

    required() {
      const result = {
        phone: false,
        email: false
      }

      if (!this.isFilter && !this.proxy.email || !!this.emailValidation) {
        result.phone = true
      }
      if (!this.isFilter && !this.proxy.phone || !!this.phoneValidation) {
        result.email = true
      }

      return result
    },

    validation() {
      return isValidPhone(this.proxy.phone) || isValidEmail(this.proxy.email)
    }
  },

  watch: {
    ListId() {
      this.proxy.ListId = this.ListId
    },

    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.proxy.ListId = this.ListId
    this.$emit('validation', this.validation)
  },

  render
}
