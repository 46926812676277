import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, modes, nestjsServices, permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'ButtonServiceRemove',

  props: {
    id: {
      type: String,
      required: true
    },

    service: {
      type: String,
      required: true
    },

    labeled: {
      type: Boolean,
      default: true
    },

    component: {
      type: String,
      default: 'g-button',
      validator: value => {
        return ~[ 'g-button', 'g-list-item', 'btn' ].indexOf(value)
      }
    },

    permissions: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: 'delete'
    },

    callback: {
      type: Function,
      default: undefined
    }
  },

  data() {
    return {
      loading: false,
      showDialog: false
    }
  },

  computed: {
    $props() {
      const commonProps = {
        label: this.labeled ? this.getTranslate('misc.buttons.remove') : undefined,
        icon: this.icon,
        color: 'error'
      }

      switch (this.component) {
        case 'g-button': {
          return {
            ...commonProps,

            flat: true,
            rounded: true
          }
        }
        case 'g-list-item': {
          return {
            ...commonProps,

            dense: true
          }
        }

        case 'btn': {
          return {
            ...commonProps,

            mode: modes.flat
          }
        }
      }
    },

    $permissions() {
      return {
        path: this.permissions.path ?? `advanced.${this.service}.remove`,
        accept: this.permissions.accept ?? permissionPresets.resellerUp
      }
    }
  },

  methods: {
    async remove() {
      if (this.checkPermissions(this.$permissions.path, this.$permissions.accept)) {
        if (typeof this.callback === 'function') {
          await this.callback()
          this.showDialog = false
        } else if (this.id && this.service) {
          try {
            this.loading = true
            const path = []
            if (Object.keys(nestjsServices).includes(this.service)) {
              path.push('n')
            }
            path.push(this.service)
            await Vue.$GRequest.remove(path.join('/'), this.id)
            this.addSnackbar({
              text: this.getTranslate(`${this.service}.snackbars.removed`),
              type: 'warning'
            })
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.loading = false
            this.showDialog = false
          }
        }
      }
    }
  },

  render
}
