export default function(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${serviceName}.set`)) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy,
          label: this.getTranslate(`${serviceName}.labels.${path}`),
          items: this.paymentsMethods.map(method => {
            return {
              title: this.getTranslate(`payments.methods.${method}`),
              value: method
            }
          }),
          multiple: true,
          mode: 'outline-label',
          rounded: true,
          clearable: true
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}
