import button from '@/components/button'
import { icons, inputModes, modes, passwordRegexSoft } from '@/utils'

function renderPasswordFiled(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate('users.labels.password'),
        error: this.errors.password || this.passwordValidation,
        name: 'password',
        afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
        afterIconCallback: () => {
          this.passwordVisibility = !this.passwordVisibility
        },
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        type: this.passwordVisibility ? 'password' : 'text',
        required: this.required,
        tabindex: 2,
        mask: passwordRegexSoft
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderGeneratePasswordButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.settings_backup_restore,
        mode: modes.flat,
        tooltip: this.getTranslate('registration.tooltips.password.generate'),
        tabindex: -1
      },
      on: {
        click: () => {
          this.passwordGenerator()
        }
      }
    }
  )
}

function renderShowButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.passwordEdit'),
        mode: modes.flat,
        tabindex: 2
      },
      on: {
        click: () => {
          this.editable = true
        }
      }
    }
  )
}

export default function(h) {
  if (this.editable || this.isNew) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '1fr auto' }
      },
      [
        renderPasswordFiled.call(this, h),
        renderGeneratePasswordButton.call(this, h)
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'grid faic fjcc'
      },
      [ renderShowButton.call(this, h) ]
    )
  }
}
