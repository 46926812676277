import { components } from '@/utils'

export default function(h) {
  return h(
    components.card,
    {
      class: 'text-editor',
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      h(
        'div',
        {
          attrs: {
            id: this.$id,
            value: this.proxy
          }
        }
      )
    ]
  )
}
