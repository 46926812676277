import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { cloneDeep } from 'lodash'

import { colors, modes, services } from '@/utils'

import button from '@/components/button'
import GenerateDocumentForm from '@/components/services/documentTemplates/generate/form'
import HeadTitle from '@/components/misc/HeadTitle'

function renderHeader(h) {
  return h(
    HeadTitle,
    {
      props: {
        value: this.getTranslate(`${services.invoices}.labels.create`)
      },
      slot: 'header'
    }
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showForm,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),

      h(
        GenerateDocumentForm,
        {
          props: {
            value: this.proxy,
            allowedTypes: [ DOCUMENT_TEMPLATES_TYPES.invoice ],
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: modes.flat
              },
              on: {
                click: () => {
                  this.$emit('show', false)
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                color: colors.primary,
                disabled: !this.isValid
              },
              on: {
                click: () => {
                  // здесь нужен cloneDeep чтобы когда форма GenerateDocumentForm
                  // данные по ссылке снаружи не превратились в undefined
                  // так как у этой формы есть цикл beforeDestroy
                  // который обнуляет все поля
                  this.$emit('input', cloneDeep(this.proxy))
                  this.$emit('show', false)
                }
              }
            }
          )
        ]
      )
    ]
  )
}
