import { types } from './sendings'

export default {
  title: 'Users',

  titles: {
    create: 'Create new user',
    add: {
      funds: 'Add funds'
    },
    token: 'API token',
    presets: 'Presets'
  },

  user: 'User',
  users: 'Users',
  currentTariff: 'Current tariff',

  contents: {
    routingGroups: {
      confirm: {
        remove: 'Confirm remove routing group only from user settings.'
      }
    }
  },

  labels: {
    owner: 'Owner',
    username: 'Username',
    password: 'Password',
    reseller: 'Reseller',
    manager: 'Manager',
    referralCode: 'Users referral code',
    target: 'no sendernames | one sendername | {count} sendernames',

    no: {
      manager: 'No manager'
    },

    data: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail',
      phone: 'Phone',
      partnership: {
        hasContract: 'Has contract'
      },
      meta: {
        source: 'Source',
        usedReferralCode: 'Used referral code'
      }
    },

    settings: {
      sendings: {
        $title: 'Sendings',
        allowDuplicates: {
          title: 'Allow duplicates',
          false: 'No',
          true: 'Yes'
        },
        sendernamesCheck: 'Strict sender names for selected types',
        strictTemplates: Object.assign({ title: 'Strict templates for sendings types' }, types),
        anySendername: Object.assign({ title: 'Any sendername' }, types),
        smpp: { async: 'SMPP async' },
        allow: 'Allow'
      },
      routingGroup: {
        $title: 'Routing group',
        sms: 'SMS routing group',
        viber: 'Viber routing group',
        vk: 'VK routing group',
        whatsapp: 'WhatsApp routing group',
        push: 'Push routing group',
        voice: 'Voice routing group',
        whatsappchat: 'WhatsApp chat routing group',
        viberchat: 'Viber chat routing group',
        vkchat: 'VK chat routing group',
        telegramchat: 'Telegram chat routing group',
        facebookchat: 'Facebook chat routing group',
        flashcall: 'Flash call routing group'
      },
      billing: {
        $title: 'Billing',
        overdraft: { title: 'Overdraft' }
      },
      websocket: {
        $title: 'WEB Socket',
        channels: {
          title: 'Websocket channels',
          dbUpdates: 'DB update',
          everything: 'Everything',
          health: 'Health',
          sendingStatusUpdates: 'Sending status updates'
        }
      },
      smpp: {
        $title: 'SMPP',
        rudeMode: {
          title: 'Rude mode',
          false: 'No',
          true: 'Yes'
        }
      },
      reseller: {
        $title: 'Reseller',
        auth: {
          registration: {
            defaults: {
              title: 'Registration defaults',
              tariffs: { title: 'Default tariff' },
              balance: { title: 'Default balance' },
              routingGroups: { title: 'Default routing groups' },
              patterns: { title: 'Patterns' },
              sendernames: { title: 'Sendernames' }
            }
          }
        },
        enabled: {
          title: 'Reseller enabled',
          false: 'False',
          true: 'True'
        },
        frontend: {
          $title: 'Site settings',
          title: { title: 'Site title' },
          colors: {
            title: 'Colors',
            primary: { title: 'Primary color' },
            secondary: { title: 'Secondary color' },
            accent: { title: 'Accent color' },
            info: { title: 'Info color' },
            warning: { title: 'Warning color' },
            error: { title: 'Error color' },
            success: { title: 'Success color' }
          }
        },
        paymentMethods: {
          title: 'Payment methods',
          key: { title: 'Payment methods' },
          value: {
            title: '',
            key: { title: 'Payment methods settings' },
            value: { title: '' }
          },
          simplepay: { title: 'SimplePay' }
        },
        billings: {
          processChildrenOperations: {
            title: 'Process children account operations as own'
          }
        }
      },
      webhooks: {
        $title: 'Webhooks',
        sendings: {
          title: 'Sendings',
          hookParameters: {
            url: { title: 'Request URL' },
            method: { title: 'Request method' },
            headers: { title: 'Request headers' },
            body: { title: 'Request body' },
            statuses: {
              title: 'Status mapping',
              seen: { title: 'Seen' },
              processing: { title: 'Processing' },
              paused: { title: 'Paused' },
              delivered: { title: 'Delivered' },
              failed: { title: 'Failed' },
              pending: { title: 'Pending' },
              sent: { title: 'Sent' }
            },
            numericStatuses: { title: 'Numeric statuses' },
            maxConnections: { title: 'Maximum connections amount' },
            successOn: {
              title: 'Conditions for successful request',
              status: { title: 'Regexp check on status' },
              body: { title: 'Regexp check on response body' }
            }
          }
        }
      }
    },

    permissions: {
      name: {
        create: 'Create',
        finalize: 'Finalize',
        find: 'Find',
        generate: 'Generate',
        set: 'Set',
        get: 'Get',
        remove: 'Remove',
        update: 'Update',
        modify: 'Modify',
        seek: 'Seek',
        download: 'Download',
        upload: 'Upload',
        setPermissions: 'Set permissions',
        setRoutingGroups: 'Set routing groups',
        setSettings: 'Set settings',
        setSendernames: 'Set sendernames',
        setPatterns: 'Set patterns',
        setTariffs: 'Set tariffs',
        showSecrets: 'Show secret permissions',
        health: 'Health',
        loginAs: 'Login as',
        request: 'Request',
        controlDocuments: 'Control documents',
        edit: 'Edit',
        viewChangelogs: 'View changelogs',
        contracts: 'Contracts',
        companies: 'Companies',
        documentTemplates: 'Document templates',
        refund: 'Refund payment',
        presetUsersBalance: 'Download user list'
      }
    },

    sendernames: {
      strict: 'Name must match one of selected below',
      names: 'Sender names'
    },

    patterns: {
      strict: 'Message must comply with one of those patterns',
      names: 'Patterns'
    }
  },

  dialogs: {
    new: 'New user'
  },

  subheaders: {
    routingGroups: 'Routing groups',
    sendings: 'Sendings',
    billing: 'Billing',
    websocket: 'Websocket',
    smpp: 'SMPP',
    partnership: 'Partnership',
    permissions: {
      billings: 'Billings',
      dictionaries: 'Dictionaries',
      providerGroups: 'Provider groups',
      providers: 'Providers',
      routingGroups: 'Routing groups',
      routingRules: 'Routing rules',
      sendings: 'Sendings',
      storage: 'Storage',
      tariffs: 'Tariffs',
      users: 'Users',
      platform: 'Platform',
      reports: 'Reports',
      components: 'Components',
      tasks: 'Tasks',
      contacts: 'Contacts',
      payment: 'Payment',
      settings: 'Site settings',
      messageProcessors: 'Message processors',
      templates: 'Templates',
      patterns: 'Patterns',
      sendernames: 'Sendernames',
      requisites: 'Requisites',
      sendingsIncoming: 'Incoming messages',
      changelogs: 'Changelogs',
      contracts: 'Contracts',
      companies: 'Companies',
      documentTemplates: 'Document templates',
      operatorGroups: 'Operator groups',
      secret: 'Secret',
      paymentLogs: 'Payments logs',
      recurrentPayments: 'Recurrent payments'
    },
    sendernames: 'Sender names',
    patterns: 'Patterns'
  },

  permissions: {
    allowAnySendername: 'Allow any sendername',
    allowSending: 'Allow sending',
    smppAsync: 'SMPP async',
    options: {
      true: 'true',
      false: 'false',
      me: 'me'
    }
  },

  tabs: {
    general: 'General',
    settings: 'Settings',
    settingsUser: 'Settings',
    permissions: 'Permissions',
    tariffs: 'Tariffs',
    routingGroups: 'Routes',
    processMessages: 'Process messages',
    requisites: 'Requisites',
    whatsapp: 'WhatsApp',
    recurrentPayments: 'Recurrent payments',
    referral: 'Referral Payment',
    tokens: 'Tokens',
    eventsHistory: 'Events history',
    eventsHistoryTab: 'History of changes',
    userComments: 'Comments',
    userChannels: 'OTP Channels'
  },

  warnings: {
    apiKey:
      'Unfortunately, generated API key exceeds permissible length. Please, try changing username and reloading page afterwards, or contact your manager for help.'
  },

  tooltips: {
    name: 'Name: {name}',
    overdraft: 'Overdraft: {overdraft}',
    reseller: 'Reseller: {reseller}',
    manager: 'Manager: {manager}',

    add: {
      funds: 'Add funds'
    },

    generate: {
      document: 'Download document'
    },

    copy: {
      apiKey: 'Copy API key',

      referral: {
        code: 'Copy referral code',
        link: 'Copy referral link'
      }
    }
  },

  buttons: {
    generate: {
      document: 'Download document'
    },
    account: 'Profile',
    reseller: 'Reseller',
    me: 'User',
    false: 'False'
  },

  hints: {
    title: 'Fine tuning'
  },

  snackbars: {
    created: 'User successfully created.',
    updated: 'User successfully updated.',
    removed: 'User successfully removed.',
    linkCopied: 'Link copied successfully.',
    refCopied: 'Referral code copied successfully.',
    copy: {
      apiKey: 'API key copied successfully.'
    }
  }
}
