import { colors, modes } from '@/utils'

import button from '@/components/button'

export function renderReleaseBucketButton(h, options = {}) {
  if (this.restData[options.name].find.bucketLength > 0) {
    return h(
      button,
      {
        props: {
          label: this.restData[options.name].find.bucketLength,
          color: colors.primary,
          mode: modes.flat,
          icon: 'add',
          disabled: this.restData[options.name].find.state === 'loading'
        },
        on: {
          click: () => {
            this.rest[options.name].bucketRelease()
          }
        }
      }
    )
  }
}
