import { colors, modes, services, states } from '@/utils'

import button from '@/components/button'
import preloader from '@/components/misc/preloader'
import HeadTitle from '@/components/misc/HeadTitle'
import ContactsForm from '@/components/services/contacts/form'
import ContactListsForm from '@/components/services/contactLists/form'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc',
      style: {
        minHeight: '400px'
      }
    },
    [
      h(
        preloader, {
          props: {
            value: true,
            size: 12
          }
        }
      )
    ]
  )
}

function renderHeader(h) {
  if (this.type) {
    const [ service, type ] = this.type.split('.')

    return h(
      HeadTitle,
      {
        props: { value: this.getTranslate(`${service}.dialogs.titles.${type}`) },
        slot: 'header'
      }
    )
  }
}
function renderBody(h) {
  switch (this.type) {
    case `${services.contacts}.create`: {
      return h(
        ContactsForm,
        {
          class: 'pa-2',
          props: {
            value: this.restData.contacts.create.data,
            errors: this.restData.contacts.create.errors
          },
          on: {
            input: event => {
              this.restData.contacts.create.data = event
            },
            submit: () => {
              this.create(`${services.contacts}`)
            },
            validation: event => {
              this.restData.contacts.create.isValid = event
            }
          }
        }
      )
    }
    case `${services.contacts}.update`: {
      if (this.restData.contacts.get.state === states.ready) {
        return h(
          ContactsForm,
          {
            class: 'pa-2',
            props: {
              value: this.restData.contacts.get.data,
              errors: this.restData.contacts.update.errors,
              ListId: this.ListId
            },
            on: {
              input: event => {
                this.restData.contacts.get.data = event
              },
              submit: () => {
                this.update(`${services.contacts}`)
              },
              validation: event => {
                this.restData.contacts.update.isValid = event
              }
            }
          }
        )
      } else if (this.restData.contacts.get.state === states.loading) {
        return renderPreloader.call(this, h)
      }
      break
    }
    case `${services.contacts}.remove`: {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate(`${services.contacts}.contents.confirm.remove`)
      )
    }
    case `${services.contactLists}.create`: {
      return h(
        ContactListsForm,
        {
          class: 'px-2 pt-2',
          props: {
            value: this.restData.contactLists.create.data,
            errors: this.restData.contactLists.create.errors
          },
          on: {
            input: event => {
              this.restData.contactLists.create.data = event
            },
            submit: () => {
              this.create(`${services.contactLists}`)
            },
            validation: event => {
              this.restData.contactLists.create.isValid = event
            }
          }
        }
      )
    }
    case `${services.contactLists}.update`: {
      if (this.restData.contactLists.get.state === states.ready) {
        return h(
          ContactListsForm,
          {
            class: 'px-2 pt-2',
            props: {
              value: this.restData.contactLists.get.data,
              errors: this.restData.contactLists.update.errors
            },
            on: {
              input: event => {
                this.restData.contactLists.get.data = event
              },
              submit: () => {
                this.update(`${services.contactLists}`)
              },
              validation: event => {
                this.restData.contactLists.update.isValid = event
              }
            }
          }
        )
      } else if (this.restData.contacts.get.state === states.loading) {
        return renderPreloader.call(this, h)
      }
      break
    }
    case `${services.contactLists}.remove`: {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate(`${services.contactLists}.contents.confirm.remove`)
      )
    }
  }
}
function renderFooter(h) {
  if (this.actionByType) {
    const { service, method } = this.actionByType

    return h(
      'div',
      {
        class: 'fjcfe facfe grid-gap--8 pa-2',
        slot: 'footer'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              mode: modes.flat,
              disabled: this.restData[service][method].state === states.loading
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              label: this.getTranslate(`misc.buttons.${method}`),
              loading: this.restData[service][method].state === states.loading,
              disabled: this.restData[service][method].state === states.loading || method !== 'remove' && !this.restData[service][method].isValid,
              color: method === 'remove' ? colors.error : colors.primary
            },
            on: {
              click: () => {
                this[method](service)
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: 420
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
