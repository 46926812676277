import { buttonModes, colors, components, icons, sizes } from '@/utils'

import render from './render'

export default {
  name: 'ButtonCopy',

  props: {
    value: [ String, Number ],

    mode: {
      type: String,
      default: buttonModes.flat,
      validator: value => {
        return Object.values(buttonModes).includes(value)
      }
    },

    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },

    color: {
      type: String,
      default: colors.primary,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    withIcon: {
      type: Boolean,
      default: true
    },
    icon: String,
    iconSVG: {
      type: String,
      default: 'copy'
    },

    tooltip: String,
    loading: Boolean,
    disabled: Boolean,

    small: Boolean,
    large: Boolean,

    label: String,

    view: {
      type: String,
      default: 'btn',
      validator: value => {
        return [ 'btn', components['list-item'] ].includes(value)
      }
    },

    snackbar: {
      type: Object,
      default: undefined
    }
  },

  data() {
    return {
      buttonCallback: undefined
    }
  },

  computed: {
    iconSize() {
      return this.small ? 16 : this.large ? 22 : 18
    },

    $snackbar() {
      if (this.snackbar) {
        return this.snackbar
      }

      return {
        text: this.getTranslate('misc.copied'),
        type: colors.success
      }
    },

    $props() {
      const result = {
        mode: this.mode,
        size: this.size,
        color: this.color,
        label: this.label,
        tooltip: this.tooltip,
        loading: this.loading,
        disabled: this.disabled
      }

      if (this.withIcon) {
        result.icon = this.icon
        result.iconSVG = !this.icon ? this.iconSVG : undefined

        if (this.buttonCallback) {
          result.icon = icons.done
          result.iconSVG = undefined
          result.color = colors.success
        }
      }

      return result
    }
  },

  methods: {
    clickHandler() {
      navigator.clipboard.writeText(this.value)
      this.addSnackbar(this.$snackbar)

      this.buttonCallback = true
      setTimeout(() => {
        this.buttonCallback = undefined
      }, 1000)
    }
  },

  render
}
