import { icons, services } from '@/utils'

import noData from '@/components/misc/noData'
import date from '@/components/serviceTemplate/item/date'
import owner from '@/components/serviceTemplate/item/owner'
import removeButton from '@/components/services/tokens/item/removeButton'
import sticker from '@/components/misc/sticker'

function renderNoData(h, value, icon) {
  return h(
    noData,
    {
      props: {
        icon,
        value: this.getTranslate(`${services.tokens}.labels.noData.${value}`),
        label: this.getTranslate(`${services.tokens}.labels.${value}`)
      }
    }
  )
}

function renderDescription(h) {
  if (this.value.description) {
    return h(
      sticker,
      {
        props: {
          value: this.value.description,
          label: this.getTranslate(`${services.tokens}.labels.description`)
        }
      }
    )
  }

  return renderNoData.call(this, h, 'description')
}
function renderOwner(h) {
  if (this.hasOwner) {
    if (this.viewport.size.width > 600) {
      return h(
        owner,
        {
          props: {
            value: this.value
          }
        }
      )
    }
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-panel__info`
    },
    [
      renderDescription.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderRemoveButton(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-panel__actions`
    },
    [
      h(
        removeButton,
        {
          props: {
            value: this.value
          }
        }
      )
    ]
  )
}

function renderExpiredAt(h) {
  if (this.viewport.size.width > 600) {
    if (this.value.expiredAt) {
      return h(
        date,
        {
          props: {
            value: this.value,
            pathToDate: 'expiredAt'
          }
        }
      )
    }

    return renderNoData.call(this, h, 'expiredAt', icons.date_range)
  }
}
function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.tokens}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderRemoveButton.call(this, h),
      renderExpiredAt.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
