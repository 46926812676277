import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import SendernameSelect from '@/components/misc/SendernameSelect'

export default function(h, { path, serviceName }) {
  return h(
    SendernameSelect,
    {
      props: {
        value: this.proxy,
        type: SENDING_TYPES.sms,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        mode: 'outline-label',
        dense: true,
        required: false
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
