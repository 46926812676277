import { REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, inputModes, permissionPresets, sizes } from '@/utils'
import { bikRegExp, innRegExp, kppRegExp, ksRegExp, ogrnRegExp, okpoRegExp, opfList, rsRegExp, serviceName } from '@/components/services/requisites/utils'

import tag from '@/components/tag'
import info from '@/components/misc/info'
import OwnerSelect from '@/components/misc/OwnerSelect'
import RequisitesFormAddress from '@/components/services/requisites/formAddress'

function renderValidation(h, state) {
  return h(
    'div',
    {
      class: 'faic fjcfe'
    },
    [
      h(
        tag,
        {
          props: {
            label: this.getTranslate(`${serviceName}.hints.validation.state.${state}`),
            color: state === true ? colors.success : colors.error,
            size: sizes.small
          }
        }
      )
    ]
  )
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.requisites.create', permissionPresets.true)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--16',
        style: {
          'grid-template-columns': this.viewport.breakpoint.mdUp ? 'repeat(2, 1fr)' : '1fr'
        }
      },
      [
        h(
          OwnerSelect,
          {
            props: {
              value: this.proxy.OwnerId,
              hint: this.getTranslate(`${serviceName}.hints.owner`),
              dense: true,
              combobox: true,
              disabled: this.ownerSelectDisabled
            },
            on: {
              input: event => {
                this.proxy.OwnerId = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderAutoFilling(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--16 pa-3',
      props: {
        title: this.getTranslate(`${serviceName}.titles.autoFilling`),
        outline: true,
        rounded: true,
        dashed: true
      }
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--16 faifs': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.suggestion.inn,
                label: this.getTranslate(`${serviceName}.labels.data.legal.inn`),
                hint: this.getTranslate(`${serviceName}.hints.suggestions.inn`),
                mode: inputModes['line-label'],
                rounded: true,
                dense: true,
                details: true,
                clearable: true,
                combobox: true,
                hideSelections: true,
                loading: this.loading.inn,
                items: this.suggestions.inn,
                mask: /^\d{1,10}$|^\d{1,12}$/
              },
              on: {
                search: event => {
                  this.getRequisitesByInn(event)
                },
                input: event => {
                  this.suggestion.inn = event
                }
              },
              scopedSlots: {
                selection: ({ item }) => {
                  return h(
                    tag,
                    {
                      props: {
                        label: item.title,
                        as: 'select-dense'
                      }
                    }
                  )
                }
              }
            }
          ),
          h(
            'g-select',
            {
              props: {
                value: this.suggestion.bank,
                label: this.getTranslate(`${serviceName}.labels.suggestions.bik`),
                hint: this.getTranslate(`${serviceName}.hints.suggestions.bik`),
                mode: inputModes['line-label'],
                rounded: true,
                dense: true,
                details: true,
                clearable: true,
                combobox: true,
                hideSelections: true,
                loading: this.loading.bank,
                items: this.suggestions.bank
              },
              on: {
                search: event => {
                  this.getSuggestions(event, 'bank')
                },
                input: event => {
                  this.suggestion.bank = event
                }
              },
              scopedSlots: {
                selection: ({ item }) => {
                  return h(
                    tag,
                    {
                      props: {
                        label: item.title,
                        as: 'select-dense'
                      }
                    }
                  )
                }
              }
            }
          )
        ]
      ),
      h(
        'g-select',
        {
          props: {
            value: this.suggestion.address,
            label: this.getTranslate(`${serviceName}.labels.suggestions.address`),
            hint: this.getTranslate(`${serviceName}.hints.suggestions.address`),
            mode: inputModes['line-label'],
            rounded: true,
            dense: true,
            details: true,
            clearable: true,
            combobox: true,
            hideSelections: true,
            loading: this.loading.address,
            items: this.suggestions.address
          },
          on: {
            search: event => {
              this.getSuggestions(event, 'address')
            },
            input: event => {
              this.suggestion.address = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                tag,
                {
                  props: {
                    label: item.title,
                    as: 'select-dense'
                  }
                }
              )
            }
          }
        }
      ),
      h(
        info,
        {
          props: {
            value: this.getTranslate(`${serviceName}.hints.suggestion`),
            color: colors.primary
          }
        }
      )
    ]
  )
}

function renderContact(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--12 pa-3',
      props: {
        title: this.getTranslate(`${serviceName}.subheaders.contact`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.contactValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.contactValidation),

      h(
        'div',
        {
          class: 'grid grid-gap--16'
        },
        [
          h(
            'div',
            {
              class: 'faic grid grid-gap--8',
              style: {
                'grid-template-columns': '36px 1fr'
              }
            },
            [
              h(
                'g-switch',
                {
                  props: {
                    value: this.contactMatches
                  },
                  on: {
                    input: event => {
                      this.contactMatches = event
                    }
                  }
                }
              ),

              h(
                'div',
                {
                  class: 'text--grey caption'
                },
                [ this.getTranslate(`${serviceName}.labels.contact.matches`) ]
              )
            ]
          ),

          h(
            'div',
            {
              class: {
                'grid grid-gap--16 faifs': true,
                'grid-cols--2': this.viewport.breakpoint.mdUp
              }
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-gap--16'
                },
                [
                  h(
                    'g-text-field',
                    {
                      attrs: { id: `${serviceName}.form.contact.lastName` },
                      props: {
                        value: this.proxy.contact.lastName,
                        label: this.getTranslate(`${serviceName}.labels.contact.lastName`),
                        error: this.errors['contact.lastName'],
                        mode: inputModes['line-label'],
                        disabled: this.contactMatches,
                        loading: this.loading.Owner,
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.contact.lastName = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      attrs: { id: `${serviceName}.form.contact.firstName` },
                      props: {
                        value: this.proxy.contact.firstName,
                        label: this.getTranslate(`${serviceName}.labels.contact.firstName`),
                        error: this.errors['contact.firstName'],
                        mode: inputModes['line-label'],
                        disabled: this.contactMatches,
                        loading: this.loading.Owner,
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.contact.firstName = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.contact.middleName,
                        label: this.getTranslate(`${serviceName}.labels.contact.middleName`),
                        mode: inputModes['line-label'],
                        disabled: this.contactMatches,
                        loading: this.loading.Owner,
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          this.proxy.contact.middleName = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                'div',
                {
                  class: 'grid grid-gap--16'
                },
                [
                  h(
                    'g-text-field',
                    {
                      attrs: { id: `${serviceName}.form.contact.email` },
                      props: {
                        value: this.proxy.contact.email,
                        label: this.getTranslate(`${serviceName}.labels.contact.email`),
                        error: this.emailValidation,
                        mode: inputModes['line-label'],
                        disabled: this.contactMatches,
                        loading: this.loading.Owner,
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.contact.email = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      attrs: {
                        id: `${serviceName}.form.contact.phone`,
                        type: 'text'
                      },
                      props: {
                        value: this.proxy.contact.phone,
                        label: this.getTranslate(`${serviceName}.labels.contact.phone`),
                        error: this.phoneValidation,
                        mode: inputModes['line-label'],
                        disabled: this.contactMatches,
                        loading: this.loading.Owner,
                        dense: true,
                        rounded: true,
                        details: false,
                        validateOnBlur: true,
                        required: true
                      },
                      on: {
                        input: event => {
                          this.proxy.contact.phone = event
                        }
                      }
                    }
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderSigner(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--12 pa-3',
      props: {
        title: this.getTranslate(`${serviceName}.subheaders.signer`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.signerValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.signerValidation),

      h(
        'div',
        {
          class: 'grid grid-gap--16 faifs'
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.proxy.signer.fullName.nominative,
                label: this.getTranslate(`${serviceName}.labels.signer.fullName.nominative`),
                hint: this.getTranslate(`${serviceName}.hints.suggestions.fio`),
                items: this.suggestions.fio,
                mode: inputModes['line-label'],
                dense: true,
                rounded: true,
                details: true,
                combobox: true,
                required: true,
                hideSelections: true,
                validateOnBlur: true,
                loading: this.loading.fio
              },
              on: {
                search: event => {
                  this.getSuggestions(event, 'fio')
                },
                input: event => {
                  this.suggestion.fio = event
                }
              },
              scopedSlots: {
                selection: ({ item }) => {
                  return h(
                    tag,
                    {
                      props: {
                        label: item.title,
                        as: 'select-dense'
                      }
                    }
                  )
                }
              }
            }
          ),

          h(
            'div',
            {
              class: {
                'grid grid-gap--16 faifs': true,
                'grid-cols--2': this.viewport.breakpoint.mdUp
              }
            },
            [
              h(
                'g-card',
                {
                  class: 'grid grid-gap--12 pa-3',
                  props: {
                    title: this.getTranslate(`${serviceName}.hints.nominative`),
                    outline: true,
                    rounded: true,
                    dashed: true
                  }
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.signer.fullName.nominative,
                        label: this.getTranslate(`${serviceName}.labels.signer.fullName.nominative`),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.signer.fullName.nominative = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.signer.position.nominative,
                        label: this.getTranslate(`${serviceName}.labels.signer.position.nominative`),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.signer.position.nominative = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.signer.basis.nominative,
                        label: this.getTranslate(`${serviceName}.labels.signer.basis.nominative`),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.signer.basis.nominative = event
                        }
                      }
                    }
                  )
                ]
              ),
              h(
                'g-card',
                {
                  class: 'grid grid-gap--12 pa-3',
                  props: {
                    title: this.getTranslate(`${serviceName}.hints.genitive`),
                    outline: true,
                    rounded: true,
                    dashed: true
                  }
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.signer.fullName.genitive,
                        label: this.getTranslate(`${serviceName}.labels.signer.fullName.genitive`),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.signer.fullName.genitive = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.signer.position.genitive,
                        label: this.getTranslate(`${serviceName}.labels.signer.position.genitive`),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.signer.position.genitive = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.signer.basis.genitive,
                        label: this.getTranslate(`${serviceName}.labels.signer.basis.genitive`),
                        mode: inputModes['line-label'],
                        dense: true,
                        rounded: true,
                        clearable: true,
                        details: false,
                        required: true,
                        validateOnBlur: true
                      },
                      on: {
                        input: event => {
                          this.proxy.signer.basis.genitive = event
                        }
                      }
                    }
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderLegal(h) {
  if (this.proxy.type === REQUISITE_TYPES.business) {
    return h(
      'g-card',
      {
        class: 'grid grid-gap--12 pa-3',
        props: {
          title: this.getTranslate(`${serviceName}.subheaders.legal`),
          outline: true,
          rounded: true,
          dashed: true,
          accentColorName: this.legalValidation ? colors.success : colors.error
        }
      },
      [
        renderValidation.call(this, h, this.legalValidation),
        h(
          'div',
          {
            class: 'grid faic grid-gap--0'
          },
          [
            h(
              'div',
              {
                class: {
                  'grid grid-gap--16 faifs': true,
                  'grid-cols--2': this.viewport.breakpoint.mdUp
                }
              },
              [
                h(
                  'g-select',
                  {
                    props: {
                      value: this.proxy.data.legal.opf,
                      label: this.getTranslate(`${serviceName}.labels.data.legal.opf`),
                      hint: this.getTranslate(`${serviceName}.hints.data.legal.opf`),
                      items: opfList,
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      details: true,
                      combobox: true,
                      required: true,
                      setOnBlur: true,
                      hideSelections: true,
                      validateOnBlur: true
                    },
                    on: {
                      input: event => {
                        this.proxy.data.legal.opf = event
                      }
                    },
                    scopedSlots: {
                      selection: ({ item }) => {
                        return h(
                          tag,
                          {
                            props: {
                              label: item.title,
                              as: 'select-dense'
                            }
                          }
                        )
                      }
                    }
                  }
                ),

                h(
                  'g-text-field',
                  {
                    props: {
                      value: this.proxy.data.legal.name,
                      label: this.getTranslate(`${serviceName}.labels.data.legal.name`),
                      hint: this.getTranslate(`${serviceName}.hints.data.legal.name`),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      details: true,
                      required: true,
                      validateOnBlur: true
                    },
                    on: {
                      input: event => {
                        this.proxy.data.legal.name = event
                      }
                    }
                  }
                ),

                h(
                  'g-text-field',
                  {
                    props: {
                      value: this.proxy.data.legal.inn,
                      label: this.getTranslate(`${serviceName}.labels.data.legal.inn`),
                      hint: this.getTranslate(`${serviceName}.hints.data.legal.inn`),
                      error: this.checkField(
                        this.proxy.data.legal.inn,
                        innRegExp,
                        this.getTranslate(`${serviceName}.errors.inn`)
                      ),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      details: true,
                      required: true,
                      validateOnBlur: true,
                      mask: /^\d{1,10}$|^\d{1,12}$/
                    },
                    on: {
                      input: event => {
                        this.proxy.data.legal.inn = event
                      }
                    }
                  }
                ),

                h(
                  'g-text-field',
                  {
                    props: {
                      value: this.proxy.data.legal.ogrn,
                      label: this.getTranslate(`${serviceName}.labels.data.legal.ogrn`),
                      hint: this.getTranslate(`${serviceName}.hints.data.legal.ogrn`),
                      error: this.checkField(
                        this.proxy.data.legal.ogrn,
                        ogrnRegExp,
                        this.getTranslate(`${serviceName}.errors.ogrn`)
                      ),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      details: true,
                      required: true,
                      validateOnBlur: true,
                      mask: /^\d{1,15}$/
                    },
                    on: {
                      input: event => {
                        this.proxy.data.legal.ogrn = event
                      }
                    }
                  }
                )
              ]
            ),
            h(
              'div',
              {
                class: 'faic'
              },
              [
                h(
                  'g-checkbox',
                  {
                    style: 'margin-left: -14px',
                    props: {
                      value: this.proxy.data.tax.vat
                    },
                    on: {
                      input: event => {
                        this.proxy.data.tax.vat = event
                      }
                    }
                  }
                ),
                h(
                  'div',
                  {
                    class: 'text--grey caption',
                    style: 'margin-left: -6px'
                  },
                  [ this.getTranslate(`${serviceName}.labels.data.tax.vat`) ]
                )
              ]
            ),

            h(
              'g-card',
              {
                class: 'grid grid-gap--12 pa-3',
                props: {
                  title: this.getTranslate(`${serviceName}.titles.notRequired`),
                  outline: true,
                  rounded: true,
                  dashed: true
                }
              },
              [
                h(
                  'div',
                  {
                    class: {
                      'grid grid-gap--16 faifs': true,
                      'grid-cols--2': this.viewport.breakpoint.mdUp
                    }
                  },
                  [
                    h(
                      'g-text-field',
                      {
                        props: {
                          value: this.proxy.data.legal.kpp,
                          label: this.getTranslate(`${serviceName}.labels.data.legal.kpp`),
                          hint: this.getTranslate(`${serviceName}.hints.data.legal.kpp`),
                          error: this.checkField(
                            this.proxy.data.legal.kpp,
                            kppRegExp,
                            this.getTranslate(`${serviceName}.errors.kpp`)
                          ),
                          mode: inputModes['line-label'],
                          dense: true,
                          rounded: true,
                          clearable: true,
                          details: true,
                          mask: /^(\d{1,9})$/
                        },
                        on: {
                          input: event => {
                            this.proxy.data.legal.kpp = event
                          }
                        }
                      }
                    ),

                    h(
                      'g-text-field',
                      {
                        props: {
                          value: this.proxy.data.legal.okpo,
                          label: this.getTranslate(`${serviceName}.labels.data.legal.okpo`),
                          hint: this.getTranslate(`${serviceName}.hints.data.legal.okpo`),
                          error: this.checkField(
                            this.proxy.data.legal.okpo,
                            okpoRegExp,
                            this.getTranslate(`${serviceName}.errors.okpo`)
                          ),
                          mode: inputModes['line-label'],
                          dense: true,
                          rounded: true,
                          clearable: true,
                          details: true,
                          mask: /^\d{1,8}$|^\d{1,10}$/
                        },
                        on: {
                          input: event => {
                            this.proxy.data.legal.okpo = event
                          }
                        }
                      }
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]
    )
  }
}

function renderBank(h) {
  if (this.proxy.type === REQUISITE_TYPES.business) {
    return h(
      'g-card',
      {
        class: 'grid grid-gap--12 pa-3',
        props: {
          title: this.getTranslate(`${serviceName}.subheaders.bank`),
          outline: true,
          rounded: true,
          dashed: true,
          accentColorName: this.bankValidation ? colors.success : colors.error
        }
      },
      [
        renderValidation.call(this, h, this.bankValidation),
        h(
          'div',
          {
            class: {
              'grid grid-gap--16 faifs': true,
              'grid-cols--2': this.viewport.breakpoint.mdUp
            }
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.name,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.name`),
                  hint: this.getTranslate(`${serviceName}.hints.data.bank.name`),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.name = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.rs,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.rs`),
                  hint: this.getTranslate(`${serviceName}.hints.data.bank.rs`),
                  error: this.checkField(
                    this.proxy.data.bank.rs,
                    rsRegExp,
                    this.getTranslate(`${serviceName}.errors.rs`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,20}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.rs = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.ks,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.ks`),
                  hint: this.getTranslate(`${serviceName}.hints.data.bank.ks`),
                  error: this.checkField(
                    this.proxy.data.bank.ks,
                    ksRegExp,
                    this.getTranslate(`${serviceName}.errors.ks`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,20}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.ks = event
                  }
                }
              }
            ),

            h(
              'g-text-field',
              {
                props: {
                  value: this.proxy.data.bank.bik,
                  label: this.getTranslate(`${serviceName}.labels.data.bank.bik`),
                  hint: this.getTranslate(`${serviceName}.hints.data.bank.bik`),
                  error: this.checkField(
                    this.proxy.data.bank.bik,
                    bikRegExp,
                    this.getTranslate(`${serviceName}.errors.bik`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,9}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.bik = event
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderLegalAddress(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--12 pa-3',
      props: {
        title: this.getTranslate(`${serviceName}.subheaders.legalAddress`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.legalAddressValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.legalAddressValidation),

      h(
        RequisitesFormAddress,
        {
          props: {
            value: this.proxy.data.legalAddress
          },
          on: {
            input: event => {
              this.proxy.data.legalAddress = event
            }
          }
        }
      )
    ]
  )
}

function renderPostalAddress(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--12 pa-3',
      props: {
        title: this.getTranslate(`${serviceName}.subheaders.postalAddress`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.postalAddressValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.postalAddressValidation),

      h(
        RequisitesFormAddress,
        {
          props: {
            value: this.proxy.data.postalAddress,
            address: this.proxy.data.legalAddress,
            matches: this.proxy.type === REQUISITE_TYPES.business
          },
          on: {
            input: event => {
              this.proxy.data.postalAddress = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--32 pa-3'
    },
    [
      renderOwner.call(this, h),
      renderAutoFilling.call(this, h),
      renderContact.call(this, h),
      renderSigner.call(this, h),
      renderLegal.call(this, h),
      renderBank.call(this, h),
      renderLegalAddress.call(this, h),
      renderPostalAddress.call(this, h)
    ]
  )
}
