import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'FileItems',

  props: {
    items: {
      type: Array,
      default: () => []
    },

    order: {
      type: Object,
      default: () => ({})
    },

    currentChosenFileId: String,

    readonly: Boolean,
    embedded: Boolean
  },

  computed: {
    editable() {
      const permissions = [ `advanced.${services.storage}.update`, `advanced.${services.storage}.remove` ]

      return this.checkPermissions(permissions, permissionPresets.meUp) && !this.readonly
    },

    $_class() {
      const result = { 'grid grid-gap--8 px-2': true }
      if (this.viewport.size.width > 1600) {
        if (!this.embedded) {
          result['grid-cols--4'] = true
        } else {
          result['grid-cols--3'] = true
        }
      } else if (this.viewport.size.width > 960) {
        result['grid-cols--3'] = true
      } else if (this.viewport.size.width > 600) {
        result['grid-cols--2'] = true
      }

      return result
    }
  },

  render
}
