import { modes, states } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: 'refresh',
        label: this.label,
        mode: modes.flat,
        loading: typeof this.state === 'boolean' ? this.state : this.state === states.loading,
        disabled: this.computedDisabled,
        tooltip: this.tooltip
      },
      on: {
        mousedown: this.clickHandler,
        mouseup: this.clickHandler,
        touchstart: this.clickHandler,
        touchend: this.clickHandler
      }
    }
  )
}
