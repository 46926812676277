import { buttonModes, colors, components, icons, permissionPresets, popperPlacements, services } from '@/utils'

import button from '@/components/button'

function wrapperForCheckPermission(permission, content, type, accept = permissionPresets.meUp) {
  if (this.checkPermissions(`advanced.${services.storage}.${permission}`, accept) && ~this.include.indexOf(type)) {
    return content
  }
}

function isDocOrExcel(type, content) {
  if (/(ms-?word|wordprocessingml)/.test(type) || /(ms-excel|spreadsheetml)/.test(type)) {
    return content
  }
}

function renderListItem(h, type) {
  const props = {
    icon: undefined,
    label: undefined,
    color: undefined,
    dense: true
  }

  switch (type) {
    case 'edit': {
      props.icon = icons.edit
      props.label = this.getTranslate('misc.buttons.edit')
      break
    }
    case 'download': {
      props.icon = icons.cloud_download
      props.label = this.getTranslate('misc.buttons.download')
      props.color = colors.primary
      break
    }
    case 'export': {
      props.icon = icons.picture_as_pdf
      props.label = this.getTranslate('misc.buttons.exports.pdf')
      props.color = colors.primary
      break
    }
    case 'update': {
      props.icon = icons.info
      props.label = this.getTranslate('misc.buttons.properties')
      break
    }
    case 'remove': {
      props.icon = icons.delete
      props.label = this.getTranslate('misc.buttons.remove')
      props.color = colors.error
      break
    }
  }

  return h(
    'g-list-item',
    {
      props,
      on: {
        click: () => {
          switch (type) {
            case 'edit': {
              this.editFile()
              break
            }
            case 'download': {
              this.downloadFile()
              break
            }
            case 'export': {
              this.exportFile()
              break
            }
            case 'update': {
              this.editMetaFile()
              break
            }
            case 'remove': {
              this.removeFile()
              break
            }
          }
        }
      }
    }
  )
}

export default function(h) {
  if (this.editable) {
    return h(
      components.menu,
      {
        props: {
          disabled: this.loading,
          placement: popperPlacements['bottom-end'],
          offsetDistance: 8,
          rounded: true
        },
        key: `file-menu:${this.value.id}`
      },
      [
        h(
          button,
          {
            props: {
              icon: icons.more_vert,
              mode: buttonModes.flat,
              loading: this.loading,
              disabled: this.loading
            },
            slot: 'activator'
          }
        ),

        h(
          components.list,
          {
            props: {
              dense: true,
              rounded: true
            }
          },
          [
            isDocOrExcel.call(
              this,
              this.value.type,
              wrapperForCheckPermission.call(
                this,
                'edit', // TODO нужны правки схемы прав стораджа
                renderListItem.call(this, h, 'edit'),
                'edit'
              )
            ),

            wrapperForCheckPermission.call(
              this,
              'download',
              renderListItem.call(this, h, 'download'),
              'download'
            ),

            isDocOrExcel.call(
              this,
              this.value.type,
              wrapperForCheckPermission.call(
                this,
                'download',
                renderListItem.call(this, h, 'export'),
                'export'
              )
            ),

            wrapperForCheckPermission.call(
              this,
              'update',
              renderListItem.call(this, h, 'update'),
              'update'
            ),

            wrapperForCheckPermission.call(
              this,
              'remove',
              renderListItem.call(this, h, 'remove'),
              'remove'
            ),

            this.$slots.default
          ]
        )
      ]
    )
  }
}
