import { components, eventHandlers, form } from '@/utils'

import render from './render'

export default {
  name: components.button,

  mixins: [ form, eventHandlers ],

  props: {
    value: null,

    name: String,
    color: String,
    icon: String,
    prefix: String,
    suffix: String,

    tiny: Boolean,
    small: Boolean,
    large: Boolean,
    fab: Boolean,
    flat: Boolean,
    block: Boolean,
    round: Boolean,
    rounded: Boolean,
    toolbar: Boolean,
    outline: Boolean,
    depressed: Boolean,
    fixed: Boolean,
    absolute: Boolean,
    loading: Boolean,
    disabled: Boolean,
    autofocus: Boolean,

    label: [ String, Number ],
    tabindex: [ String, Number ],

    top: [ String, Number, Boolean ],
    bottom: [ String, Number, Boolean ],
    left: [ String, Number, Boolean ],
    right: [ String, Number, Boolean ],

    type: {
      type: String,
      default: 'button'
    }
  },

  render
}
