import { components, inputModes, services } from '@/utils'

import template from '@/components/services/settingsSite/template'

function renderResellerSelect(h) {
  if (this.checkPermissions(`advanced.${services.settingsSite}.get`)) {
    return h(
      components.select,
      {
        style: { 'max-width': this.viewport.breakpoint.mdUp ? '260px' : undefined },
        props: {
          value: this.UserId,
          label: this.getTranslate(`${services.settingsSite}.labels.reseller`),
          itemsDisabled: [ this.account.id ],
          service: services.users,
          query: { isActive: true },
          itemTitle: 'username',
          mode: inputModes.outline,
          dense: true,
          rounded: true,
          clearable: true,
          details: false,
          autocomplete: true
        },
        on: {
          input: event => {
            this.UserId = event
          }
        }
      }
    )
  }
}

export default function(h) {
  const props = {
    rounded: true,
    outline: true
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'max-width': '800px' }
    },
    [
      renderResellerSelect.call(this, h),

      h(
        template({
          UserId: this.UserId,
          card: { props },
          panels: { props }
        })
      )
    ]
  )
}
