import Vue from 'vue'

import { inputModes, proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsMethodSelect',

  mixins: [ proxy() ],

  props: {
    OwnerId: String,

    items: {
      type: Array,
      default: []
    },

    mode: {
      type: String,
      default: inputModes.outline,
      validator: value => Object.values(inputModes).includes(value)
    },

    as: {
      type: String,
      default: 'select',
      validator: value => [ 'select', 'buttons' ].includes(value)
    },

    dense: Boolean,
    clearable: Boolean,
    required: Boolean,
    disabled: Boolean
  },

  data() {
    return {
      methods: []
    }
  },

  computed: {
    $items() {
      return this.methods.map(value => ({
        title: this.getTranslate(`${services.payments}.methods.${value}`),
        value
      }))
    },

    $disabled() {
      if (this.disabled) {
        return true
      }

      if (this.required && this.$items.length === 1) {
        return true
      }

      if (this.$items.length === 0) {
        return true
      }
    }
  },

  watch: {
    methods: {
      handler() {
        if (this.required) {
          if (this.methods.length) {
            if (!this.methods.includes(this.proxy)) {
              this.proxy = this.methods[0]
            }
          } else {
            this.proxy = undefined
          }
        }
      },
      deep: true
    }
  },

  async mounted() {
    await this.getPaymentsMethods()
    if (this.required) {
      if (this.methods.length) {
        if (!this.proxy) {
          this.proxy = this.methods[0]
        }
      }
    }
  },

  methods: {
    async getPaymentsMethods() {
      const UserId = this.OwnerId || this.account.id
      if (UserId) {
        let { data } = await Vue.$GRequest.get(services.payments, 'methods', { query: { UserId } })
        if (data) {
          if (this.items?.length) {
            data = data.filter(value => this.items.includes(value))
          }
          this.methods = data
          this.$emit('methods', data)
        }
      }
    }
  },

  render
}
