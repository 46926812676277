import Vue from 'vue'

import { isUUID, projectName } from '@/utils'

const prefixKey = service => `${projectName}:favorites:${service}`
const setItems = (service, items) => window.localStorage.setItem(prefixKey(service), JSON.stringify(items))

export default {
  namespaced: true,

  state: {
    items: {}
  },

  mutations: {
    add(state, { service, id }) {
      if (isUUID(id)) {
        if (!Array.isArray(state.items[service])) {
          Vue.set(state.items, service, [])
          state.items[service] = []
        }
        if (!state.items[service].includes(id)) {
          state.items[service].splice(0, 0, id)
          setItems(service, state.items[service])
        }
      }
    },
    remove(state, { service, id }) {
      const index = state.items[service].findIndex(item => item === id)
      if (~index) {
        state.items[service].splice(index, 1)
        setItems(service, state.items[service])
      }
    },
    refresh(state, { service, items }) {
      Vue.set(state.items, service, items)
      setItems(service, items)
    }
  },

  actions: {
    async add({ commit }, params) {
      commit('add', params)
    },
    async remove({ commit }, params) {
      commit('remove', params)
    },
    async refresh({ commit }, service) {
      try {
        const item = window.localStorage.getItem(prefixKey(service))
        if (item) {
          const items = JSON.parse(item)
          if (Array.isArray(items)) {
            await commit('refresh', {
              service,
              items
            })

            return items
          }
        }
      } catch (error) {
        return []
      }
    },
    async check({ state, dispatch }, { service, id }) {
      await dispatch('refresh', service)

      if (Array.isArray(state.items[service])) {
        return state.items[service].includes(id)
      }

      return false
    },
    async toggle({ dispatch }, params) {
      const isFavorite = await dispatch('check', params)
      if (isFavorite) {
        dispatch('remove', params)
      } else {
        dispatch('add', params)
      }
    }
  },

  getters: {
    items: state => state.items
  }
}
