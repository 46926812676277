import render from './render'

export default {
  name: 'RowWithMessageTypeIcon',

  props: {
    value: String,
    title: String,

    dense: {
      type: Boolean,
      default: true
    },

    as: {
      type: String,
      default: 'tag',
      validator: value => {
        return ~[ 'text', 'tag' ].indexOf(value)
      }
    }
  },

  computed: {
    attributes() {
      const result = {
        class: [ 'grid', 'grid-gap--8', 'faic' ],
        style: {}
      }

      const gridTemplateColumns = [ '1fr' ]
      if (this.as === 'text') {
        gridTemplateColumns.splice(0, 0, '28px')
      }
      result.style.gridTemplateColumns = gridTemplateColumns.join(' ')

      return result
    }
  },

  render
}
