import { colors, inputModes, modes, services, sizes } from '@/utils'

import button from '@/components/button'

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'headline pa-2'
    },
    this.getTranslate(`${services.keywords}.titles.matches`)
  )
}

function renderTextField(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.text,
        label: this.getTranslate(`${services.keywords}.labels.text`),
        mode: inputModes['line-label'],
        rounded: true,
        grow: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.text = event
        }
      }
    }
  )
}

function renderMatchWord(h, word) {
  return h(
    button,
    {
      props: {
        label: word,
        size: sizes.small,
        color: colors.primary,
        mode: modes.flat
      },
      on: {
        click: () => this.setKeyword(word)
      }
    }
  )
}
function renderMatchesWords(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc pa-5'
      },
      [
        h(
          'g-progress',
          {
            props: { indeterminate: true }
          }
        )
      ]
    )
  } else if (Array.isArray(this.words) && this.words.length) {
    return h(
      'div',
      {
        class: 'faic fjcfs grid-gap--8 pa-2'
      },
      [ this.words.map(word => renderMatchWord.call(this, h, word)) ]
    )
  }

  return h('g-empty')
}
function renderMatchesResult(h) {
  return h(
    'g-card',
    {
      style: { borderStyle: 'dashed' },
      props: {
        outline: true,
        rounded: true
      }
    },
    [ renderMatchesWords.call(this, h) ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.match'),
            color: colors.primary,
            mode: modes.flat,
            loading: this.loading,
            disabled: this.loading || !this.text
          },
          on: {
            click: () => this.matchText()
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faifs grid ptpx-48'
    },
    [
      h(
        'g-card',
        {
          class: 'grid grid-gap--8 pa-2',
          props: {
            rounded: true,
            width: '100%'
          }
        },
        [
          renderTitle.call(this, h),
          renderTextField.call(this, h),
          renderMatchesResult.call(this, h),
          renderFooter.call(this, h)
        ]
      )
    ]
  )
}
