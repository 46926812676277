import Vue from 'vue'

import { get } from 'lodash'

import { colors, modes, services } from '@/utils'

import registrationForm from '@/components/services/registration/form'
import info from '@/components/misc/info'
import button from '@/components/button'

const maxWidth = 400

function renderPlatformTitle(h) {
  const title = get(this.globalSettings, 'frontend.title')
  if (title) {
    return h(
      'div',
      {
        class: 'headline text--grey'
      },
      [ title ]
    )
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 px-3',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      h(
        'div',
        {
          class: 'headline'
        },
        [ this.getTranslate(`${services.registration}.title`) ]
      ),

      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderBody(h) {
  return h(
    'g-card',
    {
      class: 'grid pa-3',
      props: {
        outline: true,
        rounded: true,
        overflow: true,
        minWidth: this.viewport.breakpoint.smUp ? maxWidth : '100%',
        maxWidth,
        width: '100%'
      }
    },
    [
      h(
        registrationForm,
        {
          props: {
            value: this.payload,
            errors: this.errors
          },
          on: {
            input: event => {
              this.payload = event
            },
            submit: () => {
              this.registration(this.payload)
            },
            validation: event => {
              this.validation = event
            }
          }
        }
      ),

      h(
        info,
        {
          props:
          {
            value: this.getTranslate(`${services.registration}.hints.required`),
            color: colors.info
          }
        }
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.registration}.buttons.signin`),
            mode: modes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              Vue.router.push({ name: 'login' })
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.registration}.buttons.signup`),
            mode: modes.filled,
            color: colors.primary,
            loading: this.loading,
            disabled: !this.validation
          },
          on: {
            click: () => {
              this.registration(this.payload)
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: {
        'max-width': `${maxWidth}px`,
        width: '100%'
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
