import { components, eventHandlers, isHEX, numberToPxOrString } from '@/utils'

import render from './render'

export default {
  name: components.icon,

  mixins: [ eventHandlers ],

  props: {
    value: String,
    color: String,

    link: Boolean,
    left: Boolean,
    right: Boolean,

    size: {
      type: Number,
      default: 24
    }
  },

  computed: {
    $class() {
      const result = {
        [`${components.icon}`]: true,
        [`${components.icon}--link`]: this.link,
        [`${components.icon}--left`]: this.left,
        [`${components.icon}--right`]: this.right
      }
      if (this.color && !isHEX(this.color)) {
        result[`${components.icon}--${this.color}`] = true
      }

      const icon = this.value || Array.isArray(this.$slots.default) && this.$slots.default[0].text
      if (icon) {
        result[`${components.icon}__icon--${icon}`] = true
      }

      return result
    },

    $style() {
      const result = { 'font-size': numberToPxOrString(this.size) }
      if (isHEX(this.color)) {
        result.color = this.color
      }

      return result
    }
  },

  render
}
