import { get } from 'lodash'

import { getPrice } from '@/utils'

import entityTable from '@/components/misc/EntityTable'
import HintRow from '@/components/misc/HintRow'
import SingleLink from '@/components/misc/SingleLink'

import { serviceName, stateColors } from '@/components/services/apisender/utils'

function renderIsActiveField(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    return h(
      'div',
      {
        class: 'fjcfe px-3'
      },
      [
        h(
          'g-switch',
          {
            props: { value: this.proxy.isActive },
            directives: [
              {
                name: 'g-tooltip',
                options: {
                  value: this.getTranslate(`${serviceName}.tooltips.isActive`),
                  placement: 'left'
                }
              }
            ],
            on: {
              input: event => {
                this.proxy.isActive = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'fjcsb faic pl-3'
    },
    [
      h(
        'div',
        {
          class: 'fz-24'
        },
        this.value.phone || this.getTranslate(`${serviceName}.hints.phoneNotFound`)
      ),

      renderIsActiveField.call(this, h)
    ]
  )
}

function renderWarning(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, [ 'me', 'reseller' ])) {
    if (!this.proxy.isActive) {
      return h(
        HintRow,
        {
          props: {
            error: this.getTranslate(`${serviceName}.hints.isActive`),
            copy: false
          }
        }
      )
    }
  }
}

function renderStateValue(h, state) {
  return h(
    'div',
    {
      class: `text--${stateColors[state]}`
    },
    [ this.getTranslate(`${serviceName}.states.instance.${state}`) ]
  )
}

function renderIsPaidStateValue(h, state) {
  return h(
    'div',
    {
      class: `text--${state ? 'success' : 'error'}`
    },
    [ this.getTranslate(`${serviceName}.states.isPaid.${state}`) ]
  )
}

function renderDataTable(h) {
  const rows = [
    {
      title: this.getTranslate(`${serviceName}.labels.isPaid`),
      value: renderIsPaidStateValue.call(this, h, this.value.isPaid)
    },
    {
      title: this.getTranslate(`${serviceName}.labels.instance.state`),
      value: renderStateValue.call(this, h, this.value.$state)
    },
    {
      title: this.getTranslate(`${serviceName}.labels.instance.expiration`),
      value: new Date(get(this.value, 'expiredAt')).toLocaleDateString('ru') + ' ' + new Date(get(this.value, 'expiredAt')).toLocaleTimeString('ru')
    }
  ]

  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    rows.push(
      {
        title: this.getTranslate(`${serviceName}.labels.instance.apiKey`),
        value: get(this.value, 'instance.apiKey'),
        copy: true
      },
      {
        title: this.getTranslate(`${serviceName}.labels.instance.apiUrl`),
        value: get(this.value, 'instance.apiUrl'),
        copy: true
      }
    )
  }

  if (this.checkPermissions('advanced.users.get')) {
    rows.push({
      title: this.getTranslate(`${serviceName}.labels.owner`),
      value: h(SingleLink, { props: { value: this.proxy.Owner || this.proxy.OwnerId } })
    })
  }

  return h(entityTable, { props: { rows } })
}

function renderIsCanceled(h) {
  return h(
    'g-switch',
    {
      class: 'mx-3',
      props: {
        value: this.proxy.isCanceled,
        label: this.getTranslate(`${serviceName}.labels.isCanceled`),
        trueValue: false,
        falseValue: true
      },
      on: {
        input: event => {
          this.proxy.isCanceled = event
        }
      }
    }
  )
}

function renderUpdateHint(h) {
  if (this.proxyChanged) {
    return h(
      HintRow,
      {
        props: {
          value: this.getTranslate('misc.hints.need.save')
        }
      }
    )
  }
}

function renderContent(h) {
  if (this.isNew) {
    if (this.priceByTariff) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 pa-3'
        },
        [ this.getTranslate(`${serviceName}.hints.create`, { payment: getPrice(this.priceByTariff, { currency: this.currency }) }) ]
      )
    } else {
      return h(
        HintRow,
        {
          props: {
            error: this.getTranslate(`${serviceName}.errors.tariff-required`),
            copy: false
          }
        }
      )
    }
  }

  return h(
    'div',
    {
      class: 'grid py-3'
    },
    [
      renderHeader.call(this, h),
      renderWarning.call(this, h),
      renderDataTable.call(this, h),
      renderIsCanceled.call(this, h),
      renderUpdateHint.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [ renderContent.call(this, h) ]
  )
}
