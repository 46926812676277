import { colors, icons, modes, permissionPresets, services } from '@/utils'

import token from '@/components/services/tokens/item/token'
import description from '@/components/services/tokens/item/description'
import date from '@/components/serviceTemplate/item/date'
import button from '@/components/button'

function renderRemoveButton(h) {
  if (this.checkPermissions(`advanced.${services.tokens}.remove`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: {
          'faic fjcfe': true,
          'hover-child': this.viewport.breakpoint.mdUp
        }
      },
      [
        h(
          button,
          {
            props: {
              mode: modes.flat,
              icon: icons.delete,
              color: colors.error,
              loading: this.loading,
              disabled: this.disabled
            },
            on: {
              click: () => this.$emit('remove', this.value.id)
            }
          }
        )
      ]
    )
  }
}

function renderDescription(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  if (this.value.description) {
    return h(
      description,
      {
        props: {
          value: this.value
        }
      }
    )
  }

  return h('div')
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        'hover-parent hover-parent--opacity': true,
        [`${services.tokens}-panel`]: this.viewport.breakpoint.xs
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: {
            gridTemplateColumns: this.gridTemplateColumns
          }
        },
        [
          h(
            token,
            {
              props: {
                value: this.value,
                isPage: false
              },
              on: {
                showDialog: event => this.$emit('showDialog', event)
              }
            }
          ),
          renderDescription.call(this, h),
          renderRemoveButton.call(this, h)
        ]
      ),
      renderCreatedAt.call(this, h)
    ]
  )
}
