const sources = {
  utm_source: 'Campaign source ({param})',
  utm_medium: 'Type ({param})',
  utm_campaign: 'Campaign name ({param})',
  utm_content: 'Ad identifier ({param})',
  utm_term: 'Keyword ({param})'
}

export default {
  title: 'UTM tag generator',

  titles: {
    generate: 'Generate UTM tag',

    params: {
      required: 'Required parameters',
      optional: 'Optional parameters'
    },

    ...sources
  },

  labels: {
    protocol: 'Protocol',
    url: 'Your page address',

    ...sources,

    custom: 'Custom values',
    google: 'Google Ads',
    yandex: 'Yandex.Direct',
    vk: 'VKontakte',

    links: {
      full: 'Full link',
      short: 'Short link'
    }
  },

  hints: {
    custom: {
      utm_source: 'Advertising platform',
      utm_medium: 'Campaign or ad type',
      utm_campaign: 'Allows you to distinguish campaigns in statistics',
      utm_content: 'Additional tracking parameter',
      utm_term: 'Keyword that triggered the ad display'
    },
    google: {
      utm_source: 'Advertising platform',
      utm_medium: 'Campaign or ad type',
      utm_campaign: 'Google Ads will replace {network} with "g" (search), "s" (search partner), or "d" (display network)',
      utm_content: 'Google Ads will replace {creative} with the ad ID',
      utm_term: 'Google Ads will replace {keyword} with the keyword'
    },
    yandex: {
      utm_source: 'Advertising platform',
      utm_medium: 'Campaign or ad type',
      utm_campaign: 'Yandex.Direct will replace {campaign_id} with the campaign ID',
      utm_content: 'Yandex.Direct will replace {ad_id} with the ad ID',
      utm_term: 'Yandex.Direct will replace {keyword} with the keyword'
    },
    vk: {
      utm_source: 'Advertising platform',
      utm_medium: 'Campaign or ad type',
      utm_campaign: 'VKontakte will replace {campaign_id} with the campaign ID',
      utm_content: 'VKontakte will replace {ad_id} with the ad ID',
      utm_term: 'Keyword that triggered the ad display'
    }
  },

  references: {
    utm_source: '<strong>utm_source</strong> — the name of the advertising platform.<br/><br/>\
                <strong>Purpose:</strong><br/>To specify the traffic source name.<br/><br/>\
                <strong>Examples:</strong>\
                <ul class="ma-0">\
                  <li>utm_source=google – contextual ads on Google Ads.</li>\
                  <li>utm_source=yandex — contextual ads on Yandex.Direct.</li>\
                  <li>utm_source=vk — contextual ads on VKontakte.</li><ul/>',

    utm_medium: '<strong>utm_medium</strong> — type of advertising.<br/><br/>\
                It is recommended to use established values, such as:\
                cpc (cost per click) — contextual advertising, display — banner ads with payment per impression,\
                social_cpc — social media ads with payment per click.<br/><br/>\
                <strong>Purpose:</strong><br/>To identify the type of campaign or ad.<br/><br/>\
                <strong>Examples:</strong>\
                <ul class="ma-0">\
                  <li>utm_medium=organic – free traffic.</li>\
                  <li>utm_medium=cpc – contextual advertising (cost per click).</li>\
                  <li>utm_medium=email — email marketing.</li>\
                  <li>utm_medium=social — social media.</li>\
                  <li>utm_medium=banner — display advertising.</li>\
                  <li>utm_medium=cpa — other advertising (cost per action).</li><ul/>',

    utm_campaign: '<strong>utm_campaign</strong> — campaign name.<br/><br/>\
                  This mandatory parameter can be defined freely.<br/><br/>\
                  <strong>Purpose:</strong><br/>Allows you to differentiate between campaigns in statistics.<br/><br/>\
                  <strong>Examples:</strong>\
                  <ul class="ma-0">\
                  <li>utm_campaign=home_furniture — home furniture ad campaign.</li><ul/>',

    utm_content: '<strong>utm_content</strong> — additional information to track if other parameters are identical.<br/><br/>\
                  <strong>Purpose:</strong><br/>Often used as a marker for the ad within a campaign.\
                  The name can be set freely; it is convenient to use important ad features —\
                  product or service subcategory, ad type, etc.<br/><br/>\
                  <strong>Examples:</strong>\
                  <ul class="ma-0">\
                  <li>utm_content=leadmagnet_webinar0404 — product promotion in a lead magnet offered during a webinar on April 4.</li><ul/>',

    utm_term: '<strong>utm_term</strong> — keyword that triggered the ad display.<br/><br/>\
              This mandatory parameter can be set freely.<br/><br/>\
              <strong>Purpose:</strong><br/>Allows you to differentiate between campaigns in statistics.<br/><br/>\
              <strong>Examples:</strong>\
              <ul class="ma-0">\
              <li>utm_term=buy_sneakers — promoting sneakers to the user.</li><ul/>'
  },

  tooltips: {
    generate: 'Generate UTM tag',
    reference: 'Reference for {param}'
  },

  snackbars: {
    error: 'Failed to create short link'
  }
}
