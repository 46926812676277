import { components, popperPlacements, services } from '@/utils'

import buttonToolbar from '@/components/button/toolbar'
import widget from '@/components/services/notifications/widget'

function renderButton(h, slot, content) {
  return h(
    buttonToolbar,
    {
      props: {
        iconSVG: services.notifications,
        tooltip: this.getTranslate(`misc.buttons.${services.notifications}`)
      },
      slot,
      scopedSlots: { dialog: () => content },
      on: {
        click: () => {
          this.show = !this.show
        }
      }
    }
  )
}
function renderContent(h) {
  return h(
    widget,
    {
      props: { show: this.show },
      on: {
        show: event => {
          this.show = event
        }
      }
    }
  )
}

export default function(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      components.menu,
      {
        props: {
          closeOnContentClick: false,
          placement: popperPlacements['bottom-end'],
          offsetDistance: 16,
          transparent: true
        }
      },
      [
        renderButton.call(this, h, 'activator'),
        renderContent.call(this, h)
      ]
    )
  }

  return renderButton.call(this, h, undefined, renderContent.call(this, h))
}
