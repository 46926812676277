import render from './render'

export default {
  name: 'PaymentsItem',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    currency() {
      return this.value.currency || this.globalSettings.billings.currency
    }
  },

  render
}
