import { inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import PaymentsOffer from '@/components/services/payments/offer'
import PaymentsMethodSelect from '@/components/services/payments/method'

function renderAmountField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.amount,
        label: this.getTranslate(`${services.payments}.labels.amount`),
        hint: this.commissionHint,
        suffix: this.commissionSuffix,
        disabled: this.loading,
        mode: inputModes['line-label'],
        type: 'number',
        name: 'amount',
        min: 1,
        step: 1,
        rounded: true,
        autofocus: true,
        clearable: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.amount = event
        }
      }
    }
  )
}
function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        label: this.getTranslate(`${services.payments}.hints.owner`),
        mode: inputModes['line-label'],
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderAmountAndOwner(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderAmountField.call(this, h),
      renderOwnerField.call(this, h)
    ]
  )
}
function renderMethodField(h) {
  if (this.paymentsMethods.length > 0) {
    return h(
      PaymentsMethodSelect,
      {
        props: {
          value: this.proxy.method,
          disabled: this.loading,
          required: true
        },
        on: {
          input: event => {
            this.proxy.method = event
          }
        }
      }
    )
  }
}
function renderForm(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--24 faic'
    },
    [
      renderMethodField.call(this, h),
      renderAmountAndOwner.call(this, h)
    ]
  )
}

function renderOffer(h) {
  return h(
    PaymentsOffer,
    {
      class: 'fjcc',
      props: {
        value: this.proxy.acceptOffer,
        method: this.proxy.method
      },
      on: {
        input: event => {
          this.proxy.acceptOffer = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8 faic'
    },
    [
      renderForm.call(this, h),
      renderOffer.call(this, h)
    ]
  )
}
