import { colors, components, icons } from '@/utils'

import render from './render'

export default {
  name: 'CopyIcon',

  props: {
    value: [ String, Number, Boolean ],

    snackbar: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: icons.content_copy
    },
    size: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: colors.grey
    },

    tooltip: String,

    marginless: Boolean
  },

  computed: {
    text() {
      switch (typeof this.value) {
        case 'number':
        case 'boolean': return this.value.toString()
        case 'string':
        default: return this.value
      }
    },

    directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: components.tooltip,
          options: { value: this.tooltip }
        })
      }

      return result
    }
  },

  methods: {
    clickHandler() {
      navigator.clipboard.writeText(this.text)
      if (this.snackbar.text || this.snackbar.type) {
        this.addSnackbar(this.snackbar)
      } else {
        this.addSnackbar({
          text: this.getTranslate('misc.copied'),
          type: colors.success
        })
      }
    }
  },

  render
}
