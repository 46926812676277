import numeral from 'numeral'

import { colors, getLocaleDateString, getLocaleTimeString } from '@/utils'

import render from './render'
import { getFileMiscInfo, getFileSizeColor } from '../utils'

export default {
  name: 'FileInfo',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    tags() {
      const { type, color } = getFileMiscInfo(this.value)

      const size = this.value.size
      const sizeColor = getFileSizeColor(size)
      const createdAt = this.value.createdAt
      const year = new Date(createdAt).getFullYear()
      const currentYear = new Date().getFullYear()
      const dateOptions = {
        month: 'numeric',
        day: 'numeric'
      }
      if (year !== currentYear) {
        dateOptions.year = 'numeric'
      }
      const timeOptions = {
        locale: this.locale,
        hour: '2-digit',
        minute: '2-digit',
        second: false
      }
      const result = [
        {
          label: [
            getLocaleDateString(createdAt, this.locale, dateOptions),
            getLocaleTimeString(createdAt, timeOptions)
          ].join(' '),
          tooltip: [
            getLocaleDateString(createdAt, this.locale, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }),
            getLocaleTimeString(createdAt, { milliseconds: true })
          ].join(' '),
          color: colors.grey
        },
        {
          label: type,
          tooltip: this.value.type,
          color
        },
        {
          label: numeral(size).format('0b'),
          tooltip: numeral(size).format('0.000b'),
          color: sizeColor
        }
      ]

      return result
    }
  },

  render
}
