import { components } from '@/utils'

import FileType from '@/components/file/type'
import FileInfo from '@/components/file/info'
import FileMenu from '@/components/file/menu'

function renderType(h) {
  return h(
    FileType,
    {
      props: {
        value: this.file,
        small: this.small,
        readonly: this.readonly
      }
    }
  )
}
function renderInfo(h) {
  if (!this.compact) {
    return h(
      FileInfo,
      {
        props: {
          value: this.file,
          readonly: this.readonly
        }
      }
    )
  }
}
function renderMenu(h) {
  if (this.$slots.default) {
    return this.$slots.default
  }

  if (this.displayMenu) {
    return h(
      FileMenu,
      {
        props: {
          value: this.file,
          readonly: this.readonly,
          include: this.include
        },
        on: {
          dialog: event => {
            this.$emit('dialog', event)
          }
        }
      }
    )
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: {
        'pa-2': this.outline,
        file__card: true,
        'file__card--current': this.currentChosenFileId === this.file.id
      }
    },
    [
      renderType.call(this, h),
      renderInfo.call(this, h),
      renderMenu.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.file && this.file.id) {
    return h(
      components.card,
      {
        props: {
          rounded: this.rounded,
          outline: this.outline,
          flat: this.flat,
          hover: this.readonly,
          maxWidth: this.maxWidth,
          minWidth: this.minWidth,
          width: this.width
        },
        on: {
          click: () => {
            if (this.readonly) {
              this.$emit('input', this.file)
            }
          }
        }
      },
      [ renderContent.call(this, h) ]
    )
  }
}
