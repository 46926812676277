import { DOCUMENT_TEMPLATES_TYPES, PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

export const DEFAULT_INVOICE_PAYLOAD = {
  amount: 1000,
  requisiteId: undefined,
  companyId: undefined,
  customerId: undefined,
  type: DOCUMENT_TEMPLATES_TYPES.invoice
}
export const DEFAULT_PAYMENT_PAYLOAD = {
  method: PAYMENTS_METHODS[0],
  amount: 3000,
  acceptOffer: true,
  OwnerId: undefined
}

export default {
  DEFAULT_INVOICE_PAYLOAD,
  DEFAULT_PAYMENT_PAYLOAD
}
