import { get } from 'lodash'

import { getPrice, isStringNotEmpty, modes, permissionPresets, states } from '@/utils'

import { serviceName } from '@/components/services/users/utils'

import BillingsForm from '@/components/services/billings/form'
import EventsHistoryTab from '@/components/services/eventsHistory/tab'
import HeadTitle from '@/components/misc/HeadTitle'
import BalanceOverdraft from '@/components/misc/BalanceOverdraft'
import DocumentTemplatesGenerateButton from '@/components/services/documentTemplates/generate/button'
import OneRowString from '@/components/misc/OneRowString'
import ReportButton from '@/components/button/report'
import EntityInfo from '@/components/misc/EntityInfo'
import copyIcon from '@/components/copy/icon'
import Copy from '@/components/misc/Copy'
import userComments from '@/components/services/userComments/template'
import button from '@/components/button'

function renderLoginAsButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0 clipboard-button',
      props: {
        icon: 'exit_to_app',
        flat: true,
        color: 'grey',
        loading: this.statusLoginAs === states.loading,
        disabled: this.statusLoginAs === states.loading || !this.checkPermissions('secret.loginAs', permissionPresets.resellerUp)
      },
      on: {
        mousedown: this.clickHandler,
        mouseup: this.clickHandler,
        touchstart: this.clickHandler,
        touchend: this.clickHandler
      },
      scopedSlots: {
        dialog: () => renderLoginAsDialog.call(this, h)
      }
    }
  )
}
function renderLoginAsDialog(h) {
  if (this.checkPermissions('secret.loginAs', permissionPresets.resellerUp)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showTokenDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showTokenDialog = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'faic pa-3',
            slot: 'header'
          },
          [
            h('div', { class: 'headline' }, this.getTranslate('misc.key')),
            h('div', { class: 'ff' }),
            h(
              copyIcon,
              {
                props: {
                  value: this.token,
                  size: 24
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: 'pa-3'
          },
          [
            h(
              'g-textarea',
              {
                props: {
                  value: this.token,
                  hint: isStringNotEmpty(this.token) ? `${this.token.length}` : '0',
                  mode: 'outline',
                  rounded: true,
                  readonly: true,
                  grow: true,
                  autofocus: true
                },
                ref: 'token'
              }
            )
          ]
        ),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  flat: true,
                  rounded: true
                },
                on: {
                  click: () => {
                    this.showTokenDialog = false
                    this.token = undefined
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderAddFundsButton(h, { id, settings }) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'add',
        flat: true,
        disabled: !this.checkPermissions('advanced.billings.generate', permissionPresets.resellerUp)
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate('users.tooltips.add.funds'),
            placement: 'top'
          }
        }
      ],
      on: {
        click: () => {
          this.restData.billings.create.data.OwnerId = id
          this.showAddFunds = true
        }
      },
      scopedSlots: {
        dialog: () => renderAddFundsDialog.call(this, h, {
          id,
          settings
        })
      }
    }
  )
}
function renderAddFundsDialog(h, { id, settings }) {
  if (this.checkPermissions('advanced.billings.generate', permissionPresets.resellerUp)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showAddFunds,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showAddFunds = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate('users.titles.add.funds') },
            slot: 'header'
          }
        ),

        h(
          BillingsForm,
          {
            props: {
              value: this.restData.billings.create.data,
              errors: this.restData.billings.create.errors,
              owner: id,
              currency: get(settings, 'billings.currency')
            },
            on: {
              input: event => {
                this.restData.billings.create.data = event
              },
              validation: event => {
                this.restData.billings.create.isValid = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'faic fjcfe grid-gap--8 pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: modes.flat
                },
                on: {
                  click: () => {
                    this.showAddFunds = false
                  }
                }
              }
            ),
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  loading: this.restData.billings.create.state === 'loading',
                  disabled: !this.createDataIsValid,
                  color: 'primary'
                },
                on: {
                  click: async () => {
                    await this.createBilling()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderReseller(h, item) {
  const reseller = get(item, 'Reseller.username')
  if (reseller) {
    return h('div', { class: 'caption text--grey' }, reseller)
  }
}
function renderManager(h, item) {
  const manager = get(item, 'data.partnership.Manager.username')
  if (manager) {
    return h('div', { class: 'caption text--grey' }, manager)
  }
}

function renderTab(h, data) {
  switch (this.tab) {
    case 'userComments': {
      return h(userComments(data.id))
    }
    case 'eventsHistoryTab': {
      return h(
        EventsHistoryTab, {
          props: {
            service: serviceName,
            entityId: this.$route.params.id
          }
        }
      )
    }
  }
}

function renderTabItem(h, key, data) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        outline: true,
        width: '100%'
      },
      key
    },
    [ renderTab.call(this, h, data) ]
  )
}

function renderTabs(h, data) {
  if (this.computedTabs.length) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        h(
          'g-tabs-header',
          {
            class: 'pl-3',
            props: {
              value: this.tab,
              tabs: this.computedTabs.map(key => {
                return {
                  key,
                  title: this.getTranslate(`${serviceName}.tabs.${key}`)
                }
              })
            },
            on: {
              input: event => {
                this.tab = event
              }
            }
          }
        ),

        h(
          'g-tabs',
          {
            props: { value: this.tab },
            scopedSlots: {
              body: () => this.computedTabs.map(tab => renderTabItem.call(this, h, tab, data))
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  let report
  if (this.checkPermissions('advanced.reports.presetUsersBalance', permissionPresets.resellerUp)) {
    report = () => {
      return h(
        ReportButton,
        {
          props: {
            preset: 'users_balance',
            icon: false,
            type: 'list-item'
          }
        }
      )
    }
  }

  return h(
    'div',
    {
      class: serviceName
    },
    [
      h(
        'search-generator',
        {
          scopedSlots: {
            default: ({ item }) => {
              return h(
                'g-list-item',
                {
                  props: {
                    active: item.id === this.$route.params.id,
                    to: {
                      name: 'users.single',
                      params: { id: item.id }
                    }
                  },
                  key: item.id
                },
                [
                  h(
                    'div',
                    {
                      class: 'fc ff'
                    },
                    [
                      h(
                        'div',
                        {
                          class: 'fjcsb faic'
                        },
                        [
                          h(
                            OneRowString,
                            {
                              props: {
                                value: item.username,
                                direction: 'right',
                                length: 20
                              }
                            }
                          ),

                          h(
                            'div',
                            {
                              class: 'text--grey caption'
                            },
                            [ getPrice(item.balance, { currency: get(item.settings, 'billings.currency') }) ]
                          )
                        ]
                      ),

                      h(
                        'div',
                        {
                          class: 'faic caption'
                        },
                        [
                          renderReseller.call(this, h, item),

                          h('div', { class: 'ff' }),

                          renderManager.call(this, h, item)
                        ]
                      )
                    ]
                  )
                ]
              )
            },
            report,
            titleCreate: () => {
              return h(HeadTitle, { props: { value: this.getTranslate('users.titles.create') } })
            }
          }
        }
      ),

      h(
        'get-create-template',
        {
          scopedSlots: {
            title: ({ data }) => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--8 faic',
                  style: {
                    'grid-template-columns': '36px 1fr'
                  }
                },
                [
                  renderLoginAsButton.call(this, h),

                  h(
                    Copy,
                    {
                      class: 'users-title users-title--username subtitle-1',
                      props: { value: data.username }
                    }
                  )
                ]
              )
            },

            header: ({ data }) => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--8 fjcfe',
                  style: {
                    'grid-template-columns': 'auto 36px 36px'
                  }
                },
                [
                  h(
                    BalanceOverdraft,
                    {
                      class: 'ma-0',
                      props: {
                        UserId: data.id,
                        currency: get(data.settings, 'billings.currency')
                      }
                    }
                  ),

                  renderAddFundsButton.call(this, h, data),

                  h(
                    DocumentTemplatesGenerateButton,
                    {
                      props: {
                        currency: get(data.settings, 'billings.currency'),
                        OwnerId: data.id,
                        ResellerId: data.ResellerId || data.Reseller && data.Reseller.id
                      }
                    }
                  )
                ]
              )
            },

            'after-form': ({ data }) => {
              return h(EntityInfo, { props: { value: data } })
            },

            right: ({ data }) => {
              return renderTabs.call(this, h, data)
            }
          }
        }
      )
    ]
  )
}
