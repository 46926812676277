import { inputModes, modes, services, sizes } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import button from '@/components/button'

const serviceNameViaPoint = services['otp-settings/widgets'].split('/').join('.')

function renderNameField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.search,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.search = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.ownerId,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.owner`),
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.ownerId = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.isActive,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.isActive`)
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}
function renderIsBlockedField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.isBlocked,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.isBlocked`)
      },
      on: {
        input: event => {
          this.proxy.isBlocked = event
        }
      }
    }
  )
}
function renderCaptchaField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.captchaIsRequired,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.captchaIsRequired`)
      },
      on: {
        input: event => {
          this.proxy.captchaIsRequired = event
        }
      }
    }
  )
}
function renderClearButton(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.clear'),
            mode: modes.flat,
            size: sizes.small,
            disabled: this.flagsIsDefault
          },
          on: {
            click: () => {
              this.clearFlags()
            }
          }
        }
      )
    ]
  )
}
function renderFlags(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2 border border--rounded border--dashed'
    },
    [
      renderIsActiveField.call(this, h),
      renderIsBlockedField.call(this, h),
      renderCaptchaField.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}

function renderMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        closeOnContentClick: false,
        offsetDistance: 8,
        placement: 'bottom-end',
        rounded: true,
        maxWidth: 304,
        width: '100%'
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: 'filter_list',
            mode: modes.flat
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2'
        },
        [ renderFlags.call(this, h) ]
      )
    ]
  )
}

function renderContentByViewport(h) {
  if (this.viewport.breakpoint.lgUp) {
    return renderMenu.call(this, h)
  } else {
    return renderFlags.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceNameViaPoint.replace('.', '-')}-filter`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderNameField.call(this, h),
      renderOwnerField.call(this, h),
      renderContentByViewport.call(this, h)
    ]
  )
}
