import Vue from 'vue'

import { buttonModes, colors, components, icons, permissionPresets, states } from '@/utils'

import FormTitle from '@/components/misc/FormTitle'
import buttonConfirmRemove from '@/components/button/confirm/remove'
import componentNotFound from '@/components/misc/componentNotFound'
import preloader from '@/components/misc/preloader'
import button from '@/components/button'
import buttonFavorite from '@/components/button/favorite'

function renderHeader(h, options) {
  let slotTitle = h(
    FormTitle,
    {
      props: {
        value: this.restData[options.name].get.data,
        errors: this.restData[options.name].update.errors
      },
      on: {
        input: event => {
          this.restData[options.name].get.data = event
        },
        submit: () => {
          this.rest[options.name].update(this.restData[options.name].get.data.id, this.restData[options.name].get.data)
        }
      }
    }
  )
  if (this.$scopedSlots.title) {
    slotTitle = this.$scopedSlots.title({ data: this.restData[options.name].get.data })
  } else if (this.$slots.title) {
    slotTitle = this.$slots.title
  }

  let slotHeader = this.$slots.header
  if (this.$scopedSlots.header) {
    slotHeader = this.$scopedSlots.header({ data: this.restData[options.name].get.data })
  }

  if (slotTitle || slotHeader) {
    return h(
      'div',
      {
        class: {
          'faic fjcsb grid-gap--8': this.viewport.breakpoint.mdUp,
          'grid grid-gap--8': this.viewport.breakpoint.smDown
        }
      },
      [ slotTitle, slotHeader ]
    )
  }
}
function renderAfterHeader(h, options) {
  return this.$scopedSlots.afterTitle ? this.$scopedSlots.afterTitle({ data: this.restData[options.name].get.data }) : this.$slots.afterTitle
}

function renderFormComponent(h, options) {
  try {
    return h(
      require(`@/components/services/${options.name}/form`).default,
      {
        props: {
          value: this.restData[options.name].get.data,
          errors: this.restData[options.name].update.errors
        },
        on: {
          input: event => {
            this.restData[options.name].get.data = event
          },
          submit: () => {
            this.rest[options.name].update(
              this.restData[options.name].get.data.id,
              this.restData[options.name].get.data
            )
          }
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}
function renderAfterForm(h, options) {
  return this.$scopedSlots['after-form'] ? this.$scopedSlots['after-form']({ data: this.restData[options.name].get.data }) : this.$slots['after-form']
}
function renderForm(h, options) {
  return h(
    'g-card',
    {
      class: 'pb-3',
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderFormComponent.call(this, h, options),
      renderAfterForm.call(this, h, options)
    ]
  )
}

function renderCloseButton(h, options) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        label: this.getTranslate('misc.buttons.close')
      },
      on: {
        click: () => {
          Vue.router.push({ name: options.name })
        }
      }
    }
  )
}
function renderButtonsSlot(h, options) {
  return this.$scopedSlots.buttons ? this.$scopedSlots.buttons({ data: this.restData[options.name].get.data }) : this.$slots.buttons ? this.$slots.buttons : h('div', { class: 'ff' })
}
function renderFavoriteButton(h, options) {
  return h(
    buttonFavorite,
    {
      props: {
        id: this.restData[options.name].get.data.id,
        service: options.name
      }
    }
  )
}
function renderRefreshButton(h, options) {
  return h(
    button,
    {
      props: {
        icon: icons.refresh,
        mode: buttonModes.flat,
        loading: this.restData[options.name].get.state === states.loading,
        disabled: this.restData[options.name].get.state === states.loading || !this.checkPermissions(`advanced.${options.name}.get`, permissionPresets.resellerUp)
      },
      on: {
        click: () => {
          this.rest[options.name].get(this.restData[options.name].get.data.id)
        }
      }
    }
  )
}
function renderRemoveButton(h, options) {
  return h(
    buttonConfirmRemove,
    {
      props: {
        labeled: false,
        loading: this.restData[options.name].remove.state === states.loading,
        disabled: this.restData[options.name].remove.state === states.loading || !this.checkPermissions(`advanced.${options.name}.remove`, permissionPresets.resellerUp),
        callback: () => this.rest[options.name].remove(this.restData[options.name].get.data.id)
      }
    }
  )
}
function renderUpdateButton(h, options) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.update'),
        loading: this.restData[options.name].update.state === states.loading,
        disabled: this.restData[options.name].update.state === states.loading || !this.checkPermissions(`advanced.${options.name}.update`, permissionPresets.resellerUp),
        mode: buttonModes.filled,
        color: colors.primary
      },
      on: {
        click: () => {
          this.rest[options.name].update(
            this.restData[options.name].get.data.id,
            this.restData[options.name].get.data
          )
        }
      }
    }
  )
}
function renderFooter(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe',
      style: { 'grid-template-columns': 'auto 1fr repeat(4, auto)' }
    },
    [
      renderCloseButton.call(this, h, options),
      renderButtonsSlot.call(this, h, options),
      renderFavoriteButton.call(this, h, options),
      renderRefreshButton.call(this, h, options),
      renderRemoveButton.call(this, h, options),
      renderUpdateButton.call(this, h, options)
    ]
  )
}

function renderLeftCol(h, options) {
  if (this.$route.params.id) {
    if (this.checkPermissions(`advanced.${options.name}.get`, permissionPresets.resellerUp)) {
      switch (this.restData[options.name].get.state) {
        case states.ready: {
          if (this.restData[options.name].get.data) {
            return h(
              'div',
              {
                class: 'grid grid-gap--8'
              },
              [
                renderHeader.call(this, h, options),
                renderAfterHeader.call(this, h, options),
                renderForm.call(this, h, options),
                renderFooter.call(this, h, options)
              ]
            )
          } else {
            return h(components.empty)
          }
        }
        case states.loading: {
          return h(
            'div',
            {
              class: 'preloader__holder pa-5'
            },
            [
              h(
                preloader,
                {
                  props: {
                    value: true
                  }
                }
              )
            ]
          )
        }
        default: return h(components.empty)
      }
    }
  }
}

function renderRightCol(h, options) {
  if (this.$route.params.id) {
    const data = this.restData[options.name].get.data
    if (this.restData[options.name].get.state === states.ready && data) {
      if (this.$scopedSlots.right) {
        return this.$scopedSlots.right({ data })
      } else if (this.$slots.right) {
        return this.$slots.right
      }

      return h('div')
    }
  }
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid ff',
      style: {
        'grid-template-columns': this.viewport.breakpoint.xl ? `minmax(${this.minWidth}px, ${this.maxWidth}px) minmax(450px, auto)` : '1fr',
        'place-items': 'start stretch'
      }
    },
    [
      renderLeftCol.call(this, h, options),
      renderRightCol.call(this, h, options)
    ]
  )
}
