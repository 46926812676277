import { colors, components, inputModes, services, sizes } from '@/utils'

import tag from '@/components/tag'
import svgIcon from '@/components/icon/svg'
import squircle from '@/components/icon/squircle'
import overflowString from '@/components/misc/overflowString'

function renderSelectionItem(h, item) {
  return h(
    tag,
    {
      props: {
        label: item.title,
        color: colors[item.value],
        size: sizes.small,
        as: 'select'
      }
    }
  )
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      h(
        squircle,
        {
          props: {
            color: item.value,
            tooltip: this.getTranslate(`${services.payments}.methods.${item.value}`)
          }
        },
        [
          h(
            svgIcon,
            {
              props: {
                value: item.value,
                color: item.value
              }
            }
          )
        ]
      ),
      h(
        overflowString,
        {
          props: {
            value: item.title,
            font: {
              size: 13,
              lineHeight: 15
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${services.payments}.labels.method`),
        items: this.items,
        mode: this.isFilter ? inputModes.outline : inputModes['line-label'],
        disabled: this.disabled || this.paymentsMethods.length === 1,
        clearable: this.clearable,
        required: this.required,
        dense: this.dense,
        details: false,
        rounded: true,
        rounded: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return renderSelectionItem.call(this, h, item)
        },
        item: ({ item }) => {
          return renderItem.call(this, h, item)
        }
      }
    }
  )
}
