import { components } from '@/utils'

export default function(h) {
  return h(
    components.icon,
    {
      class: {
        [`link copy-icon-${this._uid}`]: true,
        'mx-1': !this.marginless
      },
      props: {
        value: this.icon,
        size: this.size,
        color: this.color
      },
      on: { click: this.clickHandler },
      directives: this.directives
    }
  )
}
