import { components, generateUniqueKey } from '@/utils'

import squircle from '@/components/icon/squircle'

const className = 'sticker'

function renderIcon(h) {
  if (this.icon || this.iconSVG) {
    return h(
      squircle,
      {
        props: {
          color: this.color,
          icon: this.icon,
          iconSVG: this.iconSVG,
          iconSize: this.iconSize,
          loading: this.loading,
          tooltip: this.iconTooltip
        },
        key: generateUniqueKey()
      }
    )
  }

  if (this.$scopedSlots.icon) {
    return this.$scopedSlots.icon()
  }

  return this.$slots.icon
}

function renderTitle(h) {
  return h(
    'div',
    {
      ref: 'title',
      class: `${className}__title`
    },
    [ this.title ]
  )
}

function renderLabel(h) {
  return h(
    'div',
    {
      class: `${className}__label`
    },
    [ this.label ]
  )
}

function renderContent(h) {
  if (this.hasContent) {
    return h(
      'div',
      {
        class: `${className}__content`,
        directives: this.$directives
      },
      [
        renderTitle.call(this, h),
        renderLabel.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderButtonIcon(h) {
  return h(
    components.icon,
    {
      props: {
        value: this.$button.icon,
        color: this.$button.color,
        size: this.$button.size
      }
    }
  )
}

function renderButton(h) {
  if (this.button || this.copy) {
    return h(
      'div',
      {
        class: {
          [`${className}__button`]: true,
          [`${className}__button--${this.$button.color}`]: true,
          [`${className}__button--callback`]: this.buttonCallback,
          [`${className}__button--disabled`]: this.$button.disabled
        },
        style: { borderLeftStyle: this.$borderStyle },
        on: {
          click: event => {
            event.stopPropagation()
            this.buttonClickHandler()
          }
        },
        directives: this.buttonDirectives
      },
      [ renderButtonIcon.call(this, h) ]
    )
  }
}

function renderHolder(h) {
  return h(
    this.hasTo ? 'router-link' : 'div',
    {
      class: `${className}__holder`,
      style: { gridTemplateColumns: this.gridTemplateColumns },
      props: { to: this.$to },
      on: { click: this.clickHandler }
    },
    [
      renderIcon.call(this, h),
      renderContent.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      attrs: { target: this.target },
      class: this.$class,
      style: this.$style,
      ref: className
    },
    [
      renderHolder.call(this, h),
      renderButton.call(this, h)
    ]
  )
}
