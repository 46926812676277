import numeral from 'numeral'
numeral.locale('en')

import { get } from 'lodash'
import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { SENDING_STATES, colors, icons, permissionPresets, services, sizes } from '@/utils'

import SingleLink from '@/components/misc/SingleLink'
import FileItemCard from '@/components/file/item/card'
import badge from '@/components/misc/badge'
import formatTable from '@/components/format/table'
import SpacerCell from '@/components/misc/SpacerCell'
import price from '@/components/services/sendings/price'
import resendButton from '@/components/services/sendings/outgoing/resendButton'
import sticker from '@/components/misc/sticker'
import squircle from '@/components/icon/squircle'

const fixedWidthStyle = {
  'min-width': '130px',
  'max-width': '130px'
}

function renderFallbacksLength(h) {
  const fallbacks = get(this.value, 'fallbacks', [])
  const source = get(this.value, 'meta._specific.source', undefined)

  let badgeValue
  if (Array.isArray(fallbacks) && fallbacks.length) {
    badgeValue = fallbacks.length
  } else if (source === 'fallback') {
    badgeValue = '0'
  }

  if (badgeValue) {
    return h(
      badge,
      {
        props: {
          value: badgeValue,
          offsetOut: true
        }
      }
    )
  }
}

function renderReplacement(h, path) {
  if (this.checkPermissions(`advanced.${services.sendings}.find`, permissionPresets.managerUp)) {
    const contentHistory = get(this.value, 'meta.contentHistory')
    if (Array.isArray(contentHistory) && contentHistory.length > 1) {
      const [ original, replacement, provider ] = contentHistory

      const originalValue = get(original, path)
      const replacementValue = get(replacement, path)
      const providerValue = get(provider, path, replacementValue)

      if (originalValue !== providerValue) {
        return h(
          'div',
          {
            class: 'faic grid grid-gap--4',
            style: { 'grid-template-columns': '11px 1fr' },
            directives: [
              {
                name: 'g-tooltip',
                options: { value: providerValue }
              }
            ]
          },
          [
            h(
              'g-icon',
              {
                props: {
                  value: 'repeat',
                  color: 'success',
                  size: 11
                }
              }
            ),

            h(
              'div',
              {
                class: 'tiny text--success'
              },
              this.getTranslate(`${services.sendings}.hints.has.replacement`)
            )
          ]
        )
      }
    }
  }
}
function renderReplacementType(h) {
  if (this.checkPermissions(`advanced.${services.sendings}.find`, permissionPresets.managerUp)) {
    const contentHistory = get(this.value, 'meta.contentHistory')
    if (Array.isArray(contentHistory) && contentHistory.length > 1) {
      const [ original, replacement ] = contentHistory

      const originalValue = get(original, 'type')
      const replacementValue = get(replacement, 'type')

      if (originalValue !== replacementValue) {
        return h(
          badge,
          {
            props: {
              top: true,
              offsetOut: true,
              color: 'success'
            }
          },
          [
            h(
              'g-icon',
              {
                props: {
                  size: 9,
                  value: 'repeat',
                  color: 'white'
                },
                directives: [
                  {
                    name: 'g-tooltip',
                    options: { value: this.getTranslate(`sendings.types.${replacementValue}`) }
                  }
                ]
              }
            )
          ]
        )
      }
    }
  }
}

function renderFileInfo(h) {
  if (this.value.File) {
    return h(
      FileItemCard,
      {
        props: {
          value: this.value.File,
          outline: false,
          displayMenu: false,
          flat: true,
          small: true
        }
      }
    )
  }
}

function renderFormattedDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'text--grey text-right',
        style: { 'white-space': 'pre' },
        domProps: { innerHTML: date }
      }
    )
  }
}

function renderTypeIcon(h) {
  return h(
    'div',
    {
      class: 'position-relative'
    },
    [
      h(
        squircle,
        {
          props: {
            iconSVG: this.value.type,
            color: this.value.type,
            size: sizes.small,
            tooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`)
          }
        }
      ),

      renderReplacementType.call(this, h),
      renderFallbacksLength.call(this, h)
    ]
  )
}
function renderTypeIconCell(h) {
  return h(
    'td',
    {
      class: 'pl-2 pr-0'
    },
    [ renderTypeIcon.call(this, h) ]
  )
}

function renderModerationStatus(h) {
  if (this.messageOnModeration) {
    return h(
      badge,
      {
        props: {
          top: true,
          offsetIn: true,
          color: 'none'
        }
      },
      [
        h(
          'g-icon',
          {
            props: {
              size: 19,
              value: icons.motion_photos_paused,
              color: colors.warning
            },
            directives: [
              {
                name: 'g-tooltip',
                options: { value: this.getTranslate(`${services.sendings}.tooltips.moderation`) }
              }
            ]
          }
        )
      ]
    )
  }
}
function renderStatusIcon(h) {
  const status = get(this.value, 'state.status')

  const statusName = get(SENDING_STATES, `${status}.name`)
  const statusIcon = get(SENDING_STATES, `${status}.icon`)
  const statusColor = get(SENDING_STATES, `${status}.color`)

  return h(
    'div',
    {
      class: 'fjcc facc faic square--48 position-relative'
    },
    [
      h(
        squircle,
        {
          props: {
            icon: statusIcon,
            color: statusColor,
            tooltip: this.getTranslate(`${services.sendings}.statuses.${statusName}`)
          }
        }
      ),
      renderModerationStatus.call(this, h)
    ]
  )
}

function renderStatusIconCell(h) {
  return h(
    'td',
    {
      class: 'pa-0'
    },
    [ renderStatusIcon.call(this, h) ]
  )
}

function renderOwner(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}
function renderOwnerCell(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    return h(
      'td',
      {
        class: 'px-0',
        style: fixedWidthStyle
      },
      [ renderOwner.call(this, h) ]
    )
  }
}

function renderRecipient(h) {
  return h(
    'div',
    {
      class: 'text-overflow'
    },
    get(this.value, 'payload.recipient')
  )
}
function renderRecipientCell(h) {
  return h(
    'td',
    {
      class: 'px-2',
      style: fixedWidthStyle
    },
    [ renderRecipient.call(this, h) ]
  )
}

function renderSender(h, view) {
  if (view === 'panel') {
    const value = get(this.value, 'payload.sender')
    if (value) {
      return h(
        sticker,
        {
          props: {
            borderStyle: 'dashed',
            value: value,
            label: this.getTranslate(`${services.sendings}.labels.sendername`),
            to: {
              name: 'sendernames.single',
              params: { id: value }
            }
          }
        }
      )
    }
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--2'
    },
    [
      h(
        'div',
        {
          class: 'text-overflow'
        },
        get(this.value, 'payload.sender')
      ),

      renderReplacement.call(this, h, 'payload.sender')
    ]
  )
}
function renderSenderCell(h) {
  return h(
    'td',
    {
      class: 'px-2',
      style: fixedWidthStyle
    },
    [ renderSender.call(this, h) ]
  )
}

function renderText(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--2 grid-cols--1'
    },
    [

      h(
        SpacerCell,
        {
          props: {
            value: get(this.value, 'payload.text')
          }
        }
      ),

      renderReplacement.call(this, h, 'payload.text')
    ]
  )
}
function renderTextCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderText.call(this, h) ]
  )
}

function renderResendButton(h) {
  if (this.checkPermissions(`advanced.${services.sendings}.create`)) {
    return h(
      resendButton,
      {
        class: 'hover-child',
        props: { value: this.value }
      }
    )
  }
}
function renderSourceButton() {
  if (typeof this.$scopedSlots.sourceButton === 'function') {
    return this.$scopedSlots.sourceButton()
  }

  return this.$slots.sourceButton
}
function renderButtonsCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8 grid-cols--2 faic'
        },
        [
          renderResendButton.call(this, h),
          renderSourceButton.call(this)
        ]
      )
    ]
  )
}

function renderTextLengthSegments(h) {
  const length = get(this.value, 'meta.stats.characters', 0)
  const segments = get(this.value, 'meta.stats.segments', 0)

  return h(
    'div',
    {
      class: 'text-nowrap text--grey',
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.getTranslate(`${services.sendings}.tooltips.segments`) }
        }
      ]
    },
    `${numeral(length).format('0a')} | ${segments}`
  )
}
function renderTextLengthSegmentsCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderTextLengthSegments.call(this, h) ]
  )
}

function renderBillSecSegments(h) {
  if (this.value.type === SENDING_TYPES.voice) {
    const billSec = get(this.value, 'state.communications[0].response.body.billsec', 0)

    return h(
      'div',
      {
        class: 'text-nowrap text--grey',
        directives: [
          {
            name: 'g-tooltip',
            options: { value: this.getTranslate(`${services.sendings}.tooltips.billSec`) }
          }
        ]
      },
      `${billSec}`
    )
  }
}
function renderBillSecSegmentsCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderBillSecSegments.call(this, h) ]
  )
}

function renderOperator(h, view) {
  const { group, operator, mcc, mnc } = get(this.value, 'meta._recipientData', {})

  const value = group || operator || [ mcc, mnc ].filter(v => !!v).join(' ')
  const directives = []
  if (value) {
    directives.push({
      name: 'g-tooltip',
      options: { value }
    })
  }

  if (view === 'panel') {
    if (value) {
      return h(
        sticker,
        {
          props: {
            value: value,
            label: this.getTranslate(`${services.sendings}.headers.operator`),
            borderStyle: 'dashed'
          }
        }
      )
    } else {
      return h('div')
    }
  } else {
    return h(
      'div',
      {
        class: 'text-overflow',
        directives
      },
      value
    )
  }
}
function renderOperatorCell(h) {
  return h(
    'td',
    {
      class: 'px-2',
      style: fixedWidthStyle
    },
    [ renderOperator.call(this, h) ]
  )
}

function renderProvider(h, view) {
  const value = get(this.value, 'meta._routing.provider')
  if (view === 'panel') {
    if (value) {
      return h(
        sticker,
        {
          props: {
            value: value,
            label: this.getTranslate(`${services.sendings}.labels.provider`),
            service: 'providers',
            to: {
              name: 'providers.single',
              params: { id: value }
            }
          }
        }
      )
    } else {
      return h('div')
    }
  } else if (value) {
    return h(
      SingleLink,
      {
        props: {
          value,
          service: 'providers',
          params: {
            query: {
              includeParanoid: true
            }
          },
          length: 25
        }
      }
    )
  }
}
function renderProviderCell(h) {
  // Здесь только для администратора и реселлера
  if (this.checkPermissions(`advanced.${services.providers}.get`, [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'px-2',
        style: fixedWidthStyle
      },
      [ renderProvider.call(this, h) ]
    )
  }
}

function renderPrice(h) {
  return h(price, { props: { value: this.value } })
}
function renderPriceCell(h) {
  return h(
    'td',
    {
      class: 'px-2',
      style: {
        minWidth: '100px',
        maxWidth: '100px'
      }
    },
    [ renderPrice.call(this, h) ]
  )
}

function renderCreatedAt(h) {
  return renderFormattedDate.call(this, h, this.value.$formattedCreatedAt)
}
function renderCreatedAtCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderCreatedAt.call(this, h) ]
  )
}

function renderUpdatedAt(h) {
  return renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt)
}
function renderUpdatedAtCell(h) {
  return h(
    'td',
    {
      class: 'px-2'
    },
    [ renderUpdatedAt.call(this, h) ]
  )
}

function renderRow(h) {
  return h(
    'tr',
    {
      class: 'hover-parent hover-parent--opacity',
      style: { 'background-color': this.messageOnModeration ? '#F6F6F6' : undefined },
      key: this.value.id
    },
    [
      renderTypeIconCell.call(this, h),
      renderStatusIconCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderRecipientCell.call(this, h),
      renderSenderCell.call(this, h),
      renderTextCell.call(this, h),
      renderButtonsCell.call(this, h),
      renderTextLengthSegmentsCell.call(this, h),
      renderBillSecSegmentsCell.call(this, h),
      renderOperatorCell.call(this, h),
      renderProviderCell.call(this, h),
      renderPriceCell.call(this, h),
      renderCreatedAtCell.call(this, h),
      renderUpdatedAtCell.call(this, h)
    ]
  )
}

function renderInfo(h) {
  if (this.checkPermissions(`advanced.${services.sendings}.find`, permissionPresets.true)) {
    if (this.viewport.breakpoint.smUp) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 px-2',
          style: 'grid-template-columns: repeat(3, 120px)'
        },
        [
          renderOperator.call(this, h, 'panel'),
          renderProvider.call(this, h, 'panel'),
          renderSender.call(this, h, 'panel')
        ]
      )
    }
    const viewportWidth = this.viewport.size.width
    if (viewportWidth <= 425 && viewportWidth >= 375) {
      return h(
        'div',
        {
          class: 'grid px-2',
          style: 'grid-template-columns: minmax(70px, 110px)'
        },
        [ renderProvider.call(this, h, 'panel') ]
      )
    }
  }
}

function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: () => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header caption',
              style: { 'background-color': this.messageOnModeration ? '#F6F6F6' : undefined }
            },
            [
              renderTypeIcon.call(this, h),
              renderStatusIcon.call(this, h),

              h(
                'div',
                {
                  class: 'grid grid-gap--2 ff',
                  style: 'min-width: max-content'
                },
                [
                  renderRecipient.call(this, h),
                  renderOwner.call(this, h)
                ]
              ),
              renderInfo.call(this, h),
              renderCreatedAt.call(this, h)
            ]
          )
        },
        default: () => {
          return h(
            'div',
            {
              class: 'grid grid-cols--1 grid-gap--0 pt-2'
            },
            [
              h(formatTable, { props: { value: this.value } }),

              h(
                'div',
                {
                  class: 'fc faifs ff pl-2'
                },
                [
                  renderFileInfo.call(this, h, this.value),

                  h(
                    'div',
                    {
                      class: 'grid grid-gap--8 grid-cols--2 faic'
                    },
                    [
                      renderResendButton.call(this, h),
                      renderSourceButton.call(this)
                    ]
                  )
                ]
              )
            ]
          )
        }
      },
      key: this.value.id
    }
  )
}

export default function(h) {
  switch (this.type) {
    case 'panel': return renderPanel.call(this, h)
    case 'row':
    default: return renderRow.call(this, h)
  }
}
