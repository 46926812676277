import Vue from 'vue'

import { get } from 'lodash'

import { buttonModes, colors, components, icons, inputModes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'

function renderPlatformTitle(h) {
  const title = get(this.globalSettings, 'frontend.title')
  if (title) {
    return h(
      'div',
      {
        class: 'headline text--grey'
      },
      [ title ]
    )
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 px-3',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      h(
        'div',
        {
          class: 'headline'
        },
        [ this.getTranslate(`${services.recover}.title`) ]
      ),

      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderValidationResult(h) {
  if (this.validation === true && this.state === 'request') {
    return h(
      info,
      {
        props: {
          value: this.getTranslate('recover.contents.validation.request'),
          icon: icons.check_circle,
          color: colors.success
        }
      }
    )
  }
}
function renderLoginField(h) {
  if (this.validation === undefined) {
    return h(
      components['text-field'],
      {
        props: {
          value: this.login,
          disabled: this.loading,
          label: this.getTranslate('recover.labels.login'),
          mode: inputModes['line-label'],
          rounded: true,
          required: true,
          clearable: true,
          autofocus: true,
          details: false
        },
        on: {
          input: event => {
            this.login = event
          }
        }
      }
    )
  }
}
function renderPasswordField(h) {
  if (this.validation === true && this.state === 'key') {
    return h(
      components['text-field'],
      {
        props: {
          name: 'password',
          value: this.password,
          label: this.getTranslate('recover.labels.password'),
          hint: this.getTranslate('recover.hints.password'),
          disabled: this.loading,
          type: this.passwordVisibility ? 'text' : 'password',
          afterIcon: this.passwordVisibility ? icons.visibility_off : icons.visibility,
          afterIconCallback: () => {
            this.passwordVisibility = !this.passwordVisibility
          },
          mode: inputModes['line-label'],
          rounded: true,
          required: true,
          autofocus: true,
          clearable: true
        },
        on: {
          input: event => {
            this.password = event
          }
        }
      }
    )
  }
}
function renderBody(h) {
  return h(
    components.card,
    {
      class: 'pa-3',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      renderLoginField.call(this, h),
      renderPasswordField.call(this, h),
      renderValidationResult.call(this, h)
    ]
  )
}

function renderBackButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.back'),
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          Vue.router.go(-1)
        }
      }
    }
  )
}
function renderResetButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('recover.buttons.reset'),
        mode: buttonModes.filled,
        color: colors.primary,
        loading: this.loading,
        disabled: this.$buttonDisabled
      },
      on: {
        click: () => {
          if (this.$key) {
            this.changePassword()
          } else {
            this.requestRecover()
          }
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcsb grid-gap--8'
    },
    [
      renderBackButton.call(this, h),
      renderResetButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: this.$style
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
