import { buttonModes, colors, components, getPrice, popperPlacements, sizes } from '@/utils'

import tag from '@/components/tag'
import squircle from '@/components/icon/squircle'
import UserWidget from '@/components/misc/UserWidget'

function renderAvatar(h) {
  return h(
    squircle,
    {
      props: {
        iconSVG: this.account.$verified ? 'user-verified' : 'user',
        iconSize: 32,
        size: sizes.medium,
        color: colors.white,
        tooltip: this.account.$verified ? this.getTranslate('commons.has.contract') : undefined
      }
    }
  )
}

function renderUsername(h) {
  return h(
    'div',
    {
      class: 'account-button-username'
    },
    [ this.account.username ]
  )
}

function renderBalance(h) {
  return h(
    tag,
    {
      props: {
        label: getPrice(this.balance),
        color: this.balanceColor,
        size: sizes.tiny,
        mode: buttonModes.filled
      }
    }
  )
}

export default function(h) {
  return h(
    components.menu,
    {
      props: {
        value: this.show,
        closeOnContentClick: false,
        minWidth: 300,
        maxWidth: 400,
        placement: popperPlacements['bottom-end'],
        offsetDistance: 8,
        transparent: true
      },
      on: {
        input: event => {
          this.show = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'account-button',
          slot: 'activator'
        },
        [
          renderAvatar.call(this, h),

          h(
            'div',
            {
              class: 'account-button-content'
            },
            [
              renderUsername.call(this, h),
              renderBalance.call(this, h)
            ]
          )
        ]
      ),

      h(
        UserWidget,
        {
          props: {
            overdraft: this.overdraft,
            rounded: this.viewport.breakpoint.mdUp
          },
          on: {
            show: event => {
              this.show = event
            }
          }
        }
      )
    ]
  )
}
