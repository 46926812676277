import { colors, components, modes, sizes } from '../../utils/constants'

import render from './render'

export default {
  name: components.tag,

  props: {
    label: [ String, Number, Boolean ],
    labelColor: String,

    tooltip: String,

    loading: Boolean,
    disabled: Boolean,
    strikethrough: Boolean,

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },
    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    mode: {
      type: String,
      default: modes.flat,
      validator: value => {
        return Object.values(modes).includes(value)
      }
    },
    as: {
      type: String,
      default: undefined,
      validator: value => {
        return !!~[ 'button', 'select' ].indexOf(value)
      }
    }
  },

  computed: {
    $label() {
      switch (typeof this.label) {
        case 'number':
        case 'boolean': return this.label.toString()
        case 'string': return this.label
      }
    },

    $class() {
      return {
        [`${components.tag}`]: true,

        [`${components.tag}--${this.size}`]: true,
        [`${components.tag}--${this.mode}`]: true,
        [`${components.tag}-as--${this.as}`]: !!this.as,

        [`${components.tag}--loading`]: this.loading,
        [`${components.tag}--disabled`]: this.disabled,
        [`${components.tag}--strikethrough`]: this.strikethrough,

        [`${components.tag}--${this.color}`]: !!this.color
      }
    },

    $font() {
      const result = {}

      switch (this.size) {
        case sizes.tiny: {
          result.size = 10
          result.lineHeight = 12
          break
        }
        case sizes.small: {
          result.size = 11
          result.lineHeight = 13
          break
        }
        case sizes.medium: {
          result.size = 12
          result.lineHeight = 14
          break
        }
        case sizes.large: {
          result.size = 13
          result.lineHeight = 15
          break
        }
        case sizes.huge: {
          result.size = 14
          result.lineHeight = 16
          break
        }
      }

      if (this.as === 'button') {
        result.size = 14
        result.weight = 500
        result.lineHeight = 16
      }

      if (this.as === 'select') {
        result.size = 13
        result.lineHeight = 15
      }

      return result
    },

    $directives() {
      const result = []
      if (this.tooltip || this.showTooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip || this.title,
            placement: 'top'
          }
        })
      }

      return result
    }
  },

  methods: {
    eventHandler(event) {
      this.$emit(event.type, event)
    }
  },

  render
}
