import { colors, modes } from '@/utils'

import button from '@/components/button'
import rulesActionsDialogGeneratorsFallbacksFallback from '@/components/misc/rules/actions/dialog/generators/fallbacks/fallback'

function renderContent(h) {
  return this.proxy.map((item, index) => {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { gridTemplateColumns: '1fr 36px' }
      },
      [
        h(
          rulesActionsDialogGeneratorsFallbacksFallback,
          {
            props: {
              value: item
            },
            on: {
              input: event => {
                item = event
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              icon: 'remove',
              mode: modes.flat,
              color: colors.error
            },
            on: {
              click: () => {
                this.remove(index)
              }
            }
          }
        )
      ]
    )
  })
}

export default function(h) {
  if (Array.isArray(this.proxy)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderContent.call(this, h),

        h(
          'div',
          {
            class: 'faic fjcfe ff'
          },
          [
            h(
              button,
              {
                props: {
                  icon: 'add',
                  mode: modes.flat,
                  color: colors.primary
                },
                on: {
                  click: () => {
                    this.add()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
