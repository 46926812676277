import { camelCase } from 'lodash'

import { colors, modes, services, sizes } from '@/utils'

import tag from '@/components/tag'
import sourceBox from '@/components/source/box'
import sticker from '@/components/misc/sticker'
import pagination from '@/components/misc/pagination'
import buttonRefresh from '@/components/button/refresh'
import panel from '@/components/services/eventsHistory/tab/row'
import button from '@/components/button'

function renderTab(h, type, key) {
  return h(
    'div',
    {
      class: 'eventsHistory-compare__col'
    },
    [
      h(
        tag,
        {
          props: {
            label: this.getTranslate(`${services.eventsHistory}.updatedValues.${camelCase(key)}`),
            size: sizes.tiny,
            color: colors.grey
          }
        }
      ),

      h(
        sourceBox,
        {
          props: {
            value: this.compareData[type][key],
            rounded: true
          }
        }
      )
    ]
  )
}

function renderTabForCreated(h) {
  return h(
    'div',
    {
      class: 'eventsHistory-compare__cols fz-14',
      style: 'grid-template-columns: 1fr'
    },
    [
      h(
        sourceBox, {
          props: {
            value: this.compareData[this.currentTab],
            rounded: true
          }
        }
      )
    ]
  )
}

function renderValues(h, type) {
  return Object.keys(this.compareData[type]).map(key => renderTab.call(this, h, type, key))
}

function renderTabs(h) {
  return Object.keys(this.compareData[this.currentTab]).map(key => {
    return this.types.map(type => renderTab.call(this, h, type, key))
  })
}

function renderHeaders(h) {
  return this.types.map(type => {
    return h(
      tag,
      {
        props: {
          label: this.getTranslate(`${services.eventsHistory}.labels.${type}`),
          size: sizes.small
        }
      }
    )
  })
}

function getDialogHeaderContent() {
  if (this.tabForCreated) {
    return this.getTranslate(`${services.eventsHistory}.labels.created`)
  }

  return this.getTranslate(`${services.eventsHistory}.labels.eventsHistory`)
}
function renderCompareId(h) {
  if (!this.tabForCreated) {
    return h(
      'div',
      {
        class: `${services.eventsHistory}__compareId`
      },
      [
        h(
          sticker,
          {
            props: {
              value: {
                entityId: this.compareId
              },
              label: this.getTranslate(`${services.eventsHistory}.labels.compareId`),
              pathToTitle: 'entityId',
              borderStyle: 'dashed'
            }
          }
        )
      ]
    )
  }
}
function renderDialogHeader(h) {
  return h(
    'div',
    {
      class: 'headline pa-3 grid grid-gap--8',
      slot: 'header'
    },
    [ getDialogHeaderContent.call(this), renderCompareId.call(this, h) ]
  )
}
function renderDialogBody(h) {
  if (this.tabForCreated) {
    return renderTabForCreated.call(this, h)
  } else {
    if (this.viewport.breakpoint.mdUp) {
      return h(
        'div',
        {
          class: 'eventsHistory-compare__cols'
        },
        [
          renderHeaders.call(this, h),
          renderTabs.call(this, h)
        ]
      )
    } else {
      return [
        h(
          'g-tabs-header',
          {
            props: {
              value: this.currentTab,
              tabs: this.types.map(type => {
                return {
                  key: type,
                  title: this.getTranslate(`${services.eventsHistory}.labels.${type}`)
                }
              })
            },
            on: {
              input: event => {
                this.currentTab = event
              }
            }
          }
        ),

        h(
          'g-tabs',
          {
            props: {
              value: this.currentTab
            },
            scopedSlots: {
              body: () => {
                return h(
                  'div',
                  {
                    class: 'eventsHistory-compare__cols',
                    key: this.currentTab
                  },
                  [ renderValues.call(this, h, this.currentTab) ]
                )
              }
            }
          }
        )
      ]
    }
  }
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fiac fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: modes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 800 : '100%'
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogHeader.call(this, h),
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.pagination,
        service: services.eventsHistory,
        selectRows: !this.viewport.breakpoint.xs
      },
      on: {
        input: event => {
          this.pagination = event
        }
      }
    }
  )
}
function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.loading,
        shortClickMethod: () => this.getItems()
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe faic pa-2',
      style: {
        borderTop: this.items.length ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
        gridTemplateColumns: this.items.length ? 'auto 38px' : '38px'
      }
    },
    [
      renderPagination.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderTable(h) {
  if (this.items.length) {
    return this.items.map((item, index) => {
      return h(
        panel,
        {
          props: {
            value: item
          },
          on: {
            click: () => {
              this.compareItems(item, index)
            }
          },
          key: item.id
        }
      )
    })
  } else {
    return h('g-empty')
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}-tab`
    },
    [
      renderTable.call(this, h),
      renderFooter.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
