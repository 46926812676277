import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'ButtonFavorite',

  props: {
    id: {
      type: String,
      required: true
    },
    service: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters({ items: 'favorites/items' }),

    isFavorite() {
      return Array.isArray(this.items[this.service]) && this.items[this.service].includes(this.id)
    }
  },

  mounted() {
    this.refresh(this.service)
  },

  methods: {
    ...mapActions({
      toggle: 'favorites/toggle',
      refresh: 'favorites/refresh'
    }),

    clickHandler() {
      this.toggle({
        id: this.id,
        service: this.service
      })
    }
  },

  render
}
