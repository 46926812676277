import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { keyValue, properties } from '@/utils'

import render from './render'

const defaultPayload = {
  key: undefined,
  value: undefined
}

export const propertiesSelect = (options = {}) => {
  return {
    name: `${options.serviceName}Properties`,

    mixins: [ keyValue, properties(options) ],

    props: {
      processor: String,
      ServerId: String
    },

    data() {
      return {
        editMode: false,
        showDialog: false,

        index: undefined,

        payload: cloneDeep(defaultPayload),

        processorFromServer: undefined
      }
    },

    computed: {
      computedProcessor() {
        return this.processor || this.processorFromServer
      }
    },

    watch: {
      'payload.key'() {
        if (this.payload.key) {
          const { type, property } = this._getProperty()
          switch (type) {
            case 'boolean': {
              if (typeof this.payload.value !== 'boolean') {
                this.payload.value = false
              }
              break
            }
          }
          switch (property) {
            case 'forceMessageStatus': {
              if (typeof this.payload.value !== 'object') {
                this.payload.value = {
                  conditions: [],
                  status: undefined
                }
              }
              break
            }
            case 'megafonTarget_campaignTimeWindowHrs': {
              if (!Array.isArray(this.payload.value)) {
                this.payload.value = []
              }
              break
            }
          }
        }
      },
      showDialog() {
        if (this.showDialog === false) {
          this.cancel()
        }
      },

      ServerId() {
        this.getProcessor()
      }
    },

    mounted() {
      this.getProcessor()
    },

    methods: {
      add() {
        this.proxy.push(this.payload)
        this.cancel()
      },
      cancel() {
        this.payload = cloneDeep(defaultPayload)
        if (this.editMode) {
          this.index = undefined
          this.editMode = false
        }
        this.showDialog = false
      },
      edit(index) {
        this.index = index
        this.payload = this.proxy[index]
        this.editMode = true
        this.showDialog = true
      },
      update() {
        this.proxy.splice(this.index, 1, this.payload)
        this.cancel()
      },

      async getProcessor() {
        if (this.ServerId) {
          const { data } = await Vue.$GRequest.get('servers', this.ServerId) || {}
          this.processorFromServer = data.processor
        }
      },

      valueProcessor(property, value) {
        switch (property) {
          case 'megafonTarget_campaignTimeWindowHrs': return value.map(Number)
          default: return value
        }
      },

      _inputFilter(data) {
        return Object.keys(data).reduce((result, key) => {
          result.splice(result.length, 0, {
            key,
            value: data[key]
          })

          return result
        }, [])
      },
      _outputFilter(data) {
        return data.reduce((result, item) => Object.assign(result, { [item.key]: this.valueProcessor(item.key, item.value) }), {})
      },
      _getProperty(key) {
        key = this.payload.key || key
        if (key) {
          return this.properties.find(item => item.property === key)
        }

        return {}
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}

export default { propertiesSelect }
