import { buttonModes, colors, components, icons, services } from '@/utils'

import button from '@/components/button'
import copyButton from '@/components/button/copy'
import info from '@/components/misc/info'

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.tokens}.info.token`),
        color: colors.warning
      }
    }
  )
}
function renderToken(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-dialog__token`
    },
    [ this.token ]
  )
}
function renderSensitivityButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: this.sensitivity ? icons.visibility : icons.visibility_off,
        color: this.sensitivity ? colors.success : colors.grey
      },
      on: {
        click: () => {
          this.sensitivity = !this.sensitivity
        }
      }
    }
  )
}
function renderContent(h) {
  if (this.token) {
    return h(
      'div',
      {
        class: `${services.tokens}-dialog__content`
      },
      [
        renderToken.call(this, h),
        renderSensitivityButton.call(this, h)
      ]
    )
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-dialog`
    },
    [
      renderInfo.call(this, h),
      renderContent.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.proxy = false
            }
          }
        }
      ),
      h(
        copyButton,
        {
          props: {
            value: this.data.token,
            label: this.getTranslate('misc.buttons.copy'),
            mode: buttonModes.filled,
            withIcon: false,
            snackbar: {
              text: this.getTranslate(`${services.tokens}.snackbars.copy`),
              type: colors.success
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.tokens}.titles.copy`),
        value: this.proxy,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 560 : undefined
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
