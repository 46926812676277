import Vue from 'vue'

import { colors, getPrice, modes, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import button from '@/components/button'
import tag from '@/components/tag'
import info from '@/components/misc/info'

function renderUpdateButton(h, trigger) {
  if (trigger) {
    return h(
      button,
      {
        props: {
          icon: 'refresh',
          mode: modes.flat,
          loading: this.loading,
          disabled: this.loading
        },
        on: {
          click: () => {
            this.getBalance()
          }
        }
      }
    )
  }
}

function renderRow(h, options) {
  const { icon, value, label, update } = options

  return h(
    'div',
    {
      class: 'grid grid-gap--12 faic',
      style: { 'grid-template-columns': 'auto 1fr auto' }
    },
    [
      h(
        squircle,
        {
          props: {
            icon,
            size: sizes.medium,
            color: colors.grey
          }
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--3'
        },
        [
          value,

          h(
            tag,
            {
              props: {
                label,
                size: sizes.tiny,
                color: colors.grey
              }
            }
          )
        ]
      ),

      renderUpdateButton.call(this, h, update)
    ]
  )
}

function renderInfo(h) {
  let value
  let color
  if (this.overdraft > 0) {
    value = this.getTranslate('account.tooltips.warning')
    color = colors.warning
  }
  if (this.overdraft === 0) {
    value = this.getTranslate('account.tooltips.error')
    color = colors.error
  }

  if (this.balance <= 0) {
    return h(
      info,
      {
        props: {
          value,
          color
        }
      }
    )
  }
}

function renderRows(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderRow.call(this, h, {
        icon: 'person',
        value: this.account.username,
        label: this.$name
      }),
      renderRow.call(this, h, {
        icon: 'wallet',
        value: getPrice(this.balance),
        label: this.getTranslate('account.current.balance'),
        update: true
      }),
      renderRow.call(this, h, {
        icon: 'price_change',
        value: getPrice(this.overdraft),
        label: this.getTranslate('account.current.overdraft')
      })
    ]
  )
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderRows.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}

function renderLogoutButton(h) {
  if (this.isLoggedAs) {
    return h(
      button,
      {
        props: {
          icon: 'exit_to_app',
          label: this.getTranslate('login.buttons.logout'),
          mode: modes.flat
        },
        on: {
          click: () => {
            this.$emit('show', false)
            this.loginAs(false)
          }
        }
      }
    )
  } else {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('login.buttons.logout'),
          mode: modes.flat
        },
        on: {
          click: () => {
            this.logout()
          }
        }
      }
    )
  }
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcsb',
      style: { 'grid-template-columns': 'repeat(2, auto)' }
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('users.buttons.account'),
            mode: modes.flat,
            color: colors.primary
          },
          on: {
            click: () => {
              this.$emit('show', false)
              Vue.router.push({ name: 'account' })
            }
          }
        }
      ),

      renderLogoutButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pa-2',
      props: {
        flat: this.flat,
        rounded: this.rounded,
        maxWidth: 400
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
