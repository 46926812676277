import proxy from '@sigma-legacy-libs/g-proxy'

import { components } from '@/utils'

import render from './render'

export default {
  name: components['tabs-header'],

  mixins: [ proxy() ],

  props: {
    tabs: {
      type: Array,
      default: () => []
    },

    isLink: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasArrows: false,

      tabIndex: 0,

      scrollPosition: 0,
      scrollStep: 120,

      prevDisabled: true,
      nextDisabled: false
    }
  },

  computed: {
    isOverflowing() {
      const { holder, items } = this.$refs
      if (items && holder) {
        return items.offsetWidth > holder.offsetWidth
      }

      return false
    }
  },

  mounted() {
    if (Array.isArray(this.tabs) && this.tabs.length) {
      this.setCurrentTab(this.tabs[0].key)
    }
    this.setShowArrows()
    window.addEventListener('resize', () => setTimeout(this.setShowArrows, 200))
    this.$nextTick(() => this.scrollToCurrent())
  },

  beforeDestroy() {
    window.removeEventListener('resize', () => setTimeout(this.setShowArrows, 200))
  },

  methods: {
    setCurrentTab(key) {
      if (this.proxy !== key) {
        this.proxy = key
      }
    },

    slide(direction) {
      const { holder, items } = this.$refs
      const holderWidth = holder.offsetWidth
      const itemsWidth = items.scrollWidth

      let newScrollPosition = this.scrollPosition

      switch (direction) {
        case 'prev': {
          this.nextDisabled = false
          newScrollPosition = Math.max(newScrollPosition - this.scrollStep, 0)
          if (newScrollPosition === 0) {
            this.prevDisabled = true
          }
          break
        }

        case 'next': {
          this.prevDisabled = false
          newScrollPosition = Math.min(newScrollPosition + this.scrollStep, itemsWidth - holderWidth)
          if (newScrollPosition === itemsWidth - holderWidth) {
            this.nextDisabled = true
          }

          break
        }
      }

      this.scrollPosition = newScrollPosition
      items.style.transform = `translateX(-${newScrollPosition}px)`
    },

    onWheel(event) {
      if (this.isOverflowing) {
        event.preventDefault()
        if (event.deltaY < 0) {
          this.slide('prev')
        } else {
          this.slide('next')
        }
      }
    },

    onItemClick(tab) {
      const { key, callback } = tab
      this.proxy = key
      if (typeof callback === 'function') {
        callback()
      }
    },

    scrollToCurrent() {
      const { items } = this.$refs
      const tabs = this.tabs.map(tab => tab.key || tab)

      if (items) {
        const currentIndex = tabs.findIndex(tab => tab === this.proxy)
        for (let index = 0; index < currentIndex; index++) {
          this.slide('next')
        }
      }
    },

    setShowArrows() {
      const { holder, items } = this.$refs
      if (items) {
        const parentWidth = parseInt(holder.offsetWidth)
        const itemsWidth = parseInt(items.offsetWidth)

        if (itemsWidth > parentWidth) {
          this.hasArrows = true
        } else {
          this.hasArrows = false
        }
      }
    }
  },

  render
}
