import button from '@/components/button'

export default function(h) {
  if (~[ 'g-list-item' ].indexOf(this.view)) {
    return h(
      'g-list-item',
      {
        class: `copy-list-item-${this._uid}`,
        props: {
          label: this.label,
          icon: this.icon,
          color: this.color
        }
      }
    )
  }

  return h(
    button,
    {
      attrs: { 'data-clipboard-text': `${this.value}` },
      class: `copy-button-${this._uid}`,
      props: {
        mode: this.mode,
        size: this.size,
        color: this.color,
        icon: this.icon,
        iconLibrary: this.iconLibrary,
        tooltip: this.tooltip
      }
    }
  )
}
