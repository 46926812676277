import VirtualList from 'vue-virtual-scroll-list'

import { colors, getLocaleDateString, getLocaleTimeString, moderationColors, modes, services } from '@/utils'

import { statusColors } from '@/components/services/sendingsModeration/utils'

import button from '@/components/button'
import row from '@/components/misc/virtualScroll/row'
import SingleLink from '@/components/misc/SingleLink'
import info from '@/components/misc/info'

function renderDialogHeader(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-dialog__header`
    },
    [
      h(
        'div',
        {
          class: 'headline'
        },
        this.getTranslate(`${services.sendingsModeration}.labels.task`)
      )
    ]
  )
}

function renderLabelByPath(h, path) {
  switch (path) {
    case 'pattern':
      return h(
        'div',
        {
          class: `faifs ${services.sendingsModeration}-dialog__info-label`
        },
        this.getTranslate(`${services.sendingsModeration}.labels.text`)
      )
    default: {
      return h(
        'div',
        {
          class: `${services.sendingsModeration}-dialog__info-label`
        },
        this.getTranslate(`${services.sendingsModeration}.labels.${path}`)
      )
    }
  }
}

function renderSingleLink(h, service, value) {
  return h(
    SingleLink,
    {
      props: {
        service,
        value
      }
    }
  )
}

function renderValueByPath(h, path, value) {
  switch (path) {
    case 'createdAt':
    case 'updatedAt': {
      return [ getLocaleDateString(value), getLocaleTimeString(value) ].join(' ')
    }
    case 'moderation': {
      return h(
        'span',
        {
          class: `${services.sendingsModeration}-dialog__info-status--${moderationColors[this.task.moderation]}`
        },
        this.getTranslate(`${services.sendingsModeration}.labels.tasks.moderation.${value}`)
      )
    }
    case 'status': {
      return h(
        'span',
        {
          class: `${services.sendingsModeration}-dialog__info-status--${statusColors[this.task.status]}`
        },
        this.getTranslate(`${services.sendingsModeration}.labels.tasks.status.${value}`)
      )
    }
    case 'pattern': {
      return h('pre', { style: 'text-wrap: wrap' }, value)
    }
    case 'OwnerId': {
      return renderSingleLink.call(this, h, 'users', value)
    }
    case 'providerId': {
      return renderSingleLink.call(this, h, 'providers', value)
    }
    default: {
      return value
    }
  }
}

function renderRowByPath(h, path) {
  if (this.task[path]) {
    switch (path) {
      case 'id':
      case 'status':
      case 'moderation':
      case 'pattern':
      case 'OwnerId':
      case 'sender':
      case 'providerId':
      case 'createdAt':
      case 'updatedAt': {
        return h(
          'div',
          {
            class: `${services.sendingsModeration}-dialog__info-row`
          },
          [
            renderLabelByPath.call(this, h, path),
            h(
              'div',
              {
                class: `${services.sendingsModeration}-dialog__info-value`
              },
              [ renderValueByPath.call(this, h, path, this.task[path]) ]
            )
          ]
        )
      }
    }
  }
}

function renderInfoRows(h) {
  return Object.keys(this.task).map(path => {
    return renderRowByPath.call(this, h, path)
  })
}

function renderTaskInfo(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-dialog__info`
    },
    [ renderInfoRows.call(this, h) ]
  )
}

function renderHint(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.sendingsModeration}.hints.sendings.info`),
        color: colors.primary
      }
    }
  )
}

function renderSendingsLabel(h) {
  return h(
    'div',
    {
      class: 'subtitle'
    },
    this.getTranslate(`${services.sendingsModeration}.labels.sendingsList`)
  )
}

function renderSendingsRow(h, sending) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-dialog__body-list__row`,
      on: {
        click: () => {
          this.copyId(sending.sendingId)
        }
      }
    },
    [
      h(
        'div',
        {},
        `${sending.sendingId}`
      ),
      h(
        'div',
        { class: 'text--gray fz-11' },
        this.getTranslate(`${services.sendingsModeration}.labels.sendings.sendingId`)
      )
    ]
  )
}

function renderSendings(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc pa-3'
      },
      [
        h(
          'g-progress',
          {
            props: {
              indeterminate: true
            }
          }
        )
      ]
    )
  } else if (this.sendings.length) {
    return h(
      VirtualList,
      {
        class: `${services.sendingsModeration}-dialog__body-list`,
        props: {
          dataKey: 'sendingId',
          dataSources: this.sendings,
          dataComponent: row,
          topThreshold: 1,
          bottomThreshold: 1,
          itemScopedSlots: {
            item: sending => renderSendingsRow.call(this, h, sending)
          }
        },
        on: {
          totop: () => {
            const loadTop = this.pagination.offset - this.pagination.limit > 0
            if (!this.loading && loadTop) {
              this.pagination.offset -= this.pagination.limit
            }
          },
          tobottom: () => {
            const loadBottom = this.pagination.limit + this.pagination.offset < this.pagination.total
            if (!this.loading && loadBottom) {
              this.pagination.offset += this.pagination.limit
            }
          }
        }
      }
    )
  }

  return h('g-empty')
}

function renderDialogBody(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-dialog__body`
    },
    [
      renderTaskInfo.call(this, h),
      renderHint.call(this, h),
      renderSendingsLabel.call(this, h),
      renderSendings.call(this, h)
    ]
  )
}

function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: modes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      class: `${services.sendingsModeration}-dialog`,
      props: {
        rounded: this.viewport.breakpoint.smUp,
        value: this.showDialog,
        maxWidth: this.viewport.breakpoint.smUp ? 400 : '100%'
      },
      on: {
        input: event => {
          if (event === false) {
            this.$emit('close', true)
          }
          this.showDialog = event
        }
      }
    },
    [
      renderDialogHeader.call(this, h),

      renderDialogBody.call(this, h),

      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return renderDialog.call(this, h)
}
