import { buttonModes, colors, components, icons, popperPlacements, sizes } from '@/utils'

import button from '@/components/button'
import tag from '@/components/tag'

function renderEdgeArrow(h, options) {
  if (this.arrows && this.viewport.breakpoint.mdUp) {
    return h(
      button,
      {
        props: {
          icon: options.icon,
          mode: buttonModes.flat,
          size: sizes.medium,
          disabled: options.disabled
        },
        on: {
          click: () => {
            this.setPage(options.page)
          }
        }
      }
    )
  }
}

function renderArrow(h, options) {
  if (this.arrows) {
    return h(
      button,
      {
        props: {
          icon: options.icon,
          mode: buttonModes.flat,
          size: sizes.medium,
          disabled: options.disabled
        },
        on: {
          click: () => {
            this.setPage(options.page)
          }
        }
      }
    )
  }
}

function renderPages(h) {
  return this.pages.map(page => {
    return h(
      button,
      {
        props: {
          label: page,
          size: sizes.medium,
          mode: this.currentPage !== page ? buttonModes.flat : buttonModes.filled,
          color: this.currentPage === page ? colors.primary : undefined
        },
        on: {
          click: () => {
            this.setPage(page)
          }
        }
      }
    )
  })
}

function renderSelectRows(h) {
  if (this.viewport.breakpoint.mdUp) {
    if (this.selectRows) {
      return h(
        components.menu,
        {
          props: {
            rounded: true,
            sameWidth: true,
            placement: popperPlacements['bottom-end'],
            offsetDistance: 8
          }
        },
        [
          h(
            button,
            {
              props: {
                label: this.proxy.limit,
                icon: icons.list,
                size: sizes.medium,
                color: colors.primary
              },
              slot: 'activator'
            }
          ),

          h(
            components.list,
            {
              props: {
                dense: true,
                rounded: true
              }
            },
            [
              this.rowsPerPage.map(rows => {
                return h(
                  components['list-item'],
                  {
                    props: {
                      active: this.proxy.limit === rows,
                      dense: true
                    },
                    on: {
                      click: () => {
                        this.setPage(1, rows)
                      }
                    }
                  },
                  rows
                )
              })
            ]
          )
        ]
      )
    }
  }
}

function renderTotal(h) {
  if (this.proxy.total) {
    return h(
      tag,
      {
        props: {
          as: 'button',
          label: this.proxy.total,
          size: sizes.huge,
          color: colors.primary,
          monospace: true
        }
      }
    )
  }
}

export default function(h) {
  if (this.show) {
    if (this.proxy.total > this.proxy.limit) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': this.buttonColumnsStyle }
        },
        [
          renderEdgeArrow.call(
            this,
            h,
            {
              icon: icons.first_page,
              page: 1,
              disabled: this.proxy.offset === 0
            }
          ),

          renderArrow.call(
            this,
            h,
            {
              icon: icons.keyboard_arrow_left,
              page: this.currentPage - 1,
              disabled: this.proxy.offset === 0
            }
          ),

          renderPages.call(this, h),

          renderArrow.call(
            this,
            h,
            {
              icon: icons.keyboard_arrow_right,
              page: this.currentPage + 1,
              disabled: this.proxy.offset + this.proxy.limit >= this.proxy.total
            }
          ),

          renderEdgeArrow.call(
            this,
            h,
            {
              icon: icons.last_page,
              page: this.totalPages,
              disabled: this.proxy.offset + this.proxy.limit >= this.proxy.total
            }
          ),

          renderSelectRows.call(this, h),

          renderTotal.call(this, h)
        ]
      )
    } else {
      return renderTotal.call(this, h)
    }
  }
}
