import { FILE_TYPES, buttonModes, colors, components, inputModes, numberToPxOrString, permissionPresets, services, sizes, states } from '@/utils'

import button from '@/components/button'
import FileItems from '@/components/file/items'
import FileDialog from '@/components/file/dialog'
import OwnerSelect from '@/components/misc/OwnerSelect'
import RefreshButton from '@/components/button/refresh'
import UploadFileButton from '@/components/misc/UploadFileButton'
import pagination from '@/components/misc/pagination'
import info from '@/components/misc/info'
import preloader from '@/components/misc/preloader'
import squircle from '@/components/icon/squircle'
import tag from '@/components/tag'

function renderLoadingArea(h, trigger) {
  if (trigger) {
    return h(
      'div',
      {
        attrs: { id: 'loading-area' },
        class: {
          'loading-area': true,
          'loading-area--active': this.showLoadingArea
        },
        domProps: { innerHTML: this.getTranslate('files.drop') },
        on: {
          dragleave: event => {
            event.preventDefault()
            this.showLoadingArea = false
          },
          drop: event => {
            event.preventDefault()
            this.showLoadingArea = false
          }
        }
      }
    )
  }
}
function renderUploadFileButtonOrArea(h, trigger = true, type = 'button') {
  if (trigger && this.checkPermissions(`advanced.${services.storage}.upload`, permissionPresets.meUp)) {
    return h(
      UploadFileButton,
      {
        props: {
          isPublic: this.isPublic,
          name: this.name,
          maxFileSize: this.maxFileSize,
          accepted: this.types || undefined,
          disabled: this.loading,
          type
        }
      },
      [ renderLoadingArea.call(this, h, type === 'drop') ]
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        preloader,
        {
          props: {
            value: true
          }
        }
      )
    ]
  )
}

function renderPagination(h) {
  if (this.restData[services.storage].find.pagination.total) {
    return h(
      'div',
      {
        class: {
          'fjcfe faic grid-gap--8': true
        }
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.storage].find.pagination,
              selectRows: !this.viewport.breakpoint.xs,
              chunk: this.viewport.breakpoint.xs ? 1 : 3,
              service: services.storage
            },
            on: {
              input: event => {
                this.restData[services.storage].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            props: {
              state: this.restData[services.storage].find.state,
              shortClickMethod: () => this.rest[services.storage].find(),
              longClickMethod: () => this.rest[services.storage].find({}, { noCache: true }),
              disabled: this.loading
            }
          }
        )
      ]
    )
  }
}

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.restData[services.storage].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        disabled: !this.restData[services.storage].find.filter.$search && !this.restData[services.storage].find.pagination.total,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.restData[services.storage].find.filter.$search = event
        }
      }
    }
  )
}
function renderIdField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.restData[services.storage].find.filter.id,
        label: this.getTranslate('commons.labels.id'),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.restData[services.storage].find.filter.id = event
        }
      }
    }
  )
}
function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.restData[services.storage].find.filter.OwnerId,
        mode: inputModes.outline,
        dense: true,
        loading: this.loading,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.restData[services.storage].find.filter.OwnerId = event
        }
      }
    }
  )
}
function renderFileIconInFilter(h, color, size) {
  return h(
    squircle,
    {
      props: {
        iconSVG: 'folder-file',
        color: colors[color],
        size
      }
    }
  )
}
function renderTypeSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.searchTypes,
        label: this.getTranslate('commons.labels.type'),
        items: this.selectTypes,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        multiple: true,
        disabled: this.disabled || this.loading
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: item.title,
                size: sizes.small,
                color: FILE_TYPES[item.value].color
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'faic grid-gap--8'
            },
            [
              renderFileIconInFilter.call(this, h, FILE_TYPES[item.value].color, sizes.small),

              h(
                'div',
                { class: 'grid grid-gap--0 fjcc' },
                [
                  item.title,
                  h('div', { class: 'text--grey tiny lh-11' }, FILE_TYPES[item.value].extensions.join(', '))
                ]
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.searchTypes = event
        }
      }
    }
  )
}
function renderFilter(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--2': !this.viewport.breakpoint.lg && !this.viewport.breakpoint.xs,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'px-2 pb-2': this.showFilter && !this.viewport.breakpoint.lgUp
      },
      style: {
        maxWidth: this.viewport.breakpoint.lgUp ? numberToPxOrString(840) : '100%'
      }
    },
    [
      renderSearchField.call(this, h),
      renderIdField.call(this, h),
      renderOwnerSelect.call(this, h),
      renderTypeSelect.call(this, h)
    ]
  )
}
function renderHeaderButtons(h) {
  return h(
    'div',
    {
      class: {
        'faic grid-gap--8': true,
        fjcfe: !this.viewport.breakpoint.lgUp
      }
    },
    [ renderUploadFileButtonOrArea.call(this, h, !this.embedded || this.restData[services.storage].find.data) ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: {
        'faic grid-gap--8': true,
        fjcsb: this.viewport.breakpoint.lgUp,
        grid: !this.viewport.breakpoint.lgUp
      }
    },
    [
      renderFilter.call(this, h),
      renderHeaderButtons.call(this, h)
    ]
  )
}
function renderHeaderBlock(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'pa-2': !this.embedded || !this.viewport.breakpoint.xs,
        'px-2 pt-3 pb-2': this.embedded && this.viewport.breakpoint.xs
      },
      slot: this.embedded && !this.viewport.breakpoint.xs ? 'header' : undefined
    },
    [
      renderHeader.call(this, h),
      renderPagination.call(this, h, true)
    ]
  )
}

function renderWindowWithPreloader(h) {
  if (this.state === states.loading) {
    return h(
      'div',
      {
        class: 'loading-file'
      },
      [
        renderPreloader.call(this, h),
        h('div', { class: 'pl-3' }, this.getTranslate('files.states.loading'))
      ]
    )
  }
}

function renderFileItems(h) {
  return h(
    FileItems,
    {
      props: {
        items: this.restData[services.storage].find.data,
        order: this.restData[services.storage].find.order,
        currentChosenFileId: this.currentChosenFileId,
        readonly: this.readonly,
        embedded: this.embedded
      },
      on: {
        input: event => {
          this.$emit('input', event)
        },
        order: event => {
          this.restData[services.storage].find.order = event
        },
        dialog: event => {
          if (event.close) {
            this.googleFileURL = undefined
            this.currentEditFile = undefined
            this.editDialogType = undefined
            this.showEditDialog = false

            return
          }
          this.googleFileURL = event.googleFileURL
          this.currentEditFile = event.currentEditFile
          this.editDialogType = event.editDialogType
          this.showEditDialog = true
        }
      },
      key: 'file-manager-content'
    }
  )
}
function renderBody(h) {
  if (this.restData[services.storage].find.state === states.loading) {
    return renderPreloader.call(this, h)
  } else if (this.restData[services.storage].find.pagination.total) {
    return renderFileItems.call(this, h)
  }

  return h(
    'div',
    {
      class: 'fc fjcc facc faic pa-5'
    },
    [
      h(components.empty),

      renderUploadFileButtonOrArea.call(this, h)
    ]
  )
}

function renderBodyWithDrag(h) {
  return h(
    'div',
    {
      style: {
        position: 'relative',
        pointerEvents: this.state === states.loading ? 'none' : 'auto'
      },
      on: {
        dragenter: event => {
          event.preventDefault()
          event.stopPropagation()
          this.showUploadArea()
        }
      }
    },
    [
      renderBody.call(this, h),
      renderUploadFileButtonOrArea.call(this, h, true, 'drop'),
      renderWindowWithPreloader.call(this, h)
    ]
  )
}

function renderFooterInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.storage}.hints.expire`)
      }
    }
  )
}
function renderCloseButton(h) {
  if (this.embedded) {
    return h(
      'div',
      {
        class: 'faic fjcfe'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.close'),
              mode: buttonModes.flat
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        )
      ]
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2',
      slot: this.embedded ? 'footer' : undefined
    },
    [
      renderFooterInfo.call(this, h),
      renderCloseButton.call(this, h)
    ]
  )
}

function renderFileDialog(h) {
  return h(
    FileDialog,
    {
      props: {
        value: this.currentEditFile,
        googleFileURL: this.googleFileURL,
        show: this.showEditDialog,
        type: this.editDialogType
      },
      on: {
        show: event => {
          this.showEditDialog = event
        },
        input: event => {
          this.currentEditFile = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    this.embedded ? components.dialog : components.card,
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: this.embedded ? this.viewport.breakpoint.mdUp ? 1000 : '100%' : undefined,
        maxHeight: this.embedded && !this.viewport.breakpoint.xs ? '95%' : undefined,
        outline: true,
        overflow: !this.embedded,
        closeOnClick: this.embedded && !this.loading,
        closeOnEsc: this.embedded && !this.loading
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeaderBlock.call(this, h),
      renderBodyWithDrag.call(this, h),
      renderFooter.call(this, h),
      renderFileDialog.call(this, h)
    ]
  )
}

