import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'NotificationsEventsBillingsLimit',

    mixins: [ proxy({ type: 'array' }) ],

    methods: {
      _outputFilter(data) {
        data = data.map(item => {
          if (item.invoice && item.invoice.amount) {
            item.invoice = {
              requisiteId: item.invoice.requisiteId,
              companyId: item.invoice.companyId,
              type: item.invoice.type,
              data: {
                SERVICE_PRICE: item.invoice.amount
              }
            }
          }

          return item
        })

        return data
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
