import Vue from 'vue'

import { get } from 'lodash'
import { mapGetters } from 'vuex'
import { RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, getPrice, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'RecurrentPaymentsItem',

  mixins: [
    generateServices({
      name: services.recurrentPayments,

      find: false,
      create: false,

      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    isPage: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      currency: undefined
    }
  },

  computed: {
    ...mapGetters({ token: 'authentication/token' }),

    period() {
      switch (this.value.type) {
        case RECURRENT_PAYMENTS_TYPES.threshold: {
          return this.getTranslate(`${services.recurrentPayments}.contents.${this.value.type}`, { threshold: getPrice(this.value.threshold, { currency: this.currency }) })
        }
        case RECURRENT_PAYMENTS_TYPES.frequency: {
          return this.getTranslate(`tasks.frequencies.${this.value.frequency}`)
        }
      }
    },
    isPaused() {
      return this.value.status === RECURRENT_PAYMENTS_STATUSES.pause
    },

    status() {
      if (this.isPage) {
        return this.getTranslate(`${services.recurrentPayments}.statuses.${this.value.status}`)
      }

      return this.getTranslate(`${services.recurrentPayments}.hints.statuses.${this.value.status}`)
    },

    amountLabel() {
      if (!this.viewport.breakpoint.mdUp) {
        if (this.checkPermissions(`advanced.${services.users}.get`)) {
          return get(this.value, 'Owner.username')
        }
      }

      return this.getTranslate(`${services.recurrentPayments}.labels.amount`)
    },

    ownerId() {
      return get(this.value, 'Owner.id', this.value.OwnerId)
    }
  },

  watch: {
    ownerId: 'getCurrency'
  },

  mounted() {
    this.getCurrency()
  },

  methods: {
    async repeatRecurrentPayment() {
      if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
        if (~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
          try {
            const { data } = await Vue.$GRequest.get(services.recurrentPayments, `${this.value.id}/reInit`, { method: this.value.method })
            if (data) {
              const { url } = data
              if (url) {
                window.location.href = url
              }
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    async activateRecurrentPayment() {
      if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
        if (!~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
          try {
            await this.rest[services.recurrentPayments].update(this.value.id, Object.assign(
              {},
              this.value,
              { status: this.isPaused ? RECURRENT_PAYMENTS_STATUSES.ready : RECURRENT_PAYMENTS_STATUSES.pause }
            ))
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    async getCurrency() {
      if (this.ownerId) {
        const { data } = await Vue.$GRequest.get(services.users, this.ownerId, { query: { $scope: [ 'full' ] } })
        if (data) {
          this.currency = get(data.settings, 'billings.currency', this.globalSettings.billings.currency)
        }
      }
    }
  },

  render
}
