import button from '@/components/button'
import FileManager from '@/components/file/manager'

import { FILE_TYPES, colors, icons, modes } from '@/utils'

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        flat: true,
        readonly: true,
        embedded: true,
        show: this.showFileManager,
        types: FILE_TYPES.csv.extensions,
        isPublic: true
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.import(event.id)

          this.showFileManager = false
        }
      }
    }
  )
}

function renderButton(h) {
  return h(
    button,
    {
      props: {
        mode: modes.flat,
        icon: icons.file_upload,
        color: colors.primary,
        loading: this.loading,
        disabled: this.loading || this.disabled,
        tooltip: this.getTranslate('misc.buttons.upload')
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      renderFileManager.call(this, h),
      renderButton.call(this, h)
    ]
  )
}
