import Vue from 'vue'

import { get } from 'lodash'

import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'MobilePanel',

  data() {
    return {
      isJivositeOpen: false
    }
  },

  computed: {
    items() {
      const result = [
        {
          name: 'create',
          icon: 'send',
          to: { name: 'create' }
        },
        {
          name: 'templates',
          icon: 'information',
          to: { name: 'create' }
        }
      ]
      const jivosite = get(this.globalSettings, 'frontend.jivosite')
      const contacts = get(this.globalSettings, 'contacts')

      const routeToSupport = {
        name: 'support',
        icon: 'question',
        to: { name: 'support' }
      }

      switch (this.locale) {
        case 'en': {
          if (contacts) {
            result.push(routeToSupport)
          }
          break
        }

        case 'ru':
        default: {
          if (jivosite) {
            result.push({
              name: 'jivosite',
              icon: 'question'
            })
          } else if (contacts) {
            result.push(routeToSupport)
          }
          break
        }
      }

      result.push({
        name: 'payment',
        icon: 'wallet-add'
      })

      return result
    },

    ...mapGetters({
      tab: 'currentTab/tab',
      showPaymentDialog: 'payments/showPaymentDialog'
    })
  },

  watch: {
    $route: {
      handler() {
        this.init()
      },
      deep: true
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      const sameItemRouteName = this.items.some(item => {
        if (item.to && item.to.name) {
          return item.to.name === this.$route.name
        }
      })
      if (sameItemRouteName) {
        const name = this.tab.to && this.tab.to.name || this.tab.name
        if (name && name === this.$route.name) {
          this.setCurrentTab(this.tab)
        } else {
          this.setCurrentTab({ name: this.$route.name })
        }
      } else {
        this.setCurrentTab({
          name: undefined,
          to: {}
        })
      }
    },

    itemProcessor(item) {
      switch (item.name) {
        case 'create':
        case 'templates':
        case 'support': {
          this.closeJivosite()
          this.setTabItemAndPush(item)
          this.setShowPaymentDialog(false)
          break
        }

        case 'payment': {
          this.closeJivosite()
          this.setShowPaymentDialog(true)
          break
        }

        case 'jivosite': {
          this.setShowPaymentDialog(false)
          if (window.jivo_api) {
            this.isJivositeOpen = true
            const container = document.querySelector('jdiv')
            container?.classList.remove('jivo-override')
            window.jivo_api.open()
          }
          break
        }
      }
    },

    setTabItemAndPush(tab) {
      if (this.items.some(item => item.name === tab.name)) {
        this.setCurrentTab(tab)

        if (tab.to) {
          Vue.router.push(tab.to)
        }
      }
    },

    closeJivosite() {
      if (window.jivo_api && this.isJivositeOpen) {
        this.isJivositeOpen = false
        window.jivo_api.close()
      }
    },

    ...mapActions({
      setCurrentTab: 'currentTab/setCurrentTab',
      setShowPaymentDialog: 'payments/setShowPaymentDialog'
    })
  },

  render
}
