import { isUUID, projectName } from '@/utils'

const prefixKey = service => `${projectName}:visited:${service}`
const limit = 20

export default {
  namespaced: true,

  state: {
    items: []
  },

  mutations: {
    set(state, value) {
      state.items = value
    }
  },

  actions: {
    save({ commit }, { service, value }) {
      if (Array.isArray(value)) {
        const result = value.slice(0, limit)
        if (result.length <= limit) {
          commit('set', result)
          window.localStorage.setItem(prefixKey(service), JSON.stringify(result))
        }
      }
    },

    set({ dispatch }, { service, items }) {
      try {
        const item = window.localStorage.getItem(prefixKey(service))
        const parsed = JSON.parse(item) || []
        if (items.length) {
          for (const id of items) {
            if (isUUID(id)) {
              if (parsed.includes(id)) {
                parsed.splice(parsed.indexOf(id), 1)
              }
              parsed.unshift(id)
            }
          }
        }

        dispatch('save', {
          service,
          value: parsed
        })

        return true
      } catch (error) {
        return false
      }
    },

    add({ dispatch }, { service, id }) {
      dispatch('set', {
        service,
        items: [ id ]
      })
    },

    remove({ dispatch }, { service, id }) {
      try {
        const item = window.localStorage.getItem(prefixKey(service))
        const parsed = JSON.parse(item) || []
        if (parsed.length) {
          const index = parsed.indexOf(id)
          if (~index) {
            parsed.splice(index, 1)
          }
        }

        dispatch('save', {
          service,
          value: parsed
        })

        return true
      } catch (error) {
        return false
      }
    },

    get(_, service) {
      try {
        const data = window.localStorage.getItem(prefixKey(service))
        const result = JSON.parse(data) || []

        return result
      } catch (error) {
        return []
      }
    }
  },

  getters: {
    items: state => state.items
  }
}
