import { colors, components } from '@/utils'

import overflowString from '@/components/misc/overflowString'

function renderDivider(h) {
  return h(components.divider)
}
function renderTitle(h) {
  return h(
    overflowString,
    {
      props: {
        value: this.value,
        font: { size: 13 },
        color: colors.grey
      }
    }
  )
}

export default function(h) {
  if (this.value) {
    return h(
      'div',
      {
        class: 'title-divider'
      },
      [
        renderDivider.call(this, h),
        renderTitle.call(this, h),
        renderDivider.call(this, h)
      ]
    )
  }
}
