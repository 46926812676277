import Vue from 'vue'

import { debounce } from 'lodash'
import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, generateServices, globalErrorHandler, globalErrorProcessor, isUUID, services } from '@/utils'
import { getColor } from '@/components/services/notifications/utils'

import render from './render'

export default {
  name: 'Widget',

  mixins: [
    generateServices({
      name: services.notifications,

      backendGeneration: 'nest',

      find: {
        defaultFilter: { targetUserId: undefined },
        defaultPagination: { limit: 10 }
      },

      get: false,
      create: false,
      remove: false
    })
  ],

  props: {
    show: Boolean
  },

  data() {
    return {
      arrow: {
        top: false,
        bottom: false
      },

      loading: {}
    }
  },

  computed: {
    $component() {
      let result = components.dialog
      if (this.viewport.breakpoint.smUp) {
        result = 'div'
      }

      return result
    },

    $title() {
      if (this.viewport.breakpoint.xs) {
        return this.getTranslate(`${services.notifications}.title`)
      }
    }
  },

  watch: {
    'account.id'(value) {
      if (value) {
        this.rest[services.notifications].find({ targetUserId: value })
      }
    },

    show(value) {
      if (value) {
        this.$nextTick(() => {
          this.wheelHandler()
        })
      }
    }
  },

  mounted() {
    this.rest[services.notifications].find({ targetUserId: this.account.id })

    Vue.$socket.on(`${services.notifications}.sent`, notification => {
      if (notification.channel === NOTIFICATIONS_AVAILABLE_TYPES.web) {
        if (Array.isArray(this.restData[services.notifications].find.data)) {
          this.restData[services.notifications].find.data.splice(0, 0, notification)
          if (this.restData[services.notifications].find.data.length > this.restData[services.notifications].find.pagination.limit) {
            this.restData[services.notifications].find.data.pop()
          }
          this.$emit('getNotification')
          this.addSnackbar({
            text: this.getTranslate(`${services.notifications}.events.${notification.type}`),
            type: getColor.call(this, notification.type)
          })
        }
      }
    })

    Vue.$socket.on(`${services.notifications}.read`, notification => {
      const index = this.restData[services.notifications].find.data.findIndex(item => item.id === notification.id)
      if (index !== -1) {
        this.restData[services.notifications].find.data.splice(index, 1, {
          ...this.restData[services.notifications].find.data[index],
          ...notification
        })
      }
    })

    this.$nextTick(() => {
      this.scrollHandler()
      this.scroll('top')
    })
  },

  methods: {
    read: debounce(
      async function(id) {
        if (isUUID(id)) {
          Vue.set(this.loading, id, true)

          const { data: notifications } = this.restData[services.notifications].find
          const notification = notifications.find(item => item.id === id)

          if (notification && !notification.isRead) {
            try {
              const { data } = await Vue.$GRequest.patch(`/n/${services.notifications}/${id}`, 'read')
              if (data) {
                Object.assign(notification, data)
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }

          Vue.delete(this.loading, id)
        }
      },
      200,
      {
        leading: true,
        trailing: true
      }
    ),

    scroll(direction) {
      const { viewport, list } = this.$refs
      if (!viewport || !list) {
        return
      }
      const scrollAmount = direction === 'top' ? -list.clientHeight : list.clientHeight
      viewport.scrollTo({
        top: scrollAmount,
        behavior: 'smooth'
      })
    },

    async wheelHandler() {
      if (this.show) {
        const { viewport, list } = this.$refs
        if (!viewport || !list || !list.children) {
          return
        }

        const ids = new Set()
        const viewportRect = viewport.getBoundingClientRect()
        for (const child of list.children) {
          const id = child.getAttribute('id')
          const childRect = child.getBoundingClientRect()
          if (childRect.top < viewportRect.top || childRect.bottom > viewportRect.bottom) {
            continue
          } else {
            if (id) {
              ids.add(id)
            }
          }
        }

        await Promise.allSettled(Array.from(ids).map(id => this.read(id)))
      }
    },
    scrollHandler() {
      const { viewport, list } = this.$refs
      if (viewport && list && list.children) {
        this.arrow.top = viewport.scrollTop > list.children[0].clientHeight ? true : false
        this.arrow.bottom = viewport.scrollTop + viewport.clientHeight < viewport.scrollHeight - 100
      }
    }
  },

  render
}
