import { upperFirst } from 'lodash'

import { getLocaleDateString, getPrice } from '@/utils'

import buttonCopy from '@/components/button/copy'
import RefreshButton from '@/components/button/refresh'
import ReferralDateFilter from '@/components/misc/ReferralPayments/filter'
import pagination from '@/components/misc/pagination'
import sticker from '@/components/misc/sticker'

const serviceName = 'billings'

function renderLastMonthSum(h) {
  const date = new Date()
  date.setMonth(date.getMonth() - 1)

  return h(
    sticker,
    {
      props: {
        value: getPrice(this.lastMonthSum),
        label: upperFirst(date.toLocaleString(this.locale, { month: 'long' })),
        loading: this.loading,
        icon: 'attach_money',
        color: 'grey'
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'referrals-buttons'
    },
    [
      h(
        buttonCopy,
        {
          props: {
            value: this.referralCode,
            icon: 'content_copy',
            snackbar: {
              text: this.getTranslate('users.snackbars.refCopied'),
              type: 'success'
            },
            tooltip: this.getTranslate('users.tooltips.copy.referral.code')
          }
        }
      ),
      h(
        buttonCopy,
        {
          props: {
            value: new URL(`#/registration?ref=${this.referralCode}`, window.location.origin).href,
            icon: 'link',
            snackbar: {
              text: this.getTranslate('users.snackbars.linkCopied'),
              type: 'success'
            },
            tooltip: this.getTranslate('users.tooltips.copy.referral.link')
          }
        }
      )
    ]
  )
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: 'referrals-info'
    },
    [
      renderLastMonthSum.call(this, h),
      renderButtons.call(this, h)
    ]
  )
}

function renderDateTimeFilter(h) {
  return h(
    ReferralDateFilter,
    {
      props: {
        value: this.restData[serviceName].find.filter
      },
      on: {
        input: event => {
          this.restData[serviceName].find.filter = event
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'referrals-header'
    },
    [
      renderInfo.call(this, h),
      renderDateTimeFilter.call(this, h)
    ]
  )
}

function renderPagination(h) {
  if (this.restData[serviceName].find.pagination.total) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 fjcfe faic pa-2',
        style: { 'grid-template-columns': '1fr 38px' }
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[serviceName].find.pagination,
              service: serviceName
            },
            on: {
              input: event => {
                this.restData[serviceName].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            props: {
              state: this.restData[serviceName].find.state,
              shortClickMethod: () => this.rest[serviceName].find(),
              longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  if (this.restData[serviceName].find.state === 'ready') {
    return h(
      'g-table',
      {
        props: {
          value: this.restData[serviceName].find.order,
          items: this.restData[serviceName].find.data,
          headers: this.headers,
          hideHead: this.restData[serviceName].find.pagination.total < 1,
          noDataText: this.getTranslate('referral.no.data.text')
        },
        scopedSlots: {
          items: ({ item }) => {
            return [
              h(
                'tr',
                {
                  key: item.id
                },
                [
                  h(
                    'td',
                    {
                      class: 'w--100 pl-2 pr-2 text-left'
                    },
                    item.data.hidden.referredUser
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    getPrice(item.amount)
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text--grey text-right',
                      style: { 'white-space': 'pre' }
                    },
                    getLocaleDateString(item.createdAt)
                  )
                ]
              )
            ]
          }
        }
      }
    )
  } else {
    return h(
      'div',
      {
        class: 'w--100 fjcc facc pt-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
