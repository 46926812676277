import { get } from 'lodash'

import { getFileMiscInfo } from '@/components/file/utils'

import render from './render'

export default {
  name: 'FileType',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    showPlayer: {
      type: Boolean,
      default: true
    },

    small: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    $file() {
      return getFileMiscInfo(this.value)
    },

    OwnerId() {
      return get(this.value, 'Owner.id', this.value.OwnerId)
    }
  },

  render
}
