import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'DocumentTemplatesGenerateForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    disabled: Boolean,

    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      type: DOCUMENT_TEMPLATES_TYPES.invoice,
      formValidation: false
    }
  },

  computed: {
    validation() {
      return this.formValidation
    }
  },

  watch: {
    type() {
      this.$emit('type', this.type)
    },

    validation() {
      this.$emit('validation', this.validation)
    }
  },

  render
}

