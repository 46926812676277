import { modes, services } from '@/utils'

import button from '@/components/button'
import tab from '@/components/services/eventsHistory/tab'

function renderDialogHeader(h) {
  return h(
    'div',
    {
      class: 'headline pa-3',
      slot: 'header'
    },
    [ this.getTranslate(`${services.eventsHistory}.labels.eventsHistory`) ]
  )
}
function renderDialogBody(h) {
  return h(
    tab,
    {
      props: {
        entityId: this.entityId
      }
    }
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fiac fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: modes.flat
          },
          on: {
            click: () => {
              this.$emit('dialog', false)
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 800 : '100%'
      },
      on: {
        input: event => {
          this.$emit('dialog', event)
        }
      }
    },
    [
      renderDialogHeader.call(this, h),
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}
