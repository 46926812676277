import { proxy } from '@/utils'

import render from './render'

export default function(options) {
  return {
    name: 'ApplicationCredentials',

    mixins: [ proxy({ type: 'object' }) ],

    methods: {
      transmitValue() {}
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
