import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, permissionPresets, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/yclients/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: permissionPresets.true,
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined,
          salonId: undefined
        }
      },

      view: 'panels',
      views: [ 'panels' ],
      has: {
        clone: false,
        view: false
      }
    })
  ]
}
