import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

const key = 'notifications.enabled'

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`${this.serviceName}.subtitles.${key}`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      NOTIFICATIONS_AVAILABLE_TYPES.map(type => {
        if (type !== 'voice') {
          return h(
            'g-switch',
            {
              props: {
                value: this.proxy[type],
                label: this.getTranslate(`${this.serviceName}.labels.${key}.types.${type}`),
                color: 'primary'
              },
              on: {
                input: event => {
                  this.proxy[type] = event
                }
              }
            }
          )
        }
      })
    ]
  )
}
