import { buttonModes, colors, components, services } from '@/utils'

import button from '@/components/button'
import tokensForm from '@/components/services/tokens/form'

function renderBody(h) {
  return h(
    tokensForm,
    {
      props: {
        value: this.payload,
        OwnerId: this.OwnerId
      },
      on: {
        input: event => {
          this.payload = event
        },
        validation: event => {
          this.formValidation = event
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.cancel'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.proxy = false
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            color: colors.primary,
            loading: this.loading,
            disabled: this.loading || !this.formValidation
          },
          on: {
            click: () => {
              this.createHandler()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.proxy,
        title: this.getTranslate(`${services.tokens}.titles.create`),
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
