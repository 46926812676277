import { components, icons } from '@/utils'

function renderArrow(h, direction = 'prev') {
  if (this.hasArrows) {
    return h(
      'div',
      {
        class: {
          [`${components['tabs-header']}__arrow ${components['tabs-header']}__arrow--${direction}`]: true,
          [`${components['tabs-header']}__arrow--disabled`]: this[`${direction}Disabled`]
        },
        on: {
          click: () => {
            this.slide(direction)
          }
        }
      },
      [
        h(
          components.icon,
          {
            props: {
              value: direction === 'prev' ? icons.keyboard_arrow_left : icons.keyboard_arrow_right
            }
          }
        )
      ]
    )
  }
}

function renderIcon(h, value) {
  if (value) {
    return h(
      'div',
      {
        class: `${components['tabs-header']}__icon`
      },
      [
        h(
          components.icon,
          {
            props: {
              value
            }
          }
        )
      ]
    )
  }
}
function renderItem(h, tab) {
  const { key, title, icon } = tab

  return h(
    'div',
    {
      class: {
        [`${components['tabs-header']}__item`]: true,
        [`${components['tabs-header']}__item--active`]: this.proxy === key,
        [`${components['tabs-header']}__item--has-icon`]: !!icon
      },
      on: {
        click: () => this.onItemClick(tab)
      }
    },
    [ renderIcon.call(this, h, icon), title ]
  )
}
function renderLink(h, tab) {
  const { key } = tab

  return h(
    'router-link',
    {
      class: 'link link--passive',
      props: {
        to: {
          name: this.$route.name,
          query: {
            currentTab: key
          }
        }
      }
    },
    [ renderItem.call(this, h, tab) ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: `${components['tabs-header']}__items`,
      ref: 'items'
    },
    [
      this.tabs.map(tab => {
        if (this.isLink) {
          return renderLink.call(this, h, tab)
        }

        return renderItem.call(this, h, tab)
      })
    ]
  )
}
function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['tabs-header']}__holder`,
      ref: 'holder',
      on: { wheel: this.onWheel }
    },
    [ renderItems.call(this, h) ]
  )
}

export default function(h) {
  if (Array.isArray(this.tabs) && this.tabs.length) {
    return h(
      'div',
      {
        class: {
          [`${components['tabs-header']}`]: true,
          [`${components['tabs-header']}--arrows`]: this.hasArrows
        }
      },
      [
        renderArrow.call(this, h, 'prev'),
        renderHolder.call(this, h),
        renderArrow.call(this, h, 'next')
      ]
    )
  }
}
