import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Dialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    $_data() {
      const result = {}
      if (this.data) {
        for (const key in this.data) {
          if (key === 'text') {
            result.notification = this.data[key]
          } else {
            result[key] = this.data[key]
          }
        }
      }

      return result
    }
  },

  render
}
